import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ViewOnlyAccessService {
  private readonly viewOnlyAccessSubject = new BehaviorSubject<boolean>(true);

  constructor() {}

  get viewOnlyAccessValue(): boolean {
    return this.viewOnlyAccessSubject.value;
  }

  set viewOnlyAccessValue(value: boolean) {
    this.viewOnlyAccessSubject.next(value);
  }

  saveValueToStorage(value: boolean) {
    this.viewOnlyAccessValue = value;
    localStorage.setItem('VIEW_ONLY_ACCESS', value ? 'TRUE' : 'FALSE');
  }

  loadValueFromStorage() {
    const value = localStorage.getItem('VIEW_ONLY_ACCESS');
    this.viewOnlyAccessValue = !!value && value === 'TRUE';
  }
}
