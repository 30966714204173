import { init, routingInstrumentation } from '@sentry/angular';
import { BrowserOptions } from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

import { environment } from '@env/environment';

import { SentryIssueScope } from './sentry-issue-scope';

export function intiSentry(): void {
  init(<BrowserOptions>{
    /** @link https://docs.sentry.io/product/sentry-basics/dsn-explainer/#dsn-utilization */
    dsn: environment.sentry.dsn,
    /** @link https://docs.sentry.io/platforms/javascript/guides/angular/configuration/options/#environment */
    environment: environment.name,
    /** @link https://docs.sentry.io/platforms/javascript/guides/angular/configuration/options/#tracing-options */
    tracesSampleRate: 0.8,
    /** @link https://docs.sentry.io/platforms/javascript/guides/angular/configuration/integrations/ */
    maxBreadcrumbs: 5,
    beforeSend: (event) => {
      if (!event.message) {
        return null;
      }

      if (!Object.values(SentryIssueScope).includes(event.message as any)) {
        event.extra = { message: event.message };
        event.message = SentryIssueScope.ClientSideError;
        delete event.exception;
      }

      return event;
    },
    integrations: [
      new BrowserTracing({
        idleTimeout: 10000,
        tracePropagationTargets: [
          'localhost',
          environment.paymentGraphQLUrl,
          environment.actionLogGraphQlUrl,
          environment.documentGraphQLUrl,
          environment.notificationGraphQLUrl,
          environment.notificationWSGraphQLUrl,
          environment.kycGraphQLUrl,
          environment.socketUrl,
          environment.serverUrl,
          environment.SSOUrl,
          environment.userDocumentsUrl,
          environment.currenciesUrl,
        ],
        routingInstrumentation,
      }),
    ],
  });
}
