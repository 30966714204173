import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from '@env/environment';

import { intiSentry } from '@app/core/sentry';
import { AppModule } from '@app/app.module';

if (environment.production) {
  enableProdMode();
}

if (environment.sentry.enabled) {
  intiSentry();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
