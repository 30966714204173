import { AbstractControl, AsyncValidatorFn, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

import { BehaviorSubject, Observable, of, skip, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, take, tap } from 'rxjs/operators';

import { TuiDay } from '@taiga-ui/cdk';

import { isNil } from '@app/core/services/helpers.service';

import { RepresentativeService } from '@app/modules/companies/services/representative.service';

export function exactLengthValidator(_length: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const length: number = control.value ? `${control.value}`.length : 0;
    return length !== _length ? { exactLength: { requiredLength: _length, actualLength: length } } : null;
  };
}

export function dateLessThan(from: string, to: string) {
  return (group: FormGroup): any => {
    let f = group?.controls[from]?.value as TuiDay;
    let t = group?.controls[to]?.value as TuiDay;
    if (f && t && f.dayAfter(t)) {
      return {
        dates: `Start Date cannot be after End Date`,
      };
    }
    return;
  };
}

export function minLessThanMaxValidatorGroup(fieldNameMin: string, fieldNameMax: string) {
  return (form: FormGroup) => {
    const minValue = form.get(fieldNameMin)?.value;
    const maxValue = form.get(fieldNameMax)?.value;

    if (minValue > maxValue) {
      form.get(fieldNameMax)?.setErrors({ minMoreThanMax: true });
    } else {
      form.get(fieldNameMax)?.setErrors(null);
    }

    return !isNil(minValue) && !isNil(maxValue) && +maxValue < +minValue
      ? { minValue: `${fieldNameMin} is greater than ${fieldNameMax}` }
      : null;
  };
}

export function maxLengthValidatorMessage(context: { requiredLength: string }): string {
  return `Maximum length — ${context.requiredLength}`;
}

export function minLengthValidatorMessage(context: { requiredLength: string }): string {
  return `Minimum length — ${context.requiredLength}`;
}

export function exactLengthValidatorMessage(context: { requiredLength: string; actualLength: string }): string {
  if (context.requiredLength > context.actualLength) {
    return `Minimum length — ${context.actualLength}/${context.requiredLength}`;
  }
  return `Maximum length — ${context.actualLength}/${context.requiredLength}`;
}

export function requiredValidatorMessage(): string {
  return 'Value is required';
}

// TODO: destroy subscription inside function
export function emailExistsValidator(
  representativeService: RepresentativeService,
  initialEmail: string
  // destroyNotifier: Observable<void>
): AsyncValidatorFn {
  const checkEmailSubject = new Subject<string>();
  const emailExistsSubject = new BehaviorSubject<null | { emailExists: boolean }>(null);

  checkEmailSubject
    .asObservable()
    .pipe(
      debounceTime(250),
      distinctUntilChanged(),
      switchMap((email) =>
        representativeService.checkEmailExists(email).pipe(
          map((isBusy) => (isBusy ? { emailExists: true } : null)),
          catchError((error) => of(null)),
          tap((res) => {
            emailExistsSubject.next(res);
          })
        )
      )
    )
    .subscribe();

  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    if (!control.value || control.value === initialEmail) {
      return of(null);
    }
    checkEmailSubject.next(control.value);
    return emailExistsSubject.asObservable().pipe(skip(1), take(1));
  };
}

export function emailExistsValidatorMessage(): string {
  return 'Such email already is used';
}

export const EMAIL_REGEXP = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".\+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
