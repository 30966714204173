import { FactoryProvider, NgModule } from '@angular/core';

import { APOLLO_NAMED_OPTIONS, ApolloModule } from 'apollo-angular';

import { GraphqlService } from './graphql.service';

const APOLLO_NAMED_OPTIONS_PROVIDER: FactoryProvider = {
  provide: APOLLO_NAMED_OPTIONS,
  useFactory: (graphqlService: GraphqlService) => graphqlService.getNamedClientOptions(),
  deps: [GraphqlService],
};

@NgModule({
  imports: [ApolloModule],
  providers: [APOLLO_NAMED_OPTIONS_PROVIDER],
})
export class GraphqlModule {}
