import { Component, OnInit } from '@angular/core';

import { environment } from '@env/environment';

@Component({
  selector: 'fin-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public env = environment;

  constructor() {}

  ngOnInit(): void {}

  get currentYear() {
    return new Date().getFullYear();
  }
}
