<div class="content">
  <form class="login-form background" [formGroup]="form" [attr.data-cy]="QAKeys.LoginForm">
    <h3 class="title tui-space_top-5" [attr.data-cy]="QAKeys.LoginTitleH3">{{ env?.platform?.title }}</h3>
    <p [attr.data-cy]="QAKeys.LoginInputEmailP">
      <tui-input
        tuiTextfieldSize="m"
        formControlName="email"
        [tuiTextfieldCleaner]="true"
        [attr.data-cy]="QAKeys.LoginInputEmailTuiInput"
      >
        Type an email&nbsp;<span class="tui-required"></span>
        <input tuiTextfield placeholder="your-email@your-domain.com" />
      </tui-input>
    </p>
    <p [attr.data-cy]="QAKeys.LoginInputPasswordP">
      <tui-input-password
        class="tui-space_top-3"
        tuiTextfieldSize="m"
        formControlName="password"
        [attr.data-cy]="QAKeys.LoginInputPasswordTuiInputPassword"
      >
        Type a password
      </tui-input-password>
    </p>
    <div class="tui-row tui-space_bottom-5 tui-space_top-5">
      <div class="tui-col_3"></div>
      <button
        tuiButton
        class="tui-form__button tui-col_6"
        size="m"
        type="submit"
        (click)="login()"
        [attr.data-cy]="QAKeys.LoginButton"
      >
        LOGIN
      </button>
      <div class="tui-col_3"></div>
    </div>
  </form>
</div>
