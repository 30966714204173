export const PaymentServiceErrors = {
  InvalidPayload: { code: 0, message: 'invalid_payload', text: 'Invalid payload.' },
  InvalidAuthData: { code: 1, message: 'invalid_auth_data', text: 'Invalid auth data.' },
  InvalidReceiverBeneficiaryId: {
    code: 2,
    message: 'invalid_receiver_beneficiary_id',
    text: 'Invalid receiver beneficiary id.',
  },
  InvalidAmount: { code: 3, message: 'invalid_amount', text: 'Invalid invalid_amount.' },
  InvalidTradeId: { code: 4, message: 'invalid_trade_id', text: 'Invalid trade id.' },
  Required: { code: 5, message: 'required', text: 'Missing one or more required fields.' },
  InvalidBeneficiaryInfo: {
    code: 6,
    message: 'invalid_beneficiary_info',
    text: 'Invalid beneficiary info.',
  },
  InsufficientFunds: { code: 7, message: 'insufficient_funds', text: 'Insufficient funds.' },
  InvalidTransactionType: {
    code: 8,
    message: 'invalid_transaction_type',
    text: 'Invalid transaction type.',
  },
  InvalidProviderUserId: { code: 9, message: 'invalid_provider_user_id', text: 'Invalid user id.' },
  NoBeneficiaryForCurrency: { code: 10, message: 'no_beneficiary_for_currency', text: 'Currency not supported.' }, // TODO: Check business logic
  ErrorCreatingBeneficiary: {
    code: 11,
    message: 'error_creating_beneficiary',
    text: 'Unable to create beneficiary please try again later.',
  },
  InvalidIban: { code: 12, message: 'invalid_iban', text: 'Invalid IBAN. Check IBAN and try again please.' },
  InvalidSortCode: { code: 13, message: 'invalid_sort_code', text: 'Invalid Sort Code.' },
  InvalidSwiftCode: { code: 14, message: 'invalid_swift_code', text: 'Invalid SWIFT Code.' },
  UserNotFound: { code: 15, message: 'user_not_found', text: 'User not found.' },
  BeneficiaryNotFound: { code: 16, message: 'beneficiary_not_found', text: 'Account not found.' },
  // TODO: Remove it if it is not necessary
  ValidationDidNotStartYet: {
    code: 17,
    message: 'validation_did_not_start_yet',
    text: 'Invalid validation code .', // TODO: Check business logic
  },
  WrongReferenceCode: { code: 18, message: 'wrong_reference_code', text: 'Invalid reference code. Please try again.' },
  TooManyRequests: {
    code: 19,
    message: 'too_many_requests',
    text: 'Too many requests. Please try again later.',
  },
  Unauthorized: { code: 20, message: 'unauthorized', text: 'Unauthorized request. (Token expired)' },
  WalletNotFound: { code: 21, message: 'wallet_not_found', text: 'Wallet not found.' },
  NotEnoughMoneyOnWallet: {
    code: 22,
    message: 'not_enough_money_on_wallet',
    text: 'Insufficient funds in selected wallet.',
  },
  AmountMustBeAPositiveNumber: {
    code: 23,
    message: 'amount_must_be_a_positive_number',
    text: 'Amount must be a positive number.',
  },
  WalletsMustExist: { code: 24, message: 'wallets_must_exist', text: 'Wallets must exist.' },
  BeneficiaryAlreadyExistAndValid: {
    code: 25,
    message: 'beneficiary_already_exist_and_valid',
    text: 'Bank account already added.',
  },
  // TODO: SEND ERROR TEXT FOR REVIEW
  CompanyUuidRequired: { code: 26, message: 'company_uuid_required', text: 'Company UUID is required.' },
  UserProviderClientIdEmpty: {
    code: 27,
    message: 'user_provider_client_id_empty',
    text: `User's client_id is empty.`,
  },
  BankBeneficiaryAlreadyExist: { code: 28, message: 'bank_beneficiary_already_exist', text: `Account already exists.` },
  UserDoesNotHaveBeneficiaryInProviderWithSuchCurrency: {
    code: 29,
    message: 'user_does_not_have_beneficiary_in_provider_with_such_currency',
    text: `User does not have an Account with the given currency.`,
  },
  NotBankingDay: { code: 30, message: 'not_banking_day', text: `Not banking day.` },
  ProviderClientIdNotSet: { code: 31, message: 'provider_client_id_not_set', text: 'User client id not set.' },
  ErrorCreatingTrade: { code: 32, message: 'error_creating_trade', text: 'Error creating trade.' },
  SellCurrencyNotSupported: { code: 33, message: 'sell_currency_not_supported', text: 'Sell currency not supported.' },
  BuyCurrencyNotSupported: { code: 34, message: 'buy_currency_not_supported', text: 'Buy currency not supported.' },
  InternalError: { code: 35, message: 'internal_error', text: 'Internal Error.' },
  CanNotGetRate: { code: 36, message: 'can_not_get_rate', text: 'Unable to get the rate.' },
  InvalidSettleFundsDay: { code: 37, message: 'invalid_settle_funds_day', text: 'Invalid trade settlement date.' },
  NotEnoughAvailableMoneyOnWallet: {
    code: 38,
    message: 'not_enough_available_money_on_wallet',
    text: 'Insufficient funds in wallet.',
  },
  NotEnoughMoneyOnProviderWallet: {
    code: 39,
    message: 'not_enough_money_on_provider_wallet',
    text: 'Insufficient funds in User wallet.',
  },
  ProviderBeneficiaryNotFound: { code: 40, message: 'provider_beneficiary_not_found', text: '' },
  TooManyRequestsToProvider: {
    code: 41,
    message: 'too_many_requests_to_provider',
    text: 'Too many ongoing API requests to payment provider. Please try again later.',
  },
  ErrorGettingProviderBalance: { code: 42, message: 'error_getting_provider_balance', text: '' },
  ProviderServerIssue: {
    code: 43,
    message: 'provider_server_issue',
    text: 'Provider trading platform not available at the moment. Please try again later.',
  },
  ProviderServerBadGateWay: {
    code: 44,
    message: 'provider_server_bad_gate_way',
    text: 'Provider trading platform not available at the moment. Please try again later.',
  },
  BeneficiaryAlreadyExistNeedValidation: {
    code: 45,
    message: 'beneficiary_already_exist_need_validation',
    text: 'Bank account already added and awaiting verification.',
  },
  // TODO: SEND ERROR TEXT FOR REVIEW
  CanNotCancelBeneficiary: { code: 46, message: 'can_not_cancel_beneficiary', text: `Can not cancel Beneficiary` },
  ErrorParsingToken: { code: 47, message: 'error_parsing_token', text: `error parsing token` },
  InvalidLiquidityProvider: { code: 48, message: 'invalid_liquidity_provider', text: `invalid_liquidity_provider` },
  CurrencyNotFoundInProviderMetadata: {
    code: 49,
    message: 'currency_not_found_in_provider_metadata',
    text: `Currency not found in Provider metadata`,
  },
  // TODO: SEND ERROR TEXT FOR REVIEW
  NoCutOffFoCurrency: { code: 50, message: 'no_cut_off_fo_currency', text: `no_cut_off_fo_currency` },
  BankCountryAndIbanCountyMissMatch: {
    code: 51,
    message: 'bank_country_and_iban_county_miss_match',
    text: `Bank account and IBAN country do not match`,
  },
  CantUpdateOldDefaultBeneficiary: {
    code: 52,
    message: 'cant_update_old_default_beneficiary',
    text: `Can't update old default account`,
  },
  CantUpdateNewDefaultBeneficiary: {
    code: 53,
    message: 'cant_update_new_default_beneficiary',
    text: `Can't update new default account`,
  },
  // TODO: SEND ERROR TEXT FOR REVIEW
  ProviderServerBadGateWayNeedRecreateQuoteWithTrade: {
    code: 54,
    message: 'provider_server_bad_gate_way_need_recreate_quote_with_trade',
    text: '',
  },
  TransactionQueued: {
    code: 55,
    message: 'transaction_queued',
    text: ({ execution_date }: any) =>
      `Unfortunately, today is not a banking day, so your funds will be withdrawn from the system ${
        execution_date || '????-??-??'
      }`,
  },
  BalanceNotFound: {
    code: 56,
    message: 'balance_not_found',
    text: 'Balance not found',
  },
  CanNotUpdateBeneficiary: {
    code: 57,
    message: 'can_not_update_beneficiary',
    text: 'Unfortunately we can not edit this account. Please contact us to solve this issue',
  },
  NeedUserOnboarding: {
    code: 58,
    message: 'need_user_onboarding',
    text: 'You do not have Provider account linked to a Finverity platform, please register you company in Provider first',
  },
  UserIsNotFullyRegistered: { code: 59, message: 'user_is_not_fully_registered' },
  TooManyAttemptsToGenerateReferenceCodes: { code: 60, message: 'too_many_attempts_to_generate_reference_codes' },
  CheckoutError: {
    code: 56,
    message: 'checkout_error',
    text: 'checkout_error',
  },
  TypeIsRequired: {
    code: 57,
    message: 'type_is_required',
    text: 'type_is_required',
  },
  WalletBeneficiaryCurrenciesMustBeTheSame: {
    code: 58,
    message: 'wallet_beneficiary_currencies_must_be_the_same',
    text: 'wallet_beneficiary_currencies_must_be_the_same',
  },
  FundManagerWalletNotFound: {
    code: 59,
    message: 'fund_manager_wallet_not_found',
    text: 'fund_manager_wallet_not_found',
  },
  RouteRestrictedWithout2FA: {
    code: 60,
    message: 'route_restricted_without_2fa',
    text: 'route_restricted_without_2fa',
  },
  RepaymentError: { code: 61, message: 'repayment_error', text: 'repayment_error' },
  AccountPayloadDoesNotMatchWithCurrencyValidationRules: {
    code: 62,
    message: 'account_payload_does_not_match_with_currency_validation_rules',
    text: 'Account payload does not match with currency validation rules',
  },
  ErrorGettingBeneficiaries: {
    code: 63,
    message: 'error_getting_beneficiaries',
    text: 'Error getting beneficiaries',
  },
  ErrorGettingReconciliationBeneficiaries: {
    code: 64,
    message: 'error_getting_reconciliation_beneficiaries',
    text: 'error_getting_reconciliation_beneficiaries',
  },
  IsUsedInternallyForPayout: {
    code: 65,
    message: 'is_used_internally_for_payout',
    text: ({ strategy_names }: any) =>
      strategy_names && strategy_names.length > 1
        ? `The account you are trying to delete is used in the strategies: ${strategy_names.join(', ')}`
        : `The account you are trying to delete is used in the strategy: ${
            (strategy_names && strategy_names[0]) || '????'
          }`,
  },
  CompanyNotFound: {
    code: 66,
    message: 'company_not_found',
    text: 'Company not found',
  },
  CanNotDeleteDefaultBeneficiary: {
    code: 67,
    message: 'can_not_delete_default_beneficiary',
    text: 'The account you are trying to delete is used as default',
  },
  CanNotDeleteSystemBeneficiary: {
    code: 68,
    message: 'can_not_delete_system_beneficiary',
    text: 'The account you are trying to delete is used by the system',
  },
  IsUsedInOrderFormsWithFundingPendingInvoices: {
    code: 69,
    message: 'is_used_in_order_forms_with_funding_pending_invoices',
    text: ({ invoice_numbers }: any) =>
      invoice_numbers && invoice_numbers.length > 1
        ? `We cannot delete this account because it is used in order forms connected to invoices: ${invoice_numbers.join(
            ', '
          )}`
        : `We cannot delete this account because it is used in order forms connected to invoice: ${
            (invoice_numbers && invoice_numbers[0]) || '????'
          }`,
  },
  // finverity_shared/blob/dev/src/errors/errors.ts
  // TODO: Send ERROR TEXTS FOR REVIEW
  BankNameShouldNotBeEmpty: {
    code: 1,
    message: 'bank_name_should_not_be_empty',
    text: 'Bank name should not be empty',
  },
  BankNameExceededMaxLength: {
    code: 2,
    message: 'bank_name_exceeded_max_length',
    text: 'Bank name exceeded max length',
  },
  BankAddressShouldNotBeEmpty: {
    code: 3,
    message: 'bank_address_should_not_be_empty',
    text: 'Bank address should not be empty',
  },
  BankAddressExceededMaxLength: {
    code: 4,
    message: 'bank_address_exceeded_max_length',
    text: 'Bank address exceeded max length',
  },
  AbaCodeShouldNotBeEmpty: {
    code: 5,
    message: 'aba_code_should_not_be_empty',
    text: 'Routing number should not be empty',
  },
  AbaCodeExceededMaxLength: {
    code: 6,
    message: 'aba_code_exceeded_max_length',
    text: 'Routing number exceeded max length',
  },
  IbanCodeIsNotValid: {
    code: 7,
    message: 'iban_code_is_not_valid',
    text: 'IBAN code is not valid',
  },
  SwiftCodeIsNotValid: {
    code: 8,
    message: 'swift_code_is_not_valid',
    text: 'SWIFT code is not valid',
  },
  AccountNumberOrSortCodeNotValid: {
    code: 9,
    message: 'account_number_or_sort_code_not_valid',
    text: 'Account number or sort code is not valid',
  },
  AccountNumberIsRequired: {
    code: 10,
    message: 'account_number_is_required',
    text: 'Account number is required',
  },
  SortCodeIsRequired: {
    code: 11,
    message: 'sort_code_is_required',
    text: 'Sort code is required',
  },
  CurrencyValidationRuleNotSupported: {
    code: 12,
    message: "Not supported currency's validation rule",
    text: "Not supported currency's validation rule",
  },
  CurrencyValidationNotPassed: {
    code: 13,
    message: 'currency_validation_not_passed',
    text: 'Currency validation not passed',
  },
  // TODO: Remove it if it is not necessary
  CurrencyNotSupported: {
    code: 3,
    message: 'Currency not supported',
    text: 'Currency not supported',
  },
  BeneficiaryAddressShouldNotBeEmpty: {
    code: 15,
    message: 'beneficiary_address_should_not_be_empty',
    text: 'Registered address should not be empty',
  },
  BeneficiaryAddressExceededMaxLength: {
    code: 16,
    message: 'beneficiary_address_exceeded_max_length',
    text: 'Registered address exceeded max length',
  },
  ErrorGettingMSKSettings: {
    code: 16,
    message: 'error_getting_msk_settings',
    text: 'Registered address exceeded max length',
  },
  ErrorConfiguringCurrencies: {
    code: 16,
    message: 'error_configuring_currencies',
    text: 'Registered address exceeded max length',
  },
  AccountNumberMustBeNumber: {
    code: 10,
    message: 'account_number_must_be_number',
    text: 'Account number must be number',
  },
  SortCodeNumberMustBeNumber: {
    code: 10,
    message: 'sort_code_number_must_be_number',
    text: 'Sort code must be number',
  },
  AccountNumberMustBeLess: {
    code: 10,
    message: 'account_number_must_be_less',
    text: 'Account number must be less than 20 digits',
  },
  DefaultCurrencyConfigNotFound: {
    code: 10,
    message: 'default_currency_config_not_found',
    text: 'Default currency config not found',
  },
  SortCodeMustBeLessOrMore: {
    code: 10,
    message: 'sort_code_must_be_less_or_more',
    text: 'Sort code must be less than or greater',
  },
};

export const DocumentServiceErrors = {
  InvalidPayload: { code: 0, message: 'invalid_payload', text: 'Invalid payload.' },
  InvalidAuthData: { code: 1, message: 'invalid_auth_data', text: 'Invalid auth data.' },
  Required: { code: 2, message: 'required', text: 'Missing one or more required fields.' },
  InvalidUserId: { code: 3, message: 'invalid_user_id', text: 'Invalid user id.' },
  UserNotFound: { code: 4, message: 'user_not_found', text: 'User not found.' },
  // TODO: Remove it if it is not necessary
  ValidationDidNotStartYet: {
    code: 5,
    message: 'validation_did_not_start_yet',
    text: ' ', // TODO: Check business logic.
  },
  WrongReferenceCode: { code: 6, message: 'wrong_reference_code', text: 'Invalid reference code.' },
  TooManyRequests: { code: 7, message: 'too_many_requests', text: 'Too many requests. Please try again later.' },
  Unauthorized: { code: 8, message: 'unauthorized', text: 'Unauthorized request. (Token expired)' },
  CompanyUuidRequired: { code: 9, message: 'company_uuid_required', text: 'Company uuid required.' },
  InternalError: { code: 10, message: 'internal_error', text: 'Internal Error.' },
  DocumentsNotFound: { code: 11, message: 'documents_not_found', text: `Document not found.` },
  DocumentsTypesRequired: { code: 12, message: 'documents_types_required', text: `Document type required.` },
  FileFormatIsNotSupported: { code: 13, message: 'file_format_is_not_supported', text: `File format is not supported` },
  ErrorCreationDocument: { code: 14, message: 'error_creation_document', text: `Error creating document` },
  PermissionDenied: { code: 15, message: 'permission_denied', text: `Permission denied` },
  TemplateNameAbsent: { code: 16, message: 'template_name_absent', text: `Template name absent` },
  InvalidTemplateContent: { code: 17, message: 'invalid_template_content', text: `Invalid template content` },
  TooManyNumberOfColumns: {
    code: 18,
    message: 'too_many_number_of_columns',
    text: `Too many columns in file`,
  },
  TooManyFiles: { code: 19, message: 'too_many_files', text: `Too many files` },
  FileIsTooBig: { code: 20, message: 'file_is_too_big', text: `File size is too big` },
  HiddenIssueTeamNotified: { code: 21, message: 'hidden_issue_team_notified', text: `` },
  WrongMimeType: { code: 22, message: 'wrong_mime_type', text: `Wrong MIME type` },
  UserIsNotFullyRegistered: { code: 23, message: 'user_is_not_fully_registered', text: `User is not fully registered` },
  Forbidden: { code: 24, message: 'forbidden', text: `Documents access denied` },
  DocumentWithFileNameAlreadyExists: {
    code: 25,
    message: 'document_with_file_name_already_exists',
    text: 'Document with file name already exists',
  },
  PdfIsNotAllowedForSignature: {
    code: 26,
    message: 'pdf_is_not_allowed_for_signature',
    text: 'PFD is not allowed for signature. Only images are allowed.',
  },
  TooManyAttachments: {
    code: 27,
    message: 'too_many_attachments',
    text: 'You have uploaded too many attachments. Please delete some of them and try again.',
  },
};

export const KYCServiceErrors = {
  // TODO: SEND ERRORS TEXTS FOR REVIEW
  // company validation
  CompanyNotFound: {
    message: 'company_not_found',
    text: 'Company not found',
  },
  CompanyTypeMismatch: {
    message: 'company_type_mis_match',
    text: 'Company type mismatch',
  },
  CompanyNameExist: {
    message: 'company_name_exists',
    text: 'Company name exists',
  },
  CompanyTaxIdExists: {
    message: 'company_tax_id_exists',
    text: 'Company tax id exists',
  },
  CompanyRegNoExists: {
    message: 'company_reg_no_exists',
    text: 'Registration No is busy',
  },
  CompanyListOfDirectorsEmpty: {
    message: 'company_list_of_directors_empty',
    text: 'Company list of directors is empty',
  },
  CompanyListOfPosEmpty: {
    message: 'company_list_of_pos_empty',
    text: 'Company list of pos is empty',
  },
  TradingAddressDoesNotExists: {
    message: 'trading_address_does_not_exists',
    text: 'Trading address does not exists',
  },
  RegisteredAddressDoesNotExists: {
    message: 'registered_address_does_not_exists',
    text: 'Registered address does not exists',
  },
  ParentCompanyNotExists: {
    message: 'parent_company_not_exists',
    text: 'Parent company does not exists',
  },
  NameBusy: {
    message: 'name_busy',
    text: 'Name is busy',
  },
  RegNoBusy: {
    message: 'reg_no_busy',
    text: 'Reg No is busy',
  },
  TaxIdBusy: {
    message: 'tax_id_busy',
    text: 'Tax id is busy',
  },
  // document validation
  DocumentNotFound: {
    message: 'document_not_found',
    text: 'Document has not been found',
  },
  DocumentTypeMismatch: {
    message: 'document_type_mis_match',
    text: 'Document type mismatch',
  },
  DocumentUuidRequiredSomeAreMissing: {
    message: 'document_uuid_required_some_are_missing',
    text: 'Document uuid required, but some are missing',
  },
  DocumentUuidMustByUnique: {
    message: 'document_uuid_must_by_unique',
    text: 'Document uuid must be unique',
  },
  DocumentAlreadyStored: {
    message: 'document_already_stored',
    text: 'Document has already been stored',
  },
  DocumentWithSuchProofExists: {
    message: 'document_with_such_proof_exists',
    text: 'Document with such proof exists',
  },
  CompanyUuidRepeated: {
    message: 'company_uuid_repeated',
    text: 'Company uuid has been repeated',
  },
};

export const ActionLogServiceErrors = {
  CompanyNotFound: {
    message: 'company_not_found',
    text: 'Company not found',
  },
};
