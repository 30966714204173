import { Injectable } from '@angular/core';

import { BehaviorSubject, EMPTY, Observable, of, timer } from 'rxjs';
import { concatMap, distinctUntilChanged, finalize, switchMap, tap } from 'rxjs/operators';

import { SentryReporterService } from '@app/core/sentry';

const MAX_ALLOWED_SCREEN_LOADING_TIME = 30000;

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  loadingStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadingStatus$: Observable<boolean> = this.loadingStatus.asObservable();
  public message = 'Loading';

  constructor(private sentryReporterService: SentryReporterService) {
    this.loadingStatus$
      .pipe(
        distinctUntilChanged(),
        switchMap((loading) =>
          loading
            ? timer(MAX_ALLOWED_SCREEN_LOADING_TIME).pipe(
                tap(() => {
                  this.sentryReporterService.reportLongScreenLoading();
                })
              )
            : EMPTY
        )
      )
      .subscribe();
  }

  public get isLoading(): boolean {
    return this.loadingStatus.value;
  }

  startLoading(message: string = ''): void {
    console.log('TCL: LoadingScreenService -> startLoading message', message);
    this.loadingStatus.next(true);
    this.message = message;
  }

  stopLoading(): void {
    console.log('TCL: LoadingScreenService -> stopLoading message', this.message);
    this.loadingStatus.next(false);
    this.message = '';
  }

  showLoaderUntilCompleted<T>(obs$: Observable<T>, message: string = ''): Observable<T> {
    return of(null).pipe(
      tap(() => this.startLoading(message)),
      concatMap(() => obs$),
      finalize(() => this.stopLoading())
    );
  }
}
