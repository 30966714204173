import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Subject } from 'rxjs';
import { groupBy, mergeMap, throttleTime } from 'rxjs/operators';

import { SentryReporterService } from './sentry-reporter.service';

export const SENTRY_ERRORS_THROTTLE_TIME = 1000;

@Injectable({
  providedIn: 'root',
})
export class SentryErrorHandlerService extends ErrorHandler {
  private readonly errorsSubject = new Subject<any>();

  constructor(private sentryReporter: SentryReporterService) {
    super();

    this.errorsSubject
      .pipe(
        groupBy((error) => error.toString()),
        mergeMap((group) => group.pipe(throttleTime(SENTRY_ERRORS_THROTTLE_TIME)))
      )
      .subscribe((error) => {
        if (this.isChunkLoadError(error)) {
          this.sentryReporter.reportChunkLoadError(error);
        } else if (this.isHttpError(error)) {
          this.sentryReporter.reportHttpError(error);
        } else {
          this.sentryReporter.reportClientSideError(error);
        }
      });
  }

  // Implemented as part of ErrorHandler
  handleError(error: any): void {
    if (this.isChunkLoadError(error)) {
      this.sentryReporter.reportChunkLoadError(error);
    } else if (this.isHttpError(error)) {
      this.sentryReporter.reportHttpError(error);
    } else {
      this.sentryReporter.reportClientSideError(error);
    }

    // TODO: log only for development environment
    super.handleError(error);
  }

  private isHttpError(error: Error): error is HttpErrorResponse {
    return error instanceof HttpErrorResponse;
  }

  private isChunkLoadError(error: Error): boolean {
    const chunkFailedMessage = /Loading chunk \S+ failed/i;

    return chunkFailedMessage.test(error.message);
  }
}
