import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { environment } from '@env/environment';

import { AuthenticationService } from '@app/core/services';
import { UsersnapService } from '@app/core/usersnap';

@Component({
  selector: 'fin-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public env = environment;

  constructor(
    public router: Router,
    private usersnapService: UsersnapService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.usersnapService
      .initialize({
        user: this.authenticationService.user
          ? {
              userId: this.authenticationService.user.company_uuid,
              email: this.authenticationService.user.email,
            }
          : undefined,
        fields: {
          customData: {
            defaultValue: JSON.stringify({
              company_name: this.authenticationService.user?.company_type ?? undefined,
            }),
          },
        },
      })
      .then((usersnapApi) => {
        if (environment?.userSnap?.enabled) {
          usersnapApi.show(environment?.userSnap?.ProjectAPIKey);
        }
      });
  }

  logout(): void {
    let sub: Subscription;
    sub = this.authenticationService
      .logout$()
      .pipe(
        tap(() => this.router.navigate(['login'], { replaceUrl: true })),
        finalize(() => sub.unsubscribe())
      )
      .subscribe();
  }
}
