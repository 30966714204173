import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { environment } from '@env/environment';

import { AuthenticationService } from '@app/core/services';
import { UsersnapService } from '@app/core/usersnap';
import { EMAIL_REGEXP } from '@app/shared/company-form/utils/validators';
import { CurrenciesService } from '@app/shared/services';

import { LOGIN_QA_KEYS } from './login.qa-keys';

@UntilDestroy()
@Component({
  selector: 'fin-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
  readonly env = environment;
  readonly QAKeys = LOGIN_QA_KEYS;
  form!: FormGroup;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private currenciesService: CurrenciesService,
    private usersnapService: UsersnapService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern(EMAIL_REGEXP)]],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });

    this.usersnapService.initialize().then((usersnapApi) => {
      if (environment?.userSnap?.enabled) {
        usersnapApi.show(environment?.userSnap?.ProjectAPIKey);
      }
    });
  }

  login(): void {
    if (!this.form.valid) {
      return;
    }
    this.authenticationService
      .login({
        username: this.form.value.email,
        password: this.form.value.password,
      })
      .pipe(
        untilDestroyed(this),
        tap((user) => {
          if (!user) {
            this.form.enable();
          } else {
            this.form.reset();
            this.form.markAsPristine();
            this.form.disable();
          }
          this.router.navigate(['companies'], { replaceUrl: true }).then();
        }),
        switchMap(() => {
          return this.currenciesService.getFXRates();
        }),
        switchMap(() => this.currenciesService.getFloatingRates()),
        // switchMap((user) => this.signService.setupUser(user)),
        // switchMap((user) => this.signService.setupPushToken()),
        catchError((err: any) => {
          console.log('TCL: formComponent -> login -> err', err);
          this.form.enable();
          // if (err && err.error && err.error.statusCode && err.error.statusCode === 401) {
          //   this.toastr.error('Wrong user credentials. Please check your email and password and try again.');
          // }
          return throwError(err);
        })
      )
      .subscribe();
  }
}
