/** !!! IMPORTANT !!! **/
/** !!! Make sure all values start with a slash (/) !!! **/

export enum RequestPath {
  CoverInvestmentStrategy = '/investment-strategies/cover',
  SellerPayerMetadata = '/seller-payer-metadata',
  Payers = '/payers',
  PayersById = '/payers/:id',
  PayersSellerRestricted = '/payers/seller-restricted',
  Funders = '/funders',
  InvestmentStrategies = '/investment-strategies',
  InvestmentStrategyById = '/investment-strategies/:investment_strategy',
  SellerPayments = '/seller-payments',
  FunderPortfolioValueData = '/funders/:funder_id/portfolio-value-data',
  FunderAllocationData = '/funders/:funder_id/allocation-data',
  FunderRepaymentData = '/funders/:funder_id/repayment-data',
  FunderPayerExposures = '/funders/:funder_id/payer-exposures',
  FunderTotals = '/funders/:funder_id/totals',
  Invoices = '/invoices',
  InvoiceById = '/invoices/:invoice_id',
  FundManagers = '/fund-managers',
  ManagedFunds = '/managed-funds',
  PayerPayments = '/payer-payments',
  PayerCashOutData = '/payers/:payer_id/cash-out-data',
  PayerHedgingData = '/payers/:payer_id/hedging-data',
  PayerTotals = '/payers/:payer_id/totals',
  SellerReceivedAndPendingData = '/sellers/:seller_id/received-and-pending-data',
  SellerTotals = '/sellers/:seller_id/totals',

  InvoiceWorkflowInvoicesAwaitingConfirmation = '/invoice-workflow/:fund_manager_id/invoices-awaiting-confirmation',
  InvoiceWorkflowInvoicesAwaitingConfirmationCheck = '/invoice-workflow/:fund_manager_id/invoices-awaiting-confirmation-check',
  InvoiceWorkflowInvoicesAwaitingRiskConfirmation = '/invoice-workflow/:fund_manager_id/invoices-awaiting-risk-confirmation',
  InvoiceWorkflowInvoicesAwaitingRiskConfirmationCheck = '/invoice-workflow/:fund_manager_id/invoices-awaiting-risk-confirmation-check',

  FunderById = '/funders/:funder_id',
  SellerById = '/sellers/:seller_id',
  Sellers = '/sellers',
  PayerPaymentsRepaymentReference = '/payer-payments/:repayment_reference',
  InvoicesBatch = '/invoices/batch',
  PayerPaymentsAttachManually = '/payer-payments/attach-manually',

  ManagedInvestmentStrategies = '/managed-investment-strategies',
  ManagedInvestmentStrategiesById = '/managed-investment-strategies/:id',
  RegenerateOrderForms = '/utils/regenerate-order-forms',
}
