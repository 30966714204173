import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

import { environment } from '@env/environment';

import { CurrencyTypes, IndicativeRates, RatesCurrencyEntity } from '@app/core/graphql/types/payment-types';
import { isArrayFull } from '@app/core/services/helpers.service';
import { CountryAlpha2Codes } from '@app/modules/companies/services/countries.service';

import { FloatingRate, RatesService } from './rates.service';

@Injectable({
  providedIn: 'root',
})
export class CurrenciesService {
  private readonly currenciesSubject: BehaviorSubject<string[] | null> = new BehaviorSubject<string[] | null>(null);
  private readonly defaultCurrencySubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  private readonly currencyListForSelectSubject: BehaviorSubject<{ value: string; label: string }[] | null> =
    new BehaviorSubject<{ value: string; label: string }[] | null>(null);
  private readonly currencySettingsSubject: BehaviorSubject<CurrencyConfiguration | null> =
    new BehaviorSubject<CurrencyConfiguration | null>(null);
  readonly currencies$: Observable<string[] | null> = this.currenciesSubject.asObservable();

  private ratesSubject: BehaviorSubject<any>;
  public rates$: Observable<any>;
  private readonly floatingRatesSubject: BehaviorSubject<FloatingRate[] | null> = new BehaviorSubject<
    FloatingRate[] | null
  >(null);

  constructor(
    public ratesService: RatesService,
    private http: HttpClient
  ) {
    this.ratesSubject = new BehaviorSubject<any>(null);
    this.rates$ = this.ratesSubject.asObservable();
    // TODO: @ashot use abstraction over localStorage and do try/catch
    const fxRates = localStorage.getItem('FX_RATES');
    try {
      const localRates = fxRates ? JSON.parse(fxRates) : null;
      if (!!localRates) {
        this.ratesSubject.next(localRates);
      }
    } catch (err) {}
    const floatingRates = localStorage.getItem('FLOATING_RATES');
    try {
      const localFloatingRates = floatingRates ? JSON.parse(floatingRates) : null;
      if (!!localFloatingRates) {
        this.floatingRatesSubject.next(localFloatingRates);
      }
    } catch (err) {}
  }

  get currencies(): string[] | null {
    return this.currenciesSubject.value;
  }

  public get floatingRates(): FloatingRate[] | null {
    return this.floatingRatesSubject.value;
  }

  public get rates(): any {
    return this.ratesSubject.value;
  }

  public get defaultCurrency(): string | null {
    return this.defaultCurrencySubject.value;
  }

  public get currencyListForSelect(): { value: string; label: string }[] | null {
    return this.currencyListForSelectSubject.value;
  }

  public get currencySettings(): CurrencyConfiguration | null {
    return this.currencySettingsSubject.value;
  }

  private setCurrencySettings(currencySettings: CurrencyConfiguration) {
    this.currencySettingsSubject.next(currencySettings);
  }

  private setCurrencyListForSelect(currencies: Currency[]) {
    const list = currencies.map((c: Currency) => ({ value: c.currency, label: c.currency }));
    this.currencyListForSelectSubject.next(list);
  }

  private setDefaultCurrency(currency: string) {
    this.defaultCurrencySubject.next(currency);
  }

  public getCurrencies() {
    return this.http.get<any>(environment.currenciesUrl).pipe(
      map((result: any) => {
        this.setCurrencySettings(result);
        this.setDefaultCurrency(result.default_currency);
        return result.currencies;
      }),
      tap((currencies: Currency[]) => {
        this.setCurrencies(currencies.map((c) => c.currency));
        this.setCurrencyListForSelect(currencies);
      }),
      shareReplay()
    );
  }

  private setCurrencies(currencies: string[]) {
    this.currenciesSubject.next(currencies);
  }

  private setFloatingRates(floatingRates: FloatingRate[]) {
    this.floatingRatesSubject.next(floatingRates);
  }

  public getFXRates() {
    return this.ratesService.getFXRates().pipe(
      tap((rates: IndicativeRates[]) => {
        this.ratesSubject.next(rates);
        // TODO: @ashot use abstraction over localStorage and do try/catch
        localStorage.setItem('FX_RATES', JSON.stringify(rates));
      })
    );
  }

  public getFloatingRates() {
    return this.ratesService.getFloatingRates().pipe(
      tap((rates: FloatingRate[]) => {
        this.setFloatingRates(rates);
        localStorage.setItem('FLOATING_RATES', JSON.stringify(rates));
      })
    );
  }

  public convertToCurrency(amount: number, currency_from: string, currency_to: string) {
    const currency = isArrayFull(this.rates) && this.rates.find((c: any) => c.currency === currency_from);
    const currencyRates: RatesCurrencyEntity[] = (!!currency && currency.rates) || [];
    const currencyRate = currencyRates.find((rate) => rate.currency === currency_to);
    const rateValue = (!!currencyRate && currencyRate.value) || 1;
    return Math.ceil(amount * rateValue);
  }
}

export interface CurrenciesResult {
  default_mandatory: string[];
  default_currency: string;
  currencies: Currency[];
}

export interface Currency {
  currency: string;
  html_representation: string;
  symbol: string;
}

export interface CurrencyConfiguration {
  default_mandatory?: CurrencyValidationRuleType[][];
  default_currency: CurrencyTypes;
  currencies: CurrencyRule[];
  payment_fee_currency: CurrencyTypes;
  default_payment_meta: PaymentMeta;
  default_validators?: CurrencyRuleValidators;
}

export enum CurrencyValidationRuleType {
  BankName = 'bank_name',
  BankAddress = 'bank_address',
  BeneficiaryName = 'beneficiary_name',
  BeneficiaryCountryCode = 'beneficiary_country_alpha2_code',
  BeneficiaryAddress = 'beneficiary_address',
  ABA = 'aba',
  IBAN = 'iban',
  SWIFT = 'swift',
  AccountNumberAndSortCode = 'account_number_and_sort_code',
  AccountNumber = 'account_number',
  SortCode = 'sort_code',
}

export interface CurrencyRule {
  currency: CurrencyTypes;
  html_representation: string;
  symbol: string;
  countries: CountryConfigForCurrency[];
  default_mandatory: CurrencyValidationRuleType[][];
  default_optional?: CurrencyValidationRuleType[];
  default_alternative?: any;
  default_validators?: CurrencyRuleValidators;
}

export interface CountryConfigForCurrency {
  country_code: CountryAlpha2Codes;
  payment_meta?: PaymentMeta;
  validation_rules_while_create?: CurrencyValidationRulesByCountry;
  validators?: CurrencyRuleValidators;
}

export interface CurrencyValidationRulesByCountry {
  mandatory: CurrencyValidationRuleType[][];
  optional?: CurrencyValidationRuleType[];
  alternative?: any;
}

export interface PaymentMeta {
  fee: number;
  term: number;
  payment_method: PaymentMethod;
}

export enum PaymentMethod {
  SWIFT = 'SWIFT',
  SEPA = 'SEPA',
  UKFasterPayment = 'UK Faster Payment',
}

export interface CurrencyRuleValidators {
  [CurrencyValidationRuleType.AccountNumber]?: CurrencyRuleValidatorDefinition[];
  [CurrencyValidationRuleType.SWIFT]?: CurrencyRuleValidatorDefinition[];
  [CurrencyValidationRuleType.SortCode]?: CurrencyRuleValidatorDefinition[];
  [CurrencyValidationRuleType.BankAddress]?: CurrencyRuleValidatorDefinition[];
  [CurrencyValidationRuleType.BeneficiaryName]?: CurrencyRuleValidatorDefinition[];
  [CurrencyValidationRuleType.BeneficiaryAddress]?: CurrencyRuleValidatorDefinition[];
  [CurrencyValidationRuleType.IBAN]?: CurrencyRuleValidatorDefinition[];
  [CurrencyValidationRuleType.BeneficiaryCountryCode]?: CurrencyRuleValidatorDefinition[];
  [CurrencyValidationRuleType.ABA]?: CurrencyRuleValidatorDefinition[];
  [CurrencyValidationRuleType.BankName]?: CurrencyRuleValidatorDefinition[];
  [CurrencyValidationRuleType.AccountNumberAndSortCode]?: CurrencyRuleValidatorDefinition[];
}

interface CurrencyRuleValidatorDefinition {
  name: CurrencyRuleValidator;
  params: { max?: number; min?: number };
}

export enum CurrencyRuleValidator {
  Length = 'length',
}
