import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { environment } from '@env/environment';

import { AuthenticationGuard, ViewOnlyAccessGuard } from '@app/core/guards';
import { EnvironmentName } from '@env/environment-types';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'companies',
    loadChildren: () => import('./modules/companies/companies.module').then((m) => m.CompaniesModule),
    data: { showHeader: true, showSidebar: true, showFooter: true },
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'wallets',
    loadChildren: () => import('./modules/wallets/wallets.module').then((m) => m.WalletsModule),
    data: { showHeader: true, showSidebar: true, showFooter: true },
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'bank-accounts',
    loadChildren: () => import('./modules/bank-accounts/bank-accounts.module').then((m) => m.BankAccountsModule),
    data: { showHeader: true, showSidebar: true, showFooter: true },
    canActivate: [AuthenticationGuard],
  },
  // {
  //   path: 'invoices',
  //   loadChildren: () => import('./modules/invoices/invoices.module').then((m) => m.InvoicesModule),
  //   data: { showHeader: true, showSidebar: true, showFooter: true },
  //   canActivate: [AuthenticationGuard],
  // },
  {
    path: 'payments',
    loadChildren: () => import('./modules/payments/payments.module').then((m) => m.PaymentsModule),
    data: { showHeader: true, showSidebar: true, showFooter: true },
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'email-templates',
    loadChildren: () => import('./modules/email-templates/email-templates.module').then((m) => m.EmailTemplatesModule),
    data: { showHeader: true, showSidebar: true, showFooter: true },
    canActivate: [AuthenticationGuard, ViewOnlyAccessGuard],
  },
  {
    path: 'checkout-process',
    loadChildren: () =>
      import('./modules/checkout-process/checkout-process.module').then((m) => m.CheckoutProcessModule),
    data: { showHeader: true, showSidebar: true, showFooter: true },
    canActivate: [AuthenticationGuard, ViewOnlyAccessGuard],
  },
  {
    path: 'admin-logs',
    loadChildren: () => import('./modules/admin-logs/admin-logs.module').then((m) => m.AdminLogsModule),
    data: { showHeader: true, showSidebar: true, showFooter: true },
    canActivate: [AuthenticationGuard, ViewOnlyAccessGuard],
  },
  // Component Stories
  ...((['dev', 'testing', 'staging'] as EnvironmentName[]).includes(environment.name)
    ? [
        {
          path: 'confirm-dialog-story',
          loadComponent: () =>
            import('./shared/components/confirm-dialog/confirm-dialog-story/confirm-dialog-story.component').then(
              (m) => m.ConfirmDialogStoryComponent
            ),
        },
      ]
    : []),
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      // enableTracing: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
