<!--<div class="loading-block">-->
<!--  <div class="megabox">-->
<!--    <div class="box" *ngFor="let c of cubes">-->
<!--      <div class="face" *ngFor="let c of cubeSides"></div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <span *ngIf="message" class="message">{{ message }}</span>-->
<!--</div>-->
<!-- LOADER -->
<div class="loading-block">
  <div class="mask"></div>
  <div class="loader">
    <svg width="100%" height="100%" viewBox="0 0 48 48">
      <polygon fill="#0069A6" points="24,35.5595703 24,47.2900391 4,35.5898438 4,35.5498047 14.0297852,29.7597656" />
      <polygon fill="#006199" points="44,35.5498047 44,35.5898438 24,47.2900391 24,35.5595703 33.9697266,29.7597656" />
      <polygon fill="#00598C" points="44,12.4501953 44,35.5498047 33.9697266,29.7597656 34,29.7402344 34,18.2299805" />
      <polygon points="44,12.3100586 44,12.4501953 34,18.2299805 34,18.1601563 24,12.3398438 24,0.6098633" />
      <polygon points="24,0.6098633 24,12.3398438 14,18.1601563 14,18.2299805 4,12.4501953 4,12.3100586" />
      <polygon points="14.0297852,29.7597656 4,35.5498047 4,12.4501953 14,18.2299805 14,29.7402344" />
    </svg>
  </div>
  <span *ngIf="message" class="message">{{ message }}</span>
</div>
<!-- /LOADER END -->
