<tui-tabs>
  <a tuiTab routerLink="/companies" routerLinkActive>Companies</a>
  <a tuiTab routerLink="/wallets" routerLinkActive>Wallets</a>
  <a tuiTab routerLink="/bank-accounts" routerLinkActive>Bank Accounts</a>
  <!-- <a tuiTab routerLink='/invoices' routerLinkActive>Invoices</a> -->
  <a tuiTab routerLink="/payments" routerLinkActive>Payments</a>
  <ng-container *finViewOnlyAccess>
    <a tuiTab routerLink="/email-templates" routerLinkActive>Email Templates</a>
    <a tuiTab routerLink="/checkout-process" routerLinkActive>Invoice Purchase</a>
    <a tuiTab routerLink="/admin-logs" routerLinkActive>Logs</a>
  </ng-container>
</tui-tabs>
