import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import jwtDecode from 'jwt-decode';

import { AUTH_API_KEY } from '@env/environment';

import { TokenStorageService, TokenDecoded } from './token-storage.service';
import { HelpersService } from './helpers.service';
import { ViewOnlyAccessService } from '@app/core/services/view-only-access.service';

/**
 * @param username - email
 * @param request_data - contains base64(SHA512(email + password + email))
 */
export interface LoginContext {
  username: string;
  request_data: string;
}

export interface UserDecodedToken {
  sub: string;
  company_uuid: string;
  company_type: string; // 'portal_admin';
  role: string; // 'portal_admin';
  role_uuid: string;
  timezone: string;
  iat: number;
  exp: number;
}

export interface UserProfile extends Pick<UserDecodedToken, 'company_uuid' | 'company_type' | 'role' | 'role_uuid'> {
  email: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private readonly tokenSubject = new BehaviorSubject<string | null>(null);
  readonly token$ = this.tokenSubject.asObservable();

  private readonly userSubject = new BehaviorSubject<UserProfile | null>(null);
  readonly user$ = this.userSubject.asObservable();

  private refreshTokenTimeoutId: any;

  constructor(
    private http: HttpClient,
    private tokenService: TokenStorageService,
    private viewOnlyAccessService: ViewOnlyAccessService
  ) {
    if (this.tokenService.getToken()) {
      this.tokenSubject.next(this.tokenService.getToken());

      const userProfile = this.getUserProfileFromLocalStorage();
      this.viewOnlyAccessService.loadValueFromStorage();

      if (userProfile) {
        this.userSubject.next(userProfile);
      }

      this.startRefreshTokenTimer();
    }
  }

  get user(): UserProfile | null {
    return this.userSubject.value;
  }

  get tokenValue(): string | null {
    return this.tokenSubject.value;
  }

  login(loginData: { username: string; password: string }, redirect?: string): Observable<any> {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    this.removeUserProfileFromLocalStorage();

    const payload: LoginContext = {
      username: loginData.username.trim().toLowerCase(),
      request_data: this.generateRequestData(loginData.username, loginData.password),
    };

    return this.http.post<any>(AUTH_API_KEY + 'auth/login', payload).pipe(
      tap((tokens: any) => {
        this.saveTokens(tokens);
        this.viewOnlyAccessService.saveValueToStorage(tokens?.view_only_access || false);
        this.saveUserProfileToLocalStorage(this.tokenValue as string, loginData.username);
      }),
      catchError((error) => {
        console.log(
          '🚀 ~ file: new-authentication.service.ts ~ line 76 ~ NewAuthenticationService ~ login ~ error',
          error
        );
        return throwError(error);
      })
    );
  }

  getProfile(): Observable<any> {
    return this.http.get<any>(AUTH_API_KEY + 'auth/profile').pipe(
      tap((profile: any) => {
        this.userSubject.next(profile);
        localStorage.setItem('PROFILE', JSON.stringify(profile));
      })
    );
  }

  refreshToken(): Observable<any> {
    const refreshToken = this.tokenService.getRefreshToken();

    if (!refreshToken) {
      return of(false);
    }

    const decodedToken: TokenDecoded = jwtDecode(refreshToken);
    const expiresDate = new Date(decodedToken.exp * 1000);
    const expiresMs = expiresDate.getTime() - Date.now() - 60 * 1000;

    if (expiresMs < 120) {
      return this.logout$();
    }

    const options = {
      params: {
        refresh_token: refreshToken,
      },
    };

    return this.http.get<any>(AUTH_API_KEY + 'auth/refresh', options).pipe(
      tap((tokens: any) => {
        this.saveTokens(tokens);
        this.saveUserProfileToLocalStorage(this.tokenValue as string, this.user?.email as string);
      }),
      catchError((error) => {
        console.log('🚀 ~ file: authentication.service.ts ~ refreshToken ~ error', error);
        return of(false);
      })
    );
  }

  logout$(): Observable<any> {
    const options = {
      headers: { 'no-error-handle': true.toString() },
    };

    return this.http.get<any>(AUTH_API_KEY + 'auth/logout', options).pipe(
      tap((result: any) => {
        this.logout();
      }),
      catchError((error) => {
        console.log(
          '🚀 ~ file: new-authentication.service.ts ~ line 139 ~ NewAuthenticationService ~ logout ~ error',
          error
        );
        this.logout();
        return of(false);
      })
    );
  }

  logout(): void {
    localStorage.clear();
    this.stopRefreshTokenTimer();
    this.tokenSubject.next(null);
    this.userSubject.next(null);
  }

  generateRequestData(username: string, password: string) {
    const firstPart = username.trim().toLowerCase();
    const secondPart = password.trim();
    return (
      HelpersService.base64(HelpersService.encodeSHA512(firstPart + secondPart + firstPart)) +
      '' +
      HelpersService.base64(HelpersService.encodeSHA512(HelpersService.randomString()))
    );
  }

  saveTokens(tokens: any): void {
    if (!tokens) {
      return;
    }
    this.tokenService.saveToken(tokens.access_token);
    this.tokenService.saveRefreshToken(tokens.refresh_token);
    this.startRefreshTokenTimer();
    this.tokenSubject.next(tokens.access_token);
  }

  getAccessTokenRefreshTimeout(): number {
    if (this.tokenService.getToken()) {
      const decodedToken: TokenDecoded = jwtDecode(this.tokenService.getToken() as string);
      const expires = new Date(decodedToken.exp * 1000);
      this.tokenService.saveAccessTokenExpiresIn(expires);
      return expires.getTime() - Date.now() - 60 * 1000;
    }
    return 999999999;
  }

  isAuthenticated(): boolean {
    return !!this.tokenValue && this.tokenValue.length > 0;
  }

  private saveUserProfileToLocalStorage(token: string, email: string): void {
    const decodedToken = this.decodeAccessToken(token);

    try {
      const userProfile: UserProfile = {
        company_uuid: decodedToken!.company_uuid,
        company_type: decodedToken!.company_type,
        role_uuid: decodedToken!.role_uuid,
        role: decodedToken!.role,
        email,
      };
      localStorage.setItem('PROFILE', JSON.stringify(userProfile));
      this.userSubject.next(userProfile);
    } catch (error) {}
  }

  private getUserProfileFromLocalStorage(): UserProfile | null {
    try {
      return JSON.parse(<string>localStorage.getItem('PROFILE')) as UserProfile;
    } catch (error) {
      return null;
    }
  }

  private removeUserProfileFromLocalStorage(): void {
    localStorage.removeItem('PROFILE');
  }

  private decodeAccessToken(token: string): UserDecodedToken | null {
    try {
      return jwtDecode(token);
    } catch (error) {
      return null;
    }
  }

  private startRefreshTokenTimer(): void {
    const timeout = this.getAccessTokenRefreshTimeout();
    this.refreshTokenTimeoutId = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer(): void {
    clearTimeout(this.refreshTokenTimeoutId);
  }
}
