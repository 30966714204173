import { Inject, Injectable, OnDestroy } from '@angular/core';
import { environment } from '@env/environment';
import { DOCUMENT } from '@angular/common';

declare const window: Window & { onUsersnapCXLoad?: (api: any) => void };

export interface UsersnapInputParams {
  user?: {
    userId: string;
    email: string;
  };
  fields?: {
    customData: {
      defaultValue: string;
    };
  };
}

interface InitParams {
  consoleRecorder: boolean;
  consoleRecorderCfg: {
    maxMessages: number;
    maxExceptions: number;
    logObjectSize: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class UsersnapService implements OnDestroy {
  private script: HTMLScriptElement | null = null;

  usersnapApi: any | null = null;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  initialize(inputParams: UsersnapInputParams = {}): Promise<any> {
    this.destroyApi();
    return new Promise<any>((resolve) => {
      window.onUsersnapCXLoad = (api: any) => {
        const initParams: InitParams = {
          consoleRecorder: true,
          consoleRecorderCfg: {
            maxMessages: 500,
            maxExceptions: 500,
            logObjectSize: 20480,
          },
        };
        api.init({ ...inputParams, ...initParams });
        this.usersnapApi = api;
        resolve(api);
      };
      this.script = this.document.createElement('script');
      this.script.defer = false;
      this.script.type = 'text/javascript';
      this.script.src = `https://widget.usersnap.com/global/load/${environment?.userSnap?.GlobalAPIKey}?onload=onUsersnapCXLoad`;
      this.document.body.appendChild(this.script);
    });
  }

  destroyApi(): void {
    if (this.usersnapApi) {
      this.usersnapApi.destroy();
    }
    if (this.script) {
      this.document.body.removeChild(this.script);
      this.script = null;
    }
  }

  ngOnDestroy(): void {
    this.destroyApi();
  }
}
