import { Injectable } from '@angular/core';

const ACCESS_TOKEN = 'ACCESS_TOKEN';
const ACCESS_TOKEN_EXPIRES_IN = 'ACCESS_TOKEN_EXPIRES_IN';
const REFRESH_TOKEN = 'REFRESH_TOKEN';

export interface TokenDecoded {
  sub: string;
  type: string;
  is_subscribed: boolean;
  timezone: string;
  iat: number;
  exp: number;
}

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  constructor() {}

  getToken(): string | null {
    return localStorage.getItem(ACCESS_TOKEN);
  }

  getRefreshToken(): string | null {
    return localStorage.getItem(REFRESH_TOKEN);
  }

  saveToken(token: string): void {
    localStorage.setItem(ACCESS_TOKEN, token);
  }

  saveRefreshToken(refreshToken: string): void {
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  }

  removeToken(): void {
    localStorage.removeItem(ACCESS_TOKEN);
  }

  removeRefreshToken(): void {
    localStorage.removeItem(REFRESH_TOKEN);
  }

  saveAccessTokenExpiresIn(expires: Date): void {
    localStorage.setItem(ACCESS_TOKEN_EXPIRES_IN, `${expires}`);
  }
}
