import { RequestPath } from './models/request-path.model';
import { RequestMethod } from './models/request-method.model';
import { CustomError, CustomErrorObject } from './models/custom-error.model';

import { getApiErrorMessagesList } from './errors-list';
import { getApiDomainErrorMessagesList } from './domain-errors-list';

//// Hash map data for GraphQL (for later)
// import { Query } from '@core/graphql/types/payment-types';
//
// type QueryType = Omit<Query, '__typename'>;
//
// const ApiErrorMessagesHasMap: { [queryName in keyof QueryType]: Array<CustomError> }

export class ErrorHandler {
  private static ApiErrorMessagesHashMap: { [path in RequestPath]: CustomError[] };
  private static ApiDomainErrorMessagesHashMap: { [key: string]: CustomErrorObject };

  public static init(): void {
    ErrorHandler.ApiErrorMessagesHashMap = {} as any;

    const errorsList = getApiErrorMessagesList();
    errorsList.forEach((error) => {
      if (ErrorHandler.ApiErrorMessagesHashMap[error.path]) {
        ErrorHandler.ApiErrorMessagesHashMap[error.path].push(error);
      } else {
        ErrorHandler.ApiErrorMessagesHashMap[error.path] = [error];
      }
    });

    this.ApiDomainErrorMessagesHashMap = getApiDomainErrorMessagesList();
  }

  static getErrorMessage(
    requestUrl: string,
    method: RequestMethod,
    apiErrorMessage: string | string[],
    messageArgs?: any[]
  ): string | null {
    let error: CustomError | CustomErrorObject | undefined;

    const errorMessageArr = Array.isArray(apiErrorMessage) ? apiErrorMessage : [apiErrorMessage];
    const pathName = new URL(requestUrl).pathname;

    const requestPath = ErrorHandler.getRequestPathByUrl(pathName);
    if (requestPath) {
      error = ErrorHandler.ApiErrorMessagesHashMap[requestPath]?.find(
        (item) => item.method === method && errorMessageArr.includes(item.message)
      );

      if (!!error) {
        if (typeof error.text === 'string') {
          return error.text;
        } else if (typeof error.text === 'function') {
          return error.text(messageArgs);
        }
      }
    }

    error = ErrorHandler.ApiDomainErrorMessagesHashMap[errorMessageArr[0]];
    if (!!error) {
      if (typeof error.text === 'string') {
        return error.text;
      } else if (typeof error.text === 'function') {
        return error.text(pathName, messageArgs);
      }
    }

    return null;
  }

  static isTheSameUrl(pattern: string, value: string): boolean {
    if (!pattern || !value) {
      return false;
    }

    const _patternSplit = pattern.split('/').filter((val) => !!val);
    const _valueSplit = value.split('/').filter((val) => !!val);

    if (_patternSplit.length !== _valueSplit.length) {
      return false;
    }

    for (let index = 0; index < _patternSplit.length; index++) {
      const patternItem = _patternSplit[index];
      const valueItem = _valueSplit[index];

      if (patternItem.startsWith(':')) {
        continue;
      }

      if (patternItem.toLowerCase() !== valueItem.toLowerCase()) {
        return false;
      }
    }

    return true;
  }

  static getRequestPathByUrl(url: string): RequestPath | null {
    if (!url) {
      return null;
    }

    return Object.values(RequestPath).find((value) => ErrorHandler.isTheSameUrl(value, url)) || null;
  }
}
