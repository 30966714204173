import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Operation } from '@apollo/client/core';
import { ErrorResponse } from '@apollo/client/link/error';
import { getOperationName } from '@apollo/client/utilities';
import { getOperationAST, GraphQLError } from 'graphql';

// import { getOperationDefinition } from '@apollo/client/utilities';
import { Extras } from '@sentry/types';
import { captureMessage, Scope, User as SentryExpectedUser } from '@sentry/angular';

import { AuthenticationService } from '@app/core/services';

import { SentryReporter } from './sentry-reporter';
import { SentryIssueScope } from './sentry-issue-scope';

@Injectable({
  providedIn: 'root',
})
export class SentryReporterService implements SentryReporter {
  constructor(private authenticationService: AuthenticationService) {}

  reportGraphqlErrors({ operation, graphQLErrors, networkError, response }: ErrorResponse): void {
    const graphqlErrors = graphQLErrors || response?.errors;
    if (Array.isArray(graphqlErrors) ? graphqlErrors : [graphqlErrors]) {
      graphqlErrors?.forEach((error) => {
        const scope = new Scope()
          .setTransactionName(error?.path?.join('.') || error.name)
          .setTag('kind', getOperationName(operation.query))
          .setExtras(createOperationExtras(operation))
          .setExtras(createErrorExtras(error))
          .setUser(this.getAuthUser())
          .addBreadcrumb({
            category: 'query-path',
            message: error?.path?.join(' > ') || '_',
            level: 'error',
          });

        captureMessage(SentryIssueScope.GraphQLError, scope);
      });
    }

    if (networkError) {
      const scope = new Scope()
        .setTransactionName(networkError?.name)
        .setTag('kind', getOperationName(operation?.query))
        .setExtras({
          ...createOperationExtras(operation),
          'error message': networkError?.message ?? 'empty string)',
        })
        .setUser(this.getAuthUser());

      captureMessage(SentryIssueScope.GraphQLNetworkError, scope);
    }
  }

  reportHttpError(httpError: HttpErrorResponse): void {
    const scope = new Scope()
      .setTransactionName(httpError.name)
      .setExtras({ ...httpError })
      .setUser(this.getAuthUser());

    captureMessage(SentryIssueScope.HttpError, scope);
  }

  reportChunkLoadError(error: Error): void {
    const scope = new Scope()
      .setTransactionName(error.name)
      .setExtras({ message: error.message })
      .setUser(this.getAuthUser());

    captureMessage(SentryIssueScope.ChunkLoadError, scope);
  }

  reportClientSideError(error: Error): void {
    const scope = new Scope()
      .setTransactionName(error.name)
      .setExtras({ message: error.message })
      .setUser(this.getAuthUser());

    captureMessage(SentryIssueScope.ClientSideError, scope);
  }

  reportLongScreenLoading(): void {
    const scope = new Scope().setUser(this.getAuthUser());

    captureMessage(SentryIssueScope.LongScreenLoading, scope);
  }

  getAuthUser(): SentryExpectedUser | null {
    if (this.authenticationService.user) {
      return { email: this.authenticationService.user.email };
    }
    return null;
  }
}

function createOperationExtras(operation: Operation): Extras {
  const operationTypeNode: 'query' | 'mutation' | 'subscription' = getOperationAST(operation.query)!.operation;

  return {
    [operationTypeNode]: {
      definitions: operation.query.definitions.reduce((acc, def: any) => {
        if (def?.['name']?.['value']) {
          acc[def?.['name']?.['value']] = JSON.stringify({
            kind: def?.['kind'],
            name: def?.['name'],
            operation: def?.['operation'] || '_',
          });
        }
        return acc;
      }, {} as any),
    },
    variables: JSON.stringify(operation.variables),
  };
}

function createErrorExtras(error: GraphQLError): Extras {
  return {
    error: {
      message: error.message,
      path: error.path,
    },
  };
}
