import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { TuiAlertService, TuiNotification } from '@taiga-ui/core';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(
    @Inject(TuiAlertService)
    private readonly notificationsService: TuiAlertService
  ) {}

  public showNotification(
    message: string,
    status: TuiNotification = TuiNotification.Info,
    label?: string
  ): Observable<any> {
    return this.notificationsService.open(message, {
      ...(label && { label }),
      status,
      autoClose: 5000,
    });
  }
}
