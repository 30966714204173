import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { v4 as uuidv4 } from 'uuid';

import { environment, THIRD_PARTY_API_KEY } from '@env/environment';
import { AuthenticationService } from '@app/core/services';
import { TIME_ZONE } from '@app/core/dayjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.authenticationService.tokenValue;
    let requestUrl = request.url;

    // console.log('TokenInterceptor', token);
    if (requestUrl.indexOf(THIRD_PARTY_API_KEY) !== -1) {
      requestUrl = requestUrl.replace(THIRD_PARTY_API_KEY, '');
      request = request.clone({
        url: requestUrl,
      });
      return next.handle(request);
    }

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          ratelimiter: String(environment.rateLimiterHeader),
          Timezone: TIME_ZONE,
          'X-Correlation-ID': uuidv4(),
        },
      });
    }
    return next.handle(request).pipe(
      catchError((response) => {
        // if (response?.error?.message) {
        //   console.log('Class: TokenInterceptor, Function: error, Line 40 (): ', response);
        //   if (response?.error?.statusCode === 401 && requestUrl.indexOf('logout') === -1) {
        //     this.authenticationService.logout();
        //     this.router.navigate(['login'], { replaceUrl: true }).then();
        //     return of(response);
        //   }
        // }
        return throwError(response);
      })
    );
  }
}
