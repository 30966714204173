import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AUTH_API_KEY, environment } from '@env/environment';

@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let requestUrl = request.url;
    // console.log(
    //   '🚀 ~ ApiPrefixInterceptor ~ intercept ~ request.url.toLowerCase()',
    //   request.url.toLowerCase()
    // );

    if (requestUrl.indexOf(AUTH_API_KEY) !== -1) {
      requestUrl = requestUrl.replace(AUTH_API_KEY, environment.SSOUrl);
      request = request.clone({
        url: requestUrl,
      });
    } else if (request.url.toLowerCase().indexOf('http') === -1) {
      request = request.clone({ url: environment.serverUrl + request.url });
    }

    return next.handle(request);
  }
}
