import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  NullableUUID: string;
  PositiveFloatBetween0And9007199254740991: number;
  PositiveIntBetween0And100: number;
  PositiveIntBetween0And9007199254740991: number;
  RequiredStringLimitedTo2: string;
  RequiredStringLimitedTo10: string;
  RequiredStringLimitedTo255: string;
  StringLimitedTo2: string;
  StringLimitedTo10: string;
  StringLimitedTo11: string;
  StringLimitedTo30: string;
  StringLimitedTo34: string;
  StringLimitedTo255: string;
  UUID: string;
};

export type AddMoneyToWalletDto = {
  amount?: InputMaybe<Scalars['PositiveFloatBetween0And9007199254740991']>;
  beneficiary_uuid?: InputMaybe<Scalars['NullableUUID']>;
  wallet_uuid: Scalars['UUID'];
};

export type Beneficiary = {
  __typename?: 'Beneficiary';
  aba?: Maybe<Scalars['StringLimitedTo255']>;
  account_number?: Maybe<Scalars['StringLimitedTo30']>;
  /** account_number or iban */
  account_number_or_iban?: Maybe<Scalars['StringLimitedTo255']>;
  bank_address?: Maybe<Scalars['StringLimitedTo255']>;
  /** bank_country_name */
  bank_country?: Maybe<Country>;
  bank_country_alpha2_code?: Maybe<Scalars['StringLimitedTo2']>;
  bank_name?: Maybe<Scalars['StringLimitedTo255']>;
  beneficiary_address?: Maybe<Scalars['StringLimitedTo255']>;
  /** bank_country_name */
  beneficiary_country?: Maybe<Country>;
  beneficiary_country_alpha2_code?: Maybe<CountryAlpha2Codes>;
  beneficiary_name: Scalars['RequiredStringLimitedTo255'];
  company_name?: Maybe<Scalars['StringLimitedTo255']>;
  company_uuid: Scalars['UUID'];
  created_at: Scalars['DateTime'];
  currency: CurrencyTypes;
  deleted_at?: Maybe<Scalars['DateTime']>;
  iban?: Maybe<Scalars['StringLimitedTo34']>;
  /**
   * Seller can mark beneficiary as default. Default beneficiary is in three places:
   *     1 uploading invoices by payer (by providing Excel file) on payer Dashboard page
   *     2 send money to seller on payer Pay page.
   *     3 upload and sell invoices by payer while prepayment process.
   */
  is_default?: Maybe<Scalars['Boolean']>;
  /** Beneficiary became active after success beneficiary validation process */
  is_valid?: Maybe<Scalars['Boolean']>;
  sort_code?: Maybe<Scalars['StringLimitedTo255']>;
  swift?: Maybe<Scalars['StringLimitedTo11']>;
  /** The Beneficiary Type of account can be deposit, payout, other */
  type: BeneficiaryTypeEnum;
  updated_at: Scalars['DateTime'];
  user_beneficiaries?: Maybe<ProviderUser>;
  uuid: Scalars['UUID'];
  validation_started_at?: Maybe<Scalars['DateTime']>;
};

/** BeneficiaryOrderBy */
export enum BeneficiaryOrderBy {
  Aba = 'ABA',
  AccountNumber = 'ACCOUNT_NUMBER',
  BankAddress = 'BANK_ADDRESS',
  BankCountry = 'BANK_COUNTRY',
  BankCountryAlpha2Code = 'BANK_COUNTRY_ALPHA2_CODE',
  BankName = 'BANK_NAME',
  BeneficiaryAddress = 'BENEFICIARY_ADDRESS',
  BeneficiaryCountryAlpha2Code = 'BENEFICIARY_COUNTRY_ALPHA2_CODE',
  BeneficiaryCountryName = 'BENEFICIARY_COUNTRY_NAME',
  BeneficiaryName = 'BENEFICIARY_NAME',
  CompanyName = 'COMPANY_NAME',
  CreatedAt = 'CREATED_AT',
  Currency = 'CURRENCY',
  Iban = 'IBAN',
  IsValid = 'IS_VALID',
  ReferenceCode = 'REFERENCE_CODE',
  SortCode = 'SORT_CODE',
  Swift = 'SWIFT',
  UpdatedAt = 'UPDATED_AT',
}

export type BeneficiaryOrdering = {
  orderBy?: InputMaybe<BeneficiaryOrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type BeneficiaryResponsePagination = {
  __typename?: 'BeneficiaryResponsePagination';
  items: Array<Beneficiary>;
  meta: PaginationMeta;
};

/** Beneficiary can set with one type (desposit,payout,other) */
export enum BeneficiaryTypeEnum {
  Deposit = 'DEPOSIT',
  Other = 'OTHER',
  Payout = 'PAYOUT',
}

export type BeneficiaryUuid = {
  uuid: Scalars['UUID'];
};

export type Country = {
  __typename?: 'Country';
  alpha2_code: CountryAlpha2Codes;
  name: Scalars['RequiredStringLimitedTo255'];
};

export enum CountryAlpha2Codes {
  Ad = 'AD',
  Ae = 'AE',
  Af = 'AF',
  Ag = 'AG',
  Ai = 'AI',
  Al = 'AL',
  Am = 'AM',
  Ao = 'AO',
  Aq = 'AQ',
  Ar = 'AR',
  At = 'AT',
  Au = 'AU',
  Aw = 'AW',
  Ax = 'AX',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Be = 'BE',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bi = 'BI',
  Bj = 'BJ',
  Bl = 'BL',
  Bm = 'BM',
  Bn = 'BN',
  Bo = 'BO',
  Bq = 'BQ',
  Br = 'BR',
  Bs = 'BS',
  Bt = 'BT',
  Bv = 'BV',
  Bw = 'BW',
  By = 'BY',
  Bz = 'BZ',
  Ca = 'CA',
  Cc = 'CC',
  Cd = 'CD',
  Cf = 'CF',
  Cg = 'CG',
  Ch = 'CH',
  Ci = 'CI',
  Ck = 'CK',
  Cl = 'CL',
  Cm = 'CM',
  Cn = 'CN',
  Co = 'CO',
  Cr = 'CR',
  Cu = 'CU',
  Cv = 'CV',
  Cw = 'CW',
  Cx = 'CX',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dj = 'DJ',
  Dk = 'DK',
  Dm = 'DM',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Ee = 'EE',
  Eg = 'EG',
  Eh = 'EH',
  Er = 'ER',
  Es = 'ES',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fk = 'FK',
  Fm = 'FM',
  Fo = 'FO',
  Fr = 'FR',
  Ga = 'GA',
  Gb = 'GB',
  Gd = 'GD',
  Ge = 'GE',
  Gf = 'GF',
  Gg = 'GG',
  Gh = 'GH',
  Gi = 'GI',
  Gl = 'GL',
  Gm = 'GM',
  Gn = 'GN',
  Gp = 'GP',
  Gq = 'GQ',
  Gr = 'GR',
  Gs = 'GS',
  Gt = 'GT',
  Gu = 'GU',
  Gw = 'GW',
  Gy = 'GY',
  Hk = 'HK',
  Hm = 'HM',
  Hn = 'HN',
  Hr = 'HR',
  Ht = 'HT',
  Hu = 'HU',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  Im = 'IM',
  In = 'IN',
  Io = 'IO',
  Iq = 'IQ',
  Ir = 'IR',
  Is = 'IS',
  It = 'IT',
  Je = 'JE',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Ke = 'KE',
  Kg = 'KG',
  Kh = 'KH',
  Ki = 'KI',
  Km = 'KM',
  Kn = 'KN',
  Kp = 'KP',
  Kr = 'KR',
  Kw = 'KW',
  Ky = 'KY',
  Kz = 'KZ',
  La = 'LA',
  Lb = 'LB',
  Lc = 'LC',
  Li = 'LI',
  Lk = 'LK',
  Lr = 'LR',
  Ls = 'LS',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Ly = 'LY',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Me = 'ME',
  Mf = 'MF',
  Mg = 'MG',
  Mh = 'MH',
  Mk = 'MK',
  Ml = 'ML',
  Mm = 'MM',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Mq = 'MQ',
  Mr = 'MR',
  Ms = 'MS',
  Mt = 'MT',
  Mu = 'MU',
  Mv = 'MV',
  Mw = 'MW',
  Mx = 'MX',
  My = 'MY',
  Mz = 'MZ',
  Na = 'NA',
  Nc = 'NC',
  Ne = 'NE',
  Nf = 'NF',
  Ng = 'NG',
  Ni = 'NI',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nu = 'NU',
  Nz = 'NZ',
  Om = 'OM',
  Pa = 'PA',
  Pe = 'PE',
  Pf = 'PF',
  Pg = 'PG',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pm = 'PM',
  Pn = 'PN',
  Pr = 'PR',
  Ps = 'PS',
  Pt = 'PT',
  Pw = 'PW',
  Py = 'PY',
  Qa = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Rs = 'RS',
  Ru = 'RU',
  Rw = 'RW',
  Sa = 'SA',
  Sb = 'SB',
  Sc = 'SC',
  Sd = 'SD',
  Se = 'SE',
  Sg = 'SG',
  Sh = 'SH',
  Si = 'SI',
  Sj = 'SJ',
  Sk = 'SK',
  Sl = 'SL',
  Sm = 'SM',
  Sn = 'SN',
  So = 'SO',
  Sr = 'SR',
  Ss = 'SS',
  St = 'ST',
  Sv = 'SV',
  Sx = 'SX',
  Sy = 'SY',
  Sz = 'SZ',
  Tc = 'TC',
  Td = 'TD',
  Tf = 'TF',
  Tg = 'TG',
  Th = 'TH',
  Tj = 'TJ',
  Tk = 'TK',
  Tl = 'TL',
  Tm = 'TM',
  Tn = 'TN',
  To = 'TO',
  Tr = 'TR',
  Tt = 'TT',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ua = 'UA',
  Ug = 'UG',
  Um = 'UM',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vg = 'VG',
  Vi = 'VI',
  Vn = 'VN',
  Vu = 'VU',
  Wf = 'WF',
  Ws = 'WS',
  Xk = 'XK',
  Ye = 'YE',
  Yt = 'YT',
  Za = 'ZA',
  Zm = 'ZM',
  Zw = 'ZW',
}

/** The Currency Types */
export enum CurrencyTypes {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ars = 'ARS',
  Aud = 'AUD',
  Azn = 'AZN',
  Bam = 'BAM',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bnd = 'BND',
  Bob = 'BOB',
  Brl = 'BRL',
  Bwp = 'BWP',
  Byr = 'BYR',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Chf = 'CHF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Crc = 'CRC',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Eek = 'EEK',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Khr = 'KHR',
  Kmf = 'KMF',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kzt = 'KZT',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Ltl = 'LTL',
  Lvl = 'LVL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mop = 'MOP',
  Mur = 'MUR',
  Mxn = 'MXN',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Sos = 'SOS',
  Syp = 'SYP',
  Thb = 'THB',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Vef = 'VEF',
  Vnd = 'VND',
  Xaf = 'XAF',
  Xof = 'XOF',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmk = 'ZMK',
  Zmw = 'ZMW',
}

/** DebitCredit */
export enum DebitCredit {
  Credit = 'credit',
  Debit = 'debit',
}

export type DeleteBeneficiaryInput = {
  uuid: Scalars['UUID'];
};

export type GetBeneficiariesArgs = {
  bank_country_alpha2_code?: InputMaybe<Scalars['StringLimitedTo2']>;
  /** Filter by company_uuid field */
  company_uuid?: InputMaybe<Scalars['NullableUUID']>;
  /** Filter by list of company_uuids */
  company_uuids?: InputMaybe<Array<Scalars['UUID']>>;
  /** Filter by currency */
  currency?: InputMaybe<CurrencyTypes>;
  /** Filter by is_default field */
  is_default?: InputMaybe<Scalars['Boolean']>;
  /** Filter by is_valid field */
  is_valid?: InputMaybe<Scalars['Boolean']>;
  non_wallet_representation?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<BeneficiaryTypeEnum>;
  /** Filter by list of uuids */
  uuids?: InputMaybe<Array<Scalars['UUID']>>;
};

export type IndicativeRates = {
  __typename?: 'IndicativeRates';
  currency: CurrencyTypes;
  rates: Array<RatesCurrencyEntity>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Increase wallet's amount.
   *       Can throw the following errors from ApiErrors: WalletNotFound, BeneficiaryNotFound,
   *       WalletBeneficiaryCurrenciesMustBeTheSame, NotEnoughMoneyOnWallet
   */
  addMoneyToWallet: Scalars['Boolean'];
  /** Start beneficiary validator check - send one penny to provided beneficiary */
  checkBeneficiary: Beneficiary;
  /**
   * Check reference code - provided reference code must match with code that was sent in a transaction.
   *        It may match code from beneficiaries.reference_code or transactions.provider_payment_id fields
   */
  checkReferenceCode: Scalars['String'];
  /**
   *
   * The logic depends on user role. If we create an beneficiary for funder - there are two beneficiaries created - one beneficiary
   * linked to Platform Parent beneficiary - in order to make payment to this beneficiary, second - link beneficiary to the user.
   * The funder must be onboarded on the system at this stage.
   */
  createBeneficiary: Beneficiary;
  /** Delete user beneficiary */
  deleteBeneficiary: Beneficiary;
  /** Edit wallet external ids */
  editWalletExternalIds: Scalars['Boolean'];
  /**
   * Decrease wallet's amount.
   *       Can throw the following errors from ApiErrors: WalletNotFound, BeneficiaryNotFound,
   *       WalletBeneficiaryCurrenciesMustBeTheSame, NotEnoughMoneyOnWallet
   */
  removeMoneyFromWallet: Scalars['Boolean'];
  /** Set fund manager commission payout frequency */
  setCommissionPayoutFrequency: Wallet;
  /** Set payout frequency */
  setPayoutFrequency: Wallet;
  /** Mark beneficiary as default */
  switchDefault: Scalars['String'];
  /** Update user beneficiary while beneficiary validator */
  updateBeneficiary: Beneficiary;
  /** Start to withdraw transaction to user's beneficiary */
  withdraw: WithdrawResponseDto;
};

export type MutationAddMoneyToWalletArgs = {
  addMoneyToWalletDto: AddMoneyToWalletDto;
};

export type MutationCheckBeneficiaryArgs = {
  beneficiaryUUID: BeneficiaryUuid;
};

export type MutationCheckReferenceCodeArgs = {
  referenceCode: ReferenceCode;
};

export type MutationCreateBeneficiaryArgs = {
  newBeneficiary: NewBeneficiaryInput;
};

export type MutationDeleteBeneficiaryArgs = {
  deleteBeneficiaryInput: DeleteBeneficiaryInput;
};

export type MutationEditWalletExternalIdsArgs = {
  updateWalletIdsDto: UpdateWalletIdsDto;
};

export type MutationRemoveMoneyFromWalletArgs = {
  removeMoneyFromWalletInput: RemoveMoneyToWalletDto;
};

export type MutationSetCommissionPayoutFrequencyArgs = {
  setCommissionPayoutFrequencyDto: SetPayoutFrequencyDto;
};

export type MutationSetPayoutFrequencyArgs = {
  setPayoutFrequencyDto: SetPayoutFrequencyDto;
};

export type MutationSwitchDefaultArgs = {
  switchDefaultAccountInput: BeneficiaryUuid;
};

export type MutationUpdateBeneficiaryArgs = {
  updateAccount: UpdateBeneficiaryInput;
};

export type MutationWithdrawArgs = {
  withdrawDto: WithdrawDto;
};

export type NewBeneficiaryInput = {
  aba?: InputMaybe<Scalars['StringLimitedTo255']>;
  account_number?: InputMaybe<Scalars['StringLimitedTo30']>;
  bank_address?: InputMaybe<Scalars['StringLimitedTo255']>;
  bank_country_alpha2_code: Scalars['RequiredStringLimitedTo2'];
  bank_name?: InputMaybe<Scalars['StringLimitedTo255']>;
  beneficiary_address: Scalars['RequiredStringLimitedTo255'];
  beneficiary_country_alpha2_code?: InputMaybe<CountryAlpha2Codes>;
  beneficiary_name: Scalars['RequiredStringLimitedTo255'];
  company_uuid?: InputMaybe<Scalars['NullableUUID']>;
  currency: CurrencyTypes;
  iban?: InputMaybe<Scalars['StringLimitedTo34']>;
  is_default: Scalars['Boolean'];
  sort_code?: InputMaybe<Scalars['StringLimitedTo255']>;
  swift?: InputMaybe<Scalars['StringLimitedTo11']>;
  type: BeneficiaryTypeEnum;
};

/** OrderDirection */
export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type OrderingArgs = {
  orderBy?: InputMaybe<Scalars['StringLimitedTo255']>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type PaginatedBeneficiariesFilters = {
  aba?: InputMaybe<Scalars['StringLimitedTo255']>;
  account_number?: InputMaybe<Scalars['StringLimitedTo30']>;
  /** account_number or iban */
  account_number_or_iban?: InputMaybe<Scalars['StringLimitedTo255']>;
  bank_address?: InputMaybe<Scalars['StringLimitedTo255']>;
  bank_country_alpha2_code?: InputMaybe<Scalars['StringLimitedTo2']>;
  bank_country_name?: InputMaybe<Scalars['StringLimitedTo255']>;
  bank_name?: InputMaybe<Scalars['StringLimitedTo255']>;
  beneficiary_address?: InputMaybe<Scalars['StringLimitedTo255']>;
  beneficiary_country_alpha2_code?: InputMaybe<CountryAlpha2Codes>;
  beneficiary_country_name?: InputMaybe<Scalars['StringLimitedTo255']>;
  beneficiary_name?: InputMaybe<Scalars['StringLimitedTo255']>;
  company_name?: InputMaybe<Scalars['StringLimitedTo255']>;
  company_uuid?: InputMaybe<Scalars['NullableUUID']>;
  company_uuids?: InputMaybe<Array<Scalars['UUID']>>;
  currency?: InputMaybe<CurrencyTypes>;
  iban?: InputMaybe<Scalars['StringLimitedTo34']>;
  /**
   * Seller can mark beneficiary as default. Default beneficiary is in three places:
   *     1 uploading invoices by payer (by providing Excel file) on payer Dashboard page
   *     2 send money to seller on payer Pay page.
   *     3 upload and sell invoices by payer while prepayment process.
   */
  is_default?: InputMaybe<Scalars['Boolean']>;
  /** Beneficiary became active after success beneficiary validation process */
  is_valid?: InputMaybe<Scalars['Boolean']>;
  non_wallet_representation?: InputMaybe<Scalars['Boolean']>;
  sort_code?: InputMaybe<Scalars['StringLimitedTo255']>;
  swift?: InputMaybe<Scalars['StringLimitedTo11']>;
  type?: InputMaybe<BeneficiaryTypeEnum>;
  /** Received format: YYYY-MM-DD */
  updated_at?: InputMaybe<Scalars['DateTime']>;
  uuid?: InputMaybe<Scalars['NullableUUID']>;
  uuids?: InputMaybe<Array<Scalars['UUID']>>;
};

export type PaginatedBeneficiariesInput = {
  filters?: InputMaybe<PaginatedBeneficiariesFilters>;
  ordering?: InputMaybe<BeneficiaryOrdering>;
  pagination?: InputMaybe<PaginationArgs>;
};

export type PaginatedWalletHistoryInput = {
  filters?: InputMaybe<WalletHistoryFiltersDto>;
  ordering?: InputMaybe<WalletHistoryOrderingArgs>;
  pagination?: InputMaybe<PaginationArgs>;
  wallet_uuid?: InputMaybe<Scalars['NullableUUID']>;
};

export type PaginationArgs = {
  page?: InputMaybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  perPage?: InputMaybe<Scalars['PositiveIntBetween0And100']>;
};

export type PaginationMeta = {
  __typename?: 'PaginationMeta';
  currentPage?: Maybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  itemCount?: Maybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  itemsPerPage?: Maybe<Scalars['PositiveIntBetween0And100']>;
  totalItems?: Maybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  totalPages?: Maybe<Scalars['PositiveIntBetween0And9007199254740991']>;
};

export type PaginationWalletFilterArgs = {
  account_id?: InputMaybe<Scalars['NullableUUID']>;
  amount?: InputMaybe<Scalars['PositiveFloatBetween0And9007199254740991']>;
  available?: InputMaybe<Scalars['PositiveFloatBetween0And9007199254740991']>;
  commission_payout_frequency?: InputMaybe<PayoutFrequency>;
  commission_to_payout?: InputMaybe<Scalars['PositiveFloatBetween0And9007199254740991']>;
  company_uuid?: InputMaybe<Scalars['NullableUUID']>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  currencies?: InputMaybe<Array<CurrencyTypes>>;
  funding_balance_id?: InputMaybe<Scalars['StringLimitedTo255']>;
  initial_margin_amount?: InputMaybe<Scalars['PositiveFloatBetween0And9007199254740991']>;
  invested_amount?: InputMaybe<Scalars['PositiveFloatBetween0And9007199254740991']>;
  payout_frequency?: InputMaybe<PayoutFrequency>;
  provider_user?: InputMaybe<UserFilter>;
  provider_user_id?: InputMaybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  reference_code?: InputMaybe<Scalars['StringLimitedTo10']>;
  total?: InputMaybe<Scalars['PositiveFloatBetween0And9007199254740991']>;
  uuid?: InputMaybe<Scalars['NullableUUID']>;
  yield_to_payout?: InputMaybe<Scalars['PositiveFloatBetween0And9007199254740991']>;
};

/** The payout frequency */
export enum PayoutFrequency {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY',
}

export type ProviderUser = {
  __typename?: 'ProviderUser';
  address?: Maybe<Scalars['StringLimitedTo255']>;
  company_uuid: Scalars['UUID'];
  group: ProviderUserType;
  name: Scalars['RequiredStringLimitedTo255'];
  provider_client_id: Scalars['RequiredStringLimitedTo255'];
};

/** The ProviderUserType */
export enum ProviderUserType {
  FundManager = 'fund_manager',
  Funder = 'funder',
  MainAccount = 'main_account',
  Payer = 'payer',
  Reconciliation = 'reconciliation',
}

export type Query = {
  __typename?: 'Query';
  /** Get user beneficiaries From Frontend app */
  beneficiaries: Array<Beneficiary>;
  /** Get user beneficiaries From Frontend app */
  beneficiary: Beneficiary;
  /** Get beneficiary needed for a deposit. It takes users wallet, and finds its beneficiary representation */
  deposit: Beneficiary;
  /** Get users wallets. Only available for admin portal users */
  getWallets: WalletPagination;
  indicativeRates: Array<IndicativeRates>;
  /** Get paginated beneficiaries */
  paginatedBeneficiaries: BeneficiaryResponsePagination;
  /** Test CommissionPayoutNotifications */
  testCommissionPayoutNotifications: Scalars['String'];
  /** Test MarginPayoutNotifications */
  testMarginPayoutNotifications: Scalars['String'];
  /** Test WithdrawNotifications */
  testWithdrawNotifications: Scalars['String'];
  /** Validate UK account - used on user beneficiary creation form */
  validateUkAccountNumber: Scalars['Boolean'];
  /** Wallet history shows the history of the wallet. Data is taken from the transactions table. */
  walletHistory: WalletHistoryResponsePagination;
  /** Get user wallets */
  wallets: WalletPagination;
};

export type QueryBeneficiariesArgs = {
  input: GetBeneficiariesArgs;
};

export type QueryBeneficiaryArgs = {
  uuid: Scalars['UUID'];
};

export type QueryDepositArgs = {
  wallet_uuid: Scalars['UUID'];
};

export type QueryGetWalletsArgs = {
  filters?: InputMaybe<PaginationWalletFilterArgs>;
  ordering?: InputMaybe<OrderingArgs>;
  pagination?: InputMaybe<PaginationArgs>;
};

export type QueryPaginatedBeneficiariesArgs = {
  input?: InputMaybe<PaginatedBeneficiariesInput>;
};

export type QueryValidateUkAccountNumberArgs = {
  account_number: Scalars['String'];
  sort_code: Scalars['String'];
};

export type QueryWalletHistoryArgs = {
  input: PaginatedWalletHistoryInput;
};

export type QueryWalletsArgs = {
  ordering?: InputMaybe<WalletsOrderingArgs>;
  pagination: PaginationArgs;
};

export type RatesCurrencyEntity = {
  __typename?: 'RatesCurrencyEntity';
  currency?: Maybe<CurrencyTypes>;
  value?: Maybe<Scalars['PositiveFloatBetween0And9007199254740991']>;
};

export type ReferenceCode = {
  reference_code: Scalars['RequiredStringLimitedTo10'];
  uuid: Scalars['UUID'];
};

export type RemoveMoneyToWalletDto = {
  amount?: InputMaybe<Scalars['PositiveFloatBetween0And9007199254740991']>;
  beneficiary_uuid?: InputMaybe<Scalars['NullableUUID']>;
  wallet_uuid: Scalars['UUID'];
};

export type SetPayoutFrequencyDto = {
  frequency_type: PayoutFrequency;
  wallet_uuid: Scalars['UUID'];
};

export type Subscription = {
  __typename?: 'Subscription';
  walletUpdated: Wallet;
};

/** Transaction Status */
export enum TransactionStatus {
  Delayed = 'DELAYED',
  Success = 'SUCCESS',
}

/** Transaction Type */
export enum TransactionType {
  BalancePayment = 'balance_payment',
  Checkout = 'checkout',
  CommissionCollection = 'commission_collection',
  CommissionPayout = 'commission_payout',
  Deposit = 'deposit',
  DirectPaymentToSeller = 'direct_payment_to_seller',
  Discounting = 'discounting',
  InitialMargin = 'initial_margin',
  Onboarding = 'onboarding',
  PayOut = 'pay_out',
  PlatformFee = 'platform_fee',
  Repayment = 'repayment',
  TransactionFee = 'transaction_fee',
  UnrecognisedTransaction = 'unrecognised_transaction',
  Validation = 'validation',
  Withdraw = 'withdraw',
}

export type UpdateBeneficiaryInput = {
  aba?: InputMaybe<Scalars['StringLimitedTo255']>;
  account_number?: InputMaybe<Scalars['StringLimitedTo30']>;
  bank_address?: InputMaybe<Scalars['StringLimitedTo255']>;
  bank_country_alpha2_code?: InputMaybe<Scalars['StringLimitedTo2']>;
  bank_name?: InputMaybe<Scalars['StringLimitedTo255']>;
  beneficiary_address?: InputMaybe<Scalars['StringLimitedTo255']>;
  beneficiary_country_alpha2_code?: InputMaybe<CountryAlpha2Codes>;
  beneficiary_name?: InputMaybe<Scalars['StringLimitedTo255']>;
  currency?: InputMaybe<CurrencyTypes>;
  iban?: InputMaybe<Scalars['StringLimitedTo34']>;
  id?: InputMaybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  is_default: Scalars['Boolean'];
  sort_code?: InputMaybe<Scalars['StringLimitedTo255']>;
  swift?: InputMaybe<Scalars['StringLimitedTo11']>;
  type: BeneficiaryTypeEnum;
  uuid: Scalars['UUID'];
};

/** Update only funding_balance_id and account_id fields */
export type UpdateWalletIdsDto = {
  uuid: Scalars['UUID'];
  walletDto: WalletIdsInput;
};

export type UserFilter = {
  group?: InputMaybe<UserGroup>;
  name?: InputMaybe<Scalars['StringLimitedTo255']>;
};

/** User Group */
export enum UserGroup {
  Funder = 'FUNDER',
  FundManager = 'FUND_MANAGER',
  Payer = 'PAYER',
}

export type Wallet = {
  __typename?: 'Wallet';
  /**
   *
   *     Real amount that user has in Payment Provider's accounts(account is a wallet in Payment Provider terminology).
   *
   */
  amount?: Maybe<Scalars['PositiveFloatBetween0And9007199254740991']>;
  /**
   *
   *     Amount available for investment(this amount can be used while creation new strategy).
   *     Calculate as: total - initial_margin_amount - invested_amount - yield_to_payout
   *
   */
  available?: Maybe<Scalars['PositiveFloatBetween0And9007199254740991']>;
  /**
   *
   *     Get wallet beneficiary representation
   *
   */
  beneficiary?: Maybe<Beneficiary>;
  /**
   *
   *       Fund manager commission payout frequency.
   *       For now, we can send accumulated profit WEEKLY, MONTHLY, QUARTERLY, YEARLY.
   *       Process of handling payout is configured in monolith. see src/jobs/schedule.service.ts, methods:
   *       handleStrategyPayoutWeekly, handleStrategyPayoutMonthly, handleStrategyPayoutQuarterly, handleStrategyPayoutYearly
   *
   */
  commission_payout_frequency?: Maybe<PayoutFrequency>;
  /**
   *
   *      Accumulated fund manager's commission that needs to be send while commission payout process.
   *       Job start in a monolith, see src/jobs/schedule.service.ts, methods:
   *       handleCommissionPayoutWeekly, handleCommissionPayoutMonthly, handleCommissionPayoutQuarterly, handleCommissionPayoutYearly
   *
   */
  commission_to_payout?: Maybe<Scalars['PositiveFloatBetween0And9007199254740991']>;
  created_at: Scalars['DateTime'];
  currency: CurrencyTypes;
  deleted_at?: Maybe<Scalars['DateTime']>;
  funding_balance_id?: Maybe<Scalars['StringLimitedTo255']>;
  /**
   *
   *      This relates to Payment Provider logic.
   *      When we create forward trades in Payment Provider we must have deposited in Payment Provider as some percentage of the deal.
   *      This deposit - is some amount of money for a forward contract.from_currency.
   *
   *      For example, we want to create a forward contract: from EUR to GBP that must be executed after two weeks.
   *      In order to create such a contract in Payment Provider, we must have some amount of money on our EUR Payment Provider account
   *      this amount will be booked by Payment Provider while contract creation
   *
   *      This amount is set while strategy creation.
   *
   */
  initial_margin_amount?: Maybe<Scalars['PositiveFloatBetween0And9007199254740991']>;
  /**
   *
   *      Sum of all strategies amounts.
   *      Increased while strategy creation by the amount of strategy.
   *      Decreased when you remove strategy while strategy degradation process.
   *
   */
  invested_amount?: Maybe<Scalars['PositiveFloatBetween0And9007199254740991']>;
  /**
   *
   *       If we receive profit while strategy execution and need to send it to the user (monolith tables -
   *       if investment_strategies.parameters.payout_my_returns field set to true
   *       and investment_strategies.parameters.beneficiary_uuid_for_payout is set)
   *       For now, we can send accumulated profit MONTHLY, QUARTERLY, YEARLY.
   *       Process of handling payout is configured in monolith. see src/jobs/schedule.service.ts, methods:
   *       handleStrategyPayoutMonthly, handleStrategyPayoutQuarterly, handleStrategyPayoutYearly
   *
   */
  payout_frequency?: Maybe<PayoutFrequency>;
  /** User entity from payment service */
  provider_user?: Maybe<ProviderUser>;
  provider_user_id?: Maybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  /**
   *
   *      This code is unique among all wallets - used while transaction parsing process.
   *
   */
  reference_code: Scalars['RequiredStringLimitedTo10'];
  total?: Maybe<Scalars['PositiveFloatBetween0And9007199254740991']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  uuid: Scalars['UUID'];
  /**
   *
   *      Accumulated user's profit that needs to be send while payout process. Job start in a monolith, see src/jobs/schedule.service.ts, methods:
   *       handleStrategyPayoutMonthly, handleStrategyPayoutQuarterly, handleStrategyPayoutYearly
   *
   */
  yield_to_payout?: Maybe<Scalars['PositiveFloatBetween0And9007199254740991']>;
};

export type WalletHistory = {
  __typename?: 'WalletHistory';
  amount?: Maybe<Scalars['PositiveFloatBetween0And9007199254740991']>;
  created_at: Scalars['DateTime'];
  debit_credit: DebitCredit;
  status: WalletHistoryStatus;
  transaction_type?: Maybe<TransactionType>;
  uuid: Scalars['UUID'];
};

export type WalletHistoryFiltersDto = {
  amount?: InputMaybe<Scalars['PositiveFloatBetween0And9007199254740991']>;
  from_date?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<WalletHistoryStatus>;
  to_date?: InputMaybe<Scalars['String']>;
  transaction_type?: InputMaybe<TransactionType>;
  transaction_uuid?: InputMaybe<Scalars['NullableUUID']>;
};

/** WalletHistoryOrderBy */
export enum WalletHistoryOrderBy {
  Amount = 'AMOUNT',
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  TransactionType = 'TRANSACTION_TYPE',
}

export type WalletHistoryOrderingArgs = {
  orderBy?: InputMaybe<WalletHistoryOrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type WalletHistoryResponsePagination = {
  __typename?: 'WalletHistoryResponsePagination';
  items: Array<WalletHistory>;
  meta: PaginationMeta;
};

/** The WalletHistoryStatus */
export enum WalletHistoryStatus {
  Declined = 'declined',
  Pending = 'pending',
  Success = 'success',
}

export type WalletIdsInput = {
  beneficiary_id: Scalars['RequiredStringLimitedTo255'];
  funding_balance_id?: InputMaybe<Scalars['StringLimitedTo255']>;
};

export type WalletPagination = {
  __typename?: 'WalletPagination';
  items: Array<Wallet>;
  meta: PaginationMeta;
};

/** WalletsOrderBy */
export enum WalletsOrderBy {
  Amount = 'AMOUNT',
  CreatedAt = 'CREATED_AT',
  Currency = 'CURRENCY',
  Id = 'ID',
  InvestedAmount = 'INVESTED_AMOUNT',
  Total = 'TOTAL',
}

export type WalletsOrderingArgs = {
  orderBy?: InputMaybe<WalletsOrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type WithdrawDto = {
  amount?: InputMaybe<Scalars['PositiveFloatBetween0And9007199254740991']>;
  beneficiary_uuid: Scalars['UUID'];
  company_uuid?: InputMaybe<Scalars['NullableUUID']>;
  reference?: InputMaybe<Scalars['StringLimitedTo255']>;
  transaction_type?: InputMaybe<TransactionType>;
  wallet_uuid: Scalars['UUID'];
};

export type WithdrawResponseDto = {
  __typename?: 'WithdrawResponseDto';
  buy_amount?: Maybe<Scalars['PositiveFloatBetween0And9007199254740991']>;
  status: TransactionStatus;
  transaction_date: Scalars['DateTime'];
};

export type PaginationMetaFragment = {
  __typename?: 'PaginationMeta';
  currentPage?: number | null;
  itemCount?: number | null;
  itemsPerPage?: number | null;
  totalItems?: number | null;
  totalPages?: number | null;
};

export type AccountDataFragment = {
  __typename?: 'Beneficiary';
  uuid: string;
  company_uuid: string;
  company_name?: string | null;
  beneficiary_name: string;
  beneficiary_address?: string | null;
  beneficiary_country_alpha2_code?: CountryAlpha2Codes | null;
  account_number_or_iban?: string | null;
  aba?: string | null;
  account_number?: string | null;
  sort_code?: string | null;
  iban?: string | null;
  swift?: string | null;
  bank_name?: string | null;
  bank_address?: string | null;
  bank_country_alpha2_code?: string | null;
  currency: CurrencyTypes;
  is_valid?: boolean | null;
  is_default?: boolean | null;
  type: BeneficiaryTypeEnum;
  validation_started_at?: any | null;
  created_at: any;
  updated_at: any;
  deleted_at?: any | null;
  bank_country?: { __typename?: 'Country'; name: string; alpha2_code: CountryAlpha2Codes } | null;
  beneficiary_country?: { __typename?: 'Country'; name: string; alpha2_code: CountryAlpha2Codes } | null;
};

export type WalletDataFragment = {
  __typename?: 'Wallet';
  uuid: string;
  reference_code: string;
  amount?: number | null;
  initial_margin_amount?: number | null;
  invested_amount?: number | null;
  yield_to_payout?: number | null;
  commission_to_payout?: number | null;
  total?: number | null;
  payout_frequency?: PayoutFrequency | null;
  commission_payout_frequency?: PayoutFrequency | null;
  available?: number | null;
  currency: CurrencyTypes;
  created_at: any;
  updated_at?: any | null;
  deleted_at?: any | null;
  funding_balance_id?: string | null;
  provider_user_id?: number | null;
  beneficiary?: {
    __typename?: 'Beneficiary';
    uuid: string;
    company_uuid: string;
    company_name?: string | null;
    beneficiary_name: string;
    beneficiary_address?: string | null;
    beneficiary_country_alpha2_code?: CountryAlpha2Codes | null;
    account_number_or_iban?: string | null;
    aba?: string | null;
    account_number?: string | null;
    sort_code?: string | null;
    iban?: string | null;
    swift?: string | null;
    bank_name?: string | null;
    bank_address?: string | null;
    bank_country_alpha2_code?: string | null;
    currency: CurrencyTypes;
    is_valid?: boolean | null;
    is_default?: boolean | null;
    type: BeneficiaryTypeEnum;
    validation_started_at?: any | null;
    created_at: any;
    updated_at: any;
    deleted_at?: any | null;
    bank_country?: { __typename?: 'Country'; name: string; alpha2_code: CountryAlpha2Codes } | null;
    beneficiary_country?: { __typename?: 'Country'; name: string; alpha2_code: CountryAlpha2Codes } | null;
  } | null;
  provider_user?: {
    __typename?: 'ProviderUser';
    address?: string | null;
    company_uuid: string;
    group: ProviderUserType;
    name: string;
    provider_client_id: string;
  } | null;
};

export type PaginatedAccountsQueryVariables = Exact<{
  input: PaginatedBeneficiariesInput;
}>;

export type PaginatedAccountsQuery = {
  __typename?: 'Query';
  paginatedBeneficiaries: {
    __typename?: 'BeneficiaryResponsePagination';
    items: Array<{
      __typename?: 'Beneficiary';
      uuid: string;
      company_uuid: string;
      company_name?: string | null;
      beneficiary_name: string;
      beneficiary_address?: string | null;
      beneficiary_country_alpha2_code?: CountryAlpha2Codes | null;
      account_number_or_iban?: string | null;
      aba?: string | null;
      account_number?: string | null;
      sort_code?: string | null;
      iban?: string | null;
      swift?: string | null;
      bank_name?: string | null;
      bank_address?: string | null;
      bank_country_alpha2_code?: string | null;
      currency: CurrencyTypes;
      is_valid?: boolean | null;
      is_default?: boolean | null;
      type: BeneficiaryTypeEnum;
      validation_started_at?: any | null;
      created_at: any;
      updated_at: any;
      deleted_at?: any | null;
      bank_country?: { __typename?: 'Country'; name: string; alpha2_code: CountryAlpha2Codes } | null;
      beneficiary_country?: { __typename?: 'Country'; name: string; alpha2_code: CountryAlpha2Codes } | null;
    }>;
    meta: {
      __typename?: 'PaginationMeta';
      currentPage?: number | null;
      itemCount?: number | null;
      itemsPerPage?: number | null;
      totalItems?: number | null;
      totalPages?: number | null;
    };
  };
};

export type AccountsQueryVariables = Exact<{
  input: GetBeneficiariesArgs;
}>;

export type AccountsQuery = {
  __typename?: 'Query';
  beneficiaries: Array<{
    __typename?: 'Beneficiary';
    uuid: string;
    company_uuid: string;
    company_name?: string | null;
    beneficiary_name: string;
    beneficiary_address?: string | null;
    beneficiary_country_alpha2_code?: CountryAlpha2Codes | null;
    account_number_or_iban?: string | null;
    aba?: string | null;
    account_number?: string | null;
    sort_code?: string | null;
    iban?: string | null;
    swift?: string | null;
    bank_name?: string | null;
    bank_address?: string | null;
    bank_country_alpha2_code?: string | null;
    currency: CurrencyTypes;
    is_valid?: boolean | null;
    is_default?: boolean | null;
    type: BeneficiaryTypeEnum;
    validation_started_at?: any | null;
    created_at: any;
    updated_at: any;
    deleted_at?: any | null;
    bank_country?: { __typename?: 'Country'; name: string; alpha2_code: CountryAlpha2Codes } | null;
    beneficiary_country?: { __typename?: 'Country'; name: string; alpha2_code: CountryAlpha2Codes } | null;
  }>;
};

export type AccountQueryVariables = Exact<{
  uuid: Scalars['UUID'];
}>;

export type AccountQuery = {
  __typename?: 'Query';
  beneficiary: {
    __typename?: 'Beneficiary';
    uuid: string;
    company_uuid: string;
    company_name?: string | null;
    beneficiary_name: string;
    beneficiary_address?: string | null;
    beneficiary_country_alpha2_code?: CountryAlpha2Codes | null;
    account_number_or_iban?: string | null;
    aba?: string | null;
    account_number?: string | null;
    sort_code?: string | null;
    iban?: string | null;
    swift?: string | null;
    bank_name?: string | null;
    bank_address?: string | null;
    bank_country_alpha2_code?: string | null;
    currency: CurrencyTypes;
    is_valid?: boolean | null;
    is_default?: boolean | null;
    type: BeneficiaryTypeEnum;
    validation_started_at?: any | null;
    created_at: any;
    updated_at: any;
    deleted_at?: any | null;
    bank_country?: { __typename?: 'Country'; name: string; alpha2_code: CountryAlpha2Codes } | null;
    beneficiary_country?: { __typename?: 'Country'; name: string; alpha2_code: CountryAlpha2Codes } | null;
  };
};

export type ValidateUkAccountNumberQueryVariables = Exact<{
  account_number: Scalars['String'];
  sort_code: Scalars['String'];
}>;

export type ValidateUkAccountNumberQuery = { __typename?: 'Query'; validateUkAccountNumber: boolean };

export type WalletsQueryVariables = Exact<{
  ordering?: InputMaybe<WalletsOrderingArgs>;
  pagination: PaginationArgs;
}>;

export type WalletsQuery = {
  __typename?: 'Query';
  wallets: {
    __typename?: 'WalletPagination';
    items: Array<{
      __typename?: 'Wallet';
      uuid: string;
      reference_code: string;
      amount?: number | null;
      initial_margin_amount?: number | null;
      invested_amount?: number | null;
      yield_to_payout?: number | null;
      commission_to_payout?: number | null;
      total?: number | null;
      payout_frequency?: PayoutFrequency | null;
      commission_payout_frequency?: PayoutFrequency | null;
      available?: number | null;
      currency: CurrencyTypes;
      created_at: any;
      updated_at?: any | null;
      deleted_at?: any | null;
      funding_balance_id?: string | null;
      provider_user_id?: number | null;
      beneficiary?: {
        __typename?: 'Beneficiary';
        uuid: string;
        company_uuid: string;
        company_name?: string | null;
        beneficiary_name: string;
        beneficiary_address?: string | null;
        beneficiary_country_alpha2_code?: CountryAlpha2Codes | null;
        account_number_or_iban?: string | null;
        aba?: string | null;
        account_number?: string | null;
        sort_code?: string | null;
        iban?: string | null;
        swift?: string | null;
        bank_name?: string | null;
        bank_address?: string | null;
        bank_country_alpha2_code?: string | null;
        currency: CurrencyTypes;
        is_valid?: boolean | null;
        is_default?: boolean | null;
        type: BeneficiaryTypeEnum;
        validation_started_at?: any | null;
        created_at: any;
        updated_at: any;
        deleted_at?: any | null;
        bank_country?: { __typename?: 'Country'; name: string; alpha2_code: CountryAlpha2Codes } | null;
        beneficiary_country?: { __typename?: 'Country'; name: string; alpha2_code: CountryAlpha2Codes } | null;
      } | null;
      provider_user?: {
        __typename?: 'ProviderUser';
        address?: string | null;
        company_uuid: string;
        group: ProviderUserType;
        name: string;
        provider_client_id: string;
      } | null;
    }>;
    meta: {
      __typename?: 'PaginationMeta';
      currentPage?: number | null;
      itemCount?: number | null;
      itemsPerPage?: number | null;
      totalItems?: number | null;
      totalPages?: number | null;
    };
  };
};

export type WalletHistoryQueryVariables = Exact<{
  input: PaginatedWalletHistoryInput;
}>;

export type WalletHistoryQuery = {
  __typename?: 'Query';
  walletHistory: {
    __typename?: 'WalletHistoryResponsePagination';
    items: Array<{
      __typename?: 'WalletHistory';
      uuid: string;
      amount?: number | null;
      debit_credit: DebitCredit;
      status: WalletHistoryStatus;
      transaction_type?: TransactionType | null;
      created_at: any;
    }>;
    meta: {
      __typename?: 'PaginationMeta';
      currentPage?: number | null;
      itemCount?: number | null;
      itemsPerPage?: number | null;
      totalItems?: number | null;
      totalPages?: number | null;
    };
  };
};

export type GetWalletsQueryVariables = Exact<{
  filters: PaginationWalletFilterArgs;
  ordering: OrderingArgs;
  pagination: PaginationArgs;
}>;

export type GetWalletsQuery = {
  __typename?: 'Query';
  getWallets: {
    __typename?: 'WalletPagination';
    items: Array<{
      __typename?: 'Wallet';
      uuid: string;
      reference_code: string;
      amount?: number | null;
      initial_margin_amount?: number | null;
      invested_amount?: number | null;
      yield_to_payout?: number | null;
      commission_to_payout?: number | null;
      total?: number | null;
      payout_frequency?: PayoutFrequency | null;
      commission_payout_frequency?: PayoutFrequency | null;
      available?: number | null;
      currency: CurrencyTypes;
      created_at: any;
      updated_at?: any | null;
      deleted_at?: any | null;
      funding_balance_id?: string | null;
      provider_user_id?: number | null;
      beneficiary?: {
        __typename?: 'Beneficiary';
        uuid: string;
        company_uuid: string;
        company_name?: string | null;
        beneficiary_name: string;
        beneficiary_address?: string | null;
        beneficiary_country_alpha2_code?: CountryAlpha2Codes | null;
        account_number_or_iban?: string | null;
        aba?: string | null;
        account_number?: string | null;
        sort_code?: string | null;
        iban?: string | null;
        swift?: string | null;
        bank_name?: string | null;
        bank_address?: string | null;
        bank_country_alpha2_code?: string | null;
        currency: CurrencyTypes;
        is_valid?: boolean | null;
        is_default?: boolean | null;
        type: BeneficiaryTypeEnum;
        validation_started_at?: any | null;
        created_at: any;
        updated_at: any;
        deleted_at?: any | null;
        bank_country?: { __typename?: 'Country'; name: string; alpha2_code: CountryAlpha2Codes } | null;
        beneficiary_country?: { __typename?: 'Country'; name: string; alpha2_code: CountryAlpha2Codes } | null;
      } | null;
      provider_user?: {
        __typename?: 'ProviderUser';
        address?: string | null;
        company_uuid: string;
        group: ProviderUserType;
        name: string;
        provider_client_id: string;
      } | null;
    }>;
    meta: {
      __typename?: 'PaginationMeta';
      currentPage?: number | null;
      itemCount?: number | null;
      itemsPerPage?: number | null;
      totalItems?: number | null;
      totalPages?: number | null;
    };
  };
};

export type DepositQueryVariables = Exact<{
  wallet_uuid: Scalars['UUID'];
}>;

export type DepositQuery = {
  __typename?: 'Query';
  deposit: {
    __typename?: 'Beneficiary';
    uuid: string;
    company_uuid: string;
    company_name?: string | null;
    beneficiary_name: string;
    beneficiary_address?: string | null;
    beneficiary_country_alpha2_code?: CountryAlpha2Codes | null;
    account_number_or_iban?: string | null;
    aba?: string | null;
    account_number?: string | null;
    sort_code?: string | null;
    iban?: string | null;
    swift?: string | null;
    bank_name?: string | null;
    bank_address?: string | null;
    bank_country_alpha2_code?: string | null;
    currency: CurrencyTypes;
    is_valid?: boolean | null;
    is_default?: boolean | null;
    type: BeneficiaryTypeEnum;
    validation_started_at?: any | null;
    created_at: any;
    updated_at: any;
    deleted_at?: any | null;
    bank_country?: { __typename?: 'Country'; name: string; alpha2_code: CountryAlpha2Codes } | null;
    beneficiary_country?: { __typename?: 'Country'; name: string; alpha2_code: CountryAlpha2Codes } | null;
  };
};

export type GetIndicativeRatesQueryVariables = Exact<{ [key: string]: never }>;

export type GetIndicativeRatesQuery = {
  __typename?: 'Query';
  indicativeRates: Array<{
    __typename?: 'IndicativeRates';
    currency: CurrencyTypes;
    rates: Array<{ __typename?: 'RatesCurrencyEntity'; currency?: CurrencyTypes | null; value?: number | null }>;
  }>;
};

export type CreateAccountMutationVariables = Exact<{
  newAccount: NewBeneficiaryInput;
}>;

export type CreateAccountMutation = {
  __typename?: 'Mutation';
  createBeneficiary: {
    __typename?: 'Beneficiary';
    uuid: string;
    company_uuid: string;
    company_name?: string | null;
    beneficiary_name: string;
    beneficiary_address?: string | null;
    beneficiary_country_alpha2_code?: CountryAlpha2Codes | null;
    account_number_or_iban?: string | null;
    aba?: string | null;
    account_number?: string | null;
    sort_code?: string | null;
    iban?: string | null;
    swift?: string | null;
    bank_name?: string | null;
    bank_address?: string | null;
    bank_country_alpha2_code?: string | null;
    currency: CurrencyTypes;
    is_valid?: boolean | null;
    is_default?: boolean | null;
    type: BeneficiaryTypeEnum;
    validation_started_at?: any | null;
    created_at: any;
    updated_at: any;
    deleted_at?: any | null;
    bank_country?: { __typename?: 'Country'; name: string; alpha2_code: CountryAlpha2Codes } | null;
    beneficiary_country?: { __typename?: 'Country'; name: string; alpha2_code: CountryAlpha2Codes } | null;
  };
};

export type UpdateAccountMutationVariables = Exact<{
  updateAccount: UpdateBeneficiaryInput;
}>;

export type UpdateAccountMutation = {
  __typename?: 'Mutation';
  updateBeneficiary: {
    __typename?: 'Beneficiary';
    uuid: string;
    company_uuid: string;
    company_name?: string | null;
    beneficiary_name: string;
    beneficiary_address?: string | null;
    beneficiary_country_alpha2_code?: CountryAlpha2Codes | null;
    account_number_or_iban?: string | null;
    aba?: string | null;
    account_number?: string | null;
    sort_code?: string | null;
    iban?: string | null;
    swift?: string | null;
    bank_name?: string | null;
    bank_address?: string | null;
    bank_country_alpha2_code?: string | null;
    currency: CurrencyTypes;
    is_valid?: boolean | null;
    is_default?: boolean | null;
    type: BeneficiaryTypeEnum;
    validation_started_at?: any | null;
    created_at: any;
    updated_at: any;
    deleted_at?: any | null;
    bank_country?: { __typename?: 'Country'; name: string; alpha2_code: CountryAlpha2Codes } | null;
    beneficiary_country?: { __typename?: 'Country'; name: string; alpha2_code: CountryAlpha2Codes } | null;
  };
};

export type DeleteAccountMutationVariables = Exact<{
  deleteBeneficiaryInput: DeleteBeneficiaryInput;
}>;

export type DeleteAccountMutation = {
  __typename?: 'Mutation';
  deleteBeneficiary: {
    __typename?: 'Beneficiary';
    uuid: string;
    company_uuid: string;
    company_name?: string | null;
    beneficiary_name: string;
    beneficiary_address?: string | null;
    beneficiary_country_alpha2_code?: CountryAlpha2Codes | null;
    account_number_or_iban?: string | null;
    aba?: string | null;
    account_number?: string | null;
    sort_code?: string | null;
    iban?: string | null;
    swift?: string | null;
    bank_name?: string | null;
    bank_address?: string | null;
    bank_country_alpha2_code?: string | null;
    currency: CurrencyTypes;
    is_valid?: boolean | null;
    is_default?: boolean | null;
    type: BeneficiaryTypeEnum;
    validation_started_at?: any | null;
    created_at: any;
    updated_at: any;
    deleted_at?: any | null;
    bank_country?: { __typename?: 'Country'; name: string; alpha2_code: CountryAlpha2Codes } | null;
    beneficiary_country?: { __typename?: 'Country'; name: string; alpha2_code: CountryAlpha2Codes } | null;
  };
};

export type CheckAccountMutationVariables = Exact<{
  checkAccountInput: BeneficiaryUuid;
}>;

export type CheckAccountMutation = {
  __typename?: 'Mutation';
  checkBeneficiary: {
    __typename?: 'Beneficiary';
    uuid: string;
    company_uuid: string;
    company_name?: string | null;
    beneficiary_name: string;
    beneficiary_address?: string | null;
    beneficiary_country_alpha2_code?: CountryAlpha2Codes | null;
    account_number_or_iban?: string | null;
    aba?: string | null;
    account_number?: string | null;
    sort_code?: string | null;
    iban?: string | null;
    swift?: string | null;
    bank_name?: string | null;
    bank_address?: string | null;
    bank_country_alpha2_code?: string | null;
    currency: CurrencyTypes;
    is_valid?: boolean | null;
    is_default?: boolean | null;
    type: BeneficiaryTypeEnum;
    validation_started_at?: any | null;
    created_at: any;
    updated_at: any;
    deleted_at?: any | null;
    bank_country?: { __typename?: 'Country'; name: string; alpha2_code: CountryAlpha2Codes } | null;
    beneficiary_country?: { __typename?: 'Country'; name: string; alpha2_code: CountryAlpha2Codes } | null;
  };
};

export type SwitchDefaultMutationVariables = Exact<{
  switchDefaultAccountInput: BeneficiaryUuid;
}>;

export type SwitchDefaultMutation = { __typename?: 'Mutation'; switchDefault: string };

export type CheckReferenceCodeMutationVariables = Exact<{
  validateAccountInput: ReferenceCode;
}>;

export type CheckReferenceCodeMutation = { __typename?: 'Mutation'; checkReferenceCode: string };

export type WithdrawMutationVariables = Exact<{
  withdrawDto: WithdrawDto;
}>;

export type WithdrawMutation = {
  __typename?: 'Mutation';
  withdraw: { __typename?: 'WithdrawResponseDto'; status: TransactionStatus; transaction_date: any };
};

export type AddMoneyToWalletMutationVariables = Exact<{
  addMoneyToWalletDto: AddMoneyToWalletDto;
}>;

export type AddMoneyToWalletMutation = { __typename?: 'Mutation'; addMoneyToWallet: boolean };

export type RemoveMoneyFromWalletMutationVariables = Exact<{
  removeMoneyToWalletInput: RemoveMoneyToWalletDto;
}>;

export type RemoveMoneyFromWalletMutation = { __typename?: 'Mutation'; removeMoneyFromWallet: boolean };

export type EditWalletExternalIdsMutationVariables = Exact<{
  updateWalletIdsDto: UpdateWalletIdsDto;
}>;

export type EditWalletExternalIdsMutation = { __typename?: 'Mutation'; editWalletExternalIds: boolean };

export type SetPayoutFrequencyMutationVariables = Exact<{
  setPayoutFrequencyDto: SetPayoutFrequencyDto;
}>;

export type SetPayoutFrequencyMutation = {
  __typename?: 'Mutation';
  setPayoutFrequency: {
    __typename?: 'Wallet';
    uuid: string;
    reference_code: string;
    amount?: number | null;
    initial_margin_amount?: number | null;
    invested_amount?: number | null;
    yield_to_payout?: number | null;
    commission_to_payout?: number | null;
    total?: number | null;
    payout_frequency?: PayoutFrequency | null;
    commission_payout_frequency?: PayoutFrequency | null;
    available?: number | null;
    currency: CurrencyTypes;
    created_at: any;
    updated_at?: any | null;
    deleted_at?: any | null;
    funding_balance_id?: string | null;
    provider_user_id?: number | null;
    beneficiary?: {
      __typename?: 'Beneficiary';
      uuid: string;
      company_uuid: string;
      company_name?: string | null;
      beneficiary_name: string;
      beneficiary_address?: string | null;
      beneficiary_country_alpha2_code?: CountryAlpha2Codes | null;
      account_number_or_iban?: string | null;
      aba?: string | null;
      account_number?: string | null;
      sort_code?: string | null;
      iban?: string | null;
      swift?: string | null;
      bank_name?: string | null;
      bank_address?: string | null;
      bank_country_alpha2_code?: string | null;
      currency: CurrencyTypes;
      is_valid?: boolean | null;
      is_default?: boolean | null;
      type: BeneficiaryTypeEnum;
      validation_started_at?: any | null;
      created_at: any;
      updated_at: any;
      deleted_at?: any | null;
      bank_country?: { __typename?: 'Country'; name: string; alpha2_code: CountryAlpha2Codes } | null;
      beneficiary_country?: { __typename?: 'Country'; name: string; alpha2_code: CountryAlpha2Codes } | null;
    } | null;
    provider_user?: {
      __typename?: 'ProviderUser';
      address?: string | null;
      company_uuid: string;
      group: ProviderUserType;
      name: string;
      provider_client_id: string;
    } | null;
  };
};

export type SetCommissionPayoutFrequencyMutationVariables = Exact<{
  setCommissionPayoutFrequencyDto: SetPayoutFrequencyDto;
}>;

export type SetCommissionPayoutFrequencyMutation = {
  __typename?: 'Mutation';
  setCommissionPayoutFrequency: {
    __typename?: 'Wallet';
    uuid: string;
    reference_code: string;
    amount?: number | null;
    initial_margin_amount?: number | null;
    invested_amount?: number | null;
    yield_to_payout?: number | null;
    commission_to_payout?: number | null;
    total?: number | null;
    payout_frequency?: PayoutFrequency | null;
    commission_payout_frequency?: PayoutFrequency | null;
    available?: number | null;
    currency: CurrencyTypes;
    created_at: any;
    updated_at?: any | null;
    deleted_at?: any | null;
    funding_balance_id?: string | null;
    provider_user_id?: number | null;
    beneficiary?: {
      __typename?: 'Beneficiary';
      uuid: string;
      company_uuid: string;
      company_name?: string | null;
      beneficiary_name: string;
      beneficiary_address?: string | null;
      beneficiary_country_alpha2_code?: CountryAlpha2Codes | null;
      account_number_or_iban?: string | null;
      aba?: string | null;
      account_number?: string | null;
      sort_code?: string | null;
      iban?: string | null;
      swift?: string | null;
      bank_name?: string | null;
      bank_address?: string | null;
      bank_country_alpha2_code?: string | null;
      currency: CurrencyTypes;
      is_valid?: boolean | null;
      is_default?: boolean | null;
      type: BeneficiaryTypeEnum;
      validation_started_at?: any | null;
      created_at: any;
      updated_at: any;
      deleted_at?: any | null;
      bank_country?: { __typename?: 'Country'; name: string; alpha2_code: CountryAlpha2Codes } | null;
      beneficiary_country?: { __typename?: 'Country'; name: string; alpha2_code: CountryAlpha2Codes } | null;
    } | null;
    provider_user?: {
      __typename?: 'ProviderUser';
      address?: string | null;
      company_uuid: string;
      group: ProviderUserType;
      name: string;
      provider_client_id: string;
    } | null;
  };
};

export const PaginationMetaFragmentDoc = gql`
  fragment PaginationMeta on PaginationMeta {
    currentPage
    itemCount
    itemsPerPage
    totalItems
    totalPages
  }
`;
export const AccountDataFragmentDoc = gql`
  fragment AccountData on Beneficiary {
    uuid
    company_uuid
    company_name
    bank_country {
      name
      alpha2_code
    }
    beneficiary_country {
      name
      alpha2_code
    }
    beneficiary_name
    beneficiary_address
    beneficiary_country_alpha2_code
    account_number_or_iban
    aba
    account_number
    sort_code
    iban
    swift
    bank_name
    bank_address
    bank_country_alpha2_code
    currency
    is_valid
    is_default
    type
    validation_started_at
    created_at
    updated_at
    deleted_at
  }
`;
export const WalletDataFragmentDoc = gql`
  fragment WalletData on Wallet {
    uuid
    reference_code
    amount
    initial_margin_amount
    invested_amount
    yield_to_payout
    commission_to_payout
    total
    payout_frequency
    commission_payout_frequency
    available
    currency
    created_at
    updated_at
    deleted_at
    beneficiary {
      ...AccountData
    }
    funding_balance_id
    provider_user_id
    provider_user {
      address
      company_uuid
      group
      name
      provider_client_id
    }
  }
  ${AccountDataFragmentDoc}
`;
export const PaginatedAccountsDocument = gql`
  query paginatedAccounts($input: PaginatedBeneficiariesInput!) {
    paginatedBeneficiaries(input: $input) {
      items {
        ...AccountData
      }
      meta {
        ...PaginationMeta
      }
    }
  }
  ${AccountDataFragmentDoc}
  ${PaginationMetaFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class PaginatedAccountsGQL extends Apollo.Query<PaginatedAccountsQuery, PaginatedAccountsQueryVariables> {
  document = PaginatedAccountsDocument;
  client = 'payment';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AccountsDocument = gql`
  query accounts($input: GetBeneficiariesArgs!) {
    beneficiaries(input: $input) {
      ...AccountData
    }
  }
  ${AccountDataFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class AccountsGQL extends Apollo.Query<AccountsQuery, AccountsQueryVariables> {
  document = AccountsDocument;
  client = 'payment';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AccountDocument = gql`
  query account($uuid: UUID!) {
    beneficiary(uuid: $uuid) {
      ...AccountData
    }
  }
  ${AccountDataFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class AccountGQL extends Apollo.Query<AccountQuery, AccountQueryVariables> {
  document = AccountDocument;
  client = 'payment';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ValidateUkAccountNumberDocument = gql`
  query validateUkAccountNumber($account_number: String!, $sort_code: String!) {
    validateUkAccountNumber(account_number: $account_number, sort_code: $sort_code)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ValidateUkAccountNumberGQL extends Apollo.Query<
  ValidateUkAccountNumberQuery,
  ValidateUkAccountNumberQueryVariables
> {
  document = ValidateUkAccountNumberDocument;
  client = 'payment';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const WalletsDocument = gql`
  query wallets($ordering: WalletsOrderingArgs, $pagination: PaginationArgs!) {
    wallets(ordering: $ordering, pagination: $pagination) {
      items {
        ...WalletData
      }
      meta {
        ...PaginationMeta
      }
    }
  }
  ${WalletDataFragmentDoc}
  ${PaginationMetaFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class WalletsGQL extends Apollo.Query<WalletsQuery, WalletsQueryVariables> {
  document = WalletsDocument;
  client = 'payment';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const WalletHistoryDocument = gql`
  query walletHistory($input: PaginatedWalletHistoryInput!) {
    walletHistory(input: $input) {
      items {
        uuid
        amount
        debit_credit
        status
        transaction_type
        created_at
      }
      meta {
        ...PaginationMeta
      }
    }
  }
  ${PaginationMetaFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class WalletHistoryGQL extends Apollo.Query<WalletHistoryQuery, WalletHistoryQueryVariables> {
  document = WalletHistoryDocument;
  client = 'payment';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetWalletsDocument = gql`
  query getWallets($filters: PaginationWalletFilterArgs!, $ordering: OrderingArgs!, $pagination: PaginationArgs!) {
    getWallets(pagination: $pagination, ordering: $ordering, filters: $filters) {
      items {
        ...WalletData
      }
      meta {
        ...PaginationMeta
      }
    }
  }
  ${WalletDataFragmentDoc}
  ${PaginationMetaFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetWalletsGQL extends Apollo.Query<GetWalletsQuery, GetWalletsQueryVariables> {
  document = GetWalletsDocument;
  client = 'payment';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DepositDocument = gql`
  query deposit($wallet_uuid: UUID!) {
    deposit(wallet_uuid: $wallet_uuid) {
      ...AccountData
    }
  }
  ${AccountDataFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class DepositGQL extends Apollo.Query<DepositQuery, DepositQueryVariables> {
  document = DepositDocument;
  client = 'payment';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetIndicativeRatesDocument = gql`
  query getIndicativeRates {
    indicativeRates {
      currency
      rates {
        ... on RatesCurrencyEntity {
          currency
          value
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetIndicativeRatesGQL extends Apollo.Query<GetIndicativeRatesQuery, GetIndicativeRatesQueryVariables> {
  document = GetIndicativeRatesDocument;
  client = 'payment';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateAccountDocument = gql`
  mutation createAccount($newAccount: NewBeneficiaryInput!) {
    createBeneficiary(newBeneficiary: $newAccount) {
      ...AccountData
    }
  }
  ${AccountDataFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CreateAccountGQL extends Apollo.Mutation<CreateAccountMutation, CreateAccountMutationVariables> {
  document = CreateAccountDocument;
  client = 'payment';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateAccountDocument = gql`
  mutation updateAccount($updateAccount: UpdateBeneficiaryInput!) {
    updateBeneficiary(updateAccount: $updateAccount) {
      ...AccountData
    }
  }
  ${AccountDataFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateAccountGQL extends Apollo.Mutation<UpdateAccountMutation, UpdateAccountMutationVariables> {
  document = UpdateAccountDocument;
  client = 'payment';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteAccountDocument = gql`
  mutation deleteAccount($deleteBeneficiaryInput: DeleteBeneficiaryInput!) {
    deleteBeneficiary(deleteBeneficiaryInput: $deleteBeneficiaryInput) {
      ...AccountData
    }
  }
  ${AccountDataFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class DeleteAccountGQL extends Apollo.Mutation<DeleteAccountMutation, DeleteAccountMutationVariables> {
  document = DeleteAccountDocument;
  client = 'payment';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CheckAccountDocument = gql`
  mutation checkAccount($checkAccountInput: BeneficiaryUuid!) {
    checkBeneficiary(beneficiaryUUID: $checkAccountInput) {
      ...AccountData
    }
  }
  ${AccountDataFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CheckAccountGQL extends Apollo.Mutation<CheckAccountMutation, CheckAccountMutationVariables> {
  document = CheckAccountDocument;
  client = 'payment';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SwitchDefaultDocument = gql`
  mutation switchDefault($switchDefaultAccountInput: BeneficiaryUuid!) {
    switchDefault(switchDefaultAccountInput: $switchDefaultAccountInput)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class SwitchDefaultGQL extends Apollo.Mutation<SwitchDefaultMutation, SwitchDefaultMutationVariables> {
  document = SwitchDefaultDocument;
  client = 'payment';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CheckReferenceCodeDocument = gql`
  mutation checkReferenceCode($validateAccountInput: ReferenceCode!) {
    checkReferenceCode(referenceCode: $validateAccountInput)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CheckReferenceCodeGQL extends Apollo.Mutation<
  CheckReferenceCodeMutation,
  CheckReferenceCodeMutationVariables
> {
  document = CheckReferenceCodeDocument;
  client = 'payment';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const WithdrawDocument = gql`
  mutation withdraw($withdrawDto: WithdrawDto!) {
    withdraw(withdrawDto: $withdrawDto) {
      status
      transaction_date
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class WithdrawGQL extends Apollo.Mutation<WithdrawMutation, WithdrawMutationVariables> {
  document = WithdrawDocument;
  client = 'payment';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddMoneyToWalletDocument = gql`
  mutation addMoneyToWallet($addMoneyToWalletDto: AddMoneyToWalletDto!) {
    addMoneyToWallet(addMoneyToWalletDto: $addMoneyToWalletDto)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AddMoneyToWalletGQL extends Apollo.Mutation<AddMoneyToWalletMutation, AddMoneyToWalletMutationVariables> {
  document = AddMoneyToWalletDocument;
  client = 'payment';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RemoveMoneyFromWalletDocument = gql`
  mutation removeMoneyFromWallet($removeMoneyToWalletInput: RemoveMoneyToWalletDto!) {
    removeMoneyFromWallet(removeMoneyFromWalletInput: $removeMoneyToWalletInput)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class RemoveMoneyFromWalletGQL extends Apollo.Mutation<
  RemoveMoneyFromWalletMutation,
  RemoveMoneyFromWalletMutationVariables
> {
  document = RemoveMoneyFromWalletDocument;
  client = 'payment';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const EditWalletExternalIdsDocument = gql`
  mutation editWalletExternalIds($updateWalletIdsDto: UpdateWalletIdsDto!) {
    editWalletExternalIds(updateWalletIdsDto: $updateWalletIdsDto)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class EditWalletExternalIdsGQL extends Apollo.Mutation<
  EditWalletExternalIdsMutation,
  EditWalletExternalIdsMutationVariables
> {
  document = EditWalletExternalIdsDocument;
  client = 'payment';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SetPayoutFrequencyDocument = gql`
  mutation setPayoutFrequency($setPayoutFrequencyDto: SetPayoutFrequencyDto!) {
    setPayoutFrequency(setPayoutFrequencyDto: $setPayoutFrequencyDto) {
      ...WalletData
    }
  }
  ${WalletDataFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class SetPayoutFrequencyGQL extends Apollo.Mutation<
  SetPayoutFrequencyMutation,
  SetPayoutFrequencyMutationVariables
> {
  document = SetPayoutFrequencyDocument;
  client = 'payment';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SetCommissionPayoutFrequencyDocument = gql`
  mutation setCommissionPayoutFrequency($setCommissionPayoutFrequencyDto: SetPayoutFrequencyDto!) {
    setCommissionPayoutFrequency(setCommissionPayoutFrequencyDto: $setCommissionPayoutFrequencyDto) {
      ...WalletData
    }
  }
  ${WalletDataFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class SetCommissionPayoutFrequencyGQL extends Apollo.Mutation<
  SetCommissionPayoutFrequencyMutation,
  SetCommissionPayoutFrequencyMutationVariables
> {
  document = SetCommissionPayoutFrequencyDocument;
  client = 'payment';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
