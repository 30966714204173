import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './page/login.component';
import { AuthorizedGuard } from '../../core/guards/authorized.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: { showHeader: false, showSidebar: false, showFooter: true },
    canActivate: [AuthorizedGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoginRoutingModule {}
