<tui-root>
  <fin-header *ngIf="showHeader"></fin-header>

  <!-- content of your app -->
  <div class="layout" [ngClass]="{ 'layout__only-footer': !showHeader && showFooter }">
    <router-outlet></router-outlet>
  </div>
  <!--
   If you need, you can add something between Taiga portal layers:
  -->
  <fin-footer *ngIf="showFooter"></fin-footer>

  <ng-container ngProjectAs="tuiOverDialogs">
    <!-- Content over dialogs-->
  </ng-container>
  <ng-container ngProjectAs="tuiOverNotifications">
    <!-- Content over notifications -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverPortals">
    <!-- Content over dropdowns -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverHints">
    <!-- Content over hints -->
  </ng-container>
  <fin-loader-screen></fin-loader-screen>
</tui-root>
<div id="particles-js"></div>
