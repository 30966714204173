import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TuiTabsModule } from '@taiga-ui/kit';
import { TuiButtonModule } from '@taiga-ui/core';

import {
  HeaderComponent,
  FooterComponent,
  SidebarComponent,
  NavbarComponent,
  LoaderScreenComponent,
} from './components';
import { ViewOnlyAccessModule } from '@app/shared/directives/view-only-access/view-only-access.module';

const LAYOUT_COMPONENTS = [HeaderComponent, FooterComponent, SidebarComponent, NavbarComponent, LoaderScreenComponent];

@NgModule({
  declarations: [LAYOUT_COMPONENTS],
  imports: [CommonModule, RouterModule, TuiTabsModule, TuiButtonModule, ViewOnlyAccessModule],
  exports: [LAYOUT_COMPONENTS],
})
export class LayoutModule {}
