import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewOnlyAccessDirective } from '@app/shared/directives/view-only-access/view-only-access.directive';

@NgModule({
  declarations: [ViewOnlyAccessDirective],
  imports: [CommonModule],
  exports: [ViewOnlyAccessDirective],
})
export class ViewOnlyAccessModule {}
