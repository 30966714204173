import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

import { fromEvent, interval, Subscription } from 'rxjs';
import { finalize, tap, throttleTime } from 'rxjs/operators';

import { TuiNotification } from '@taiga-ui/core';

import { AuthenticationService } from '@app/core/services/authentication.service';
import { NotificationsService } from '@app/core/services/notification.service';
import { environment } from '@env/environment';

export const USER_INACTIVITY_CHECK_INTERVAL = 60 * 1000;
export const MAX_USER_INACTIVITY_TIME = environment.maxUserInactivityMinutes * 60 * 1000;

@Injectable({
  providedIn: 'root',
})
export class UserActivityService {
  private lastUserAction: Date = new Date();

  private subscriptions: Subscription[] = [];
  private intervalSubscription: Subscription | null = null;

  private events: string[] = ['keydown', 'click', 'wheel', 'mousemove'];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private notificationService: NotificationsService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  init() {
    this.authenticationService.token$.subscribe((token) => {
      if (token) {
        this.startTracking();
      } else {
        this.stopTracking();
      }
    });
  }

  private trackUserAction(eventType: string) {
    return fromEvent(this.document, eventType).pipe(
      throttleTime(1000),
      tap(() => (this.lastUserAction = new Date()))
    );
  }

  private startTracking() {
    this.subscriptions = this.events.map((event) => this.trackUserAction(event).subscribe());

    this.intervalSubscription = interval(USER_INACTIVITY_CHECK_INTERVAL)
      .pipe(tap(() => this.checkForInactivity()))
      .subscribe();

    this.subscriptions.push(this.intervalSubscription);
  }

  private stopTracking() {
    if (this.subscriptions.length) {
      this.subscriptions.forEach((sub) => sub.unsubscribe());
      this.subscriptions = [];
    }

    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
    this.intervalSubscription = null;
  }

  private checkForInactivity() {
    const now = new Date();
    const diff = now.getTime() - this.lastUserAction.getTime();

    if (diff > MAX_USER_INACTIVITY_TIME) {
      let sub: Subscription;
      sub = this.authenticationService
        .logout$()
        .pipe(
          tap(() => {
            this.router.navigate(['login'], { replaceUrl: true }).then();
            this.notificationService
              .showNotification(`You have been logged out due to inactivity`, TuiNotification.Info, 'Session expired')
              .subscribe();
          }),
          finalize(() => sub.unsubscribe())
        )
        .subscribe();
    }
  }
}
