<header>
  <div class="tui-container">
    <div class="tui-row align-center justify-between">
      <div class="logo-container">
        <img class="header-logo cursor-pointer" [src]="env?.platform?.logoUrl" alt="LOGO" />
        <img
          *ngIf="env?.platform?.saasSuffixLogoPath"
          src="{{ env?.platform?.saasSuffixLogoPath }}"
          alt="powered by Finverity"
          (click)="router.navigate(['/'])"
          class="header-logo-suffix cursor-pointer"
        />
      </div>
      <div class="right-bar row align-center">
        <fin-navbar></fin-navbar>
        <button
          tuiButton
          type="button"
          class="tui-form__button tui-space_left-8"
          appearance="flat"
          size="m"
          (click)="logout()"
        >
          Sign Out
        </button>
      </div>
    </div>
  </div>
</header>
