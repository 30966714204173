import { NgModule, Optional, SkipSelf } from '@angular/core';

import { LayoutModule } from './layout';
import { SettingsModule } from './modules/settings';
import { httpInterceptorProviders } from './http-interceptors';

@NgModule({
  imports: [LayoutModule, SettingsModule],
  exports: [LayoutModule],
  providers: [...httpInterceptorProviders],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Singleton CoreModule Guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
