export enum LoginQaKey {
  LoginForm = 'LOGIN_FORM',
  LoginTitleH3 = 'LOGIN_TITLE_H3',
  LoginInputEmailP = 'LOGIN_INPUT_EMAIL_P',
  LoginInputEmailTuiInput = 'LOGIN_INPUT_EMAIL_TUI_INPUT',
  LoginInputPasswordP = 'LOGIN_INPUT_PASSWORD_P',
  LoginInputPasswordTuiInputPassword = 'LOGIN_INPUT_PASSWORD_TUI_INPUT_PASSWORD',
  LoginButton = 'LOGIN_BUTTON',
}

const dataCyForeachIndex = (index: number, key: LoginQaKey) => key + index;

export const LOGIN_QA_KEYS = {
  LoginForm: LoginQaKey.LoginForm,
  LoginTitleH3: LoginQaKey.LoginTitleH3,
  LoginInputEmailP: LoginQaKey.LoginInputEmailP,
  LoginInputEmailTuiInput: LoginQaKey.LoginInputEmailTuiInput,
  LoginInputPasswordP: LoginQaKey.LoginInputPasswordP,
  LoginInputPasswordTuiInputPassword: LoginQaKey.LoginInputPasswordTuiInputPassword,
  LoginButton: LoginQaKey.LoginButton,
};
