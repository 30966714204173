import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';
import { TUI_SANITIZER, TuiAlertModule, TuiDialogModule, TuiRootModule } from '@taiga-ui/core';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

import { environment } from '@env/environment';

import { CoreModule } from '@app/core';
import { GraphqlModule } from '@app/core/graphql/client';
import { SentryErrorHandlerService } from '@app/core/sentry';
import { LoginModule } from '@app/modules/login/login.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TuiAlertModule,
    TuiRootModule,
    TuiDialogModule,
    NgxJsonViewerModule,
    GraphqlModule,
    CoreModule,
    LoginModule,
    AppRoutingModule,
  ],
  providers: [
    environment.sentry.enabled
      ? {
          provide: ErrorHandler,
          useClass: SentryErrorHandlerService,
        }
      : [],
    { provide: TUI_SANITIZER, useClass: NgDompurifySanitizer },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
