import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { TuiNotification } from '@taiga-ui/core';

import { AuthenticationService, NotificationsService } from '@app/core/services';
import { dayjs } from '@app/core/dayjs';
import { ErrorHandler } from '../services/api-error-handler/error-handler';
import { RequestMethod } from '../services/api-error-handler/models/request-method.model';

/**
 * Adds a default error handler to all requests.
 */
export enum ErrorCodes {
  required = 0,
  number = 1,
  string = 2,
  boolean = 3,
  in = 4,
  maxStringLength = 5,
  minStringLength = 6,
  maxNumberLength = 7,
  maxNumber = 8,
  date = 9,
  email = 10,
  regex = 11,
  requiredIf = 12,
  requiredIfNot = 13,
  mustAbsentIf = 14,
  exists = 15,
  unique = 16,
  base64 = 17,
  size = 18,
  mimes = 19,
  equalTo = 20,
  phoneNumber = 21,
  stringEqualLength = 22,
  alreadySoled = 23,
  glnNotExists = 24,
  absent = 25,
  someInvoicesDoesNotExists = 26,
  taxInvoiceWrongFormat = 27,
  emailIsNotEqual = 28,
  notFound = 29,
  alreadyVerified = 30,
  wrongUserIdProvided = 31,
  unknownErrorSeeLogs = 32,
  passwordIsNotGood = 33,
  wrongAccountType = 34,
  statusIncorrectOrNotFunded = 35,
  userSignAbsent = 36,
  array = 37,
  isArrayOfNumbers = 38,
  maxFileSizeReached = 39,
  fieldIsUnique = 40,
  someInvoicesNotYours = 41,
  tooYoungFunder = 42,
  tooLongMaturityDate = 43,
  someRecordsDoesNotExists = 44,
  dateMustBeGreater = 45,
  recordMustBelongToUser = 46,
  recordNotExist = 47,
  invalidDatePair = 48,
  oneInvoicePerFunderAllowed = 49, // One invoice investment per funder allowed
  funderWalletCurrencyNotEqualAlreadyCreatedAssetPerInvoice = 50,
  assetAmountTooBig = 51,
  assetAmountTooSmall = 52,
  notEnoughFunds = 53,
  wrongFormat = 54,
  ibanWrongFormat = 56,
  swiftWrongFormat = 57,
  invoiceSellerRoleMismatch = 58,
  funderRoleMismatch = 59,
  statusIncorrectOrWrongId = 60,
  payerCanNotSetThisStatus = 61,
  sellerCanNotSetThisStatus = 62,
  mustBeGreater = 63,
  uuid = 64,
  hasStrategyWithSuchPayer = 65,
  mustBeOnePayer = 66,
  currencyMissMatch = 67,
  strategyAmountCanNotBeLessThanCurrentlyInvestedAmount = 68, // TODO PATCH `investment-strategies` {amount: [ErrorCodes.strategyAmountCanNotBeLessThanCurrentlyInvestedAmount]}
  isSellersRestricted = 69, // POST/ PATCH `invoices` {"is_sellers_restricted":[69],"invoice_no":"333222111A"}
  isArrayOfStrings = 70,
  referenceAlreadyAttached = 71,
  allInvoicesMustBeInOneCurrency = 72,
  referenceWasNotAttached = 73,
  referenceCanNotBeDeleted = 74,
  referenceCanNotBeAttached = 75,
  hasDiscountStrategyWithSuchInvoiceSeller = 76,
  notEnoughMoneyOnWallet = 77,
  directRepaymentStarted = 78,
  someSellersDoesNotExists = 79,
  mustBePositiveNumber = 80,
  walletUUIDIncorrect = 81,
  positive = 82,
  svgString = 83,
  somePayersDoesNotExists = 84,
  walletNotFound = 85,
  companyTypeMissMatch = 86,
  duplicate = 87,
  minOneCherOnNumber = 88,
  wrongDocumentType = 89,
  stringMustContainsOnlyNumbers = 90,
  sellerPayerRelationshipNotFound = 91,
  sellerPayerInvoiceNoExists = 92,
  maturityIsOnHoliday = 93,
}

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private notificationsService: NotificationsService,
    private authenticationService: AuthenticationService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((error) => this.errorHandler(error, request)));
  }

  private errorHandler(response: HttpEvent<any>, request: HttpRequest<any>): Observable<HttpEvent<any>> {
    console.log(response);
    if (response instanceof HttpErrorResponse && !request.headers.has('no-error-handle')) {
      let err = <HttpErrorResponse>response;
      const pathname = new URL(err.url as string).pathname;
      if (err.status === 401 && pathname && pathname === '/auth/login') {
        this.notificationsService.showNotification('Wrong email or password', TuiNotification.Error).subscribe();
        throw response;
      } else if (err.status === 401 && err?.error?.message === 're_login_needed') {
        if (!this.authenticationService.isAuthenticated()) {
          throw response;
        }

        this.notificationsService
          .showNotification('Your session has expired. Please login again.', TuiNotification.Warning)
          .subscribe();
        return this.authenticationService.logout$().pipe(
          tap(() => this.router.navigate(['login'])),
          map(() => response)
        );
      } else {
        if (err.status === 403 && err?.error?.message === 'platform_contract_confirmation_needed') {
          this.notificationsService
            .showNotification('Contract was not confirmed for this user', TuiNotification.Error)
            .subscribe();
          throw response;
        }
        if (err.status === 429) {
          console.log(
            '🚀 ~ file: error-handler.interceptor.ts ~ line 345 ~ ErrorHandlerInterceptor ~ errorHandler ~ err.status',
            err.status
          );
          if (pathname === '/auth/login') {
            let xRateLimitMilliseconds = 0;
            if (response.headers.has('X-RateLimit-Reset')) {
              xRateLimitMilliseconds = Number(response.headers.get('X-RateLimit-Reset'));
            }
            if (response.headers.has('x-ratelimit-reset')) {
              xRateLimitMilliseconds = Number(response.headers.get('x-ratelimit-reset'));
            }
            const xRateLimitTime = dayjs.unix(xRateLimitMilliseconds).format('HH:mm:ss');
            this.notificationsService
              .showNotification(
                `Too many requests. Please try again later. Please try again after ${xRateLimitTime}.`,
                TuiNotification.Error
              )
              .subscribe();
          } else {
            this.notificationsService
              .showNotification('Too many requests. Please try again later.', TuiNotification.Error)
              .subscribe();
          }
          throw response;
        }
        if (
          response.headers.has('access-control-allow-origin') ||
          response.headers.has('Access-Control-Allow-Origin')
        ) {
          if (err.status === 409 && err.error && err.error.message === 'use_old_login') {
            throw response;
          } else if (err.status !== 401) {
            if (!!err.url) {
              let msg: string | null;

              msg = ErrorHandler.getErrorMessage(
                err.url,
                request.method.toUpperCase() as RequestMethod,
                err.error.message
              );

              if (!msg) {
                msg = this.generateErrorMessage(
                  err.status + ':' + new URL(err.url).pathname,
                  err.error['message'] || err.error
                );
              }

              this.notificationsService.showNotification(msg, TuiNotification.Error).subscribe();
            }
          }
        } else {
          if (err.status === 409 && err.error && err.error.message === 'use_old_login') {
            throw response;
          }
          this.notificationsService
            .showNotification('API not available at this moment.', TuiNotification.Error)
            .subscribe();
        }
      }
    }
    throw response;
  }

  private generateErrorMessage(requestTuple: string, error: any): string {
    console.log('requestTuple', requestTuple);

    switch (requestTuple) {
      case '422:/sellers':
      case '422:/sellers/' + requestTuple.slice('422:/sellers/'.length): {
        if (error && error['registration_no']?.includes(ErrorCodes.unique)) {
          return 'Registration No is busy';
        }
        if (error && error['name']?.includes(ErrorCodes.unique)) {
          return 'Such a company name is already used.';
        }
        if (error && error['email']?.includes(ErrorCodes.unique)) {
          return 'Such email already is used.';
        }
        if (error && error['yearly_turnover']?.includes(ErrorCodes.maxNumber)) {
          // TODO: SEND ERROR TEXT FOR REVIEW
          return 'Yearly turnover reached maximum value';
        }
        return this.undefinedErrorMessage(requestTuple, error);
      }
      case '422:/payers':
      case '422:/payers/' + requestTuple.slice('422:/payers/'.length): {
        if (error && error['registration_no']?.includes(ErrorCodes.unique)) {
          return 'Registration No is busy';
        }
        if (error && error['name']?.includes(ErrorCodes.unique)) {
          return 'Such a company name is already used.';
        }
        if (error && error['email']?.includes(ErrorCodes.unique)) {
          return 'Such email already is used.';
        }
        return this.undefinedErrorMessage(requestTuple, error);
      }
      case '422:/funders':
      case '422:/funders/' + requestTuple.slice('422:/funders/'.length): {
        if (error && error['registration_no'].includes(ErrorCodes.unique)) {
          return 'Registration No is busy';
        }
        if (error && error['name']?.includes(ErrorCodes.unique)) {
          return 'Such a company name is already used.';
        }
        if (error && error['email']?.includes(ErrorCodes.unique)) {
          return 'Such email already is used.';
        }
        return this.undefinedErrorMessage(requestTuple, error);
      }
      case '400:/sellers':
      case '400:/sellers/' + requestTuple.slice('400:/sellers/'.length): {
        if (error === 'reg_no_or_tax_id_or_name_is_busy') {
          return 'Registration Number or Tax ID or Company Name is busy';
        }
        if (error === 'no_default_beneficiary_found') {
          return 'No default beneficiary found';
        }
        return this.undefinedErrorMessage(requestTuple, error);
      }
      case '400:/funders':
      case '400:/funders/' + requestTuple.slice('400:/funders/'.length): {
        if (error === 'company_exists') {
          return 'Such a company name is already used.';
        }
        if (error === 'no_default_beneficiary_found') {
          return 'No default beneficiary found';
        }
        if (error === 'reg_no_or_tax_id_or_name_is_busy' || error.includes('name must be unique')) {
          return 'Registration Number or Tax ID or Company Name is busy';
        }
        return this.undefinedErrorMessage(requestTuple, error);
      }
      case '409:/sellers':
      case '409:/sellers/' + requestTuple.slice('409:/sellers/'.length):
      case '409:/payers':
      case '409:/payers/' + requestTuple.slice('409:/payers/'.length):
      case '409:/funders':
      case '409:/funders/' + requestTuple.slice('409:/funders/'.length): {
        if (error === 'reg_no_or_tax_id_is_busy') {
          return 'Such registration number or tax id already esixts.';
        }
        return this.undefinedErrorMessage(requestTuple, error);
      }
      case '400:/invoices':
      case '400:/invoices/' + requestTuple.slice('400:/invoices/'.length): {
        if (error === 'input_validation_failed') {
          return 'Input validation failed.';
        }
        return this.undefinedErrorMessage(requestTuple, error);
      }
      case '400:/companies' + requestTuple.slice('400:/companies'.length): {
        if (error === 'company_has_no_active_director_or_pos') {
          return 'Company has no active Director or PSC';
        }
        return this.undefinedErrorMessage(requestTuple, error);
      }
      case '400:/payers':
      case '400:/payers/' + requestTuple.slice('400:/payers/'.length): {
        if (error === 'no_default_beneficiary_found') {
          return 'No default beneficiary found';
        }

        if (error === 'reg_no_or_tax_id_or_name_is_busy' || error.includes('name must be unique')) {
          return 'Registration Number or Tax ID or Company Name is busy';
        }

        if (error.includes('tenor must not be greater than 360')) {
          return 'Tenor must not be greater than 360 days';
        }

        if (error.includes('earliest_funding must not be greater than 360')) {
          return 'Earliest funding date must not be greater than 360 days';
        }

        if (error.includes('latest_funding must not be greater than 360')) {
          return 'Latest funding date must not be greater than 360 days';
        }

        if (error.includes('extension_tenor must not be greater than 360')) {
          return 'Extension tenor must not be greater than 360 days';
        }

        if (error.includes('overview.annual_revenue must be a positive number')) {
          // TODO: SEND ERROR TEXT TO REVIEW
          return 'Annual Revenue must be a positive number';
        }

        return this.undefinedErrorMessage(requestTuple, error);
      }
      case '400:/payer-payments/proceed-repayment': {
        if (error === 'only_funded_invoice_may_be_marked_as_repaid') {
          return 'Only funded invoice may be marked as repaid';
        }
        return this.undefinedErrorMessage(requestTuple, error);
      }
      case '400:/payer-payments/send-money-to-seller': {
        if (error && error['invoices'].includes(ErrorCodes.statusIncorrectOrNotFunded)) {
          return 'Status of invoices are incorrect';
        }
        return this.undefinedErrorMessage(requestTuple, error);
      }
      case '400:/utils/start-checkout': {
        if (error === 'checkout_in_progress') {
          // TODO: SEND ERROR TEXT TO REVIEW
          return 'Only one checkout operation can be launched';
        }
        return this.undefinedErrorMessage(requestTuple, error);
      }
      case '404:/additional-fees':
      case '404:/additional-fees/' + requestTuple.slice('404:/additional-fees/'.length): {
        if (error === 'additional_fee_not_found') {
          return 'Additional fee not found';
        }
        if (error === 'one_company_type_needed') {
          return 'The request includes both a Payer Id and a Seller Id. Only one company type is required.';
        }

        return this.undefinedErrorMessage(requestTuple, error);
      }
      case '409:/company-representatives': {
        if (error === 'user_with_such_email_exists') {
          return 'Such email already is used.';
        }
        return this.undefinedErrorMessage(requestTuple, error);
      }
      case '400:/company-representatives': {
        if (error.includes('email must be an email')) {
          return 'You have entered an invalid e-mail format. Please try again.';
        }
        return this.undefinedErrorMessage(requestTuple, error);
      }
      default:
        return this.undefinedErrorMessage(requestTuple, error);
    }
  }

  private undefinedErrorMessage(requestTuple: string, error: any): string {
    switch (requestTuple) {
      case '403' + requestTuple.slice('403'.length): {
        return 'You have not access to this information';
      }
      case '404' + requestTuple.slice('404'.length): {
        return 'Ups… Something went wrong and your details are no longer in our Database. Please contact us.';
      }
    }
    return 'Ups… Sorry, something went wrong. Try refresh page or wait a 30 minutes';
  }
}
