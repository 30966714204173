import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

import * as sha512 from 'js-sha512';

@Injectable({
  providedIn: 'root',
})
export class HelpersService {
  constructor() {}

  static base64 = (value: string) => btoa(value);

  static encodeSHA512 = (value: string) => sha512.sha512(value);

  static randomString = () =>
    Array(32)
      .fill(0)
      .map(() => Math.random().toString(36).charAt(2))
      .join('');

  numberPattern(value: string, control: AbstractControl) {
    if (!value || !control) {
      return;
    }
    const changedValue = value.toString().replace(/[^0-9]/g, '');
    control.setValue(changedValue);
  }

  amountValueChange(value: string, control: AbstractControl) {
    if (!value || !control) {
      return;
    }
    let changedValue = value
      .toString()
      .replace(/[^\d\.\,\s]+/g, '')
      .replace(',', '.')
      .replace(' ', '');
    const arrayOfValue = changedValue.split('.');
    if (!arrayOfValue[0]) {
      arrayOfValue[0] = '0';
    }
    if (arrayOfValue.length >= 2) {
      changedValue = arrayOfValue[0] + `.` + arrayOfValue[1].slice(0, 2);
    }
    control.setValue(changedValue);
  }
}

export const roundNumber = (value: number, decimals: number = 2) =>
  Number(Math.round(+`${value}e${decimals}`) + 'e-' + decimals);

export const isUndefined = (val: any): boolean => typeof val === 'undefined';
export const isNull = (val: any): boolean => val === null;
export const isNil = (val: any): boolean => isUndefined(val) || isNull(val);
export const isString = (val: any): boolean => typeof val === 'string' || val instanceof String;
export const hasLength = (val: any): boolean => val.length > 0;
export const isStringFull = (val: any): boolean => isString(val) && hasLength(val);
export const isArrayFull = (val: any): boolean => Array.isArray(val) && hasLength(val);
export const isArrayStrings = (val: any): boolean =>
  isArrayFull(val) && (val as string[]).every((v) => isStringFull(v));
export const objKeys = (val: any): string[] => Object.keys(val);
export const getOwnPropNames = (val: any): string[] => Object.getOwnPropertyNames(val);
export const isObject = (val: any): boolean => typeof val === 'object' && !isNull(val);
export const isObjectFull = (val: any) => isObject(val) && hasLength(objKeys(val));
export const isNumber = (val: any): boolean => typeof val === 'number' && !Number.isNaN(val) && Number.isFinite(val);
export const isEqual = (val: any, eq: any): boolean => val === eq;
export const isFalse = (val: any): boolean => val === false;
export const isTrue = (val: any): boolean => val === true;
export const isIn = (val: any, arr: any[] = []): boolean => arr.some((o) => isEqual(val, o));
export const isBoolean = (val: any): boolean => typeof val === 'boolean';
export const isNumeric = (val: any): boolean => /^[+-]?([0-9]*[.])?[0-9]+$/.test(val);
export const isDateString = (val: any): boolean =>
  isStringFull(val) &&
  /^\d{4}-[01]\d-[0-3]\d(?:T[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)?(?:Z|[-+][0-2]\d(?::?[0-5]\d)?)?)?$/g.test(val);
export const isDate = (val: any): val is Date => val instanceof Date;
export const isValue = (val: any): boolean => isStringFull(val) || isNumber(val) || isBoolean(val) || isDate(val);
export const hasValue = (val: any): boolean =>
  isArrayFull(val) ? (val as any[]).every((o) => isValue(o)) : isValue(val);
export const isFunction = (val: any): boolean => typeof val === 'function';
export const isFormGroup = (control: AbstractControl): control is FormGroup => !!(control as FormGroup).controls;
export const valueToNullOrZero = (val: any) => {
  if (isNull(val)) {
    return 0;
  }

  if (isUndefined(val)) {
    return null;
  }

  return val;
};
export const valueToZeroOrNull = (val: any) => {
  if (isUndefined(val)) {
    return null;
  }

  return val;
};
export const stripNameSpecialCharacters = (str: string): string => {
  return str.replace(/[^A-Za-z0-9-_'"“”‘’()/\s]/gi, '');
};
export const isSafari = (): boolean => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};
