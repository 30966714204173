import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { ViewOnlyAccessService } from '@app/core/services/view-only-access.service';

@Directive({
  selector: '[finViewOnlyAccess]',
})
export class ViewOnlyAccessDirective {
  constructor(
    private viewOnlyAccessService: ViewOnlyAccessService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  ngOnInit() {
    if (!this.viewOnlyAccessService.viewOnlyAccessValue) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
