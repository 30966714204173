import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { TuiAlertService, TuiNotification } from '@taiga-ui/core';
import { ViewOnlyAccessService } from '@app/core/services/view-only-access.service';

@Injectable({
  providedIn: 'root',
})
export class ViewOnlyAccessGuard implements CanActivate {
  constructor(
    private readonly notificationsService: TuiAlertService,
    private viewOnlyAccessService: ViewOnlyAccessService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.viewOnlyAccessService.viewOnlyAccessValue) {
      this.notificationsService
        .open('Sorry, you can not access for this functional', {
          label: 'No access',
          status: TuiNotification.Warning,
        })
        .subscribe();
      return false;
    }

    return true;
  }
}
