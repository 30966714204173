import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Cursor for paging through collections */
  ConnectionCursor: any;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  NullableUUID: string;
  PositiveFloatBetween0And9007199254740991: number;
  PositiveIntBetween0And11: number;
  PositiveIntBetween0And9007199254740991: number;
  PositiveIntBetween1And31: number;
  PositiveIntBetween1900And2008: number;
  RequiredStringLimitedTo2: string;
  RequiredStringLimitedTo50: string;
  RequiredStringLimitedTo255: string;
  StringLimitedTo10: string;
  StringLimitedTo50: string;
  StringLimitedTo255: string;
  UUID: string;
};

export type AddAdditional_DocumentsToCompanyInput = {
  /** The id of the record. */
  id: Scalars['Int'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Int']>;
};

export type AddAdditional_DocumentsToPersonInput = {
  /** The id of the record. */
  id: Scalars['Int'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Int']>;
};

export type AddAdditional_FeesToCompanyInput = {
  /** The id of the record. */
  id: Scalars['Int'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Int']>;
};

export type AddCompaniesToCompanyInput = {
  /** The id of the record. */
  id: Scalars['Int'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Int']>;
};

export type AddPersonsToCompanyInput = {
  /** The id of the record. */
  id: Scalars['Int'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Int']>;
};

export type AdditionalDocument = {
  __typename?: 'AdditionalDocument';
  company_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['DateTime'];
  description: Scalars['RequiredStringLimitedTo255'];
  document_uuid: Scalars['UUID'];
  id?: Maybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  original_name?: Maybe<Scalars['StringLimitedTo255']>;
  person_id?: Maybe<Scalars['Int']>;
  type: AdditionalDocumentsTypes;
};

export type AdditionalDocumentAggregateGroupBy = {
  __typename?: 'AdditionalDocumentAggregateGroupBy';
  document_uuid?: Maybe<Scalars['NullableUUID']>;
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<AdditionalDocumentsTypes>;
};

export type AdditionalDocumentAvgAggregate = {
  __typename?: 'AdditionalDocumentAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

export type AdditionalDocumentCountAggregate = {
  __typename?: 'AdditionalDocumentCountAggregate';
  document_uuid?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
};

export type AdditionalDocumentDeleteResponse = {
  __typename?: 'AdditionalDocumentDeleteResponse';
  company_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['StringLimitedTo255']>;
  document_uuid?: Maybe<Scalars['NullableUUID']>;
  id?: Maybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  original_name?: Maybe<Scalars['StringLimitedTo255']>;
  person_id?: Maybe<Scalars['Int']>;
  type?: Maybe<AdditionalDocumentsTypes>;
};

export type AdditionalDocumentFilter = {
  and?: InputMaybe<Array<AdditionalDocumentFilter>>;
  document_uuid?: InputMaybe<UuidFilterComparison>;
  id?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<AdditionalDocumentFilter>>;
  type?: InputMaybe<AdditionalDocumentsTypesFilterComparison>;
};

export type AdditionalDocumentMaxAggregate = {
  __typename?: 'AdditionalDocumentMaxAggregate';
  document_uuid?: Maybe<Scalars['NullableUUID']>;
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<AdditionalDocumentsTypes>;
};

export type AdditionalDocumentMinAggregate = {
  __typename?: 'AdditionalDocumentMinAggregate';
  document_uuid?: Maybe<Scalars['NullableUUID']>;
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<AdditionalDocumentsTypes>;
};

export type AdditionalDocumentSort = {
  direction: SortDirection;
  field: AdditionalDocumentSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum AdditionalDocumentSortFields {
  DocumentUuid = 'document_uuid',
  Id = 'id',
  Type = 'type',
}

export type AdditionalDocumentSumAggregate = {
  __typename?: 'AdditionalDocumentSumAggregate';
  id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

export enum AdditionalDocumentsTypes {
  Common = 'common',
  DigitallySigned = 'digitally_signed',
  SignedOffline = 'signed_offline',
}

export type AdditionalDocumentsTypesFilterComparison = {
  eq?: InputMaybe<AdditionalDocumentsTypes>;
  gt?: InputMaybe<AdditionalDocumentsTypes>;
  gte?: InputMaybe<AdditionalDocumentsTypes>;
  iLike?: InputMaybe<AdditionalDocumentsTypes>;
  in?: InputMaybe<Array<AdditionalDocumentsTypes>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<AdditionalDocumentsTypes>;
  lt?: InputMaybe<AdditionalDocumentsTypes>;
  lte?: InputMaybe<AdditionalDocumentsTypes>;
  neq?: InputMaybe<AdditionalDocumentsTypes>;
  notILike?: InputMaybe<AdditionalDocumentsTypes>;
  notIn?: InputMaybe<Array<AdditionalDocumentsTypes>>;
  notLike?: InputMaybe<AdditionalDocumentsTypes>;
};

export type AdditionalFee = {
  __typename?: 'AdditionalFee';
  company_id?: Maybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  description: Scalars['RequiredStringLimitedTo50'];
  fee?: Maybe<Scalars['PositiveFloatBetween0And9007199254740991']>;
  id?: Maybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type AdditionalFeeAggregateGroupBy = {
  __typename?: 'AdditionalFeeAggregateGroupBy';
  id?: Maybe<Scalars['Int']>;
};

export type AdditionalFeeAvgAggregate = {
  __typename?: 'AdditionalFeeAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type AdditionalFeeCountAggregate = {
  __typename?: 'AdditionalFeeCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type AdditionalFeeDeleteResponse = {
  __typename?: 'AdditionalFeeDeleteResponse';
  company_id?: Maybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  created_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['StringLimitedTo50']>;
  fee?: Maybe<Scalars['PositiveFloatBetween0And9007199254740991']>;
  id?: Maybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type AdditionalFeeFilter = {
  and?: InputMaybe<Array<AdditionalFeeFilter>>;
  id?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<AdditionalFeeFilter>>;
};

export type AdditionalFeeMaxAggregate = {
  __typename?: 'AdditionalFeeMaxAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type AdditionalFeeMinAggregate = {
  __typename?: 'AdditionalFeeMinAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type AdditionalFeeSort = {
  direction: SortDirection;
  field: AdditionalFeeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum AdditionalFeeSortFields {
  Id = 'id',
}

export type AdditionalFeeSumAggregate = {
  __typename?: 'AdditionalFeeSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type Address = {
  __typename?: 'Address';
  address_line: Scalars['RequiredStringLimitedTo255'];
  country?: Maybe<Country>;
  country_id?: Maybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  created_at: Scalars['DateTime'];
  id?: Maybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  place_details?: Maybe<Scalars['JSON']>;
  postal_code: Scalars['RequiredStringLimitedTo50'];
  proof_expired_at?: Maybe<Scalars['DateTime']>;
  proof_uuid: Scalars['UUID'];
  updated_at: Scalars['DateTime'];
};

export type AddressAggregateGroupBy = {
  __typename?: 'AddressAggregateGroupBy';
  id?: Maybe<Scalars['Int']>;
};

export type AddressAvgAggregate = {
  __typename?: 'AddressAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type AddressConnection = {
  __typename?: 'AddressConnection';
  /** Array of edges. */
  edges: Array<AddressEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type AddressCountAggregate = {
  __typename?: 'AddressCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type AddressDeleteFilter = {
  and?: InputMaybe<Array<AddressDeleteFilter>>;
  id?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<AddressDeleteFilter>>;
};

export type AddressDeleteResponse = {
  __typename?: 'AddressDeleteResponse';
  address_line?: Maybe<Scalars['StringLimitedTo255']>;
  country_id?: Maybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  created_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  place_details?: Maybe<Scalars['JSON']>;
  postal_code?: Maybe<Scalars['StringLimitedTo50']>;
  proof_expired_at?: Maybe<Scalars['DateTime']>;
  proof_uuid?: Maybe<Scalars['NullableUUID']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type AddressEdge = {
  __typename?: 'AddressEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Address */
  node: Address;
};

export type AddressFilter = {
  and?: InputMaybe<Array<AddressFilter>>;
  id?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<AddressFilter>>;
};

export type AddressMaxAggregate = {
  __typename?: 'AddressMaxAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type AddressMinAggregate = {
  __typename?: 'AddressMinAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type AddressSort = {
  direction: SortDirection;
  field: AddressSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum AddressSortFields {
  Id = 'id',
}

export type AddressSumAggregate = {
  __typename?: 'AddressSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

/** One of the: seller, funder, payer */
export enum ApplicantCompanyType {
  FundManager = 'fund_manager',
  Funder = 'funder',
  Payer = 'payer',
  PortalAdmin = 'portal_admin',
  Seller = 'seller',
}

export type ApplicantCompanyTypeFilterComparison = {
  eq?: InputMaybe<ApplicantCompanyType>;
  gt?: InputMaybe<ApplicantCompanyType>;
  gte?: InputMaybe<ApplicantCompanyType>;
  iLike?: InputMaybe<ApplicantCompanyType>;
  in?: InputMaybe<Array<ApplicantCompanyType>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<ApplicantCompanyType>;
  lt?: InputMaybe<ApplicantCompanyType>;
  lte?: InputMaybe<ApplicantCompanyType>;
  neq?: InputMaybe<ApplicantCompanyType>;
  notILike?: InputMaybe<ApplicantCompanyType>;
  notIn?: InputMaybe<Array<ApplicantCompanyType>>;
  notLike?: InputMaybe<ApplicantCompanyType>;
};

export type Company = {
  __typename?: 'Company';
  activated_at?: Maybe<Scalars['DateTime']>;
  additional_documents?: Maybe<Array<AdditionalDocument>>;
  additional_fees?: Maybe<Array<AdditionalFee>>;
  agreement_date: Scalars['DateTime'];
  /**
   * Portal admin User uuid. UUID from sso users table.
   *    Is set during company or person update(only if current company/person kyc_passed field
   *    is null or false and become true).
   */
  approver_uuid?: Maybe<Scalars['NullableUUID']>;
  companies?: Maybe<Array<Company>>;
  /** One of the: seller, funder, payer */
  company_type?: Maybe<ApplicantCompanyType>;
  company_uuid: Scalars['UUID'];
  created_at: Scalars['DateTime'];
  deactivated_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  /**
   * This flag is set after success kyc passing process.
   *      After that we can switch off kyc just updating this field - set to false.
   *      If this field is null - than kyc is in pending state - we check this company
   *      of person.
   */
  kyc_passed?: Maybe<Scalars['Boolean']>;
  kyc_status: KycStatus;
  name: Scalars['RequiredStringLimitedTo255'];
  /**
   * At the moment of writing - only for seller and payer company types.
   *     Example: Sole Proprietorship, Partnership, Corporation, Limited Liability Company (LLC)
   */
  organizational_form?: Maybe<Scalars['StringLimitedTo255']>;
  parent_id?: Maybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  persons?: Maybe<Array<Person>>;
  registration_address: Address;
  registration_address_id?: Maybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  registration_no: Scalars['RequiredStringLimitedTo255'];
  /** Status of the company in real world - is it operation now? */
  status: Status;
  /** At the time of writing(Now - 14.06.2021). Tax is only required for Romania */
  tax_id?: Maybe<Scalars['StringLimitedTo255']>;
  trading_address: Address;
  trading_address_id?: Maybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  /** One of the: regulated, unregulated, other */
  type: CompanyType;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type CompanyAdditional_DocumentsArgs = {
  filter?: InputMaybe<AdditionalDocumentFilter>;
  sorting?: InputMaybe<Array<AdditionalDocumentSort>>;
};

export type CompanyAdditional_FeesArgs = {
  filter?: InputMaybe<AdditionalFeeFilter>;
  sorting?: InputMaybe<Array<AdditionalFeeSort>>;
};

export type CompanyCompaniesArgs = {
  filter?: InputMaybe<CompanyFilter>;
  sorting?: InputMaybe<Array<CompanySort>>;
};

export type CompanyPersonsArgs = {
  filter?: InputMaybe<PersonFilter>;
  sorting?: InputMaybe<Array<PersonSort>>;
};

export type CompanyAggregateGroupBy = {
  __typename?: 'CompanyAggregateGroupBy';
  company_type?: Maybe<ApplicantCompanyType>;
  company_uuid?: Maybe<Scalars['NullableUUID']>;
  id?: Maybe<Scalars['Int']>;
  organizational_form?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
};

export type CompanyAvgAggregate = {
  __typename?: 'CompanyAvgAggregate';
  parent_id?: Maybe<Scalars['Float']>;
};

export type CompanyCompany_UuidFilterComparison = {
  eq?: InputMaybe<Scalars['NullableUUID']>;
};

export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  /** Array of edges. */
  edges: Array<CompanyEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type CompanyCountAggregate = {
  __typename?: 'CompanyCountAggregate';
  company_type?: Maybe<Scalars['Int']>;
  company_uuid?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  organizational_form?: Maybe<Scalars['Int']>;
  parent_id?: Maybe<Scalars['Int']>;
};

export type CompanyDeleteFilter = {
  and?: InputMaybe<Array<CompanyDeleteFilter>>;
  company_type?: InputMaybe<ApplicantCompanyTypeFilterComparison>;
  company_uuid?: InputMaybe<CompanyCompany_UuidFilterComparison>;
  id?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<CompanyDeleteFilter>>;
  organizational_form?: InputMaybe<StringFieldComparison>;
  parent_id?: InputMaybe<CompanyParent_IdFilterComparison>;
};

export type CompanyDeleteResponse = {
  __typename?: 'CompanyDeleteResponse';
  activated_at?: Maybe<Scalars['DateTime']>;
  agreement_date?: Maybe<Scalars['DateTime']>;
  /**
   * Portal admin User uuid. UUID from sso users table.
   *    Is set during company or person update(only if current company/person kyc_passed field
   *    is null or false and become true).
   */
  approver_uuid?: Maybe<Scalars['NullableUUID']>;
  /** One of the: seller, funder, payer */
  company_type?: Maybe<ApplicantCompanyType>;
  company_uuid?: Maybe<Scalars['NullableUUID']>;
  created_at?: Maybe<Scalars['DateTime']>;
  deactivated_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  /**
   * This flag is set after success kyc passing process.
   *      After that we can switch off kyc just updating this field - set to false.
   *      If this field is null - than kyc is in pending state - we check this company
   *      of person.
   */
  kyc_passed?: Maybe<Scalars['Boolean']>;
  kyc_status?: Maybe<KycStatus>;
  name?: Maybe<Scalars['StringLimitedTo255']>;
  /**
   * At the moment of writing - only for seller and payer company types.
   *     Example: Sole Proprietorship, Partnership, Corporation, Limited Liability Company (LLC)
   */
  organizational_form?: Maybe<Scalars['StringLimitedTo255']>;
  parent_id?: Maybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  registration_address_id?: Maybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  registration_no?: Maybe<Scalars['StringLimitedTo255']>;
  /** Status of the company in real world - is it operation now? */
  status?: Maybe<Status>;
  /** At the time of writing(Now - 14.06.2021). Tax is only required for Romania */
  tax_id?: Maybe<Scalars['StringLimitedTo255']>;
  trading_address_id?: Maybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  /** One of the: regulated, unregulated, other */
  type?: Maybe<CompanyType>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type CompanyEdge = {
  __typename?: 'CompanyEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Company */
  node: Company;
};

export type CompanyFilter = {
  additional_fees?: InputMaybe<CompanyFilterAdditionalFeeFilter>;
  and?: InputMaybe<Array<CompanyFilter>>;
  company_type?: InputMaybe<ApplicantCompanyTypeFilterComparison>;
  company_uuid?: InputMaybe<CompanyCompany_UuidFilterComparison>;
  id?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<CompanyFilter>>;
  organizational_form?: InputMaybe<StringFieldComparison>;
  parent_id?: InputMaybe<CompanyParent_IdFilterComparison>;
};

export type CompanyFilterAdditionalFeeFilter = {
  and?: InputMaybe<Array<CompanyFilterAdditionalFeeFilter>>;
  id?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<CompanyFilterAdditionalFeeFilter>>;
};

export type CompanyMaxAggregate = {
  __typename?: 'CompanyMaxAggregate';
  company_type?: Maybe<ApplicantCompanyType>;
  company_uuid?: Maybe<Scalars['NullableUUID']>;
  id?: Maybe<Scalars['Int']>;
  organizational_form?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
};

export type CompanyMinAggregate = {
  __typename?: 'CompanyMinAggregate';
  company_type?: Maybe<ApplicantCompanyType>;
  company_uuid?: Maybe<Scalars['NullableUUID']>;
  id?: Maybe<Scalars['Int']>;
  organizational_form?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
};

export type CompanyParent_IdFilterComparison = {
  is?: InputMaybe<Scalars['Boolean']>;
};

export type CompanySort = {
  direction: SortDirection;
  field: CompanySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CompanySortFields {
  CompanyType = 'company_type',
  CompanyUuid = 'company_uuid',
  Id = 'id',
  OrganizationalForm = 'organizational_form',
  ParentId = 'parent_id',
}

export type CompanySumAggregate = {
  __typename?: 'CompanySumAggregate';
  parent_id?: Maybe<Scalars['Float']>;
};

/** One of the: regulated, unregulated, other */
export enum CompanyType {
  Other = 'other',
  Regulated = 'regulated',
  Unregulated = 'unregulated',
}

export type Country = {
  __typename?: 'Country';
  alpha2_code: Scalars['RequiredStringLimitedTo2'];
  flag?: Maybe<Scalars['StringLimitedTo255']>;
  id?: Maybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  name: Scalars['RequiredStringLimitedTo255'];
  phone_code_prefix?: Maybe<Scalars['StringLimitedTo255']>;
};

export type CountryAggregateGroupBy = {
  __typename?: 'CountryAggregateGroupBy';
  alpha2_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type CountryAvgAggregate = {
  __typename?: 'CountryAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type CountryCountAggregate = {
  __typename?: 'CountryCountAggregate';
  alpha2_code?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type CountryFilter = {
  alpha2_code?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<CountryFilter>>;
  id?: InputMaybe<IntFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CountryFilter>>;
};

export type CountryMaxAggregate = {
  __typename?: 'CountryMaxAggregate';
  alpha2_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type CountryMinAggregate = {
  __typename?: 'CountryMinAggregate';
  alpha2_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type CountrySort = {
  direction: SortDirection;
  field: CountrySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CountrySortFields {
  Alpha2Code = 'alpha2_code',
  Id = 'id',
  Name = 'name',
}

export type CountrySumAggregate = {
  __typename?: 'CountrySumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type CreateAdditionalDocumentInput = {
  company_id?: InputMaybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  description: Scalars['RequiredStringLimitedTo255'];
  document_uuid: Scalars['UUID'];
  original_name: Scalars['RequiredStringLimitedTo255'];
  person_id?: InputMaybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  type: AdditionalDocumentsTypes;
};

export type CreateAdditionalFeeInput = {
  company_id?: InputMaybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  description: Scalars['RequiredStringLimitedTo50'];
  fee?: InputMaybe<Scalars['PositiveIntBetween0And9007199254740991']>;
};

export type CreateAddressInput = {
  address_line: Scalars['RequiredStringLimitedTo255'];
  country_id?: InputMaybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  place_details?: InputMaybe<Scalars['JSON']>;
  postal_code: Scalars['RequiredStringLimitedTo255'];
  proof_uuid: Scalars['UUID'];
};

export type CreateCompanyInput = {
  /** Start date on Fronted */
  activated_at: Scalars['DateTime'];
  /** Managed by frontend. Means when company signed agreement with the platform */
  agreement_date: Scalars['DateTime'];
  /** One of the: seller, funder, payer */
  company_type?: InputMaybe<ApplicantCompanyType>;
  company_uuid: Scalars['UUID'];
  /** End date on Frontend. Start data for Frontend is - created_at */
  deactivated_at?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['RequiredStringLimitedTo255'];
  /**
   * At the moment of writing - only for seller and payer company types.
   *     Example: Sole Proprietorship, Partnership, Corporation, Limited Liability Company (LLC)
   */
  organizational_form?: InputMaybe<Scalars['StringLimitedTo255']>;
  parent_id?: InputMaybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  registration_address_id?: InputMaybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  registration_no: Scalars['RequiredStringLimitedTo255'];
  /** Status of the company in real world - is it operation now? */
  status: Status;
  /** At the time of writing(Now - 14.06.2021). Tax is only required for Romania */
  tax_id?: InputMaybe<Scalars['StringLimitedTo255']>;
  trading_address_id?: InputMaybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  /** One of the: regulated, unregulated, other */
  type: CompanyType;
};

export type CreateManyAdditionalDocumentsInput = {
  /** Array of records to create */
  additionalDocuments: Array<CreateAdditionalDocumentInput>;
};

export type CreateManyAddressesInput = {
  /** Array of records to create */
  addresses: Array<CreateAddressInput>;
};

export type CreateManyPeopleInput = {
  /** Array of records to create */
  people: Array<CreatePersonInput>;
};

export type CreateOneAdditionalDocumentInput = {
  /** The record to create */
  additionalDocument: CreateAdditionalDocumentInput;
};

export type CreateOneAdditionalFeeInput = {
  /** The record to create */
  additionalFee: CreateAdditionalFeeInput;
};

export type CreateOneAddressInput = {
  /** The record to create */
  address: CreateAddressInput;
};

export type CreateOneCompanyInput = {
  /** The record to create */
  company: CreateCompanyInput;
};

export type CreateOnePersonInput = {
  /** The record to create */
  person: CreatePersonInput;
};

export type CreatePersonInput = {
  /** Start date on Fronted */
  activated_at?: InputMaybe<Scalars['DateTime']>;
  address_id?: InputMaybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  /** Is nullable because we need to create person as individual funder. Is not optional for frontend - we do not create funder from frontend.  */
  company_id?: InputMaybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  company_uuid?: InputMaybe<Scalars['NullableUUID']>;
  day_of_birth?: InputMaybe<Scalars['PositiveIntBetween1And31']>;
  /** End date on Frontend. Start data for Frontend is - created_at */
  deactivated_at?: InputMaybe<Scalars['DateTime']>;
  first_name: Scalars['RequiredStringLimitedTo255'];
  is_director: Scalars['Boolean'];
  is_poc: Scalars['Boolean'];
  last_name: Scalars['RequiredStringLimitedTo255'];
  middle_name?: InputMaybe<Scalars['StringLimitedTo255']>;
  month_of_birth?: InputMaybe<Scalars['PositiveIntBetween0And11']>;
  national_id?: InputMaybe<Scalars['StringLimitedTo255']>;
  proof_of_identity_back_uuid: Scalars['UUID'];
  proof_of_identity_front_uuid?: InputMaybe<Scalars['NullableUUID']>;
  proof_of_identity_type: ProofOfIdentityType;
  /** Is optional because must be filled only for individual funders */
  source_of_wealth?: InputMaybe<Array<SourceOfWealth>>;
  status: Status;
  title?: InputMaybe<Scalars['StringLimitedTo10']>;
  year_of_birth?: InputMaybe<Scalars['PositiveIntBetween1900And2008']>;
};

export type CursorPaging = {
  /** Paginate after opaque cursor */
  after?: InputMaybe<Scalars['ConnectionCursor']>;
  /** Paginate before opaque cursor */
  before?: InputMaybe<Scalars['ConnectionCursor']>;
  /** Paginate first */
  first?: InputMaybe<Scalars['Int']>;
  /** Paginate last */
  last?: InputMaybe<Scalars['Int']>;
};

export type DeleteManyAddressesInput = {
  /** Filter to find records to delete */
  filter: AddressDeleteFilter;
};

export type DeleteManyCompaniesInput = {
  /** Filter to find records to delete */
  filter: CompanyDeleteFilter;
};

export type DeleteManyPeopleInput = {
  /** Filter to find records to delete */
  filter: PersonDeleteFilter;
};

export type DeleteManyResponse = {
  __typename?: 'DeleteManyResponse';
  /** The number of records deleted. */
  deletedCount: Scalars['Int'];
};

export type DeleteOneAdditionalDocumentInput = {
  /** The id of the record to delete. */
  id: Scalars['Int'];
};

export type DeleteOneAdditionalFeeInput = {
  /** The id of the record to delete. */
  id: Scalars['Int'];
};

export type DeleteOneAddressInput = {
  /** The id of the record to delete. */
  id: Scalars['Int'];
};

export type DeleteOneCompanyInput = {
  /** The id of the record to delete. */
  id: Scalars['Int'];
};

export type DeleteOnePersonInput = {
  /** The id of the record to delete. */
  id: Scalars['Int'];
};

export type IntFieldComparison = {
  between?: InputMaybe<IntFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  notBetween?: InputMaybe<IntFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntFieldComparisonBetween = {
  lower: Scalars['Int'];
  upper: Scalars['Int'];
};

/** Kyc status */
export enum KycStatus {
  Failed = 'failed',
  Passed = 'passed',
  Pending = 'pending',
}

export type Mutation = {
  __typename?: 'Mutation';
  addAdditional_documentsToCompany: Company;
  addAdditional_documentsToPerson: Person;
  addAdditional_feesToCompany: Company;
  addCompaniesToCompany: Company;
  addPersonsToCompany: Company;
  createManyAdditionalDocuments: Array<AdditionalDocument>;
  createManyAddresses: Array<Address>;
  createManyPeople: Array<Person>;
  createOneAdditionalDocument: AdditionalDocument;
  createOneAdditionalFee: AdditionalFee;
  createOneAddress: Address;
  createOneCompany: Company;
  createOnePerson: Person;
  deleteManyAddresses: DeleteManyResponse;
  deleteManyCompanies: DeleteManyResponse;
  deleteManyPeople: DeleteManyResponse;
  deleteOneAdditionalDocument: AdditionalDocumentDeleteResponse;
  deleteOneAdditionalFee: AdditionalFeeDeleteResponse;
  deleteOneAddress: AddressDeleteResponse;
  deleteOneCompany: CompanyDeleteResponse;
  deleteOnePerson: PersonDeleteResponse;
  setAdditional_documentsOnCompany: Company;
  setAdditional_documentsOnPerson: Person;
  setAdditional_feesOnCompany: Company;
  setAddressOnPerson: Person;
  setCompaniesOnCompany: Company;
  setCountryOnAddress: Address;
  setPersonsOnCompany: Company;
  setRegistration_addressOnCompany: Company;
  setTrading_addressOnCompany: Company;
  updateOneAdditionalDocument: AdditionalDocument;
  updateOneAdditionalFee: AdditionalFee;
  updateOneAddress: Address;
  updateOneCompany: Company;
  updateOnePerson: Person;
};

export type MutationAddAdditional_DocumentsToCompanyArgs = {
  input: AddAdditional_DocumentsToCompanyInput;
};

export type MutationAddAdditional_DocumentsToPersonArgs = {
  input: AddAdditional_DocumentsToPersonInput;
};

export type MutationAddAdditional_FeesToCompanyArgs = {
  input: AddAdditional_FeesToCompanyInput;
};

export type MutationAddCompaniesToCompanyArgs = {
  input: AddCompaniesToCompanyInput;
};

export type MutationAddPersonsToCompanyArgs = {
  input: AddPersonsToCompanyInput;
};

export type MutationCreateManyAdditionalDocumentsArgs = {
  input: CreateManyAdditionalDocumentsInput;
};

export type MutationCreateManyAddressesArgs = {
  input: CreateManyAddressesInput;
};

export type MutationCreateManyPeopleArgs = {
  input: CreateManyPeopleInput;
};

export type MutationCreateOneAdditionalDocumentArgs = {
  input: CreateOneAdditionalDocumentInput;
};

export type MutationCreateOneAdditionalFeeArgs = {
  input: CreateOneAdditionalFeeInput;
};

export type MutationCreateOneAddressArgs = {
  input: CreateOneAddressInput;
};

export type MutationCreateOneCompanyArgs = {
  input: CreateOneCompanyInput;
};

export type MutationCreateOnePersonArgs = {
  input: CreateOnePersonInput;
};

export type MutationDeleteManyAddressesArgs = {
  input: DeleteManyAddressesInput;
};

export type MutationDeleteManyCompaniesArgs = {
  input: DeleteManyCompaniesInput;
};

export type MutationDeleteManyPeopleArgs = {
  input: DeleteManyPeopleInput;
};

export type MutationDeleteOneAdditionalDocumentArgs = {
  input: DeleteOneAdditionalDocumentInput;
};

export type MutationDeleteOneAdditionalFeeArgs = {
  input: DeleteOneAdditionalFeeInput;
};

export type MutationDeleteOneAddressArgs = {
  input: DeleteOneAddressInput;
};

export type MutationDeleteOneCompanyArgs = {
  input: DeleteOneCompanyInput;
};

export type MutationDeleteOnePersonArgs = {
  input: DeleteOnePersonInput;
};

export type MutationSetAdditional_DocumentsOnCompanyArgs = {
  input: SetAdditional_DocumentsOnCompanyInput;
};

export type MutationSetAdditional_DocumentsOnPersonArgs = {
  input: SetAdditional_DocumentsOnPersonInput;
};

export type MutationSetAdditional_FeesOnCompanyArgs = {
  input: SetAdditional_FeesOnCompanyInput;
};

export type MutationSetAddressOnPersonArgs = {
  input: SetAddressOnPersonInput;
};

export type MutationSetCompaniesOnCompanyArgs = {
  input: SetCompaniesOnCompanyInput;
};

export type MutationSetCountryOnAddressArgs = {
  input: SetCountryOnAddressInput;
};

export type MutationSetPersonsOnCompanyArgs = {
  input: SetPersonsOnCompanyInput;
};

export type MutationSetRegistration_AddressOnCompanyArgs = {
  input: SetRegistration_AddressOnCompanyInput;
};

export type MutationSetTrading_AddressOnCompanyArgs = {
  input: SetTrading_AddressOnCompanyInput;
};

export type MutationUpdateOneAdditionalDocumentArgs = {
  input: UpdateOneAdditionalDocumentInput;
};

export type MutationUpdateOneAdditionalFeeArgs = {
  input: UpdateOneAdditionalFeeInput;
};

export type MutationUpdateOneAddressArgs = {
  input: UpdateOneAddressInput;
};

export type MutationUpdateOneCompanyArgs = {
  input: UpdateOneCompanyInput;
};

export type MutationUpdateOnePersonArgs = {
  input: UpdateOnePersonInput;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** The cursor of the last returned record. */
  endCursor?: Maybe<Scalars['ConnectionCursor']>;
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  /** The cursor of the first returned record. */
  startCursor?: Maybe<Scalars['ConnectionCursor']>;
};

export type Person = {
  __typename?: 'Person';
  activated_at?: Maybe<Scalars['DateTime']>;
  additional_documents?: Maybe<Array<AdditionalDocument>>;
  address?: Maybe<Address>;
  address_id?: Maybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  /**
   * Portal admin User uuid.
   *    UUID from sso users table.
   *    Is set during company or person update(only if current company/person kyc_passed field is null or false and become true).
   */
  approver_uuid?: Maybe<Scalars['NullableUUID']>;
  /** Is nullable because we need to create person as individual funder. Is not optional for frontend - we do not create funder from frontend.  */
  company_id?: Maybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  /** Globally unique company uuid. Company stored in monolith( seller|payers|funders ) for now(Now - 14.06.2021). */
  company_uuid?: Maybe<Scalars['NullableUUID']>;
  created_at: Scalars['DateTime'];
  day_of_birth?: Maybe<Scalars['PositiveIntBetween1And31']>;
  deactivated_at?: Maybe<Scalars['DateTime']>;
  first_name: Scalars['RequiredStringLimitedTo255'];
  id?: Maybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  is_director: Scalars['Boolean'];
  is_poc: Scalars['Boolean'];
  /**
   * This flag is set after success kyc passing process.
   *      After that we can switch off kyc just updating this field - set to false.
   *      If this field is null - than kyc is in pending state - we check this company
   *      of person
   */
  kyc_passed?: Maybe<Scalars['Boolean']>;
  kyc_status: KycStatus;
  last_name: Scalars['RequiredStringLimitedTo255'];
  middle_name?: Maybe<Scalars['StringLimitedTo255']>;
  month_of_birth?: Maybe<Scalars['PositiveIntBetween0And11']>;
  /** National ethnicity */
  national_id?: Maybe<Scalars['StringLimitedTo255']>;
  /** Link to document uuid stored in document service  */
  proof_of_identity_back_uuid: Scalars['UUID'];
  proof_of_identity_expired_at?: Maybe<Scalars['DateTime']>;
  /** Link to document uuid stored in document service  */
  proof_of_identity_front_uuid?: Maybe<Scalars['NullableUUID']>;
  proof_of_identity_type: ProofOfIdentityType;
  /** Is optional because must be filled only for individual funders */
  source_of_wealth?: Maybe<Array<SourceOfWealth>>;
  /** Status of the company in real world - is it operation now? */
  status?: Maybe<Status>;
  title?: Maybe<Scalars['StringLimitedTo10']>;
  year_of_birth?: Maybe<Scalars['PositiveIntBetween1900And2008']>;
};

export type PersonAdditional_DocumentsArgs = {
  filter?: InputMaybe<AdditionalDocumentFilter>;
  sorting?: InputMaybe<Array<AdditionalDocumentSort>>;
};

export type PersonAggregateGroupBy = {
  __typename?: 'PersonAggregateGroupBy';
  id?: Maybe<Scalars['Int']>;
};

export type PersonAvgAggregate = {
  __typename?: 'PersonAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type PersonConnection = {
  __typename?: 'PersonConnection';
  /** Array of edges. */
  edges: Array<PersonEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type PersonCountAggregate = {
  __typename?: 'PersonCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type PersonDeleteFilter = {
  and?: InputMaybe<Array<PersonDeleteFilter>>;
  id?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<PersonDeleteFilter>>;
};

export type PersonDeleteResponse = {
  __typename?: 'PersonDeleteResponse';
  activated_at?: Maybe<Scalars['DateTime']>;
  address_id?: Maybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  /**
   * Portal admin User uuid.
   *    UUID from sso users table.
   *    Is set during company or person update(only if current company/person kyc_passed field is null or false and become true).
   */
  approver_uuid?: Maybe<Scalars['NullableUUID']>;
  /** Is nullable because we need to create person as individual funder. Is not optional for frontend - we do not create funder from frontend.  */
  company_id?: Maybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  /** Globally unique company uuid. Company stored in monolith( seller|payers|funders ) for now(Now - 14.06.2021). */
  company_uuid?: Maybe<Scalars['NullableUUID']>;
  created_at?: Maybe<Scalars['DateTime']>;
  day_of_birth?: Maybe<Scalars['PositiveIntBetween1And31']>;
  deactivated_at?: Maybe<Scalars['DateTime']>;
  first_name?: Maybe<Scalars['StringLimitedTo255']>;
  id?: Maybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  is_director?: Maybe<Scalars['Boolean']>;
  is_poc?: Maybe<Scalars['Boolean']>;
  /**
   * This flag is set after success kyc passing process.
   *      After that we can switch off kyc just updating this field - set to false.
   *      If this field is null - than kyc is in pending state - we check this company
   *      of person
   */
  kyc_passed?: Maybe<Scalars['Boolean']>;
  kyc_status?: Maybe<KycStatus>;
  last_name?: Maybe<Scalars['StringLimitedTo255']>;
  middle_name?: Maybe<Scalars['StringLimitedTo255']>;
  month_of_birth?: Maybe<Scalars['PositiveIntBetween0And11']>;
  /** National ethnicity */
  national_id?: Maybe<Scalars['StringLimitedTo255']>;
  /** Link to document uuid stored in document service  */
  proof_of_identity_back_uuid?: Maybe<Scalars['NullableUUID']>;
  proof_of_identity_expired_at?: Maybe<Scalars['DateTime']>;
  /** Link to document uuid stored in document service  */
  proof_of_identity_front_uuid?: Maybe<Scalars['NullableUUID']>;
  proof_of_identity_type?: Maybe<ProofOfIdentityType>;
  /** Is optional because must be filled only for individual funders */
  source_of_wealth?: Maybe<Array<SourceOfWealth>>;
  /** Status of the company in real world - is it operation now? */
  status?: Maybe<Status>;
  title?: Maybe<Scalars['StringLimitedTo10']>;
  year_of_birth?: Maybe<Scalars['PositiveIntBetween1900And2008']>;
};

export type PersonEdge = {
  __typename?: 'PersonEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Person */
  node: Person;
};

export type PersonFilter = {
  and?: InputMaybe<Array<PersonFilter>>;
  id?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<PersonFilter>>;
};

export type PersonMaxAggregate = {
  __typename?: 'PersonMaxAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type PersonMinAggregate = {
  __typename?: 'PersonMinAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type PersonSort = {
  direction: SortDirection;
  field: PersonSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PersonSortFields {
  Id = 'id',
}

export type PersonSumAggregate = {
  __typename?: 'PersonSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export enum ProofOfIdentityType {
  DriverLicense = 'driver_license',
  IdentityCard = 'identity_card',
  Passport = 'passport',
}

export type Query = {
  __typename?: 'Query';
  additionalDocument?: Maybe<AdditionalDocument>;
  additionalDocuments: Array<AdditionalDocument>;
  additionalFee?: Maybe<AdditionalFee>;
  additionalFees: Array<AdditionalFee>;
  address?: Maybe<Address>;
  addresses: AddressConnection;
  companies: CompanyConnection;
  company?: Maybe<Company>;
  countries: Array<Country>;
  country?: Maybe<Country>;
  people: PersonConnection;
  person?: Maybe<Person>;
};

export type QueryAdditionalDocumentArgs = {
  id: Scalars['Int'];
};

export type QueryAdditionalDocumentsArgs = {
  filter?: InputMaybe<AdditionalDocumentFilter>;
  sorting?: InputMaybe<Array<AdditionalDocumentSort>>;
};

export type QueryAdditionalFeeArgs = {
  id: Scalars['Int'];
};

export type QueryAdditionalFeesArgs = {
  filter?: InputMaybe<AdditionalFeeFilter>;
  sorting?: InputMaybe<Array<AdditionalFeeSort>>;
};

export type QueryAddressArgs = {
  id: Scalars['Int'];
};

export type QueryAddressesArgs = {
  filter?: InputMaybe<AddressFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<AddressSort>>;
};

export type QueryCompaniesArgs = {
  filter?: InputMaybe<CompanyFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<CompanySort>>;
};

export type QueryCompanyArgs = {
  id: Scalars['Int'];
};

export type QueryCountriesArgs = {
  filter?: InputMaybe<CountryFilter>;
  sorting?: InputMaybe<Array<CountrySort>>;
};

export type QueryCountryArgs = {
  id: Scalars['Int'];
};

export type QueryPeopleArgs = {
  filter?: InputMaybe<PersonFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<PersonSort>>;
};

export type QueryPersonArgs = {
  id: Scalars['Int'];
};

export type SetAdditional_DocumentsOnCompanyInput = {
  /** The id of the record. */
  id: Scalars['Int'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Int']>;
};

export type SetAdditional_DocumentsOnPersonInput = {
  /** The id of the record. */
  id: Scalars['Int'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Int']>;
};

export type SetAdditional_FeesOnCompanyInput = {
  /** The id of the record. */
  id: Scalars['Int'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Int']>;
};

export type SetAddressOnPersonInput = {
  /** The id of the record. */
  id: Scalars['Int'];
  /** The id of relation. */
  relationId: Scalars['Int'];
};

export type SetCompaniesOnCompanyInput = {
  /** The id of the record. */
  id: Scalars['Int'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Int']>;
};

export type SetCountryOnAddressInput = {
  /** The id of the record. */
  id: Scalars['Int'];
  /** The id of relation. */
  relationId: Scalars['Int'];
};

export type SetPersonsOnCompanyInput = {
  /** The id of the record. */
  id: Scalars['Int'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['Int']>;
};

export type SetRegistration_AddressOnCompanyInput = {
  /** The id of the record. */
  id: Scalars['Int'];
  /** The id of relation. */
  relationId: Scalars['Int'];
};

export type SetTrading_AddressOnCompanyInput = {
  /** The id of the record. */
  id: Scalars['Int'];
  /** The id of relation. */
  relationId: Scalars['Int'];
};

/** Sort Directions */
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

/** Sort Nulls Options */
export enum SortNulls {
  NullsFirst = 'NULLS_FIRST',
  NullsLast = 'NULLS_LAST',
}

/** Source Of Wealth */
export enum SourceOfWealth {
  AssetSale = 'asset_sale',
  BirthRight = 'birth_right',
  Gift = 'gift',
  Insurance = 'insurance',
  InvestmentMaturity = 'investment_maturity',
  Loan = 'loan',
  Other = 'other',
  Pension = 'pension',
  RedundancyPayment = 'redundancy_payment',
  Salary = 'salary',
  SelfEmployment = 'self_employment',
  Settlement = 'settlement',
}

/** Status of the entity */
export enum Status {
  Active = 'active',
  Inactive = 'inactive',
}

export type StringFieldComparison = {
  eq?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  iLike?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  notILike?: InputMaybe<Scalars['String']>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  notLike?: InputMaybe<Scalars['String']>;
};

export type UuidFilterComparison = {
  eq?: InputMaybe<Scalars['NullableUUID']>;
  gt?: InputMaybe<Scalars['NullableUUID']>;
  gte?: InputMaybe<Scalars['NullableUUID']>;
  iLike?: InputMaybe<Scalars['NullableUUID']>;
  in?: InputMaybe<Array<Scalars['UUID']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<Scalars['NullableUUID']>;
  lt?: InputMaybe<Scalars['NullableUUID']>;
  lte?: InputMaybe<Scalars['NullableUUID']>;
  neq?: InputMaybe<Scalars['NullableUUID']>;
  notILike?: InputMaybe<Scalars['NullableUUID']>;
  notIn?: InputMaybe<Array<Scalars['UUID']>>;
  notLike?: InputMaybe<Scalars['NullableUUID']>;
};

export type UpdateAdditionalDocumentInput = {
  description: Scalars['RequiredStringLimitedTo255'];
};

export type UpdateAdditionalFeeInput = {
  description: Scalars['RequiredStringLimitedTo50'];
  fee?: InputMaybe<Scalars['PositiveFloatBetween0And9007199254740991']>;
};

export type UpdateAddressInput = {
  address_line?: InputMaybe<Scalars['StringLimitedTo255']>;
  country_id?: InputMaybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  place_details?: InputMaybe<Scalars['JSON']>;
  postal_code?: InputMaybe<Scalars['StringLimitedTo255']>;
  proof_uuid?: InputMaybe<Scalars['NullableUUID']>;
};

export type UpdateCompanyInput = {
  /** Start date on Fronted */
  activated_at?: InputMaybe<Scalars['DateTime']>;
  /** Managed by frontend. Means when company signed agreement with the platform */
  agreement_date?: InputMaybe<Scalars['DateTime']>;
  company_type?: InputMaybe<ApplicantCompanyType>;
  company_uuid?: InputMaybe<Scalars['NullableUUID']>;
  /** End date on Frontend. Start data for Frontend is - created_at */
  deactivated_at?: InputMaybe<Scalars['DateTime']>;
  kyc_passed?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['StringLimitedTo255']>;
  /**
   * At the moment of writing - only for seller and payer company types.
   *     Example: Sole Proprietorship, Partnership, Corporation, Limited Liability Company (LLC)
   */
  organizational_form?: InputMaybe<Scalars['StringLimitedTo255']>;
  parent_id?: InputMaybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  registration_address_id?: InputMaybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  registration_no?: InputMaybe<Scalars['StringLimitedTo255']>;
  /** Status of the company in real world - is it operation now? */
  status: Status;
  /** At the time of writing(Now - 14.06.2021). Tax is only required for Romania */
  tax_id?: InputMaybe<Scalars['StringLimitedTo255']>;
  trading_address_id?: InputMaybe<Scalars['PositiveIntBetween0And9007199254740991']>;
  /** One of the: regulated, unregulated, other */
  type?: InputMaybe<CompanyType>;
};

export type UpdateOneAdditionalDocumentInput = {
  /** The id of the record to update */
  id: Scalars['Int'];
  /** The update to apply. */
  update: UpdateAdditionalDocumentInput;
};

export type UpdateOneAdditionalFeeInput = {
  /** The id of the record to update */
  id: Scalars['Int'];
  /** The update to apply. */
  update: UpdateAdditionalFeeInput;
};

export type UpdateOneAddressInput = {
  /** The id of the record to update */
  id: Scalars['Int'];
  /** The update to apply. */
  update: UpdateAddressInput;
};

export type UpdateOneCompanyInput = {
  /** The id of the record to update */
  id: Scalars['Int'];
  /** The update to apply. */
  update: UpdateCompanyInput;
};

export type UpdateOnePersonInput = {
  /** The id of the record to update */
  id: Scalars['Int'];
  /** The update to apply. */
  update: UpdatePersonInput;
};

export type UpdatePersonInput = {
  /** Start date on Fronted */
  activated_at?: InputMaybe<Scalars['DateTime']>;
  company_uuid?: InputMaybe<Scalars['NullableUUID']>;
  /** Start date on Fronted */
  created_at?: InputMaybe<Scalars['DateTime']>;
  day_of_birth?: InputMaybe<Scalars['PositiveIntBetween1And31']>;
  /** End date on Frontend. Start data for Frontend is - created_at */
  deactivated_at?: InputMaybe<Scalars['DateTime']>;
  first_name?: InputMaybe<Scalars['StringLimitedTo255']>;
  is_director?: InputMaybe<Scalars['Boolean']>;
  is_poc?: InputMaybe<Scalars['Boolean']>;
  /**
   * This flag is set after success kyc passing process.
   *      After that we can switch off kyc just updating this field - set to false.
   *      If this field is null - than kyc is in pending state - we check this company
   *      of person
   */
  kyc_passed?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['StringLimitedTo255']>;
  middle_name?: InputMaybe<Scalars['StringLimitedTo255']>;
  month_of_birth?: InputMaybe<Scalars['PositiveIntBetween0And11']>;
  national_id?: InputMaybe<Scalars['StringLimitedTo255']>;
  proof_of_identity_back_uuid?: InputMaybe<Scalars['NullableUUID']>;
  proof_of_identity_front_uuid?: InputMaybe<Scalars['NullableUUID']>;
  proof_of_identity_type?: InputMaybe<ProofOfIdentityType>;
  status?: InputMaybe<Status>;
  title?: InputMaybe<Scalars['StringLimitedTo10']>;
  year_of_birth?: InputMaybe<Scalars['PositiveIntBetween1900And2008']>;
};

export type AddressFragmentFragment = {
  __typename?: 'Address';
  id?: number | null;
  country_id?: number | null;
  proof_uuid: string;
  proof_expired_at?: any | null;
  postal_code: string;
  created_at: any;
  updated_at: any;
  address_line: string;
  place_details?: any | null;
  country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
};

export type CountryFragmentFragment = { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string };

export type AdditionalDocumentFragmentFragment = {
  __typename?: 'AdditionalDocument';
  id?: number | null;
  company_id?: number | null;
  person_id?: number | null;
  document_uuid: string;
  description: string;
  original_name?: string | null;
  created_at: any;
  type: AdditionalDocumentsTypes;
};

export type AdditionalFeeFragmentFragment = {
  __typename?: 'AdditionalFee';
  company_id?: number | null;
  created_at: any;
  deleted_at?: any | null;
  description: string;
  fee?: number | null;
  id?: number | null;
  updated_at?: any | null;
};

export type PersonFragmentFragment = {
  __typename?: 'Person';
  id?: number | null;
  company_id?: number | null;
  company_uuid?: string | null;
  approver_uuid?: string | null;
  address_id?: number | null;
  proof_of_identity_front_uuid?: string | null;
  proof_of_identity_back_uuid: string;
  proof_of_identity_type: ProofOfIdentityType;
  title?: string | null;
  first_name: string;
  middle_name?: string | null;
  last_name: string;
  national_id?: string | null;
  day_of_birth?: number | null;
  month_of_birth?: number | null;
  year_of_birth?: number | null;
  is_director: boolean;
  is_poc: boolean;
  kyc_status: KycStatus;
  kyc_passed?: boolean | null;
  status?: Status | null;
  source_of_wealth?: Array<SourceOfWealth> | null;
  proof_of_identity_expired_at?: any | null;
  activated_at?: any | null;
  deactivated_at?: any | null;
  created_at: any;
  address?: {
    __typename?: 'Address';
    id?: number | null;
    country_id?: number | null;
    proof_uuid: string;
    proof_expired_at?: any | null;
    postal_code: string;
    created_at: any;
    updated_at: any;
    address_line: string;
    place_details?: any | null;
    country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
  } | null;
  additional_documents?: Array<{
    __typename?: 'AdditionalDocument';
    id?: number | null;
    company_id?: number | null;
    person_id?: number | null;
    document_uuid: string;
    description: string;
    original_name?: string | null;
    created_at: any;
    type: AdditionalDocumentsTypes;
  }> | null;
};

export type CompanyFragmentFragment = {
  __typename?: 'Company';
  id?: number | null;
  parent_id?: number | null;
  approver_uuid?: string | null;
  company_type?: ApplicantCompanyType | null;
  organizational_form?: string | null;
  company_uuid: string;
  registration_address_id?: number | null;
  trading_address_id?: number | null;
  name: string;
  type: CompanyType;
  registration_no: string;
  tax_id?: string | null;
  kyc_status: KycStatus;
  kyc_passed?: boolean | null;
  status: Status;
  agreement_date: any;
  created_at: any;
  updated_at?: any | null;
  deactivated_at?: any | null;
  deleted_at?: any | null;
  additional_fees?: Array<{
    __typename?: 'AdditionalFee';
    company_id?: number | null;
    created_at: any;
    deleted_at?: any | null;
    description: string;
    fee?: number | null;
    id?: number | null;
    updated_at?: any | null;
  }> | null;
  registration_address: {
    __typename?: 'Address';
    id?: number | null;
    country_id?: number | null;
    proof_uuid: string;
    proof_expired_at?: any | null;
    postal_code: string;
    created_at: any;
    updated_at: any;
    address_line: string;
    place_details?: any | null;
    country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
  };
  trading_address: {
    __typename?: 'Address';
    id?: number | null;
    country_id?: number | null;
    proof_uuid: string;
    proof_expired_at?: any | null;
    postal_code: string;
    created_at: any;
    updated_at: any;
    address_line: string;
    place_details?: any | null;
    country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
  };
  additional_documents?: Array<{
    __typename?: 'AdditionalDocument';
    id?: number | null;
    company_id?: number | null;
    person_id?: number | null;
    document_uuid: string;
    description: string;
    original_name?: string | null;
    created_at: any;
    type: AdditionalDocumentsTypes;
  }> | null;
  companies?: Array<{
    __typename?: 'Company';
    id?: number | null;
    parent_id?: number | null;
    approver_uuid?: string | null;
    company_type?: ApplicantCompanyType | null;
    organizational_form?: string | null;
    company_uuid: string;
    registration_address_id?: number | null;
    trading_address_id?: number | null;
    name: string;
    type: CompanyType;
    registration_no: string;
    tax_id?: string | null;
    kyc_status: KycStatus;
    kyc_passed?: boolean | null;
    status: Status;
    agreement_date: any;
    created_at: any;
    updated_at?: any | null;
    activated_at?: any | null;
    deactivated_at?: any | null;
    deleted_at?: any | null;
    registration_address: {
      __typename?: 'Address';
      id?: number | null;
      country_id?: number | null;
      proof_uuid: string;
      proof_expired_at?: any | null;
      postal_code: string;
      created_at: any;
      updated_at: any;
      address_line: string;
      place_details?: any | null;
      country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
    };
    trading_address: {
      __typename?: 'Address';
      id?: number | null;
      country_id?: number | null;
      proof_uuid: string;
      proof_expired_at?: any | null;
      postal_code: string;
      created_at: any;
      updated_at: any;
      address_line: string;
      place_details?: any | null;
      country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
    };
    additional_documents?: Array<{
      __typename?: 'AdditionalDocument';
      id?: number | null;
      company_id?: number | null;
      person_id?: number | null;
      document_uuid: string;
      description: string;
      original_name?: string | null;
      created_at: any;
      type: AdditionalDocumentsTypes;
    }> | null;
    companies?: Array<{
      __typename?: 'Company';
      id?: number | null;
      parent_id?: number | null;
      approver_uuid?: string | null;
      company_type?: ApplicantCompanyType | null;
      organizational_form?: string | null;
      company_uuid: string;
      registration_address_id?: number | null;
      trading_address_id?: number | null;
      name: string;
      type: CompanyType;
      registration_no: string;
      tax_id?: string | null;
      kyc_status: KycStatus;
      kyc_passed?: boolean | null;
      status: Status;
      agreement_date: any;
      created_at: any;
      updated_at?: any | null;
      activated_at?: any | null;
      deactivated_at?: any | null;
      deleted_at?: any | null;
      registration_address: {
        __typename?: 'Address';
        id?: number | null;
        country_id?: number | null;
        proof_uuid: string;
        proof_expired_at?: any | null;
        postal_code: string;
        created_at: any;
        updated_at: any;
        address_line: string;
        place_details?: any | null;
        country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
      };
      trading_address: {
        __typename?: 'Address';
        id?: number | null;
        country_id?: number | null;
        proof_uuid: string;
        proof_expired_at?: any | null;
        postal_code: string;
        created_at: any;
        updated_at: any;
        address_line: string;
        place_details?: any | null;
        country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
      };
      additional_documents?: Array<{
        __typename?: 'AdditionalDocument';
        id?: number | null;
        company_id?: number | null;
        person_id?: number | null;
        document_uuid: string;
        description: string;
        original_name?: string | null;
        created_at: any;
        type: AdditionalDocumentsTypes;
      }> | null;
      companies?: Array<{
        __typename?: 'Company';
        id?: number | null;
        parent_id?: number | null;
        approver_uuid?: string | null;
        company_type?: ApplicantCompanyType | null;
        organizational_form?: string | null;
        company_uuid: string;
        registration_address_id?: number | null;
        trading_address_id?: number | null;
        name: string;
        type: CompanyType;
        registration_no: string;
        tax_id?: string | null;
        kyc_status: KycStatus;
        kyc_passed?: boolean | null;
        status: Status;
        agreement_date: any;
        created_at: any;
        updated_at?: any | null;
        activated_at?: any | null;
        deactivated_at?: any | null;
        deleted_at?: any | null;
        registration_address: {
          __typename?: 'Address';
          id?: number | null;
          country_id?: number | null;
          proof_uuid: string;
          proof_expired_at?: any | null;
          postal_code: string;
          created_at: any;
          updated_at: any;
          address_line: string;
          place_details?: any | null;
          country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
        };
        trading_address: {
          __typename?: 'Address';
          id?: number | null;
          country_id?: number | null;
          proof_uuid: string;
          proof_expired_at?: any | null;
          postal_code: string;
          created_at: any;
          updated_at: any;
          address_line: string;
          place_details?: any | null;
          country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
        };
        additional_documents?: Array<{
          __typename?: 'AdditionalDocument';
          id?: number | null;
          company_id?: number | null;
          person_id?: number | null;
          document_uuid: string;
          description: string;
          original_name?: string | null;
          created_at: any;
          type: AdditionalDocumentsTypes;
        }> | null;
        companies?: Array<{
          __typename?: 'Company';
          id?: number | null;
          parent_id?: number | null;
          approver_uuid?: string | null;
          company_type?: ApplicantCompanyType | null;
          organizational_form?: string | null;
          company_uuid: string;
          registration_address_id?: number | null;
          trading_address_id?: number | null;
          name: string;
          type: CompanyType;
          registration_no: string;
          tax_id?: string | null;
          kyc_status: KycStatus;
          kyc_passed?: boolean | null;
          status: Status;
          agreement_date: any;
          created_at: any;
          updated_at?: any | null;
          activated_at?: any | null;
          deactivated_at?: any | null;
          deleted_at?: any | null;
          registration_address: {
            __typename?: 'Address';
            id?: number | null;
            country_id?: number | null;
            proof_uuid: string;
            proof_expired_at?: any | null;
            postal_code: string;
            created_at: any;
            updated_at: any;
            address_line: string;
            place_details?: any | null;
            country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
          };
          trading_address: {
            __typename?: 'Address';
            id?: number | null;
            country_id?: number | null;
            proof_uuid: string;
            proof_expired_at?: any | null;
            postal_code: string;
            created_at: any;
            updated_at: any;
            address_line: string;
            place_details?: any | null;
            country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
          };
          additional_documents?: Array<{
            __typename?: 'AdditionalDocument';
            id?: number | null;
            company_id?: number | null;
            person_id?: number | null;
            document_uuid: string;
            description: string;
            original_name?: string | null;
            created_at: any;
            type: AdditionalDocumentsTypes;
          }> | null;
          companies?: Array<{
            __typename?: 'Company';
            id?: number | null;
            parent_id?: number | null;
            approver_uuid?: string | null;
            company_type?: ApplicantCompanyType | null;
            organizational_form?: string | null;
            company_uuid: string;
            registration_address_id?: number | null;
            trading_address_id?: number | null;
            name: string;
            type: CompanyType;
            registration_no: string;
            tax_id?: string | null;
            kyc_status: KycStatus;
            kyc_passed?: boolean | null;
            status: Status;
            agreement_date: any;
            created_at: any;
            updated_at?: any | null;
            activated_at?: any | null;
            deactivated_at?: any | null;
            deleted_at?: any | null;
            registration_address: {
              __typename?: 'Address';
              id?: number | null;
              country_id?: number | null;
              proof_uuid: string;
              proof_expired_at?: any | null;
              postal_code: string;
              created_at: any;
              updated_at: any;
              address_line: string;
              place_details?: any | null;
              country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
            };
            trading_address: {
              __typename?: 'Address';
              id?: number | null;
              country_id?: number | null;
              proof_uuid: string;
              proof_expired_at?: any | null;
              postal_code: string;
              created_at: any;
              updated_at: any;
              address_line: string;
              place_details?: any | null;
              country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
            };
            additional_documents?: Array<{
              __typename?: 'AdditionalDocument';
              id?: number | null;
              company_id?: number | null;
              person_id?: number | null;
              document_uuid: string;
              description: string;
              original_name?: string | null;
              created_at: any;
              type: AdditionalDocumentsTypes;
            }> | null;
            companies?: Array<{
              __typename?: 'Company';
              id?: number | null;
              parent_id?: number | null;
              approver_uuid?: string | null;
              company_type?: ApplicantCompanyType | null;
              organizational_form?: string | null;
              company_uuid: string;
              registration_address_id?: number | null;
              trading_address_id?: number | null;
              name: string;
              type: CompanyType;
              registration_no: string;
              tax_id?: string | null;
              kyc_status: KycStatus;
              kyc_passed?: boolean | null;
              status: Status;
              agreement_date: any;
              created_at: any;
              updated_at?: any | null;
              activated_at?: any | null;
              deactivated_at?: any | null;
              deleted_at?: any | null;
              registration_address: {
                __typename?: 'Address';
                id?: number | null;
                country_id?: number | null;
                proof_uuid: string;
                proof_expired_at?: any | null;
                postal_code: string;
                created_at: any;
                updated_at: any;
                address_line: string;
                place_details?: any | null;
                country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
              };
              trading_address: {
                __typename?: 'Address';
                id?: number | null;
                country_id?: number | null;
                proof_uuid: string;
                proof_expired_at?: any | null;
                postal_code: string;
                created_at: any;
                updated_at: any;
                address_line: string;
                place_details?: any | null;
                country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
              };
              additional_documents?: Array<{
                __typename?: 'AdditionalDocument';
                id?: number | null;
                company_id?: number | null;
                person_id?: number | null;
                document_uuid: string;
                description: string;
                original_name?: string | null;
                created_at: any;
                type: AdditionalDocumentsTypes;
              }> | null;
              companies?: Array<{
                __typename?: 'Company';
                id?: number | null;
                parent_id?: number | null;
                approver_uuid?: string | null;
                company_type?: ApplicantCompanyType | null;
                organizational_form?: string | null;
                company_uuid: string;
                registration_address_id?: number | null;
                trading_address_id?: number | null;
                name: string;
                type: CompanyType;
                registration_no: string;
                tax_id?: string | null;
                kyc_status: KycStatus;
                kyc_passed?: boolean | null;
                status: Status;
                agreement_date: any;
                created_at: any;
                updated_at?: any | null;
                activated_at?: any | null;
                deactivated_at?: any | null;
                deleted_at?: any | null;
                registration_address: {
                  __typename?: 'Address';
                  id?: number | null;
                  country_id?: number | null;
                  proof_uuid: string;
                  proof_expired_at?: any | null;
                  postal_code: string;
                  created_at: any;
                  updated_at: any;
                  address_line: string;
                  place_details?: any | null;
                  country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
                };
                trading_address: {
                  __typename?: 'Address';
                  id?: number | null;
                  country_id?: number | null;
                  proof_uuid: string;
                  proof_expired_at?: any | null;
                  postal_code: string;
                  created_at: any;
                  updated_at: any;
                  address_line: string;
                  place_details?: any | null;
                  country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
                };
                additional_documents?: Array<{
                  __typename?: 'AdditionalDocument';
                  id?: number | null;
                  company_id?: number | null;
                  person_id?: number | null;
                  document_uuid: string;
                  description: string;
                  original_name?: string | null;
                  created_at: any;
                  type: AdditionalDocumentsTypes;
                }> | null;
                companies?: Array<{ __typename?: 'Company'; id?: number | null }> | null;
                persons?: Array<{
                  __typename?: 'Person';
                  id?: number | null;
                  company_id?: number | null;
                  company_uuid?: string | null;
                  approver_uuid?: string | null;
                  address_id?: number | null;
                  proof_of_identity_front_uuid?: string | null;
                  proof_of_identity_back_uuid: string;
                  proof_of_identity_type: ProofOfIdentityType;
                  title?: string | null;
                  first_name: string;
                  middle_name?: string | null;
                  last_name: string;
                  national_id?: string | null;
                  day_of_birth?: number | null;
                  month_of_birth?: number | null;
                  year_of_birth?: number | null;
                  is_director: boolean;
                  is_poc: boolean;
                  kyc_status: KycStatus;
                  kyc_passed?: boolean | null;
                  status?: Status | null;
                  source_of_wealth?: Array<SourceOfWealth> | null;
                  proof_of_identity_expired_at?: any | null;
                  activated_at?: any | null;
                  deactivated_at?: any | null;
                  created_at: any;
                  address?: {
                    __typename?: 'Address';
                    id?: number | null;
                    country_id?: number | null;
                    proof_uuid: string;
                    proof_expired_at?: any | null;
                    postal_code: string;
                    created_at: any;
                    updated_at: any;
                    address_line: string;
                    place_details?: any | null;
                    country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
                  } | null;
                  additional_documents?: Array<{
                    __typename?: 'AdditionalDocument';
                    id?: number | null;
                    company_id?: number | null;
                    person_id?: number | null;
                    document_uuid: string;
                    description: string;
                    original_name?: string | null;
                    created_at: any;
                    type: AdditionalDocumentsTypes;
                  }> | null;
                }> | null;
              }> | null;
              persons?: Array<{
                __typename?: 'Person';
                id?: number | null;
                company_id?: number | null;
                company_uuid?: string | null;
                approver_uuid?: string | null;
                address_id?: number | null;
                proof_of_identity_front_uuid?: string | null;
                proof_of_identity_back_uuid: string;
                proof_of_identity_type: ProofOfIdentityType;
                title?: string | null;
                first_name: string;
                middle_name?: string | null;
                last_name: string;
                national_id?: string | null;
                day_of_birth?: number | null;
                month_of_birth?: number | null;
                year_of_birth?: number | null;
                is_director: boolean;
                is_poc: boolean;
                kyc_status: KycStatus;
                kyc_passed?: boolean | null;
                status?: Status | null;
                source_of_wealth?: Array<SourceOfWealth> | null;
                proof_of_identity_expired_at?: any | null;
                activated_at?: any | null;
                deactivated_at?: any | null;
                created_at: any;
                address?: {
                  __typename?: 'Address';
                  id?: number | null;
                  country_id?: number | null;
                  proof_uuid: string;
                  proof_expired_at?: any | null;
                  postal_code: string;
                  created_at: any;
                  updated_at: any;
                  address_line: string;
                  place_details?: any | null;
                  country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
                } | null;
                additional_documents?: Array<{
                  __typename?: 'AdditionalDocument';
                  id?: number | null;
                  company_id?: number | null;
                  person_id?: number | null;
                  document_uuid: string;
                  description: string;
                  original_name?: string | null;
                  created_at: any;
                  type: AdditionalDocumentsTypes;
                }> | null;
              }> | null;
            }> | null;
            persons?: Array<{
              __typename?: 'Person';
              id?: number | null;
              company_id?: number | null;
              company_uuid?: string | null;
              approver_uuid?: string | null;
              address_id?: number | null;
              proof_of_identity_front_uuid?: string | null;
              proof_of_identity_back_uuid: string;
              proof_of_identity_type: ProofOfIdentityType;
              title?: string | null;
              first_name: string;
              middle_name?: string | null;
              last_name: string;
              national_id?: string | null;
              day_of_birth?: number | null;
              month_of_birth?: number | null;
              year_of_birth?: number | null;
              is_director: boolean;
              is_poc: boolean;
              kyc_status: KycStatus;
              kyc_passed?: boolean | null;
              status?: Status | null;
              source_of_wealth?: Array<SourceOfWealth> | null;
              proof_of_identity_expired_at?: any | null;
              activated_at?: any | null;
              deactivated_at?: any | null;
              created_at: any;
              address?: {
                __typename?: 'Address';
                id?: number | null;
                country_id?: number | null;
                proof_uuid: string;
                proof_expired_at?: any | null;
                postal_code: string;
                created_at: any;
                updated_at: any;
                address_line: string;
                place_details?: any | null;
                country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
              } | null;
              additional_documents?: Array<{
                __typename?: 'AdditionalDocument';
                id?: number | null;
                company_id?: number | null;
                person_id?: number | null;
                document_uuid: string;
                description: string;
                original_name?: string | null;
                created_at: any;
                type: AdditionalDocumentsTypes;
              }> | null;
            }> | null;
          }> | null;
          persons?: Array<{
            __typename?: 'Person';
            id?: number | null;
            company_id?: number | null;
            company_uuid?: string | null;
            approver_uuid?: string | null;
            address_id?: number | null;
            proof_of_identity_front_uuid?: string | null;
            proof_of_identity_back_uuid: string;
            proof_of_identity_type: ProofOfIdentityType;
            title?: string | null;
            first_name: string;
            middle_name?: string | null;
            last_name: string;
            national_id?: string | null;
            day_of_birth?: number | null;
            month_of_birth?: number | null;
            year_of_birth?: number | null;
            is_director: boolean;
            is_poc: boolean;
            kyc_status: KycStatus;
            kyc_passed?: boolean | null;
            status?: Status | null;
            source_of_wealth?: Array<SourceOfWealth> | null;
            proof_of_identity_expired_at?: any | null;
            activated_at?: any | null;
            deactivated_at?: any | null;
            created_at: any;
            address?: {
              __typename?: 'Address';
              id?: number | null;
              country_id?: number | null;
              proof_uuid: string;
              proof_expired_at?: any | null;
              postal_code: string;
              created_at: any;
              updated_at: any;
              address_line: string;
              place_details?: any | null;
              country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
            } | null;
            additional_documents?: Array<{
              __typename?: 'AdditionalDocument';
              id?: number | null;
              company_id?: number | null;
              person_id?: number | null;
              document_uuid: string;
              description: string;
              original_name?: string | null;
              created_at: any;
              type: AdditionalDocumentsTypes;
            }> | null;
          }> | null;
        }> | null;
        persons?: Array<{
          __typename?: 'Person';
          id?: number | null;
          company_id?: number | null;
          company_uuid?: string | null;
          approver_uuid?: string | null;
          address_id?: number | null;
          proof_of_identity_front_uuid?: string | null;
          proof_of_identity_back_uuid: string;
          proof_of_identity_type: ProofOfIdentityType;
          title?: string | null;
          first_name: string;
          middle_name?: string | null;
          last_name: string;
          national_id?: string | null;
          day_of_birth?: number | null;
          month_of_birth?: number | null;
          year_of_birth?: number | null;
          is_director: boolean;
          is_poc: boolean;
          kyc_status: KycStatus;
          kyc_passed?: boolean | null;
          status?: Status | null;
          source_of_wealth?: Array<SourceOfWealth> | null;
          proof_of_identity_expired_at?: any | null;
          activated_at?: any | null;
          deactivated_at?: any | null;
          created_at: any;
          address?: {
            __typename?: 'Address';
            id?: number | null;
            country_id?: number | null;
            proof_uuid: string;
            proof_expired_at?: any | null;
            postal_code: string;
            created_at: any;
            updated_at: any;
            address_line: string;
            place_details?: any | null;
            country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
          } | null;
          additional_documents?: Array<{
            __typename?: 'AdditionalDocument';
            id?: number | null;
            company_id?: number | null;
            person_id?: number | null;
            document_uuid: string;
            description: string;
            original_name?: string | null;
            created_at: any;
            type: AdditionalDocumentsTypes;
          }> | null;
        }> | null;
      }> | null;
      persons?: Array<{
        __typename?: 'Person';
        id?: number | null;
        company_id?: number | null;
        company_uuid?: string | null;
        approver_uuid?: string | null;
        address_id?: number | null;
        proof_of_identity_front_uuid?: string | null;
        proof_of_identity_back_uuid: string;
        proof_of_identity_type: ProofOfIdentityType;
        title?: string | null;
        first_name: string;
        middle_name?: string | null;
        last_name: string;
        national_id?: string | null;
        day_of_birth?: number | null;
        month_of_birth?: number | null;
        year_of_birth?: number | null;
        is_director: boolean;
        is_poc: boolean;
        kyc_status: KycStatus;
        kyc_passed?: boolean | null;
        status?: Status | null;
        source_of_wealth?: Array<SourceOfWealth> | null;
        proof_of_identity_expired_at?: any | null;
        activated_at?: any | null;
        deactivated_at?: any | null;
        created_at: any;
        address?: {
          __typename?: 'Address';
          id?: number | null;
          country_id?: number | null;
          proof_uuid: string;
          proof_expired_at?: any | null;
          postal_code: string;
          created_at: any;
          updated_at: any;
          address_line: string;
          place_details?: any | null;
          country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
        } | null;
        additional_documents?: Array<{
          __typename?: 'AdditionalDocument';
          id?: number | null;
          company_id?: number | null;
          person_id?: number | null;
          document_uuid: string;
          description: string;
          original_name?: string | null;
          created_at: any;
          type: AdditionalDocumentsTypes;
        }> | null;
      }> | null;
    }> | null;
    persons?: Array<{
      __typename?: 'Person';
      id?: number | null;
      company_id?: number | null;
      company_uuid?: string | null;
      approver_uuid?: string | null;
      address_id?: number | null;
      proof_of_identity_front_uuid?: string | null;
      proof_of_identity_back_uuid: string;
      proof_of_identity_type: ProofOfIdentityType;
      title?: string | null;
      first_name: string;
      middle_name?: string | null;
      last_name: string;
      national_id?: string | null;
      day_of_birth?: number | null;
      month_of_birth?: number | null;
      year_of_birth?: number | null;
      is_director: boolean;
      is_poc: boolean;
      kyc_status: KycStatus;
      kyc_passed?: boolean | null;
      status?: Status | null;
      source_of_wealth?: Array<SourceOfWealth> | null;
      proof_of_identity_expired_at?: any | null;
      activated_at?: any | null;
      deactivated_at?: any | null;
      created_at: any;
      address?: {
        __typename?: 'Address';
        id?: number | null;
        country_id?: number | null;
        proof_uuid: string;
        proof_expired_at?: any | null;
        postal_code: string;
        created_at: any;
        updated_at: any;
        address_line: string;
        place_details?: any | null;
        country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
      } | null;
      additional_documents?: Array<{
        __typename?: 'AdditionalDocument';
        id?: number | null;
        company_id?: number | null;
        person_id?: number | null;
        document_uuid: string;
        description: string;
        original_name?: string | null;
        created_at: any;
        type: AdditionalDocumentsTypes;
      }> | null;
    }> | null;
  }> | null;
  persons?: Array<{
    __typename?: 'Person';
    id?: number | null;
    company_id?: number | null;
    company_uuid?: string | null;
    approver_uuid?: string | null;
    address_id?: number | null;
    proof_of_identity_front_uuid?: string | null;
    proof_of_identity_back_uuid: string;
    proof_of_identity_type: ProofOfIdentityType;
    title?: string | null;
    first_name: string;
    middle_name?: string | null;
    last_name: string;
    national_id?: string | null;
    day_of_birth?: number | null;
    month_of_birth?: number | null;
    year_of_birth?: number | null;
    is_director: boolean;
    is_poc: boolean;
    kyc_status: KycStatus;
    kyc_passed?: boolean | null;
    status?: Status | null;
    source_of_wealth?: Array<SourceOfWealth> | null;
    proof_of_identity_expired_at?: any | null;
    activated_at?: any | null;
    deactivated_at?: any | null;
    created_at: any;
    address?: {
      __typename?: 'Address';
      id?: number | null;
      country_id?: number | null;
      proof_uuid: string;
      proof_expired_at?: any | null;
      postal_code: string;
      created_at: any;
      updated_at: any;
      address_line: string;
      place_details?: any | null;
      country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
    } | null;
    additional_documents?: Array<{
      __typename?: 'AdditionalDocument';
      id?: number | null;
      company_id?: number | null;
      person_id?: number | null;
      document_uuid: string;
      description: string;
      original_name?: string | null;
      created_at: any;
      type: AdditionalDocumentsTypes;
    }> | null;
  }> | null;
};

export type GetCountryQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetCountryQuery = {
  __typename?: 'Query';
  country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
};

export type GetCountriesQueryVariables = Exact<{
  filter?: InputMaybe<CountryFilter>;
  sorting?: InputMaybe<Array<CountrySort> | CountrySort>;
}>;

export type GetCountriesQuery = {
  __typename?: 'Query';
  countries: Array<{ __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string }>;
};

export type GetAddressQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetAddressQuery = {
  __typename?: 'Query';
  address?: {
    __typename?: 'Address';
    id?: number | null;
    country_id?: number | null;
    proof_uuid: string;
    proof_expired_at?: any | null;
    postal_code: string;
    created_at: any;
    updated_at: any;
    address_line: string;
    place_details?: any | null;
    country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
  } | null;
};

export type GetCompanyQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetCompanyQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id?: number | null;
    parent_id?: number | null;
    approver_uuid?: string | null;
    company_type?: ApplicantCompanyType | null;
    organizational_form?: string | null;
    company_uuid: string;
    registration_address_id?: number | null;
    trading_address_id?: number | null;
    name: string;
    type: CompanyType;
    registration_no: string;
    tax_id?: string | null;
    kyc_status: KycStatus;
    kyc_passed?: boolean | null;
    status: Status;
    agreement_date: any;
    created_at: any;
    updated_at?: any | null;
    deactivated_at?: any | null;
    deleted_at?: any | null;
    additional_fees?: Array<{
      __typename?: 'AdditionalFee';
      company_id?: number | null;
      created_at: any;
      deleted_at?: any | null;
      description: string;
      fee?: number | null;
      id?: number | null;
      updated_at?: any | null;
    }> | null;
    registration_address: {
      __typename?: 'Address';
      id?: number | null;
      country_id?: number | null;
      proof_uuid: string;
      proof_expired_at?: any | null;
      postal_code: string;
      created_at: any;
      updated_at: any;
      address_line: string;
      place_details?: any | null;
      country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
    };
    trading_address: {
      __typename?: 'Address';
      id?: number | null;
      country_id?: number | null;
      proof_uuid: string;
      proof_expired_at?: any | null;
      postal_code: string;
      created_at: any;
      updated_at: any;
      address_line: string;
      place_details?: any | null;
      country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
    };
    additional_documents?: Array<{
      __typename?: 'AdditionalDocument';
      id?: number | null;
      company_id?: number | null;
      person_id?: number | null;
      document_uuid: string;
      description: string;
      original_name?: string | null;
      created_at: any;
      type: AdditionalDocumentsTypes;
    }> | null;
    companies?: Array<{
      __typename?: 'Company';
      id?: number | null;
      parent_id?: number | null;
      approver_uuid?: string | null;
      company_type?: ApplicantCompanyType | null;
      organizational_form?: string | null;
      company_uuid: string;
      registration_address_id?: number | null;
      trading_address_id?: number | null;
      name: string;
      type: CompanyType;
      registration_no: string;
      tax_id?: string | null;
      kyc_status: KycStatus;
      kyc_passed?: boolean | null;
      status: Status;
      agreement_date: any;
      created_at: any;
      updated_at?: any | null;
      activated_at?: any | null;
      deactivated_at?: any | null;
      deleted_at?: any | null;
      registration_address: {
        __typename?: 'Address';
        id?: number | null;
        country_id?: number | null;
        proof_uuid: string;
        proof_expired_at?: any | null;
        postal_code: string;
        created_at: any;
        updated_at: any;
        address_line: string;
        place_details?: any | null;
        country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
      };
      trading_address: {
        __typename?: 'Address';
        id?: number | null;
        country_id?: number | null;
        proof_uuid: string;
        proof_expired_at?: any | null;
        postal_code: string;
        created_at: any;
        updated_at: any;
        address_line: string;
        place_details?: any | null;
        country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
      };
      additional_documents?: Array<{
        __typename?: 'AdditionalDocument';
        id?: number | null;
        company_id?: number | null;
        person_id?: number | null;
        document_uuid: string;
        description: string;
        original_name?: string | null;
        created_at: any;
        type: AdditionalDocumentsTypes;
      }> | null;
      companies?: Array<{
        __typename?: 'Company';
        id?: number | null;
        parent_id?: number | null;
        approver_uuid?: string | null;
        company_type?: ApplicantCompanyType | null;
        organizational_form?: string | null;
        company_uuid: string;
        registration_address_id?: number | null;
        trading_address_id?: number | null;
        name: string;
        type: CompanyType;
        registration_no: string;
        tax_id?: string | null;
        kyc_status: KycStatus;
        kyc_passed?: boolean | null;
        status: Status;
        agreement_date: any;
        created_at: any;
        updated_at?: any | null;
        activated_at?: any | null;
        deactivated_at?: any | null;
        deleted_at?: any | null;
        registration_address: {
          __typename?: 'Address';
          id?: number | null;
          country_id?: number | null;
          proof_uuid: string;
          proof_expired_at?: any | null;
          postal_code: string;
          created_at: any;
          updated_at: any;
          address_line: string;
          place_details?: any | null;
          country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
        };
        trading_address: {
          __typename?: 'Address';
          id?: number | null;
          country_id?: number | null;
          proof_uuid: string;
          proof_expired_at?: any | null;
          postal_code: string;
          created_at: any;
          updated_at: any;
          address_line: string;
          place_details?: any | null;
          country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
        };
        additional_documents?: Array<{
          __typename?: 'AdditionalDocument';
          id?: number | null;
          company_id?: number | null;
          person_id?: number | null;
          document_uuid: string;
          description: string;
          original_name?: string | null;
          created_at: any;
          type: AdditionalDocumentsTypes;
        }> | null;
        companies?: Array<{
          __typename?: 'Company';
          id?: number | null;
          parent_id?: number | null;
          approver_uuid?: string | null;
          company_type?: ApplicantCompanyType | null;
          organizational_form?: string | null;
          company_uuid: string;
          registration_address_id?: number | null;
          trading_address_id?: number | null;
          name: string;
          type: CompanyType;
          registration_no: string;
          tax_id?: string | null;
          kyc_status: KycStatus;
          kyc_passed?: boolean | null;
          status: Status;
          agreement_date: any;
          created_at: any;
          updated_at?: any | null;
          activated_at?: any | null;
          deactivated_at?: any | null;
          deleted_at?: any | null;
          registration_address: {
            __typename?: 'Address';
            id?: number | null;
            country_id?: number | null;
            proof_uuid: string;
            proof_expired_at?: any | null;
            postal_code: string;
            created_at: any;
            updated_at: any;
            address_line: string;
            place_details?: any | null;
            country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
          };
          trading_address: {
            __typename?: 'Address';
            id?: number | null;
            country_id?: number | null;
            proof_uuid: string;
            proof_expired_at?: any | null;
            postal_code: string;
            created_at: any;
            updated_at: any;
            address_line: string;
            place_details?: any | null;
            country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
          };
          additional_documents?: Array<{
            __typename?: 'AdditionalDocument';
            id?: number | null;
            company_id?: number | null;
            person_id?: number | null;
            document_uuid: string;
            description: string;
            original_name?: string | null;
            created_at: any;
            type: AdditionalDocumentsTypes;
          }> | null;
          companies?: Array<{
            __typename?: 'Company';
            id?: number | null;
            parent_id?: number | null;
            approver_uuid?: string | null;
            company_type?: ApplicantCompanyType | null;
            organizational_form?: string | null;
            company_uuid: string;
            registration_address_id?: number | null;
            trading_address_id?: number | null;
            name: string;
            type: CompanyType;
            registration_no: string;
            tax_id?: string | null;
            kyc_status: KycStatus;
            kyc_passed?: boolean | null;
            status: Status;
            agreement_date: any;
            created_at: any;
            updated_at?: any | null;
            activated_at?: any | null;
            deactivated_at?: any | null;
            deleted_at?: any | null;
            registration_address: {
              __typename?: 'Address';
              id?: number | null;
              country_id?: number | null;
              proof_uuid: string;
              proof_expired_at?: any | null;
              postal_code: string;
              created_at: any;
              updated_at: any;
              address_line: string;
              place_details?: any | null;
              country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
            };
            trading_address: {
              __typename?: 'Address';
              id?: number | null;
              country_id?: number | null;
              proof_uuid: string;
              proof_expired_at?: any | null;
              postal_code: string;
              created_at: any;
              updated_at: any;
              address_line: string;
              place_details?: any | null;
              country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
            };
            additional_documents?: Array<{
              __typename?: 'AdditionalDocument';
              id?: number | null;
              company_id?: number | null;
              person_id?: number | null;
              document_uuid: string;
              description: string;
              original_name?: string | null;
              created_at: any;
              type: AdditionalDocumentsTypes;
            }> | null;
            companies?: Array<{
              __typename?: 'Company';
              id?: number | null;
              parent_id?: number | null;
              approver_uuid?: string | null;
              company_type?: ApplicantCompanyType | null;
              organizational_form?: string | null;
              company_uuid: string;
              registration_address_id?: number | null;
              trading_address_id?: number | null;
              name: string;
              type: CompanyType;
              registration_no: string;
              tax_id?: string | null;
              kyc_status: KycStatus;
              kyc_passed?: boolean | null;
              status: Status;
              agreement_date: any;
              created_at: any;
              updated_at?: any | null;
              activated_at?: any | null;
              deactivated_at?: any | null;
              deleted_at?: any | null;
              registration_address: {
                __typename?: 'Address';
                id?: number | null;
                country_id?: number | null;
                proof_uuid: string;
                proof_expired_at?: any | null;
                postal_code: string;
                created_at: any;
                updated_at: any;
                address_line: string;
                place_details?: any | null;
                country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
              };
              trading_address: {
                __typename?: 'Address';
                id?: number | null;
                country_id?: number | null;
                proof_uuid: string;
                proof_expired_at?: any | null;
                postal_code: string;
                created_at: any;
                updated_at: any;
                address_line: string;
                place_details?: any | null;
                country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
              };
              additional_documents?: Array<{
                __typename?: 'AdditionalDocument';
                id?: number | null;
                company_id?: number | null;
                person_id?: number | null;
                document_uuid: string;
                description: string;
                original_name?: string | null;
                created_at: any;
                type: AdditionalDocumentsTypes;
              }> | null;
              companies?: Array<{
                __typename?: 'Company';
                id?: number | null;
                parent_id?: number | null;
                approver_uuid?: string | null;
                company_type?: ApplicantCompanyType | null;
                organizational_form?: string | null;
                company_uuid: string;
                registration_address_id?: number | null;
                trading_address_id?: number | null;
                name: string;
                type: CompanyType;
                registration_no: string;
                tax_id?: string | null;
                kyc_status: KycStatus;
                kyc_passed?: boolean | null;
                status: Status;
                agreement_date: any;
                created_at: any;
                updated_at?: any | null;
                activated_at?: any | null;
                deactivated_at?: any | null;
                deleted_at?: any | null;
                registration_address: {
                  __typename?: 'Address';
                  id?: number | null;
                  country_id?: number | null;
                  proof_uuid: string;
                  proof_expired_at?: any | null;
                  postal_code: string;
                  created_at: any;
                  updated_at: any;
                  address_line: string;
                  place_details?: any | null;
                  country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
                };
                trading_address: {
                  __typename?: 'Address';
                  id?: number | null;
                  country_id?: number | null;
                  proof_uuid: string;
                  proof_expired_at?: any | null;
                  postal_code: string;
                  created_at: any;
                  updated_at: any;
                  address_line: string;
                  place_details?: any | null;
                  country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
                };
                additional_documents?: Array<{
                  __typename?: 'AdditionalDocument';
                  id?: number | null;
                  company_id?: number | null;
                  person_id?: number | null;
                  document_uuid: string;
                  description: string;
                  original_name?: string | null;
                  created_at: any;
                  type: AdditionalDocumentsTypes;
                }> | null;
                companies?: Array<{
                  __typename?: 'Company';
                  id?: number | null;
                  parent_id?: number | null;
                  approver_uuid?: string | null;
                  company_type?: ApplicantCompanyType | null;
                  organizational_form?: string | null;
                  company_uuid: string;
                  registration_address_id?: number | null;
                  trading_address_id?: number | null;
                  name: string;
                  type: CompanyType;
                  registration_no: string;
                  tax_id?: string | null;
                  kyc_status: KycStatus;
                  kyc_passed?: boolean | null;
                  status: Status;
                  agreement_date: any;
                  created_at: any;
                  updated_at?: any | null;
                  activated_at?: any | null;
                  deactivated_at?: any | null;
                  deleted_at?: any | null;
                  registration_address: {
                    __typename?: 'Address';
                    id?: number | null;
                    country_id?: number | null;
                    proof_uuid: string;
                    proof_expired_at?: any | null;
                    postal_code: string;
                    created_at: any;
                    updated_at: any;
                    address_line: string;
                    place_details?: any | null;
                    country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
                  };
                  trading_address: {
                    __typename?: 'Address';
                    id?: number | null;
                    country_id?: number | null;
                    proof_uuid: string;
                    proof_expired_at?: any | null;
                    postal_code: string;
                    created_at: any;
                    updated_at: any;
                    address_line: string;
                    place_details?: any | null;
                    country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
                  };
                  additional_documents?: Array<{
                    __typename?: 'AdditionalDocument';
                    id?: number | null;
                    company_id?: number | null;
                    person_id?: number | null;
                    document_uuid: string;
                    description: string;
                    original_name?: string | null;
                    created_at: any;
                    type: AdditionalDocumentsTypes;
                  }> | null;
                  companies?: Array<{ __typename?: 'Company'; id?: number | null }> | null;
                  persons?: Array<{
                    __typename?: 'Person';
                    id?: number | null;
                    company_id?: number | null;
                    company_uuid?: string | null;
                    approver_uuid?: string | null;
                    address_id?: number | null;
                    proof_of_identity_front_uuid?: string | null;
                    proof_of_identity_back_uuid: string;
                    proof_of_identity_type: ProofOfIdentityType;
                    title?: string | null;
                    first_name: string;
                    middle_name?: string | null;
                    last_name: string;
                    national_id?: string | null;
                    day_of_birth?: number | null;
                    month_of_birth?: number | null;
                    year_of_birth?: number | null;
                    is_director: boolean;
                    is_poc: boolean;
                    kyc_status: KycStatus;
                    kyc_passed?: boolean | null;
                    status?: Status | null;
                    source_of_wealth?: Array<SourceOfWealth> | null;
                    proof_of_identity_expired_at?: any | null;
                    activated_at?: any | null;
                    deactivated_at?: any | null;
                    created_at: any;
                    address?: {
                      __typename?: 'Address';
                      id?: number | null;
                      country_id?: number | null;
                      proof_uuid: string;
                      proof_expired_at?: any | null;
                      postal_code: string;
                      created_at: any;
                      updated_at: any;
                      address_line: string;
                      place_details?: any | null;
                      country?: {
                        __typename?: 'Country';
                        id?: number | null;
                        name: string;
                        alpha2_code: string;
                      } | null;
                    } | null;
                    additional_documents?: Array<{
                      __typename?: 'AdditionalDocument';
                      id?: number | null;
                      company_id?: number | null;
                      person_id?: number | null;
                      document_uuid: string;
                      description: string;
                      original_name?: string | null;
                      created_at: any;
                      type: AdditionalDocumentsTypes;
                    }> | null;
                  }> | null;
                }> | null;
                persons?: Array<{
                  __typename?: 'Person';
                  id?: number | null;
                  company_id?: number | null;
                  company_uuid?: string | null;
                  approver_uuid?: string | null;
                  address_id?: number | null;
                  proof_of_identity_front_uuid?: string | null;
                  proof_of_identity_back_uuid: string;
                  proof_of_identity_type: ProofOfIdentityType;
                  title?: string | null;
                  first_name: string;
                  middle_name?: string | null;
                  last_name: string;
                  national_id?: string | null;
                  day_of_birth?: number | null;
                  month_of_birth?: number | null;
                  year_of_birth?: number | null;
                  is_director: boolean;
                  is_poc: boolean;
                  kyc_status: KycStatus;
                  kyc_passed?: boolean | null;
                  status?: Status | null;
                  source_of_wealth?: Array<SourceOfWealth> | null;
                  proof_of_identity_expired_at?: any | null;
                  activated_at?: any | null;
                  deactivated_at?: any | null;
                  created_at: any;
                  address?: {
                    __typename?: 'Address';
                    id?: number | null;
                    country_id?: number | null;
                    proof_uuid: string;
                    proof_expired_at?: any | null;
                    postal_code: string;
                    created_at: any;
                    updated_at: any;
                    address_line: string;
                    place_details?: any | null;
                    country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
                  } | null;
                  additional_documents?: Array<{
                    __typename?: 'AdditionalDocument';
                    id?: number | null;
                    company_id?: number | null;
                    person_id?: number | null;
                    document_uuid: string;
                    description: string;
                    original_name?: string | null;
                    created_at: any;
                    type: AdditionalDocumentsTypes;
                  }> | null;
                }> | null;
              }> | null;
              persons?: Array<{
                __typename?: 'Person';
                id?: number | null;
                company_id?: number | null;
                company_uuid?: string | null;
                approver_uuid?: string | null;
                address_id?: number | null;
                proof_of_identity_front_uuid?: string | null;
                proof_of_identity_back_uuid: string;
                proof_of_identity_type: ProofOfIdentityType;
                title?: string | null;
                first_name: string;
                middle_name?: string | null;
                last_name: string;
                national_id?: string | null;
                day_of_birth?: number | null;
                month_of_birth?: number | null;
                year_of_birth?: number | null;
                is_director: boolean;
                is_poc: boolean;
                kyc_status: KycStatus;
                kyc_passed?: boolean | null;
                status?: Status | null;
                source_of_wealth?: Array<SourceOfWealth> | null;
                proof_of_identity_expired_at?: any | null;
                activated_at?: any | null;
                deactivated_at?: any | null;
                created_at: any;
                address?: {
                  __typename?: 'Address';
                  id?: number | null;
                  country_id?: number | null;
                  proof_uuid: string;
                  proof_expired_at?: any | null;
                  postal_code: string;
                  created_at: any;
                  updated_at: any;
                  address_line: string;
                  place_details?: any | null;
                  country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
                } | null;
                additional_documents?: Array<{
                  __typename?: 'AdditionalDocument';
                  id?: number | null;
                  company_id?: number | null;
                  person_id?: number | null;
                  document_uuid: string;
                  description: string;
                  original_name?: string | null;
                  created_at: any;
                  type: AdditionalDocumentsTypes;
                }> | null;
              }> | null;
            }> | null;
            persons?: Array<{
              __typename?: 'Person';
              id?: number | null;
              company_id?: number | null;
              company_uuid?: string | null;
              approver_uuid?: string | null;
              address_id?: number | null;
              proof_of_identity_front_uuid?: string | null;
              proof_of_identity_back_uuid: string;
              proof_of_identity_type: ProofOfIdentityType;
              title?: string | null;
              first_name: string;
              middle_name?: string | null;
              last_name: string;
              national_id?: string | null;
              day_of_birth?: number | null;
              month_of_birth?: number | null;
              year_of_birth?: number | null;
              is_director: boolean;
              is_poc: boolean;
              kyc_status: KycStatus;
              kyc_passed?: boolean | null;
              status?: Status | null;
              source_of_wealth?: Array<SourceOfWealth> | null;
              proof_of_identity_expired_at?: any | null;
              activated_at?: any | null;
              deactivated_at?: any | null;
              created_at: any;
              address?: {
                __typename?: 'Address';
                id?: number | null;
                country_id?: number | null;
                proof_uuid: string;
                proof_expired_at?: any | null;
                postal_code: string;
                created_at: any;
                updated_at: any;
                address_line: string;
                place_details?: any | null;
                country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
              } | null;
              additional_documents?: Array<{
                __typename?: 'AdditionalDocument';
                id?: number | null;
                company_id?: number | null;
                person_id?: number | null;
                document_uuid: string;
                description: string;
                original_name?: string | null;
                created_at: any;
                type: AdditionalDocumentsTypes;
              }> | null;
            }> | null;
          }> | null;
          persons?: Array<{
            __typename?: 'Person';
            id?: number | null;
            company_id?: number | null;
            company_uuid?: string | null;
            approver_uuid?: string | null;
            address_id?: number | null;
            proof_of_identity_front_uuid?: string | null;
            proof_of_identity_back_uuid: string;
            proof_of_identity_type: ProofOfIdentityType;
            title?: string | null;
            first_name: string;
            middle_name?: string | null;
            last_name: string;
            national_id?: string | null;
            day_of_birth?: number | null;
            month_of_birth?: number | null;
            year_of_birth?: number | null;
            is_director: boolean;
            is_poc: boolean;
            kyc_status: KycStatus;
            kyc_passed?: boolean | null;
            status?: Status | null;
            source_of_wealth?: Array<SourceOfWealth> | null;
            proof_of_identity_expired_at?: any | null;
            activated_at?: any | null;
            deactivated_at?: any | null;
            created_at: any;
            address?: {
              __typename?: 'Address';
              id?: number | null;
              country_id?: number | null;
              proof_uuid: string;
              proof_expired_at?: any | null;
              postal_code: string;
              created_at: any;
              updated_at: any;
              address_line: string;
              place_details?: any | null;
              country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
            } | null;
            additional_documents?: Array<{
              __typename?: 'AdditionalDocument';
              id?: number | null;
              company_id?: number | null;
              person_id?: number | null;
              document_uuid: string;
              description: string;
              original_name?: string | null;
              created_at: any;
              type: AdditionalDocumentsTypes;
            }> | null;
          }> | null;
        }> | null;
        persons?: Array<{
          __typename?: 'Person';
          id?: number | null;
          company_id?: number | null;
          company_uuid?: string | null;
          approver_uuid?: string | null;
          address_id?: number | null;
          proof_of_identity_front_uuid?: string | null;
          proof_of_identity_back_uuid: string;
          proof_of_identity_type: ProofOfIdentityType;
          title?: string | null;
          first_name: string;
          middle_name?: string | null;
          last_name: string;
          national_id?: string | null;
          day_of_birth?: number | null;
          month_of_birth?: number | null;
          year_of_birth?: number | null;
          is_director: boolean;
          is_poc: boolean;
          kyc_status: KycStatus;
          kyc_passed?: boolean | null;
          status?: Status | null;
          source_of_wealth?: Array<SourceOfWealth> | null;
          proof_of_identity_expired_at?: any | null;
          activated_at?: any | null;
          deactivated_at?: any | null;
          created_at: any;
          address?: {
            __typename?: 'Address';
            id?: number | null;
            country_id?: number | null;
            proof_uuid: string;
            proof_expired_at?: any | null;
            postal_code: string;
            created_at: any;
            updated_at: any;
            address_line: string;
            place_details?: any | null;
            country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
          } | null;
          additional_documents?: Array<{
            __typename?: 'AdditionalDocument';
            id?: number | null;
            company_id?: number | null;
            person_id?: number | null;
            document_uuid: string;
            description: string;
            original_name?: string | null;
            created_at: any;
            type: AdditionalDocumentsTypes;
          }> | null;
        }> | null;
      }> | null;
      persons?: Array<{
        __typename?: 'Person';
        id?: number | null;
        company_id?: number | null;
        company_uuid?: string | null;
        approver_uuid?: string | null;
        address_id?: number | null;
        proof_of_identity_front_uuid?: string | null;
        proof_of_identity_back_uuid: string;
        proof_of_identity_type: ProofOfIdentityType;
        title?: string | null;
        first_name: string;
        middle_name?: string | null;
        last_name: string;
        national_id?: string | null;
        day_of_birth?: number | null;
        month_of_birth?: number | null;
        year_of_birth?: number | null;
        is_director: boolean;
        is_poc: boolean;
        kyc_status: KycStatus;
        kyc_passed?: boolean | null;
        status?: Status | null;
        source_of_wealth?: Array<SourceOfWealth> | null;
        proof_of_identity_expired_at?: any | null;
        activated_at?: any | null;
        deactivated_at?: any | null;
        created_at: any;
        address?: {
          __typename?: 'Address';
          id?: number | null;
          country_id?: number | null;
          proof_uuid: string;
          proof_expired_at?: any | null;
          postal_code: string;
          created_at: any;
          updated_at: any;
          address_line: string;
          place_details?: any | null;
          country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
        } | null;
        additional_documents?: Array<{
          __typename?: 'AdditionalDocument';
          id?: number | null;
          company_id?: number | null;
          person_id?: number | null;
          document_uuid: string;
          description: string;
          original_name?: string | null;
          created_at: any;
          type: AdditionalDocumentsTypes;
        }> | null;
      }> | null;
    }> | null;
    persons?: Array<{
      __typename?: 'Person';
      id?: number | null;
      company_id?: number | null;
      company_uuid?: string | null;
      approver_uuid?: string | null;
      address_id?: number | null;
      proof_of_identity_front_uuid?: string | null;
      proof_of_identity_back_uuid: string;
      proof_of_identity_type: ProofOfIdentityType;
      title?: string | null;
      first_name: string;
      middle_name?: string | null;
      last_name: string;
      national_id?: string | null;
      day_of_birth?: number | null;
      month_of_birth?: number | null;
      year_of_birth?: number | null;
      is_director: boolean;
      is_poc: boolean;
      kyc_status: KycStatus;
      kyc_passed?: boolean | null;
      status?: Status | null;
      source_of_wealth?: Array<SourceOfWealth> | null;
      proof_of_identity_expired_at?: any | null;
      activated_at?: any | null;
      deactivated_at?: any | null;
      created_at: any;
      address?: {
        __typename?: 'Address';
        id?: number | null;
        country_id?: number | null;
        proof_uuid: string;
        proof_expired_at?: any | null;
        postal_code: string;
        created_at: any;
        updated_at: any;
        address_line: string;
        place_details?: any | null;
        country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
      } | null;
      additional_documents?: Array<{
        __typename?: 'AdditionalDocument';
        id?: number | null;
        company_id?: number | null;
        person_id?: number | null;
        document_uuid: string;
        description: string;
        original_name?: string | null;
        created_at: any;
        type: AdditionalDocumentsTypes;
      }> | null;
    }> | null;
  } | null;
};

export type GetCompaniesQueryVariables = Exact<{
  paging?: InputMaybe<CursorPaging>;
  filter?: InputMaybe<CompanyFilter>;
  sorting?: InputMaybe<Array<CompanySort> | CompanySort>;
}>;

export type GetCompaniesQuery = {
  __typename?: 'Query';
  companies: {
    __typename?: 'CompanyConnection';
    edges: Array<{
      __typename?: 'CompanyEdge';
      cursor: any;
      node: {
        __typename?: 'Company';
        id?: number | null;
        parent_id?: number | null;
        approver_uuid?: string | null;
        company_type?: ApplicantCompanyType | null;
        organizational_form?: string | null;
        company_uuid: string;
        registration_address_id?: number | null;
        trading_address_id?: number | null;
        name: string;
        type: CompanyType;
        registration_no: string;
        tax_id?: string | null;
        kyc_status: KycStatus;
        kyc_passed?: boolean | null;
        status: Status;
        agreement_date: any;
        created_at: any;
        updated_at?: any | null;
        deactivated_at?: any | null;
        deleted_at?: any | null;
        additional_fees?: Array<{
          __typename?: 'AdditionalFee';
          company_id?: number | null;
          created_at: any;
          deleted_at?: any | null;
          description: string;
          fee?: number | null;
          id?: number | null;
          updated_at?: any | null;
        }> | null;
        registration_address: {
          __typename?: 'Address';
          id?: number | null;
          country_id?: number | null;
          proof_uuid: string;
          proof_expired_at?: any | null;
          postal_code: string;
          created_at: any;
          updated_at: any;
          address_line: string;
          place_details?: any | null;
          country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
        };
        trading_address: {
          __typename?: 'Address';
          id?: number | null;
          country_id?: number | null;
          proof_uuid: string;
          proof_expired_at?: any | null;
          postal_code: string;
          created_at: any;
          updated_at: any;
          address_line: string;
          place_details?: any | null;
          country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
        };
        additional_documents?: Array<{
          __typename?: 'AdditionalDocument';
          id?: number | null;
          company_id?: number | null;
          person_id?: number | null;
          document_uuid: string;
          description: string;
          original_name?: string | null;
          created_at: any;
          type: AdditionalDocumentsTypes;
        }> | null;
        companies?: Array<{
          __typename?: 'Company';
          id?: number | null;
          parent_id?: number | null;
          approver_uuid?: string | null;
          company_type?: ApplicantCompanyType | null;
          organizational_form?: string | null;
          company_uuid: string;
          registration_address_id?: number | null;
          trading_address_id?: number | null;
          name: string;
          type: CompanyType;
          registration_no: string;
          tax_id?: string | null;
          kyc_status: KycStatus;
          kyc_passed?: boolean | null;
          status: Status;
          agreement_date: any;
          created_at: any;
          updated_at?: any | null;
          activated_at?: any | null;
          deactivated_at?: any | null;
          deleted_at?: any | null;
          registration_address: {
            __typename?: 'Address';
            id?: number | null;
            country_id?: number | null;
            proof_uuid: string;
            proof_expired_at?: any | null;
            postal_code: string;
            created_at: any;
            updated_at: any;
            address_line: string;
            place_details?: any | null;
            country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
          };
          trading_address: {
            __typename?: 'Address';
            id?: number | null;
            country_id?: number | null;
            proof_uuid: string;
            proof_expired_at?: any | null;
            postal_code: string;
            created_at: any;
            updated_at: any;
            address_line: string;
            place_details?: any | null;
            country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
          };
          additional_documents?: Array<{
            __typename?: 'AdditionalDocument';
            id?: number | null;
            company_id?: number | null;
            person_id?: number | null;
            document_uuid: string;
            description: string;
            original_name?: string | null;
            created_at: any;
            type: AdditionalDocumentsTypes;
          }> | null;
          companies?: Array<{
            __typename?: 'Company';
            id?: number | null;
            parent_id?: number | null;
            approver_uuid?: string | null;
            company_type?: ApplicantCompanyType | null;
            organizational_form?: string | null;
            company_uuid: string;
            registration_address_id?: number | null;
            trading_address_id?: number | null;
            name: string;
            type: CompanyType;
            registration_no: string;
            tax_id?: string | null;
            kyc_status: KycStatus;
            kyc_passed?: boolean | null;
            status: Status;
            agreement_date: any;
            created_at: any;
            updated_at?: any | null;
            activated_at?: any | null;
            deactivated_at?: any | null;
            deleted_at?: any | null;
            registration_address: {
              __typename?: 'Address';
              id?: number | null;
              country_id?: number | null;
              proof_uuid: string;
              proof_expired_at?: any | null;
              postal_code: string;
              created_at: any;
              updated_at: any;
              address_line: string;
              place_details?: any | null;
              country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
            };
            trading_address: {
              __typename?: 'Address';
              id?: number | null;
              country_id?: number | null;
              proof_uuid: string;
              proof_expired_at?: any | null;
              postal_code: string;
              created_at: any;
              updated_at: any;
              address_line: string;
              place_details?: any | null;
              country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
            };
            additional_documents?: Array<{
              __typename?: 'AdditionalDocument';
              id?: number | null;
              company_id?: number | null;
              person_id?: number | null;
              document_uuid: string;
              description: string;
              original_name?: string | null;
              created_at: any;
              type: AdditionalDocumentsTypes;
            }> | null;
            companies?: Array<{
              __typename?: 'Company';
              id?: number | null;
              parent_id?: number | null;
              approver_uuid?: string | null;
              company_type?: ApplicantCompanyType | null;
              organizational_form?: string | null;
              company_uuid: string;
              registration_address_id?: number | null;
              trading_address_id?: number | null;
              name: string;
              type: CompanyType;
              registration_no: string;
              tax_id?: string | null;
              kyc_status: KycStatus;
              kyc_passed?: boolean | null;
              status: Status;
              agreement_date: any;
              created_at: any;
              updated_at?: any | null;
              activated_at?: any | null;
              deactivated_at?: any | null;
              deleted_at?: any | null;
              registration_address: {
                __typename?: 'Address';
                id?: number | null;
                country_id?: number | null;
                proof_uuid: string;
                proof_expired_at?: any | null;
                postal_code: string;
                created_at: any;
                updated_at: any;
                address_line: string;
                place_details?: any | null;
                country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
              };
              trading_address: {
                __typename?: 'Address';
                id?: number | null;
                country_id?: number | null;
                proof_uuid: string;
                proof_expired_at?: any | null;
                postal_code: string;
                created_at: any;
                updated_at: any;
                address_line: string;
                place_details?: any | null;
                country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
              };
              additional_documents?: Array<{
                __typename?: 'AdditionalDocument';
                id?: number | null;
                company_id?: number | null;
                person_id?: number | null;
                document_uuid: string;
                description: string;
                original_name?: string | null;
                created_at: any;
                type: AdditionalDocumentsTypes;
              }> | null;
              companies?: Array<{
                __typename?: 'Company';
                id?: number | null;
                parent_id?: number | null;
                approver_uuid?: string | null;
                company_type?: ApplicantCompanyType | null;
                organizational_form?: string | null;
                company_uuid: string;
                registration_address_id?: number | null;
                trading_address_id?: number | null;
                name: string;
                type: CompanyType;
                registration_no: string;
                tax_id?: string | null;
                kyc_status: KycStatus;
                kyc_passed?: boolean | null;
                status: Status;
                agreement_date: any;
                created_at: any;
                updated_at?: any | null;
                activated_at?: any | null;
                deactivated_at?: any | null;
                deleted_at?: any | null;
                registration_address: {
                  __typename?: 'Address';
                  id?: number | null;
                  country_id?: number | null;
                  proof_uuid: string;
                  proof_expired_at?: any | null;
                  postal_code: string;
                  created_at: any;
                  updated_at: any;
                  address_line: string;
                  place_details?: any | null;
                  country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
                };
                trading_address: {
                  __typename?: 'Address';
                  id?: number | null;
                  country_id?: number | null;
                  proof_uuid: string;
                  proof_expired_at?: any | null;
                  postal_code: string;
                  created_at: any;
                  updated_at: any;
                  address_line: string;
                  place_details?: any | null;
                  country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
                };
                additional_documents?: Array<{
                  __typename?: 'AdditionalDocument';
                  id?: number | null;
                  company_id?: number | null;
                  person_id?: number | null;
                  document_uuid: string;
                  description: string;
                  original_name?: string | null;
                  created_at: any;
                  type: AdditionalDocumentsTypes;
                }> | null;
                companies?: Array<{
                  __typename?: 'Company';
                  id?: number | null;
                  parent_id?: number | null;
                  approver_uuid?: string | null;
                  company_type?: ApplicantCompanyType | null;
                  organizational_form?: string | null;
                  company_uuid: string;
                  registration_address_id?: number | null;
                  trading_address_id?: number | null;
                  name: string;
                  type: CompanyType;
                  registration_no: string;
                  tax_id?: string | null;
                  kyc_status: KycStatus;
                  kyc_passed?: boolean | null;
                  status: Status;
                  agreement_date: any;
                  created_at: any;
                  updated_at?: any | null;
                  activated_at?: any | null;
                  deactivated_at?: any | null;
                  deleted_at?: any | null;
                  registration_address: {
                    __typename?: 'Address';
                    id?: number | null;
                    country_id?: number | null;
                    proof_uuid: string;
                    proof_expired_at?: any | null;
                    postal_code: string;
                    created_at: any;
                    updated_at: any;
                    address_line: string;
                    place_details?: any | null;
                    country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
                  };
                  trading_address: {
                    __typename?: 'Address';
                    id?: number | null;
                    country_id?: number | null;
                    proof_uuid: string;
                    proof_expired_at?: any | null;
                    postal_code: string;
                    created_at: any;
                    updated_at: any;
                    address_line: string;
                    place_details?: any | null;
                    country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
                  };
                  additional_documents?: Array<{
                    __typename?: 'AdditionalDocument';
                    id?: number | null;
                    company_id?: number | null;
                    person_id?: number | null;
                    document_uuid: string;
                    description: string;
                    original_name?: string | null;
                    created_at: any;
                    type: AdditionalDocumentsTypes;
                  }> | null;
                  companies?: Array<{
                    __typename?: 'Company';
                    id?: number | null;
                    parent_id?: number | null;
                    approver_uuid?: string | null;
                    company_type?: ApplicantCompanyType | null;
                    organizational_form?: string | null;
                    company_uuid: string;
                    registration_address_id?: number | null;
                    trading_address_id?: number | null;
                    name: string;
                    type: CompanyType;
                    registration_no: string;
                    tax_id?: string | null;
                    kyc_status: KycStatus;
                    kyc_passed?: boolean | null;
                    status: Status;
                    agreement_date: any;
                    created_at: any;
                    updated_at?: any | null;
                    activated_at?: any | null;
                    deactivated_at?: any | null;
                    deleted_at?: any | null;
                    registration_address: {
                      __typename?: 'Address';
                      id?: number | null;
                      country_id?: number | null;
                      proof_uuid: string;
                      proof_expired_at?: any | null;
                      postal_code: string;
                      created_at: any;
                      updated_at: any;
                      address_line: string;
                      place_details?: any | null;
                      country?: {
                        __typename?: 'Country';
                        id?: number | null;
                        name: string;
                        alpha2_code: string;
                      } | null;
                    };
                    trading_address: {
                      __typename?: 'Address';
                      id?: number | null;
                      country_id?: number | null;
                      proof_uuid: string;
                      proof_expired_at?: any | null;
                      postal_code: string;
                      created_at: any;
                      updated_at: any;
                      address_line: string;
                      place_details?: any | null;
                      country?: {
                        __typename?: 'Country';
                        id?: number | null;
                        name: string;
                        alpha2_code: string;
                      } | null;
                    };
                    additional_documents?: Array<{
                      __typename?: 'AdditionalDocument';
                      id?: number | null;
                      company_id?: number | null;
                      person_id?: number | null;
                      document_uuid: string;
                      description: string;
                      original_name?: string | null;
                      created_at: any;
                      type: AdditionalDocumentsTypes;
                    }> | null;
                    companies?: Array<{
                      __typename?: 'Company';
                      id?: number | null;
                      parent_id?: number | null;
                      approver_uuid?: string | null;
                      company_type?: ApplicantCompanyType | null;
                      organizational_form?: string | null;
                      company_uuid: string;
                      registration_address_id?: number | null;
                      trading_address_id?: number | null;
                      name: string;
                      type: CompanyType;
                      registration_no: string;
                      tax_id?: string | null;
                      kyc_status: KycStatus;
                      kyc_passed?: boolean | null;
                      status: Status;
                      agreement_date: any;
                      created_at: any;
                      updated_at?: any | null;
                      activated_at?: any | null;
                      deactivated_at?: any | null;
                      deleted_at?: any | null;
                      registration_address: {
                        __typename?: 'Address';
                        id?: number | null;
                        country_id?: number | null;
                        proof_uuid: string;
                        proof_expired_at?: any | null;
                        postal_code: string;
                        created_at: any;
                        updated_at: any;
                        address_line: string;
                        place_details?: any | null;
                        country?: {
                          __typename?: 'Country';
                          id?: number | null;
                          name: string;
                          alpha2_code: string;
                        } | null;
                      };
                      trading_address: {
                        __typename?: 'Address';
                        id?: number | null;
                        country_id?: number | null;
                        proof_uuid: string;
                        proof_expired_at?: any | null;
                        postal_code: string;
                        created_at: any;
                        updated_at: any;
                        address_line: string;
                        place_details?: any | null;
                        country?: {
                          __typename?: 'Country';
                          id?: number | null;
                          name: string;
                          alpha2_code: string;
                        } | null;
                      };
                      additional_documents?: Array<{
                        __typename?: 'AdditionalDocument';
                        id?: number | null;
                        company_id?: number | null;
                        person_id?: number | null;
                        document_uuid: string;
                        description: string;
                        original_name?: string | null;
                        created_at: any;
                        type: AdditionalDocumentsTypes;
                      }> | null;
                      companies?: Array<{ __typename?: 'Company'; id?: number | null }> | null;
                      persons?: Array<{
                        __typename?: 'Person';
                        id?: number | null;
                        company_id?: number | null;
                        company_uuid?: string | null;
                        approver_uuid?: string | null;
                        address_id?: number | null;
                        proof_of_identity_front_uuid?: string | null;
                        proof_of_identity_back_uuid: string;
                        proof_of_identity_type: ProofOfIdentityType;
                        title?: string | null;
                        first_name: string;
                        middle_name?: string | null;
                        last_name: string;
                        national_id?: string | null;
                        day_of_birth?: number | null;
                        month_of_birth?: number | null;
                        year_of_birth?: number | null;
                        is_director: boolean;
                        is_poc: boolean;
                        kyc_status: KycStatus;
                        kyc_passed?: boolean | null;
                        status?: Status | null;
                        source_of_wealth?: Array<SourceOfWealth> | null;
                        proof_of_identity_expired_at?: any | null;
                        activated_at?: any | null;
                        deactivated_at?: any | null;
                        created_at: any;
                        address?: {
                          __typename?: 'Address';
                          id?: number | null;
                          country_id?: number | null;
                          proof_uuid: string;
                          proof_expired_at?: any | null;
                          postal_code: string;
                          created_at: any;
                          updated_at: any;
                          address_line: string;
                          place_details?: any | null;
                          country?: {
                            __typename?: 'Country';
                            id?: number | null;
                            name: string;
                            alpha2_code: string;
                          } | null;
                        } | null;
                        additional_documents?: Array<{
                          __typename?: 'AdditionalDocument';
                          id?: number | null;
                          company_id?: number | null;
                          person_id?: number | null;
                          document_uuid: string;
                          description: string;
                          original_name?: string | null;
                          created_at: any;
                          type: AdditionalDocumentsTypes;
                        }> | null;
                      }> | null;
                    }> | null;
                    persons?: Array<{
                      __typename?: 'Person';
                      id?: number | null;
                      company_id?: number | null;
                      company_uuid?: string | null;
                      approver_uuid?: string | null;
                      address_id?: number | null;
                      proof_of_identity_front_uuid?: string | null;
                      proof_of_identity_back_uuid: string;
                      proof_of_identity_type: ProofOfIdentityType;
                      title?: string | null;
                      first_name: string;
                      middle_name?: string | null;
                      last_name: string;
                      national_id?: string | null;
                      day_of_birth?: number | null;
                      month_of_birth?: number | null;
                      year_of_birth?: number | null;
                      is_director: boolean;
                      is_poc: boolean;
                      kyc_status: KycStatus;
                      kyc_passed?: boolean | null;
                      status?: Status | null;
                      source_of_wealth?: Array<SourceOfWealth> | null;
                      proof_of_identity_expired_at?: any | null;
                      activated_at?: any | null;
                      deactivated_at?: any | null;
                      created_at: any;
                      address?: {
                        __typename?: 'Address';
                        id?: number | null;
                        country_id?: number | null;
                        proof_uuid: string;
                        proof_expired_at?: any | null;
                        postal_code: string;
                        created_at: any;
                        updated_at: any;
                        address_line: string;
                        place_details?: any | null;
                        country?: {
                          __typename?: 'Country';
                          id?: number | null;
                          name: string;
                          alpha2_code: string;
                        } | null;
                      } | null;
                      additional_documents?: Array<{
                        __typename?: 'AdditionalDocument';
                        id?: number | null;
                        company_id?: number | null;
                        person_id?: number | null;
                        document_uuid: string;
                        description: string;
                        original_name?: string | null;
                        created_at: any;
                        type: AdditionalDocumentsTypes;
                      }> | null;
                    }> | null;
                  }> | null;
                  persons?: Array<{
                    __typename?: 'Person';
                    id?: number | null;
                    company_id?: number | null;
                    company_uuid?: string | null;
                    approver_uuid?: string | null;
                    address_id?: number | null;
                    proof_of_identity_front_uuid?: string | null;
                    proof_of_identity_back_uuid: string;
                    proof_of_identity_type: ProofOfIdentityType;
                    title?: string | null;
                    first_name: string;
                    middle_name?: string | null;
                    last_name: string;
                    national_id?: string | null;
                    day_of_birth?: number | null;
                    month_of_birth?: number | null;
                    year_of_birth?: number | null;
                    is_director: boolean;
                    is_poc: boolean;
                    kyc_status: KycStatus;
                    kyc_passed?: boolean | null;
                    status?: Status | null;
                    source_of_wealth?: Array<SourceOfWealth> | null;
                    proof_of_identity_expired_at?: any | null;
                    activated_at?: any | null;
                    deactivated_at?: any | null;
                    created_at: any;
                    address?: {
                      __typename?: 'Address';
                      id?: number | null;
                      country_id?: number | null;
                      proof_uuid: string;
                      proof_expired_at?: any | null;
                      postal_code: string;
                      created_at: any;
                      updated_at: any;
                      address_line: string;
                      place_details?: any | null;
                      country?: {
                        __typename?: 'Country';
                        id?: number | null;
                        name: string;
                        alpha2_code: string;
                      } | null;
                    } | null;
                    additional_documents?: Array<{
                      __typename?: 'AdditionalDocument';
                      id?: number | null;
                      company_id?: number | null;
                      person_id?: number | null;
                      document_uuid: string;
                      description: string;
                      original_name?: string | null;
                      created_at: any;
                      type: AdditionalDocumentsTypes;
                    }> | null;
                  }> | null;
                }> | null;
                persons?: Array<{
                  __typename?: 'Person';
                  id?: number | null;
                  company_id?: number | null;
                  company_uuid?: string | null;
                  approver_uuid?: string | null;
                  address_id?: number | null;
                  proof_of_identity_front_uuid?: string | null;
                  proof_of_identity_back_uuid: string;
                  proof_of_identity_type: ProofOfIdentityType;
                  title?: string | null;
                  first_name: string;
                  middle_name?: string | null;
                  last_name: string;
                  national_id?: string | null;
                  day_of_birth?: number | null;
                  month_of_birth?: number | null;
                  year_of_birth?: number | null;
                  is_director: boolean;
                  is_poc: boolean;
                  kyc_status: KycStatus;
                  kyc_passed?: boolean | null;
                  status?: Status | null;
                  source_of_wealth?: Array<SourceOfWealth> | null;
                  proof_of_identity_expired_at?: any | null;
                  activated_at?: any | null;
                  deactivated_at?: any | null;
                  created_at: any;
                  address?: {
                    __typename?: 'Address';
                    id?: number | null;
                    country_id?: number | null;
                    proof_uuid: string;
                    proof_expired_at?: any | null;
                    postal_code: string;
                    created_at: any;
                    updated_at: any;
                    address_line: string;
                    place_details?: any | null;
                    country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
                  } | null;
                  additional_documents?: Array<{
                    __typename?: 'AdditionalDocument';
                    id?: number | null;
                    company_id?: number | null;
                    person_id?: number | null;
                    document_uuid: string;
                    description: string;
                    original_name?: string | null;
                    created_at: any;
                    type: AdditionalDocumentsTypes;
                  }> | null;
                }> | null;
              }> | null;
              persons?: Array<{
                __typename?: 'Person';
                id?: number | null;
                company_id?: number | null;
                company_uuid?: string | null;
                approver_uuid?: string | null;
                address_id?: number | null;
                proof_of_identity_front_uuid?: string | null;
                proof_of_identity_back_uuid: string;
                proof_of_identity_type: ProofOfIdentityType;
                title?: string | null;
                first_name: string;
                middle_name?: string | null;
                last_name: string;
                national_id?: string | null;
                day_of_birth?: number | null;
                month_of_birth?: number | null;
                year_of_birth?: number | null;
                is_director: boolean;
                is_poc: boolean;
                kyc_status: KycStatus;
                kyc_passed?: boolean | null;
                status?: Status | null;
                source_of_wealth?: Array<SourceOfWealth> | null;
                proof_of_identity_expired_at?: any | null;
                activated_at?: any | null;
                deactivated_at?: any | null;
                created_at: any;
                address?: {
                  __typename?: 'Address';
                  id?: number | null;
                  country_id?: number | null;
                  proof_uuid: string;
                  proof_expired_at?: any | null;
                  postal_code: string;
                  created_at: any;
                  updated_at: any;
                  address_line: string;
                  place_details?: any | null;
                  country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
                } | null;
                additional_documents?: Array<{
                  __typename?: 'AdditionalDocument';
                  id?: number | null;
                  company_id?: number | null;
                  person_id?: number | null;
                  document_uuid: string;
                  description: string;
                  original_name?: string | null;
                  created_at: any;
                  type: AdditionalDocumentsTypes;
                }> | null;
              }> | null;
            }> | null;
            persons?: Array<{
              __typename?: 'Person';
              id?: number | null;
              company_id?: number | null;
              company_uuid?: string | null;
              approver_uuid?: string | null;
              address_id?: number | null;
              proof_of_identity_front_uuid?: string | null;
              proof_of_identity_back_uuid: string;
              proof_of_identity_type: ProofOfIdentityType;
              title?: string | null;
              first_name: string;
              middle_name?: string | null;
              last_name: string;
              national_id?: string | null;
              day_of_birth?: number | null;
              month_of_birth?: number | null;
              year_of_birth?: number | null;
              is_director: boolean;
              is_poc: boolean;
              kyc_status: KycStatus;
              kyc_passed?: boolean | null;
              status?: Status | null;
              source_of_wealth?: Array<SourceOfWealth> | null;
              proof_of_identity_expired_at?: any | null;
              activated_at?: any | null;
              deactivated_at?: any | null;
              created_at: any;
              address?: {
                __typename?: 'Address';
                id?: number | null;
                country_id?: number | null;
                proof_uuid: string;
                proof_expired_at?: any | null;
                postal_code: string;
                created_at: any;
                updated_at: any;
                address_line: string;
                place_details?: any | null;
                country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
              } | null;
              additional_documents?: Array<{
                __typename?: 'AdditionalDocument';
                id?: number | null;
                company_id?: number | null;
                person_id?: number | null;
                document_uuid: string;
                description: string;
                original_name?: string | null;
                created_at: any;
                type: AdditionalDocumentsTypes;
              }> | null;
            }> | null;
          }> | null;
          persons?: Array<{
            __typename?: 'Person';
            id?: number | null;
            company_id?: number | null;
            company_uuid?: string | null;
            approver_uuid?: string | null;
            address_id?: number | null;
            proof_of_identity_front_uuid?: string | null;
            proof_of_identity_back_uuid: string;
            proof_of_identity_type: ProofOfIdentityType;
            title?: string | null;
            first_name: string;
            middle_name?: string | null;
            last_name: string;
            national_id?: string | null;
            day_of_birth?: number | null;
            month_of_birth?: number | null;
            year_of_birth?: number | null;
            is_director: boolean;
            is_poc: boolean;
            kyc_status: KycStatus;
            kyc_passed?: boolean | null;
            status?: Status | null;
            source_of_wealth?: Array<SourceOfWealth> | null;
            proof_of_identity_expired_at?: any | null;
            activated_at?: any | null;
            deactivated_at?: any | null;
            created_at: any;
            address?: {
              __typename?: 'Address';
              id?: number | null;
              country_id?: number | null;
              proof_uuid: string;
              proof_expired_at?: any | null;
              postal_code: string;
              created_at: any;
              updated_at: any;
              address_line: string;
              place_details?: any | null;
              country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
            } | null;
            additional_documents?: Array<{
              __typename?: 'AdditionalDocument';
              id?: number | null;
              company_id?: number | null;
              person_id?: number | null;
              document_uuid: string;
              description: string;
              original_name?: string | null;
              created_at: any;
              type: AdditionalDocumentsTypes;
            }> | null;
          }> | null;
        }> | null;
        persons?: Array<{
          __typename?: 'Person';
          id?: number | null;
          company_id?: number | null;
          company_uuid?: string | null;
          approver_uuid?: string | null;
          address_id?: number | null;
          proof_of_identity_front_uuid?: string | null;
          proof_of_identity_back_uuid: string;
          proof_of_identity_type: ProofOfIdentityType;
          title?: string | null;
          first_name: string;
          middle_name?: string | null;
          last_name: string;
          national_id?: string | null;
          day_of_birth?: number | null;
          month_of_birth?: number | null;
          year_of_birth?: number | null;
          is_director: boolean;
          is_poc: boolean;
          kyc_status: KycStatus;
          kyc_passed?: boolean | null;
          status?: Status | null;
          source_of_wealth?: Array<SourceOfWealth> | null;
          proof_of_identity_expired_at?: any | null;
          activated_at?: any | null;
          deactivated_at?: any | null;
          created_at: any;
          address?: {
            __typename?: 'Address';
            id?: number | null;
            country_id?: number | null;
            proof_uuid: string;
            proof_expired_at?: any | null;
            postal_code: string;
            created_at: any;
            updated_at: any;
            address_line: string;
            place_details?: any | null;
            country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
          } | null;
          additional_documents?: Array<{
            __typename?: 'AdditionalDocument';
            id?: number | null;
            company_id?: number | null;
            person_id?: number | null;
            document_uuid: string;
            description: string;
            original_name?: string | null;
            created_at: any;
            type: AdditionalDocumentsTypes;
          }> | null;
        }> | null;
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor?: any | null;
      endCursor?: any | null;
      hasNextPage?: boolean | null;
      hasPreviousPage?: boolean | null;
    };
  };
};

export type GetAdditionalFeeQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetAdditionalFeeQuery = {
  __typename?: 'Query';
  additionalFee?: {
    __typename?: 'AdditionalFee';
    company_id?: number | null;
    created_at: any;
    deleted_at?: any | null;
    description: string;
    fee?: number | null;
    id?: number | null;
    updated_at?: any | null;
  } | null;
};

export type GetAdditionalFeesQueryVariables = Exact<{
  filter?: InputMaybe<AdditionalFeeFilter>;
  sorting?: InputMaybe<Array<AdditionalFeeSort> | AdditionalFeeSort>;
}>;

export type GetAdditionalFeesQuery = {
  __typename?: 'Query';
  additionalFees: Array<{
    __typename?: 'AdditionalFee';
    company_id?: number | null;
    created_at: any;
    deleted_at?: any | null;
    description: string;
    fee?: number | null;
    id?: number | null;
    updated_at?: any | null;
  }>;
};

export type GetPersonQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetPersonQuery = {
  __typename?: 'Query';
  person?: {
    __typename?: 'Person';
    id?: number | null;
    company_id?: number | null;
    company_uuid?: string | null;
    approver_uuid?: string | null;
    address_id?: number | null;
    proof_of_identity_front_uuid?: string | null;
    proof_of_identity_back_uuid: string;
    proof_of_identity_type: ProofOfIdentityType;
    title?: string | null;
    first_name: string;
    middle_name?: string | null;
    last_name: string;
    national_id?: string | null;
    day_of_birth?: number | null;
    month_of_birth?: number | null;
    year_of_birth?: number | null;
    is_director: boolean;
    is_poc: boolean;
    kyc_status: KycStatus;
    kyc_passed?: boolean | null;
    status?: Status | null;
    source_of_wealth?: Array<SourceOfWealth> | null;
    proof_of_identity_expired_at?: any | null;
    activated_at?: any | null;
    deactivated_at?: any | null;
    created_at: any;
    address?: {
      __typename?: 'Address';
      id?: number | null;
      country_id?: number | null;
      proof_uuid: string;
      proof_expired_at?: any | null;
      postal_code: string;
      created_at: any;
      updated_at: any;
      address_line: string;
      place_details?: any | null;
      country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
    } | null;
    additional_documents?: Array<{
      __typename?: 'AdditionalDocument';
      id?: number | null;
      company_id?: number | null;
      person_id?: number | null;
      document_uuid: string;
      description: string;
      original_name?: string | null;
      created_at: any;
      type: AdditionalDocumentsTypes;
    }> | null;
  } | null;
};

export type GetPeopleQueryVariables = Exact<{
  paging?: InputMaybe<CursorPaging>;
  filter?: InputMaybe<PersonFilter>;
  sorting?: InputMaybe<Array<PersonSort> | PersonSort>;
}>;

export type GetPeopleQuery = {
  __typename?: 'Query';
  people: {
    __typename?: 'PersonConnection';
    edges: Array<{
      __typename?: 'PersonEdge';
      cursor: any;
      node: {
        __typename?: 'Person';
        id?: number | null;
        company_id?: number | null;
        company_uuid?: string | null;
        approver_uuid?: string | null;
        address_id?: number | null;
        proof_of_identity_front_uuid?: string | null;
        proof_of_identity_back_uuid: string;
        proof_of_identity_type: ProofOfIdentityType;
        title?: string | null;
        first_name: string;
        middle_name?: string | null;
        last_name: string;
        national_id?: string | null;
        day_of_birth?: number | null;
        month_of_birth?: number | null;
        year_of_birth?: number | null;
        is_director: boolean;
        is_poc: boolean;
        kyc_status: KycStatus;
        kyc_passed?: boolean | null;
        status?: Status | null;
        source_of_wealth?: Array<SourceOfWealth> | null;
        proof_of_identity_expired_at?: any | null;
        activated_at?: any | null;
        deactivated_at?: any | null;
        created_at: any;
        address?: {
          __typename?: 'Address';
          id?: number | null;
          country_id?: number | null;
          proof_uuid: string;
          proof_expired_at?: any | null;
          postal_code: string;
          created_at: any;
          updated_at: any;
          address_line: string;
          place_details?: any | null;
          country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
        } | null;
        additional_documents?: Array<{
          __typename?: 'AdditionalDocument';
          id?: number | null;
          company_id?: number | null;
          person_id?: number | null;
          document_uuid: string;
          description: string;
          original_name?: string | null;
          created_at: any;
          type: AdditionalDocumentsTypes;
        }> | null;
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor?: any | null;
      endCursor?: any | null;
      hasNextPage?: boolean | null;
      hasPreviousPage?: boolean | null;
    };
  };
};

export type GetAdditionalDocumentQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetAdditionalDocumentQuery = {
  __typename?: 'Query';
  additionalDocument?: {
    __typename?: 'AdditionalDocument';
    id?: number | null;
    company_id?: number | null;
    person_id?: number | null;
    document_uuid: string;
    description: string;
    original_name?: string | null;
    created_at: any;
    type: AdditionalDocumentsTypes;
  } | null;
};

export type GetAdditionalDocumentsQueryVariables = Exact<{
  filter?: InputMaybe<AdditionalDocumentFilter>;
  sorting?: InputMaybe<Array<AdditionalDocumentSort> | AdditionalDocumentSort>;
}>;

export type GetAdditionalDocumentsQuery = {
  __typename?: 'Query';
  additionalDocuments: Array<{
    __typename?: 'AdditionalDocument';
    id?: number | null;
    company_id?: number | null;
    person_id?: number | null;
    document_uuid: string;
    description: string;
    original_name?: string | null;
    created_at: any;
    type: AdditionalDocumentsTypes;
  }>;
};

export type CreateAddressMutationVariables = Exact<{
  input: CreateOneAddressInput;
}>;

export type CreateAddressMutation = {
  __typename?: 'Mutation';
  createOneAddress: {
    __typename?: 'Address';
    id?: number | null;
    country_id?: number | null;
    proof_uuid: string;
    proof_expired_at?: any | null;
    postal_code: string;
    created_at: any;
    updated_at: any;
    address_line: string;
    place_details?: any | null;
    country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
  };
};

export type UpdateAddressMutationVariables = Exact<{
  input: UpdateOneAddressInput;
}>;

export type UpdateAddressMutation = {
  __typename?: 'Mutation';
  updateOneAddress: {
    __typename?: 'Address';
    id?: number | null;
    country_id?: number | null;
    proof_uuid: string;
    proof_expired_at?: any | null;
    postal_code: string;
    created_at: any;
    updated_at: any;
    address_line: string;
    place_details?: any | null;
    country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
  };
};

export type CreateCompanyMutationVariables = Exact<{
  input: CreateOneCompanyInput;
}>;

export type CreateCompanyMutation = {
  __typename?: 'Mutation';
  createOneCompany: {
    __typename?: 'Company';
    id?: number | null;
    parent_id?: number | null;
    approver_uuid?: string | null;
    company_type?: ApplicantCompanyType | null;
    organizational_form?: string | null;
    company_uuid: string;
    registration_address_id?: number | null;
    trading_address_id?: number | null;
    name: string;
    type: CompanyType;
    registration_no: string;
    tax_id?: string | null;
    kyc_status: KycStatus;
    kyc_passed?: boolean | null;
    status: Status;
    agreement_date: any;
    created_at: any;
    updated_at?: any | null;
    deactivated_at?: any | null;
    deleted_at?: any | null;
    additional_fees?: Array<{
      __typename?: 'AdditionalFee';
      company_id?: number | null;
      created_at: any;
      deleted_at?: any | null;
      description: string;
      fee?: number | null;
      id?: number | null;
      updated_at?: any | null;
    }> | null;
    registration_address: {
      __typename?: 'Address';
      id?: number | null;
      country_id?: number | null;
      proof_uuid: string;
      proof_expired_at?: any | null;
      postal_code: string;
      created_at: any;
      updated_at: any;
      address_line: string;
      place_details?: any | null;
      country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
    };
    trading_address: {
      __typename?: 'Address';
      id?: number | null;
      country_id?: number | null;
      proof_uuid: string;
      proof_expired_at?: any | null;
      postal_code: string;
      created_at: any;
      updated_at: any;
      address_line: string;
      place_details?: any | null;
      country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
    };
    additional_documents?: Array<{
      __typename?: 'AdditionalDocument';
      id?: number | null;
      company_id?: number | null;
      person_id?: number | null;
      document_uuid: string;
      description: string;
      original_name?: string | null;
      created_at: any;
      type: AdditionalDocumentsTypes;
    }> | null;
    companies?: Array<{
      __typename?: 'Company';
      id?: number | null;
      parent_id?: number | null;
      approver_uuid?: string | null;
      company_type?: ApplicantCompanyType | null;
      organizational_form?: string | null;
      company_uuid: string;
      registration_address_id?: number | null;
      trading_address_id?: number | null;
      name: string;
      type: CompanyType;
      registration_no: string;
      tax_id?: string | null;
      kyc_status: KycStatus;
      kyc_passed?: boolean | null;
      status: Status;
      agreement_date: any;
      created_at: any;
      updated_at?: any | null;
      activated_at?: any | null;
      deactivated_at?: any | null;
      deleted_at?: any | null;
      registration_address: {
        __typename?: 'Address';
        id?: number | null;
        country_id?: number | null;
        proof_uuid: string;
        proof_expired_at?: any | null;
        postal_code: string;
        created_at: any;
        updated_at: any;
        address_line: string;
        place_details?: any | null;
        country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
      };
      trading_address: {
        __typename?: 'Address';
        id?: number | null;
        country_id?: number | null;
        proof_uuid: string;
        proof_expired_at?: any | null;
        postal_code: string;
        created_at: any;
        updated_at: any;
        address_line: string;
        place_details?: any | null;
        country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
      };
      additional_documents?: Array<{
        __typename?: 'AdditionalDocument';
        id?: number | null;
        company_id?: number | null;
        person_id?: number | null;
        document_uuid: string;
        description: string;
        original_name?: string | null;
        created_at: any;
        type: AdditionalDocumentsTypes;
      }> | null;
      companies?: Array<{
        __typename?: 'Company';
        id?: number | null;
        parent_id?: number | null;
        approver_uuid?: string | null;
        company_type?: ApplicantCompanyType | null;
        organizational_form?: string | null;
        company_uuid: string;
        registration_address_id?: number | null;
        trading_address_id?: number | null;
        name: string;
        type: CompanyType;
        registration_no: string;
        tax_id?: string | null;
        kyc_status: KycStatus;
        kyc_passed?: boolean | null;
        status: Status;
        agreement_date: any;
        created_at: any;
        updated_at?: any | null;
        activated_at?: any | null;
        deactivated_at?: any | null;
        deleted_at?: any | null;
        registration_address: {
          __typename?: 'Address';
          id?: number | null;
          country_id?: number | null;
          proof_uuid: string;
          proof_expired_at?: any | null;
          postal_code: string;
          created_at: any;
          updated_at: any;
          address_line: string;
          place_details?: any | null;
          country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
        };
        trading_address: {
          __typename?: 'Address';
          id?: number | null;
          country_id?: number | null;
          proof_uuid: string;
          proof_expired_at?: any | null;
          postal_code: string;
          created_at: any;
          updated_at: any;
          address_line: string;
          place_details?: any | null;
          country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
        };
        additional_documents?: Array<{
          __typename?: 'AdditionalDocument';
          id?: number | null;
          company_id?: number | null;
          person_id?: number | null;
          document_uuid: string;
          description: string;
          original_name?: string | null;
          created_at: any;
          type: AdditionalDocumentsTypes;
        }> | null;
        companies?: Array<{
          __typename?: 'Company';
          id?: number | null;
          parent_id?: number | null;
          approver_uuid?: string | null;
          company_type?: ApplicantCompanyType | null;
          organizational_form?: string | null;
          company_uuid: string;
          registration_address_id?: number | null;
          trading_address_id?: number | null;
          name: string;
          type: CompanyType;
          registration_no: string;
          tax_id?: string | null;
          kyc_status: KycStatus;
          kyc_passed?: boolean | null;
          status: Status;
          agreement_date: any;
          created_at: any;
          updated_at?: any | null;
          activated_at?: any | null;
          deactivated_at?: any | null;
          deleted_at?: any | null;
          registration_address: {
            __typename?: 'Address';
            id?: number | null;
            country_id?: number | null;
            proof_uuid: string;
            proof_expired_at?: any | null;
            postal_code: string;
            created_at: any;
            updated_at: any;
            address_line: string;
            place_details?: any | null;
            country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
          };
          trading_address: {
            __typename?: 'Address';
            id?: number | null;
            country_id?: number | null;
            proof_uuid: string;
            proof_expired_at?: any | null;
            postal_code: string;
            created_at: any;
            updated_at: any;
            address_line: string;
            place_details?: any | null;
            country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
          };
          additional_documents?: Array<{
            __typename?: 'AdditionalDocument';
            id?: number | null;
            company_id?: number | null;
            person_id?: number | null;
            document_uuid: string;
            description: string;
            original_name?: string | null;
            created_at: any;
            type: AdditionalDocumentsTypes;
          }> | null;
          companies?: Array<{
            __typename?: 'Company';
            id?: number | null;
            parent_id?: number | null;
            approver_uuid?: string | null;
            company_type?: ApplicantCompanyType | null;
            organizational_form?: string | null;
            company_uuid: string;
            registration_address_id?: number | null;
            trading_address_id?: number | null;
            name: string;
            type: CompanyType;
            registration_no: string;
            tax_id?: string | null;
            kyc_status: KycStatus;
            kyc_passed?: boolean | null;
            status: Status;
            agreement_date: any;
            created_at: any;
            updated_at?: any | null;
            activated_at?: any | null;
            deactivated_at?: any | null;
            deleted_at?: any | null;
            registration_address: {
              __typename?: 'Address';
              id?: number | null;
              country_id?: number | null;
              proof_uuid: string;
              proof_expired_at?: any | null;
              postal_code: string;
              created_at: any;
              updated_at: any;
              address_line: string;
              place_details?: any | null;
              country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
            };
            trading_address: {
              __typename?: 'Address';
              id?: number | null;
              country_id?: number | null;
              proof_uuid: string;
              proof_expired_at?: any | null;
              postal_code: string;
              created_at: any;
              updated_at: any;
              address_line: string;
              place_details?: any | null;
              country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
            };
            additional_documents?: Array<{
              __typename?: 'AdditionalDocument';
              id?: number | null;
              company_id?: number | null;
              person_id?: number | null;
              document_uuid: string;
              description: string;
              original_name?: string | null;
              created_at: any;
              type: AdditionalDocumentsTypes;
            }> | null;
            companies?: Array<{
              __typename?: 'Company';
              id?: number | null;
              parent_id?: number | null;
              approver_uuid?: string | null;
              company_type?: ApplicantCompanyType | null;
              organizational_form?: string | null;
              company_uuid: string;
              registration_address_id?: number | null;
              trading_address_id?: number | null;
              name: string;
              type: CompanyType;
              registration_no: string;
              tax_id?: string | null;
              kyc_status: KycStatus;
              kyc_passed?: boolean | null;
              status: Status;
              agreement_date: any;
              created_at: any;
              updated_at?: any | null;
              activated_at?: any | null;
              deactivated_at?: any | null;
              deleted_at?: any | null;
              registration_address: {
                __typename?: 'Address';
                id?: number | null;
                country_id?: number | null;
                proof_uuid: string;
                proof_expired_at?: any | null;
                postal_code: string;
                created_at: any;
                updated_at: any;
                address_line: string;
                place_details?: any | null;
                country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
              };
              trading_address: {
                __typename?: 'Address';
                id?: number | null;
                country_id?: number | null;
                proof_uuid: string;
                proof_expired_at?: any | null;
                postal_code: string;
                created_at: any;
                updated_at: any;
                address_line: string;
                place_details?: any | null;
                country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
              };
              additional_documents?: Array<{
                __typename?: 'AdditionalDocument';
                id?: number | null;
                company_id?: number | null;
                person_id?: number | null;
                document_uuid: string;
                description: string;
                original_name?: string | null;
                created_at: any;
                type: AdditionalDocumentsTypes;
              }> | null;
              companies?: Array<{
                __typename?: 'Company';
                id?: number | null;
                parent_id?: number | null;
                approver_uuid?: string | null;
                company_type?: ApplicantCompanyType | null;
                organizational_form?: string | null;
                company_uuid: string;
                registration_address_id?: number | null;
                trading_address_id?: number | null;
                name: string;
                type: CompanyType;
                registration_no: string;
                tax_id?: string | null;
                kyc_status: KycStatus;
                kyc_passed?: boolean | null;
                status: Status;
                agreement_date: any;
                created_at: any;
                updated_at?: any | null;
                activated_at?: any | null;
                deactivated_at?: any | null;
                deleted_at?: any | null;
                registration_address: {
                  __typename?: 'Address';
                  id?: number | null;
                  country_id?: number | null;
                  proof_uuid: string;
                  proof_expired_at?: any | null;
                  postal_code: string;
                  created_at: any;
                  updated_at: any;
                  address_line: string;
                  place_details?: any | null;
                  country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
                };
                trading_address: {
                  __typename?: 'Address';
                  id?: number | null;
                  country_id?: number | null;
                  proof_uuid: string;
                  proof_expired_at?: any | null;
                  postal_code: string;
                  created_at: any;
                  updated_at: any;
                  address_line: string;
                  place_details?: any | null;
                  country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
                };
                additional_documents?: Array<{
                  __typename?: 'AdditionalDocument';
                  id?: number | null;
                  company_id?: number | null;
                  person_id?: number | null;
                  document_uuid: string;
                  description: string;
                  original_name?: string | null;
                  created_at: any;
                  type: AdditionalDocumentsTypes;
                }> | null;
                companies?: Array<{
                  __typename?: 'Company';
                  id?: number | null;
                  parent_id?: number | null;
                  approver_uuid?: string | null;
                  company_type?: ApplicantCompanyType | null;
                  organizational_form?: string | null;
                  company_uuid: string;
                  registration_address_id?: number | null;
                  trading_address_id?: number | null;
                  name: string;
                  type: CompanyType;
                  registration_no: string;
                  tax_id?: string | null;
                  kyc_status: KycStatus;
                  kyc_passed?: boolean | null;
                  status: Status;
                  agreement_date: any;
                  created_at: any;
                  updated_at?: any | null;
                  activated_at?: any | null;
                  deactivated_at?: any | null;
                  deleted_at?: any | null;
                  registration_address: {
                    __typename?: 'Address';
                    id?: number | null;
                    country_id?: number | null;
                    proof_uuid: string;
                    proof_expired_at?: any | null;
                    postal_code: string;
                    created_at: any;
                    updated_at: any;
                    address_line: string;
                    place_details?: any | null;
                    country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
                  };
                  trading_address: {
                    __typename?: 'Address';
                    id?: number | null;
                    country_id?: number | null;
                    proof_uuid: string;
                    proof_expired_at?: any | null;
                    postal_code: string;
                    created_at: any;
                    updated_at: any;
                    address_line: string;
                    place_details?: any | null;
                    country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
                  };
                  additional_documents?: Array<{
                    __typename?: 'AdditionalDocument';
                    id?: number | null;
                    company_id?: number | null;
                    person_id?: number | null;
                    document_uuid: string;
                    description: string;
                    original_name?: string | null;
                    created_at: any;
                    type: AdditionalDocumentsTypes;
                  }> | null;
                  companies?: Array<{ __typename?: 'Company'; id?: number | null }> | null;
                  persons?: Array<{
                    __typename?: 'Person';
                    id?: number | null;
                    company_id?: number | null;
                    company_uuid?: string | null;
                    approver_uuid?: string | null;
                    address_id?: number | null;
                    proof_of_identity_front_uuid?: string | null;
                    proof_of_identity_back_uuid: string;
                    proof_of_identity_type: ProofOfIdentityType;
                    title?: string | null;
                    first_name: string;
                    middle_name?: string | null;
                    last_name: string;
                    national_id?: string | null;
                    day_of_birth?: number | null;
                    month_of_birth?: number | null;
                    year_of_birth?: number | null;
                    is_director: boolean;
                    is_poc: boolean;
                    kyc_status: KycStatus;
                    kyc_passed?: boolean | null;
                    status?: Status | null;
                    source_of_wealth?: Array<SourceOfWealth> | null;
                    proof_of_identity_expired_at?: any | null;
                    activated_at?: any | null;
                    deactivated_at?: any | null;
                    created_at: any;
                    address?: {
                      __typename?: 'Address';
                      id?: number | null;
                      country_id?: number | null;
                      proof_uuid: string;
                      proof_expired_at?: any | null;
                      postal_code: string;
                      created_at: any;
                      updated_at: any;
                      address_line: string;
                      place_details?: any | null;
                      country?: {
                        __typename?: 'Country';
                        id?: number | null;
                        name: string;
                        alpha2_code: string;
                      } | null;
                    } | null;
                    additional_documents?: Array<{
                      __typename?: 'AdditionalDocument';
                      id?: number | null;
                      company_id?: number | null;
                      person_id?: number | null;
                      document_uuid: string;
                      description: string;
                      original_name?: string | null;
                      created_at: any;
                      type: AdditionalDocumentsTypes;
                    }> | null;
                  }> | null;
                }> | null;
                persons?: Array<{
                  __typename?: 'Person';
                  id?: number | null;
                  company_id?: number | null;
                  company_uuid?: string | null;
                  approver_uuid?: string | null;
                  address_id?: number | null;
                  proof_of_identity_front_uuid?: string | null;
                  proof_of_identity_back_uuid: string;
                  proof_of_identity_type: ProofOfIdentityType;
                  title?: string | null;
                  first_name: string;
                  middle_name?: string | null;
                  last_name: string;
                  national_id?: string | null;
                  day_of_birth?: number | null;
                  month_of_birth?: number | null;
                  year_of_birth?: number | null;
                  is_director: boolean;
                  is_poc: boolean;
                  kyc_status: KycStatus;
                  kyc_passed?: boolean | null;
                  status?: Status | null;
                  source_of_wealth?: Array<SourceOfWealth> | null;
                  proof_of_identity_expired_at?: any | null;
                  activated_at?: any | null;
                  deactivated_at?: any | null;
                  created_at: any;
                  address?: {
                    __typename?: 'Address';
                    id?: number | null;
                    country_id?: number | null;
                    proof_uuid: string;
                    proof_expired_at?: any | null;
                    postal_code: string;
                    created_at: any;
                    updated_at: any;
                    address_line: string;
                    place_details?: any | null;
                    country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
                  } | null;
                  additional_documents?: Array<{
                    __typename?: 'AdditionalDocument';
                    id?: number | null;
                    company_id?: number | null;
                    person_id?: number | null;
                    document_uuid: string;
                    description: string;
                    original_name?: string | null;
                    created_at: any;
                    type: AdditionalDocumentsTypes;
                  }> | null;
                }> | null;
              }> | null;
              persons?: Array<{
                __typename?: 'Person';
                id?: number | null;
                company_id?: number | null;
                company_uuid?: string | null;
                approver_uuid?: string | null;
                address_id?: number | null;
                proof_of_identity_front_uuid?: string | null;
                proof_of_identity_back_uuid: string;
                proof_of_identity_type: ProofOfIdentityType;
                title?: string | null;
                first_name: string;
                middle_name?: string | null;
                last_name: string;
                national_id?: string | null;
                day_of_birth?: number | null;
                month_of_birth?: number | null;
                year_of_birth?: number | null;
                is_director: boolean;
                is_poc: boolean;
                kyc_status: KycStatus;
                kyc_passed?: boolean | null;
                status?: Status | null;
                source_of_wealth?: Array<SourceOfWealth> | null;
                proof_of_identity_expired_at?: any | null;
                activated_at?: any | null;
                deactivated_at?: any | null;
                created_at: any;
                address?: {
                  __typename?: 'Address';
                  id?: number | null;
                  country_id?: number | null;
                  proof_uuid: string;
                  proof_expired_at?: any | null;
                  postal_code: string;
                  created_at: any;
                  updated_at: any;
                  address_line: string;
                  place_details?: any | null;
                  country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
                } | null;
                additional_documents?: Array<{
                  __typename?: 'AdditionalDocument';
                  id?: number | null;
                  company_id?: number | null;
                  person_id?: number | null;
                  document_uuid: string;
                  description: string;
                  original_name?: string | null;
                  created_at: any;
                  type: AdditionalDocumentsTypes;
                }> | null;
              }> | null;
            }> | null;
            persons?: Array<{
              __typename?: 'Person';
              id?: number | null;
              company_id?: number | null;
              company_uuid?: string | null;
              approver_uuid?: string | null;
              address_id?: number | null;
              proof_of_identity_front_uuid?: string | null;
              proof_of_identity_back_uuid: string;
              proof_of_identity_type: ProofOfIdentityType;
              title?: string | null;
              first_name: string;
              middle_name?: string | null;
              last_name: string;
              national_id?: string | null;
              day_of_birth?: number | null;
              month_of_birth?: number | null;
              year_of_birth?: number | null;
              is_director: boolean;
              is_poc: boolean;
              kyc_status: KycStatus;
              kyc_passed?: boolean | null;
              status?: Status | null;
              source_of_wealth?: Array<SourceOfWealth> | null;
              proof_of_identity_expired_at?: any | null;
              activated_at?: any | null;
              deactivated_at?: any | null;
              created_at: any;
              address?: {
                __typename?: 'Address';
                id?: number | null;
                country_id?: number | null;
                proof_uuid: string;
                proof_expired_at?: any | null;
                postal_code: string;
                created_at: any;
                updated_at: any;
                address_line: string;
                place_details?: any | null;
                country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
              } | null;
              additional_documents?: Array<{
                __typename?: 'AdditionalDocument';
                id?: number | null;
                company_id?: number | null;
                person_id?: number | null;
                document_uuid: string;
                description: string;
                original_name?: string | null;
                created_at: any;
                type: AdditionalDocumentsTypes;
              }> | null;
            }> | null;
          }> | null;
          persons?: Array<{
            __typename?: 'Person';
            id?: number | null;
            company_id?: number | null;
            company_uuid?: string | null;
            approver_uuid?: string | null;
            address_id?: number | null;
            proof_of_identity_front_uuid?: string | null;
            proof_of_identity_back_uuid: string;
            proof_of_identity_type: ProofOfIdentityType;
            title?: string | null;
            first_name: string;
            middle_name?: string | null;
            last_name: string;
            national_id?: string | null;
            day_of_birth?: number | null;
            month_of_birth?: number | null;
            year_of_birth?: number | null;
            is_director: boolean;
            is_poc: boolean;
            kyc_status: KycStatus;
            kyc_passed?: boolean | null;
            status?: Status | null;
            source_of_wealth?: Array<SourceOfWealth> | null;
            proof_of_identity_expired_at?: any | null;
            activated_at?: any | null;
            deactivated_at?: any | null;
            created_at: any;
            address?: {
              __typename?: 'Address';
              id?: number | null;
              country_id?: number | null;
              proof_uuid: string;
              proof_expired_at?: any | null;
              postal_code: string;
              created_at: any;
              updated_at: any;
              address_line: string;
              place_details?: any | null;
              country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
            } | null;
            additional_documents?: Array<{
              __typename?: 'AdditionalDocument';
              id?: number | null;
              company_id?: number | null;
              person_id?: number | null;
              document_uuid: string;
              description: string;
              original_name?: string | null;
              created_at: any;
              type: AdditionalDocumentsTypes;
            }> | null;
          }> | null;
        }> | null;
        persons?: Array<{
          __typename?: 'Person';
          id?: number | null;
          company_id?: number | null;
          company_uuid?: string | null;
          approver_uuid?: string | null;
          address_id?: number | null;
          proof_of_identity_front_uuid?: string | null;
          proof_of_identity_back_uuid: string;
          proof_of_identity_type: ProofOfIdentityType;
          title?: string | null;
          first_name: string;
          middle_name?: string | null;
          last_name: string;
          national_id?: string | null;
          day_of_birth?: number | null;
          month_of_birth?: number | null;
          year_of_birth?: number | null;
          is_director: boolean;
          is_poc: boolean;
          kyc_status: KycStatus;
          kyc_passed?: boolean | null;
          status?: Status | null;
          source_of_wealth?: Array<SourceOfWealth> | null;
          proof_of_identity_expired_at?: any | null;
          activated_at?: any | null;
          deactivated_at?: any | null;
          created_at: any;
          address?: {
            __typename?: 'Address';
            id?: number | null;
            country_id?: number | null;
            proof_uuid: string;
            proof_expired_at?: any | null;
            postal_code: string;
            created_at: any;
            updated_at: any;
            address_line: string;
            place_details?: any | null;
            country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
          } | null;
          additional_documents?: Array<{
            __typename?: 'AdditionalDocument';
            id?: number | null;
            company_id?: number | null;
            person_id?: number | null;
            document_uuid: string;
            description: string;
            original_name?: string | null;
            created_at: any;
            type: AdditionalDocumentsTypes;
          }> | null;
        }> | null;
      }> | null;
      persons?: Array<{
        __typename?: 'Person';
        id?: number | null;
        company_id?: number | null;
        company_uuid?: string | null;
        approver_uuid?: string | null;
        address_id?: number | null;
        proof_of_identity_front_uuid?: string | null;
        proof_of_identity_back_uuid: string;
        proof_of_identity_type: ProofOfIdentityType;
        title?: string | null;
        first_name: string;
        middle_name?: string | null;
        last_name: string;
        national_id?: string | null;
        day_of_birth?: number | null;
        month_of_birth?: number | null;
        year_of_birth?: number | null;
        is_director: boolean;
        is_poc: boolean;
        kyc_status: KycStatus;
        kyc_passed?: boolean | null;
        status?: Status | null;
        source_of_wealth?: Array<SourceOfWealth> | null;
        proof_of_identity_expired_at?: any | null;
        activated_at?: any | null;
        deactivated_at?: any | null;
        created_at: any;
        address?: {
          __typename?: 'Address';
          id?: number | null;
          country_id?: number | null;
          proof_uuid: string;
          proof_expired_at?: any | null;
          postal_code: string;
          created_at: any;
          updated_at: any;
          address_line: string;
          place_details?: any | null;
          country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
        } | null;
        additional_documents?: Array<{
          __typename?: 'AdditionalDocument';
          id?: number | null;
          company_id?: number | null;
          person_id?: number | null;
          document_uuid: string;
          description: string;
          original_name?: string | null;
          created_at: any;
          type: AdditionalDocumentsTypes;
        }> | null;
      }> | null;
    }> | null;
    persons?: Array<{
      __typename?: 'Person';
      id?: number | null;
      company_id?: number | null;
      company_uuid?: string | null;
      approver_uuid?: string | null;
      address_id?: number | null;
      proof_of_identity_front_uuid?: string | null;
      proof_of_identity_back_uuid: string;
      proof_of_identity_type: ProofOfIdentityType;
      title?: string | null;
      first_name: string;
      middle_name?: string | null;
      last_name: string;
      national_id?: string | null;
      day_of_birth?: number | null;
      month_of_birth?: number | null;
      year_of_birth?: number | null;
      is_director: boolean;
      is_poc: boolean;
      kyc_status: KycStatus;
      kyc_passed?: boolean | null;
      status?: Status | null;
      source_of_wealth?: Array<SourceOfWealth> | null;
      proof_of_identity_expired_at?: any | null;
      activated_at?: any | null;
      deactivated_at?: any | null;
      created_at: any;
      address?: {
        __typename?: 'Address';
        id?: number | null;
        country_id?: number | null;
        proof_uuid: string;
        proof_expired_at?: any | null;
        postal_code: string;
        created_at: any;
        updated_at: any;
        address_line: string;
        place_details?: any | null;
        country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
      } | null;
      additional_documents?: Array<{
        __typename?: 'AdditionalDocument';
        id?: number | null;
        company_id?: number | null;
        person_id?: number | null;
        document_uuid: string;
        description: string;
        original_name?: string | null;
        created_at: any;
        type: AdditionalDocumentsTypes;
      }> | null;
    }> | null;
  };
};

export type UpdateCompanyMutationVariables = Exact<{
  input: UpdateOneCompanyInput;
}>;

export type UpdateCompanyMutation = {
  __typename?: 'Mutation';
  updateOneCompany: {
    __typename?: 'Company';
    id?: number | null;
    parent_id?: number | null;
    approver_uuid?: string | null;
    company_type?: ApplicantCompanyType | null;
    organizational_form?: string | null;
    company_uuid: string;
    registration_address_id?: number | null;
    trading_address_id?: number | null;
    name: string;
    type: CompanyType;
    registration_no: string;
    tax_id?: string | null;
    kyc_status: KycStatus;
    kyc_passed?: boolean | null;
    status: Status;
    agreement_date: any;
    created_at: any;
    updated_at?: any | null;
    deactivated_at?: any | null;
    deleted_at?: any | null;
    additional_fees?: Array<{
      __typename?: 'AdditionalFee';
      company_id?: number | null;
      created_at: any;
      deleted_at?: any | null;
      description: string;
      fee?: number | null;
      id?: number | null;
      updated_at?: any | null;
    }> | null;
    registration_address: {
      __typename?: 'Address';
      id?: number | null;
      country_id?: number | null;
      proof_uuid: string;
      proof_expired_at?: any | null;
      postal_code: string;
      created_at: any;
      updated_at: any;
      address_line: string;
      place_details?: any | null;
      country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
    };
    trading_address: {
      __typename?: 'Address';
      id?: number | null;
      country_id?: number | null;
      proof_uuid: string;
      proof_expired_at?: any | null;
      postal_code: string;
      created_at: any;
      updated_at: any;
      address_line: string;
      place_details?: any | null;
      country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
    };
    additional_documents?: Array<{
      __typename?: 'AdditionalDocument';
      id?: number | null;
      company_id?: number | null;
      person_id?: number | null;
      document_uuid: string;
      description: string;
      original_name?: string | null;
      created_at: any;
      type: AdditionalDocumentsTypes;
    }> | null;
    companies?: Array<{
      __typename?: 'Company';
      id?: number | null;
      parent_id?: number | null;
      approver_uuid?: string | null;
      company_type?: ApplicantCompanyType | null;
      organizational_form?: string | null;
      company_uuid: string;
      registration_address_id?: number | null;
      trading_address_id?: number | null;
      name: string;
      type: CompanyType;
      registration_no: string;
      tax_id?: string | null;
      kyc_status: KycStatus;
      kyc_passed?: boolean | null;
      status: Status;
      agreement_date: any;
      created_at: any;
      updated_at?: any | null;
      activated_at?: any | null;
      deactivated_at?: any | null;
      deleted_at?: any | null;
      registration_address: {
        __typename?: 'Address';
        id?: number | null;
        country_id?: number | null;
        proof_uuid: string;
        proof_expired_at?: any | null;
        postal_code: string;
        created_at: any;
        updated_at: any;
        address_line: string;
        place_details?: any | null;
        country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
      };
      trading_address: {
        __typename?: 'Address';
        id?: number | null;
        country_id?: number | null;
        proof_uuid: string;
        proof_expired_at?: any | null;
        postal_code: string;
        created_at: any;
        updated_at: any;
        address_line: string;
        place_details?: any | null;
        country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
      };
      additional_documents?: Array<{
        __typename?: 'AdditionalDocument';
        id?: number | null;
        company_id?: number | null;
        person_id?: number | null;
        document_uuid: string;
        description: string;
        original_name?: string | null;
        created_at: any;
        type: AdditionalDocumentsTypes;
      }> | null;
      companies?: Array<{
        __typename?: 'Company';
        id?: number | null;
        parent_id?: number | null;
        approver_uuid?: string | null;
        company_type?: ApplicantCompanyType | null;
        organizational_form?: string | null;
        company_uuid: string;
        registration_address_id?: number | null;
        trading_address_id?: number | null;
        name: string;
        type: CompanyType;
        registration_no: string;
        tax_id?: string | null;
        kyc_status: KycStatus;
        kyc_passed?: boolean | null;
        status: Status;
        agreement_date: any;
        created_at: any;
        updated_at?: any | null;
        activated_at?: any | null;
        deactivated_at?: any | null;
        deleted_at?: any | null;
        registration_address: {
          __typename?: 'Address';
          id?: number | null;
          country_id?: number | null;
          proof_uuid: string;
          proof_expired_at?: any | null;
          postal_code: string;
          created_at: any;
          updated_at: any;
          address_line: string;
          place_details?: any | null;
          country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
        };
        trading_address: {
          __typename?: 'Address';
          id?: number | null;
          country_id?: number | null;
          proof_uuid: string;
          proof_expired_at?: any | null;
          postal_code: string;
          created_at: any;
          updated_at: any;
          address_line: string;
          place_details?: any | null;
          country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
        };
        additional_documents?: Array<{
          __typename?: 'AdditionalDocument';
          id?: number | null;
          company_id?: number | null;
          person_id?: number | null;
          document_uuid: string;
          description: string;
          original_name?: string | null;
          created_at: any;
          type: AdditionalDocumentsTypes;
        }> | null;
        companies?: Array<{
          __typename?: 'Company';
          id?: number | null;
          parent_id?: number | null;
          approver_uuid?: string | null;
          company_type?: ApplicantCompanyType | null;
          organizational_form?: string | null;
          company_uuid: string;
          registration_address_id?: number | null;
          trading_address_id?: number | null;
          name: string;
          type: CompanyType;
          registration_no: string;
          tax_id?: string | null;
          kyc_status: KycStatus;
          kyc_passed?: boolean | null;
          status: Status;
          agreement_date: any;
          created_at: any;
          updated_at?: any | null;
          activated_at?: any | null;
          deactivated_at?: any | null;
          deleted_at?: any | null;
          registration_address: {
            __typename?: 'Address';
            id?: number | null;
            country_id?: number | null;
            proof_uuid: string;
            proof_expired_at?: any | null;
            postal_code: string;
            created_at: any;
            updated_at: any;
            address_line: string;
            place_details?: any | null;
            country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
          };
          trading_address: {
            __typename?: 'Address';
            id?: number | null;
            country_id?: number | null;
            proof_uuid: string;
            proof_expired_at?: any | null;
            postal_code: string;
            created_at: any;
            updated_at: any;
            address_line: string;
            place_details?: any | null;
            country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
          };
          additional_documents?: Array<{
            __typename?: 'AdditionalDocument';
            id?: number | null;
            company_id?: number | null;
            person_id?: number | null;
            document_uuid: string;
            description: string;
            original_name?: string | null;
            created_at: any;
            type: AdditionalDocumentsTypes;
          }> | null;
          companies?: Array<{
            __typename?: 'Company';
            id?: number | null;
            parent_id?: number | null;
            approver_uuid?: string | null;
            company_type?: ApplicantCompanyType | null;
            organizational_form?: string | null;
            company_uuid: string;
            registration_address_id?: number | null;
            trading_address_id?: number | null;
            name: string;
            type: CompanyType;
            registration_no: string;
            tax_id?: string | null;
            kyc_status: KycStatus;
            kyc_passed?: boolean | null;
            status: Status;
            agreement_date: any;
            created_at: any;
            updated_at?: any | null;
            activated_at?: any | null;
            deactivated_at?: any | null;
            deleted_at?: any | null;
            registration_address: {
              __typename?: 'Address';
              id?: number | null;
              country_id?: number | null;
              proof_uuid: string;
              proof_expired_at?: any | null;
              postal_code: string;
              created_at: any;
              updated_at: any;
              address_line: string;
              place_details?: any | null;
              country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
            };
            trading_address: {
              __typename?: 'Address';
              id?: number | null;
              country_id?: number | null;
              proof_uuid: string;
              proof_expired_at?: any | null;
              postal_code: string;
              created_at: any;
              updated_at: any;
              address_line: string;
              place_details?: any | null;
              country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
            };
            additional_documents?: Array<{
              __typename?: 'AdditionalDocument';
              id?: number | null;
              company_id?: number | null;
              person_id?: number | null;
              document_uuid: string;
              description: string;
              original_name?: string | null;
              created_at: any;
              type: AdditionalDocumentsTypes;
            }> | null;
            companies?: Array<{
              __typename?: 'Company';
              id?: number | null;
              parent_id?: number | null;
              approver_uuid?: string | null;
              company_type?: ApplicantCompanyType | null;
              organizational_form?: string | null;
              company_uuid: string;
              registration_address_id?: number | null;
              trading_address_id?: number | null;
              name: string;
              type: CompanyType;
              registration_no: string;
              tax_id?: string | null;
              kyc_status: KycStatus;
              kyc_passed?: boolean | null;
              status: Status;
              agreement_date: any;
              created_at: any;
              updated_at?: any | null;
              activated_at?: any | null;
              deactivated_at?: any | null;
              deleted_at?: any | null;
              registration_address: {
                __typename?: 'Address';
                id?: number | null;
                country_id?: number | null;
                proof_uuid: string;
                proof_expired_at?: any | null;
                postal_code: string;
                created_at: any;
                updated_at: any;
                address_line: string;
                place_details?: any | null;
                country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
              };
              trading_address: {
                __typename?: 'Address';
                id?: number | null;
                country_id?: number | null;
                proof_uuid: string;
                proof_expired_at?: any | null;
                postal_code: string;
                created_at: any;
                updated_at: any;
                address_line: string;
                place_details?: any | null;
                country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
              };
              additional_documents?: Array<{
                __typename?: 'AdditionalDocument';
                id?: number | null;
                company_id?: number | null;
                person_id?: number | null;
                document_uuid: string;
                description: string;
                original_name?: string | null;
                created_at: any;
                type: AdditionalDocumentsTypes;
              }> | null;
              companies?: Array<{
                __typename?: 'Company';
                id?: number | null;
                parent_id?: number | null;
                approver_uuid?: string | null;
                company_type?: ApplicantCompanyType | null;
                organizational_form?: string | null;
                company_uuid: string;
                registration_address_id?: number | null;
                trading_address_id?: number | null;
                name: string;
                type: CompanyType;
                registration_no: string;
                tax_id?: string | null;
                kyc_status: KycStatus;
                kyc_passed?: boolean | null;
                status: Status;
                agreement_date: any;
                created_at: any;
                updated_at?: any | null;
                activated_at?: any | null;
                deactivated_at?: any | null;
                deleted_at?: any | null;
                registration_address: {
                  __typename?: 'Address';
                  id?: number | null;
                  country_id?: number | null;
                  proof_uuid: string;
                  proof_expired_at?: any | null;
                  postal_code: string;
                  created_at: any;
                  updated_at: any;
                  address_line: string;
                  place_details?: any | null;
                  country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
                };
                trading_address: {
                  __typename?: 'Address';
                  id?: number | null;
                  country_id?: number | null;
                  proof_uuid: string;
                  proof_expired_at?: any | null;
                  postal_code: string;
                  created_at: any;
                  updated_at: any;
                  address_line: string;
                  place_details?: any | null;
                  country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
                };
                additional_documents?: Array<{
                  __typename?: 'AdditionalDocument';
                  id?: number | null;
                  company_id?: number | null;
                  person_id?: number | null;
                  document_uuid: string;
                  description: string;
                  original_name?: string | null;
                  created_at: any;
                  type: AdditionalDocumentsTypes;
                }> | null;
                companies?: Array<{
                  __typename?: 'Company';
                  id?: number | null;
                  parent_id?: number | null;
                  approver_uuid?: string | null;
                  company_type?: ApplicantCompanyType | null;
                  organizational_form?: string | null;
                  company_uuid: string;
                  registration_address_id?: number | null;
                  trading_address_id?: number | null;
                  name: string;
                  type: CompanyType;
                  registration_no: string;
                  tax_id?: string | null;
                  kyc_status: KycStatus;
                  kyc_passed?: boolean | null;
                  status: Status;
                  agreement_date: any;
                  created_at: any;
                  updated_at?: any | null;
                  activated_at?: any | null;
                  deactivated_at?: any | null;
                  deleted_at?: any | null;
                  registration_address: {
                    __typename?: 'Address';
                    id?: number | null;
                    country_id?: number | null;
                    proof_uuid: string;
                    proof_expired_at?: any | null;
                    postal_code: string;
                    created_at: any;
                    updated_at: any;
                    address_line: string;
                    place_details?: any | null;
                    country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
                  };
                  trading_address: {
                    __typename?: 'Address';
                    id?: number | null;
                    country_id?: number | null;
                    proof_uuid: string;
                    proof_expired_at?: any | null;
                    postal_code: string;
                    created_at: any;
                    updated_at: any;
                    address_line: string;
                    place_details?: any | null;
                    country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
                  };
                  additional_documents?: Array<{
                    __typename?: 'AdditionalDocument';
                    id?: number | null;
                    company_id?: number | null;
                    person_id?: number | null;
                    document_uuid: string;
                    description: string;
                    original_name?: string | null;
                    created_at: any;
                    type: AdditionalDocumentsTypes;
                  }> | null;
                  companies?: Array<{ __typename?: 'Company'; id?: number | null }> | null;
                  persons?: Array<{
                    __typename?: 'Person';
                    id?: number | null;
                    company_id?: number | null;
                    company_uuid?: string | null;
                    approver_uuid?: string | null;
                    address_id?: number | null;
                    proof_of_identity_front_uuid?: string | null;
                    proof_of_identity_back_uuid: string;
                    proof_of_identity_type: ProofOfIdentityType;
                    title?: string | null;
                    first_name: string;
                    middle_name?: string | null;
                    last_name: string;
                    national_id?: string | null;
                    day_of_birth?: number | null;
                    month_of_birth?: number | null;
                    year_of_birth?: number | null;
                    is_director: boolean;
                    is_poc: boolean;
                    kyc_status: KycStatus;
                    kyc_passed?: boolean | null;
                    status?: Status | null;
                    source_of_wealth?: Array<SourceOfWealth> | null;
                    proof_of_identity_expired_at?: any | null;
                    activated_at?: any | null;
                    deactivated_at?: any | null;
                    created_at: any;
                    address?: {
                      __typename?: 'Address';
                      id?: number | null;
                      country_id?: number | null;
                      proof_uuid: string;
                      proof_expired_at?: any | null;
                      postal_code: string;
                      created_at: any;
                      updated_at: any;
                      address_line: string;
                      place_details?: any | null;
                      country?: {
                        __typename?: 'Country';
                        id?: number | null;
                        name: string;
                        alpha2_code: string;
                      } | null;
                    } | null;
                    additional_documents?: Array<{
                      __typename?: 'AdditionalDocument';
                      id?: number | null;
                      company_id?: number | null;
                      person_id?: number | null;
                      document_uuid: string;
                      description: string;
                      original_name?: string | null;
                      created_at: any;
                      type: AdditionalDocumentsTypes;
                    }> | null;
                  }> | null;
                }> | null;
                persons?: Array<{
                  __typename?: 'Person';
                  id?: number | null;
                  company_id?: number | null;
                  company_uuid?: string | null;
                  approver_uuid?: string | null;
                  address_id?: number | null;
                  proof_of_identity_front_uuid?: string | null;
                  proof_of_identity_back_uuid: string;
                  proof_of_identity_type: ProofOfIdentityType;
                  title?: string | null;
                  first_name: string;
                  middle_name?: string | null;
                  last_name: string;
                  national_id?: string | null;
                  day_of_birth?: number | null;
                  month_of_birth?: number | null;
                  year_of_birth?: number | null;
                  is_director: boolean;
                  is_poc: boolean;
                  kyc_status: KycStatus;
                  kyc_passed?: boolean | null;
                  status?: Status | null;
                  source_of_wealth?: Array<SourceOfWealth> | null;
                  proof_of_identity_expired_at?: any | null;
                  activated_at?: any | null;
                  deactivated_at?: any | null;
                  created_at: any;
                  address?: {
                    __typename?: 'Address';
                    id?: number | null;
                    country_id?: number | null;
                    proof_uuid: string;
                    proof_expired_at?: any | null;
                    postal_code: string;
                    created_at: any;
                    updated_at: any;
                    address_line: string;
                    place_details?: any | null;
                    country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
                  } | null;
                  additional_documents?: Array<{
                    __typename?: 'AdditionalDocument';
                    id?: number | null;
                    company_id?: number | null;
                    person_id?: number | null;
                    document_uuid: string;
                    description: string;
                    original_name?: string | null;
                    created_at: any;
                    type: AdditionalDocumentsTypes;
                  }> | null;
                }> | null;
              }> | null;
              persons?: Array<{
                __typename?: 'Person';
                id?: number | null;
                company_id?: number | null;
                company_uuid?: string | null;
                approver_uuid?: string | null;
                address_id?: number | null;
                proof_of_identity_front_uuid?: string | null;
                proof_of_identity_back_uuid: string;
                proof_of_identity_type: ProofOfIdentityType;
                title?: string | null;
                first_name: string;
                middle_name?: string | null;
                last_name: string;
                national_id?: string | null;
                day_of_birth?: number | null;
                month_of_birth?: number | null;
                year_of_birth?: number | null;
                is_director: boolean;
                is_poc: boolean;
                kyc_status: KycStatus;
                kyc_passed?: boolean | null;
                status?: Status | null;
                source_of_wealth?: Array<SourceOfWealth> | null;
                proof_of_identity_expired_at?: any | null;
                activated_at?: any | null;
                deactivated_at?: any | null;
                created_at: any;
                address?: {
                  __typename?: 'Address';
                  id?: number | null;
                  country_id?: number | null;
                  proof_uuid: string;
                  proof_expired_at?: any | null;
                  postal_code: string;
                  created_at: any;
                  updated_at: any;
                  address_line: string;
                  place_details?: any | null;
                  country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
                } | null;
                additional_documents?: Array<{
                  __typename?: 'AdditionalDocument';
                  id?: number | null;
                  company_id?: number | null;
                  person_id?: number | null;
                  document_uuid: string;
                  description: string;
                  original_name?: string | null;
                  created_at: any;
                  type: AdditionalDocumentsTypes;
                }> | null;
              }> | null;
            }> | null;
            persons?: Array<{
              __typename?: 'Person';
              id?: number | null;
              company_id?: number | null;
              company_uuid?: string | null;
              approver_uuid?: string | null;
              address_id?: number | null;
              proof_of_identity_front_uuid?: string | null;
              proof_of_identity_back_uuid: string;
              proof_of_identity_type: ProofOfIdentityType;
              title?: string | null;
              first_name: string;
              middle_name?: string | null;
              last_name: string;
              national_id?: string | null;
              day_of_birth?: number | null;
              month_of_birth?: number | null;
              year_of_birth?: number | null;
              is_director: boolean;
              is_poc: boolean;
              kyc_status: KycStatus;
              kyc_passed?: boolean | null;
              status?: Status | null;
              source_of_wealth?: Array<SourceOfWealth> | null;
              proof_of_identity_expired_at?: any | null;
              activated_at?: any | null;
              deactivated_at?: any | null;
              created_at: any;
              address?: {
                __typename?: 'Address';
                id?: number | null;
                country_id?: number | null;
                proof_uuid: string;
                proof_expired_at?: any | null;
                postal_code: string;
                created_at: any;
                updated_at: any;
                address_line: string;
                place_details?: any | null;
                country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
              } | null;
              additional_documents?: Array<{
                __typename?: 'AdditionalDocument';
                id?: number | null;
                company_id?: number | null;
                person_id?: number | null;
                document_uuid: string;
                description: string;
                original_name?: string | null;
                created_at: any;
                type: AdditionalDocumentsTypes;
              }> | null;
            }> | null;
          }> | null;
          persons?: Array<{
            __typename?: 'Person';
            id?: number | null;
            company_id?: number | null;
            company_uuid?: string | null;
            approver_uuid?: string | null;
            address_id?: number | null;
            proof_of_identity_front_uuid?: string | null;
            proof_of_identity_back_uuid: string;
            proof_of_identity_type: ProofOfIdentityType;
            title?: string | null;
            first_name: string;
            middle_name?: string | null;
            last_name: string;
            national_id?: string | null;
            day_of_birth?: number | null;
            month_of_birth?: number | null;
            year_of_birth?: number | null;
            is_director: boolean;
            is_poc: boolean;
            kyc_status: KycStatus;
            kyc_passed?: boolean | null;
            status?: Status | null;
            source_of_wealth?: Array<SourceOfWealth> | null;
            proof_of_identity_expired_at?: any | null;
            activated_at?: any | null;
            deactivated_at?: any | null;
            created_at: any;
            address?: {
              __typename?: 'Address';
              id?: number | null;
              country_id?: number | null;
              proof_uuid: string;
              proof_expired_at?: any | null;
              postal_code: string;
              created_at: any;
              updated_at: any;
              address_line: string;
              place_details?: any | null;
              country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
            } | null;
            additional_documents?: Array<{
              __typename?: 'AdditionalDocument';
              id?: number | null;
              company_id?: number | null;
              person_id?: number | null;
              document_uuid: string;
              description: string;
              original_name?: string | null;
              created_at: any;
              type: AdditionalDocumentsTypes;
            }> | null;
          }> | null;
        }> | null;
        persons?: Array<{
          __typename?: 'Person';
          id?: number | null;
          company_id?: number | null;
          company_uuid?: string | null;
          approver_uuid?: string | null;
          address_id?: number | null;
          proof_of_identity_front_uuid?: string | null;
          proof_of_identity_back_uuid: string;
          proof_of_identity_type: ProofOfIdentityType;
          title?: string | null;
          first_name: string;
          middle_name?: string | null;
          last_name: string;
          national_id?: string | null;
          day_of_birth?: number | null;
          month_of_birth?: number | null;
          year_of_birth?: number | null;
          is_director: boolean;
          is_poc: boolean;
          kyc_status: KycStatus;
          kyc_passed?: boolean | null;
          status?: Status | null;
          source_of_wealth?: Array<SourceOfWealth> | null;
          proof_of_identity_expired_at?: any | null;
          activated_at?: any | null;
          deactivated_at?: any | null;
          created_at: any;
          address?: {
            __typename?: 'Address';
            id?: number | null;
            country_id?: number | null;
            proof_uuid: string;
            proof_expired_at?: any | null;
            postal_code: string;
            created_at: any;
            updated_at: any;
            address_line: string;
            place_details?: any | null;
            country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
          } | null;
          additional_documents?: Array<{
            __typename?: 'AdditionalDocument';
            id?: number | null;
            company_id?: number | null;
            person_id?: number | null;
            document_uuid: string;
            description: string;
            original_name?: string | null;
            created_at: any;
            type: AdditionalDocumentsTypes;
          }> | null;
        }> | null;
      }> | null;
      persons?: Array<{
        __typename?: 'Person';
        id?: number | null;
        company_id?: number | null;
        company_uuid?: string | null;
        approver_uuid?: string | null;
        address_id?: number | null;
        proof_of_identity_front_uuid?: string | null;
        proof_of_identity_back_uuid: string;
        proof_of_identity_type: ProofOfIdentityType;
        title?: string | null;
        first_name: string;
        middle_name?: string | null;
        last_name: string;
        national_id?: string | null;
        day_of_birth?: number | null;
        month_of_birth?: number | null;
        year_of_birth?: number | null;
        is_director: boolean;
        is_poc: boolean;
        kyc_status: KycStatus;
        kyc_passed?: boolean | null;
        status?: Status | null;
        source_of_wealth?: Array<SourceOfWealth> | null;
        proof_of_identity_expired_at?: any | null;
        activated_at?: any | null;
        deactivated_at?: any | null;
        created_at: any;
        address?: {
          __typename?: 'Address';
          id?: number | null;
          country_id?: number | null;
          proof_uuid: string;
          proof_expired_at?: any | null;
          postal_code: string;
          created_at: any;
          updated_at: any;
          address_line: string;
          place_details?: any | null;
          country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
        } | null;
        additional_documents?: Array<{
          __typename?: 'AdditionalDocument';
          id?: number | null;
          company_id?: number | null;
          person_id?: number | null;
          document_uuid: string;
          description: string;
          original_name?: string | null;
          created_at: any;
          type: AdditionalDocumentsTypes;
        }> | null;
      }> | null;
    }> | null;
    persons?: Array<{
      __typename?: 'Person';
      id?: number | null;
      company_id?: number | null;
      company_uuid?: string | null;
      approver_uuid?: string | null;
      address_id?: number | null;
      proof_of_identity_front_uuid?: string | null;
      proof_of_identity_back_uuid: string;
      proof_of_identity_type: ProofOfIdentityType;
      title?: string | null;
      first_name: string;
      middle_name?: string | null;
      last_name: string;
      national_id?: string | null;
      day_of_birth?: number | null;
      month_of_birth?: number | null;
      year_of_birth?: number | null;
      is_director: boolean;
      is_poc: boolean;
      kyc_status: KycStatus;
      kyc_passed?: boolean | null;
      status?: Status | null;
      source_of_wealth?: Array<SourceOfWealth> | null;
      proof_of_identity_expired_at?: any | null;
      activated_at?: any | null;
      deactivated_at?: any | null;
      created_at: any;
      address?: {
        __typename?: 'Address';
        id?: number | null;
        country_id?: number | null;
        proof_uuid: string;
        proof_expired_at?: any | null;
        postal_code: string;
        created_at: any;
        updated_at: any;
        address_line: string;
        place_details?: any | null;
        country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
      } | null;
      additional_documents?: Array<{
        __typename?: 'AdditionalDocument';
        id?: number | null;
        company_id?: number | null;
        person_id?: number | null;
        document_uuid: string;
        description: string;
        original_name?: string | null;
        created_at: any;
        type: AdditionalDocumentsTypes;
      }> | null;
    }> | null;
  };
};

export type DeleteManyCompaniesMutationVariables = Exact<{
  input: DeleteManyCompaniesInput;
}>;

export type DeleteManyCompaniesMutation = {
  __typename?: 'Mutation';
  deleteManyCompanies: { __typename?: 'DeleteManyResponse'; deletedCount: number };
};

export type CreateAdditionalDocumentMutationVariables = Exact<{
  input: CreateOneAdditionalDocumentInput;
}>;

export type CreateAdditionalDocumentMutation = {
  __typename?: 'Mutation';
  createOneAdditionalDocument: {
    __typename?: 'AdditionalDocument';
    id?: number | null;
    company_id?: number | null;
    person_id?: number | null;
    document_uuid: string;
    description: string;
    original_name?: string | null;
    created_at: any;
    type: AdditionalDocumentsTypes;
  };
};

export type UpdateAdditionalDocumentMutationVariables = Exact<{
  input: UpdateOneAdditionalDocumentInput;
}>;

export type UpdateAdditionalDocumentMutation = {
  __typename?: 'Mutation';
  updateOneAdditionalDocument: {
    __typename?: 'AdditionalDocument';
    id?: number | null;
    company_id?: number | null;
    person_id?: number | null;
    document_uuid: string;
    description: string;
    original_name?: string | null;
    created_at: any;
    type: AdditionalDocumentsTypes;
  };
};

export type DeleteAdditionalDocumentMutationVariables = Exact<{
  input: DeleteOneAdditionalDocumentInput;
}>;

export type DeleteAdditionalDocumentMutation = {
  __typename?: 'Mutation';
  deleteOneAdditionalDocument: {
    __typename?: 'AdditionalDocumentDeleteResponse';
    id?: number | null;
    company_id?: number | null;
    original_name?: string | null;
  };
};

export type CreatePersonMutationVariables = Exact<{
  input: CreateOnePersonInput;
}>;

export type CreatePersonMutation = {
  __typename?: 'Mutation';
  createOnePerson: {
    __typename?: 'Person';
    id?: number | null;
    company_id?: number | null;
    company_uuid?: string | null;
    approver_uuid?: string | null;
    address_id?: number | null;
    proof_of_identity_front_uuid?: string | null;
    proof_of_identity_back_uuid: string;
    proof_of_identity_type: ProofOfIdentityType;
    title?: string | null;
    first_name: string;
    middle_name?: string | null;
    last_name: string;
    national_id?: string | null;
    day_of_birth?: number | null;
    month_of_birth?: number | null;
    year_of_birth?: number | null;
    is_director: boolean;
    is_poc: boolean;
    kyc_status: KycStatus;
    kyc_passed?: boolean | null;
    status?: Status | null;
    source_of_wealth?: Array<SourceOfWealth> | null;
    proof_of_identity_expired_at?: any | null;
    activated_at?: any | null;
    deactivated_at?: any | null;
    created_at: any;
    address?: {
      __typename?: 'Address';
      id?: number | null;
      country_id?: number | null;
      proof_uuid: string;
      proof_expired_at?: any | null;
      postal_code: string;
      created_at: any;
      updated_at: any;
      address_line: string;
      place_details?: any | null;
      country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
    } | null;
    additional_documents?: Array<{
      __typename?: 'AdditionalDocument';
      id?: number | null;
      company_id?: number | null;
      person_id?: number | null;
      document_uuid: string;
      description: string;
      original_name?: string | null;
      created_at: any;
      type: AdditionalDocumentsTypes;
    }> | null;
  };
};

export type CreateManyPeopleMutationVariables = Exact<{
  input: CreateManyPeopleInput;
}>;

export type CreateManyPeopleMutation = {
  __typename?: 'Mutation';
  createManyPeople: Array<{
    __typename?: 'Person';
    id?: number | null;
    company_id?: number | null;
    company_uuid?: string | null;
    approver_uuid?: string | null;
    address_id?: number | null;
    proof_of_identity_front_uuid?: string | null;
    proof_of_identity_back_uuid: string;
    proof_of_identity_type: ProofOfIdentityType;
    title?: string | null;
    first_name: string;
    middle_name?: string | null;
    last_name: string;
    national_id?: string | null;
    day_of_birth?: number | null;
    month_of_birth?: number | null;
    year_of_birth?: number | null;
    is_director: boolean;
    is_poc: boolean;
    kyc_status: KycStatus;
    kyc_passed?: boolean | null;
    status?: Status | null;
    source_of_wealth?: Array<SourceOfWealth> | null;
    proof_of_identity_expired_at?: any | null;
    activated_at?: any | null;
    deactivated_at?: any | null;
    created_at: any;
    address?: {
      __typename?: 'Address';
      id?: number | null;
      country_id?: number | null;
      proof_uuid: string;
      proof_expired_at?: any | null;
      postal_code: string;
      created_at: any;
      updated_at: any;
      address_line: string;
      place_details?: any | null;
      country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
    } | null;
    additional_documents?: Array<{
      __typename?: 'AdditionalDocument';
      id?: number | null;
      company_id?: number | null;
      person_id?: number | null;
      document_uuid: string;
      description: string;
      original_name?: string | null;
      created_at: any;
      type: AdditionalDocumentsTypes;
    }> | null;
  }>;
};

export type UpdatePersonMutationVariables = Exact<{
  input: UpdateOnePersonInput;
}>;

export type UpdatePersonMutation = {
  __typename?: 'Mutation';
  updateOnePerson: {
    __typename?: 'Person';
    id?: number | null;
    company_id?: number | null;
    company_uuid?: string | null;
    approver_uuid?: string | null;
    address_id?: number | null;
    proof_of_identity_front_uuid?: string | null;
    proof_of_identity_back_uuid: string;
    proof_of_identity_type: ProofOfIdentityType;
    title?: string | null;
    first_name: string;
    middle_name?: string | null;
    last_name: string;
    national_id?: string | null;
    day_of_birth?: number | null;
    month_of_birth?: number | null;
    year_of_birth?: number | null;
    is_director: boolean;
    is_poc: boolean;
    kyc_status: KycStatus;
    kyc_passed?: boolean | null;
    status?: Status | null;
    source_of_wealth?: Array<SourceOfWealth> | null;
    proof_of_identity_expired_at?: any | null;
    activated_at?: any | null;
    deactivated_at?: any | null;
    created_at: any;
    address?: {
      __typename?: 'Address';
      id?: number | null;
      country_id?: number | null;
      proof_uuid: string;
      proof_expired_at?: any | null;
      postal_code: string;
      created_at: any;
      updated_at: any;
      address_line: string;
      place_details?: any | null;
      country?: { __typename?: 'Country'; id?: number | null; name: string; alpha2_code: string } | null;
    } | null;
    additional_documents?: Array<{
      __typename?: 'AdditionalDocument';
      id?: number | null;
      company_id?: number | null;
      person_id?: number | null;
      document_uuid: string;
      description: string;
      original_name?: string | null;
      created_at: any;
      type: AdditionalDocumentsTypes;
    }> | null;
  };
};

export type DeletePersonMutationVariables = Exact<{
  input: DeleteOnePersonInput;
}>;

export type DeletePersonMutation = {
  __typename?: 'Mutation';
  deleteOnePerson: {
    __typename?: 'PersonDeleteResponse';
    id?: number | null;
    is_director?: boolean | null;
    is_poc?: boolean | null;
  };
};

export type DeleteManyPeopleMutationVariables = Exact<{
  input: DeleteManyPeopleInput;
}>;

export type DeleteManyPeopleMutation = {
  __typename?: 'Mutation';
  deleteManyPeople: { __typename?: 'DeleteManyResponse'; deletedCount: number };
};

export type CreateOneAdditionalFeeMutationVariables = Exact<{
  input: CreateOneAdditionalFeeInput;
}>;

export type CreateOneAdditionalFeeMutation = {
  __typename?: 'Mutation';
  createOneAdditionalFee: {
    __typename?: 'AdditionalFee';
    company_id?: number | null;
    created_at: any;
    deleted_at?: any | null;
    description: string;
    fee?: number | null;
    id?: number | null;
    updated_at?: any | null;
  };
};

export type UpdateOneAdditionalFeeMutationVariables = Exact<{
  input: UpdateOneAdditionalFeeInput;
}>;

export type UpdateOneAdditionalFeeMutation = {
  __typename?: 'Mutation';
  updateOneAdditionalFee: {
    __typename?: 'AdditionalFee';
    company_id?: number | null;
    created_at: any;
    deleted_at?: any | null;
    description: string;
    fee?: number | null;
    id?: number | null;
    updated_at?: any | null;
  };
};

export type DeleteOneAdditionalFeeMutationVariables = Exact<{
  input: DeleteOneAdditionalFeeInput;
}>;

export type DeleteOneAdditionalFeeMutation = {
  __typename?: 'Mutation';
  deleteOneAdditionalFee: {
    __typename?: 'AdditionalFeeDeleteResponse';
    id?: number | null;
    company_id?: number | null;
    deleted_at?: any | null;
  };
};

export const AdditionalFeeFragmentFragmentDoc = gql`
  fragment AdditionalFeeFragment on AdditionalFee {
    company_id
    created_at
    deleted_at
    description
    fee
    id
    updated_at
  }
`;
export const CountryFragmentFragmentDoc = gql`
  fragment CountryFragment on Country {
    id
    name
    alpha2_code
  }
`;
export const AddressFragmentFragmentDoc = gql`
  fragment AddressFragment on Address {
    id
    country_id
    proof_uuid
    proof_expired_at
    postal_code
    created_at
    updated_at
    country {
      ...CountryFragment
    }
    address_line
    place_details
  }
  ${CountryFragmentFragmentDoc}
`;
export const AdditionalDocumentFragmentFragmentDoc = gql`
  fragment AdditionalDocumentFragment on AdditionalDocument {
    id
    company_id
    person_id
    document_uuid
    description
    original_name
    created_at
    type
  }
`;
export const PersonFragmentFragmentDoc = gql`
  fragment PersonFragment on Person {
    id
    company_id
    company_uuid
    approver_uuid
    address_id
    proof_of_identity_front_uuid
    proof_of_identity_back_uuid
    proof_of_identity_type
    title
    first_name
    middle_name
    last_name
    national_id
    day_of_birth
    month_of_birth
    year_of_birth
    is_director
    is_poc
    kyc_status
    kyc_passed
    status
    source_of_wealth
    proof_of_identity_expired_at
    activated_at
    deactivated_at
    created_at
    address {
      ...AddressFragment
    }
    additional_documents {
      ...AdditionalDocumentFragment
    }
  }
  ${AddressFragmentFragmentDoc}
  ${AdditionalDocumentFragmentFragmentDoc}
`;
export const CompanyFragmentFragmentDoc = gql`
  fragment CompanyFragment on Company {
    id
    parent_id
    approver_uuid
    company_type
    organizational_form
    company_uuid
    registration_address_id
    trading_address_id
    name
    type
    registration_no
    tax_id
    kyc_status
    kyc_passed
    status
    agreement_date
    created_at
    updated_at
    deactivated_at
    deleted_at
    additional_fees {
      ...AdditionalFeeFragment
    }
    registration_address {
      ...AddressFragment
    }
    trading_address {
      ...AddressFragment
    }
    additional_documents {
      ...AdditionalDocumentFragment
    }
    companies {
      ... on Company {
        id
        parent_id
        approver_uuid
        company_type
        organizational_form
        company_uuid
        registration_address_id
        trading_address_id
        name
        type
        registration_no
        tax_id
        kyc_status
        kyc_passed
        status
        agreement_date
        created_at
        updated_at
        activated_at
        deactivated_at
        deleted_at
        registration_address {
          ...AddressFragment
        }
        trading_address {
          ...AddressFragment
        }
        additional_documents {
          ...AdditionalDocumentFragment
        }
        companies {
          ... on Company {
            id
            parent_id
            approver_uuid
            company_type
            organizational_form
            company_uuid
            registration_address_id
            trading_address_id
            name
            type
            registration_no
            tax_id
            kyc_status
            kyc_passed
            status
            agreement_date
            created_at
            updated_at
            activated_at
            deactivated_at
            deleted_at
            registration_address {
              ...AddressFragment
            }
            trading_address {
              ...AddressFragment
            }
            additional_documents {
              ...AdditionalDocumentFragment
            }
            companies {
              ... on Company {
                id
                parent_id
                approver_uuid
                company_type
                organizational_form
                company_uuid
                registration_address_id
                trading_address_id
                name
                type
                registration_no
                tax_id
                kyc_status
                kyc_passed
                status
                agreement_date
                created_at
                updated_at
                activated_at
                deactivated_at
                deleted_at
                registration_address {
                  ...AddressFragment
                }
                trading_address {
                  ...AddressFragment
                }
                additional_documents {
                  ...AdditionalDocumentFragment
                }
                companies {
                  ... on Company {
                    id
                    parent_id
                    approver_uuid
                    company_type
                    organizational_form
                    company_uuid
                    registration_address_id
                    trading_address_id
                    name
                    type
                    registration_no
                    tax_id
                    kyc_status
                    kyc_passed
                    status
                    agreement_date
                    created_at
                    updated_at
                    activated_at
                    deactivated_at
                    deleted_at
                    registration_address {
                      ...AddressFragment
                    }
                    trading_address {
                      ...AddressFragment
                    }
                    additional_documents {
                      ...AdditionalDocumentFragment
                    }
                    companies {
                      ... on Company {
                        id
                        parent_id
                        approver_uuid
                        company_type
                        organizational_form
                        company_uuid
                        registration_address_id
                        trading_address_id
                        name
                        type
                        registration_no
                        tax_id
                        kyc_status
                        kyc_passed
                        status
                        agreement_date
                        created_at
                        updated_at
                        activated_at
                        deactivated_at
                        deleted_at
                        registration_address {
                          ...AddressFragment
                        }
                        trading_address {
                          ...AddressFragment
                        }
                        additional_documents {
                          ...AdditionalDocumentFragment
                        }
                        companies {
                          ... on Company {
                            id
                            parent_id
                            approver_uuid
                            company_type
                            organizational_form
                            company_uuid
                            registration_address_id
                            trading_address_id
                            name
                            type
                            registration_no
                            tax_id
                            kyc_status
                            kyc_passed
                            status
                            agreement_date
                            created_at
                            updated_at
                            activated_at
                            deactivated_at
                            deleted_at
                            registration_address {
                              ...AddressFragment
                            }
                            trading_address {
                              ...AddressFragment
                            }
                            additional_documents {
                              ...AdditionalDocumentFragment
                            }
                            companies {
                              ... on Company {
                                id
                                parent_id
                                approver_uuid
                                company_type
                                organizational_form
                                company_uuid
                                registration_address_id
                                trading_address_id
                                name
                                type
                                registration_no
                                tax_id
                                kyc_status
                                kyc_passed
                                status
                                agreement_date
                                created_at
                                updated_at
                                activated_at
                                deactivated_at
                                deleted_at
                                registration_address {
                                  ...AddressFragment
                                }
                                trading_address {
                                  ...AddressFragment
                                }
                                additional_documents {
                                  ...AdditionalDocumentFragment
                                }
                                companies {
                                  ... on Company {
                                    id
                                  }
                                }
                                persons {
                                  ...PersonFragment
                                }
                              }
                            }
                            persons {
                              ...PersonFragment
                            }
                          }
                        }
                        persons {
                          ...PersonFragment
                        }
                      }
                    }
                    persons {
                      ...PersonFragment
                    }
                  }
                }
                persons {
                  ...PersonFragment
                }
              }
            }
            persons {
              ...PersonFragment
            }
          }
        }
        persons {
          ...PersonFragment
        }
      }
    }
    persons {
      ...PersonFragment
    }
  }
  ${AdditionalFeeFragmentFragmentDoc}
  ${AddressFragmentFragmentDoc}
  ${AdditionalDocumentFragmentFragmentDoc}
  ${PersonFragmentFragmentDoc}
`;
export const GetCountryDocument = gql`
  query getCountry($id: Int!) {
    country(id: $id) {
      ...CountryFragment
    }
  }
  ${CountryFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetCountryGQL extends Apollo.Query<GetCountryQuery, GetCountryQueryVariables> {
  document = GetCountryDocument;
  client = 'kyc';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetCountriesDocument = gql`
  query getCountries($filter: CountryFilter = {}, $sorting: [CountrySort!] = []) {
    countries(filter: $filter, sorting: $sorting) {
      ...CountryFragment
    }
  }
  ${CountryFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetCountriesGQL extends Apollo.Query<GetCountriesQuery, GetCountriesQueryVariables> {
  document = GetCountriesDocument;
  client = 'kyc';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetAddressDocument = gql`
  query getAddress($id: Int!) {
    address(id: $id) {
      ...AddressFragment
    }
  }
  ${AddressFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetAddressGQL extends Apollo.Query<GetAddressQuery, GetAddressQueryVariables> {
  document = GetAddressDocument;
  client = 'kyc';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetCompanyDocument = gql`
  query getCompany($id: Int!) {
    company(id: $id) {
      ...CompanyFragment
    }
  }
  ${CompanyFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetCompanyGQL extends Apollo.Query<GetCompanyQuery, GetCompanyQueryVariables> {
  document = GetCompanyDocument;
  client = 'kyc';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetCompaniesDocument = gql`
  query getCompanies($paging: CursorPaging = {}, $filter: CompanyFilter = {}, $sorting: [CompanySort!] = []) {
    companies(paging: $paging, filter: $filter, sorting: $sorting) {
      ... on CompanyConnection {
        edges {
          ... on CompanyEdge {
            cursor
            node {
              ...CompanyFragment
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
  ${CompanyFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetCompaniesGQL extends Apollo.Query<GetCompaniesQuery, GetCompaniesQueryVariables> {
  document = GetCompaniesDocument;
  client = 'kyc';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetAdditionalFeeDocument = gql`
  query getAdditionalFee($id: Int!) {
    additionalFee(id: $id) {
      ...AdditionalFeeFragment
    }
  }
  ${AdditionalFeeFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetAdditionalFeeGQL extends Apollo.Query<GetAdditionalFeeQuery, GetAdditionalFeeQueryVariables> {
  document = GetAdditionalFeeDocument;
  client = 'kyc';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetAdditionalFeesDocument = gql`
  query getAdditionalFees($filter: AdditionalFeeFilter = {}, $sorting: [AdditionalFeeSort!] = []) {
    additionalFees(filter: $filter, sorting: $sorting) {
      ...AdditionalFeeFragment
    }
  }
  ${AdditionalFeeFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetAdditionalFeesGQL extends Apollo.Query<GetAdditionalFeesQuery, GetAdditionalFeesQueryVariables> {
  document = GetAdditionalFeesDocument;
  client = 'kyc';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetPersonDocument = gql`
  query getPerson($id: Int!) {
    person(id: $id) {
      ...PersonFragment
    }
  }
  ${PersonFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetPersonGQL extends Apollo.Query<GetPersonQuery, GetPersonQueryVariables> {
  document = GetPersonDocument;
  client = 'kyc';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetPeopleDocument = gql`
  query getPeople($paging: CursorPaging = {}, $filter: PersonFilter = {}, $sorting: [PersonSort!] = []) {
    people(paging: $paging, filter: $filter, sorting: $sorting) {
      edges {
        cursor
        node {
          ...PersonFragment
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${PersonFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetPeopleGQL extends Apollo.Query<GetPeopleQuery, GetPeopleQueryVariables> {
  document = GetPeopleDocument;
  client = 'kyc';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetAdditionalDocumentDocument = gql`
  query getAdditionalDocument($id: Int!) {
    additionalDocument(id: $id) {
      ...AdditionalDocumentFragment
    }
  }
  ${AdditionalDocumentFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetAdditionalDocumentGQL extends Apollo.Query<
  GetAdditionalDocumentQuery,
  GetAdditionalDocumentQueryVariables
> {
  document = GetAdditionalDocumentDocument;
  client = 'kyc';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetAdditionalDocumentsDocument = gql`
  query getAdditionalDocuments($filter: AdditionalDocumentFilter = {}, $sorting: [AdditionalDocumentSort!] = []) {
    additionalDocuments(filter: $filter, sorting: $sorting) {
      ...AdditionalDocumentFragment
    }
  }
  ${AdditionalDocumentFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class GetAdditionalDocumentsGQL extends Apollo.Query<
  GetAdditionalDocumentsQuery,
  GetAdditionalDocumentsQueryVariables
> {
  document = GetAdditionalDocumentsDocument;
  client = 'kyc';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateAddressDocument = gql`
  mutation createAddress($input: CreateOneAddressInput!) {
    createOneAddress(input: $input) {
      ...AddressFragment
    }
  }
  ${AddressFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CreateAddressGQL extends Apollo.Mutation<CreateAddressMutation, CreateAddressMutationVariables> {
  document = CreateAddressDocument;
  client = 'kyc';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateAddressDocument = gql`
  mutation updateAddress($input: UpdateOneAddressInput!) {
    updateOneAddress(input: $input) {
      ...AddressFragment
    }
  }
  ${AddressFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateAddressGQL extends Apollo.Mutation<UpdateAddressMutation, UpdateAddressMutationVariables> {
  document = UpdateAddressDocument;
  client = 'kyc';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateCompanyDocument = gql`
  mutation createCompany($input: CreateOneCompanyInput!) {
    createOneCompany(input: $input) {
      ...CompanyFragment
    }
  }
  ${CompanyFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CreateCompanyGQL extends Apollo.Mutation<CreateCompanyMutation, CreateCompanyMutationVariables> {
  document = CreateCompanyDocument;
  client = 'kyc';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateCompanyDocument = gql`
  mutation updateCompany($input: UpdateOneCompanyInput!) {
    updateOneCompany(input: $input) {
      ...CompanyFragment
    }
  }
  ${CompanyFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateCompanyGQL extends Apollo.Mutation<UpdateCompanyMutation, UpdateCompanyMutationVariables> {
  document = UpdateCompanyDocument;
  client = 'kyc';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteManyCompaniesDocument = gql`
  mutation deleteManyCompanies($input: DeleteManyCompaniesInput!) {
    deleteManyCompanies(input: $input) {
      deletedCount
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DeleteManyCompaniesGQL extends Apollo.Mutation<
  DeleteManyCompaniesMutation,
  DeleteManyCompaniesMutationVariables
> {
  document = DeleteManyCompaniesDocument;
  client = 'kyc';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateAdditionalDocumentDocument = gql`
  mutation createAdditionalDocument($input: CreateOneAdditionalDocumentInput!) {
    createOneAdditionalDocument(input: $input) {
      ...AdditionalDocumentFragment
    }
  }
  ${AdditionalDocumentFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CreateAdditionalDocumentGQL extends Apollo.Mutation<
  CreateAdditionalDocumentMutation,
  CreateAdditionalDocumentMutationVariables
> {
  document = CreateAdditionalDocumentDocument;
  client = 'kyc';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateAdditionalDocumentDocument = gql`
  mutation updateAdditionalDocument($input: UpdateOneAdditionalDocumentInput!) {
    updateOneAdditionalDocument(input: $input) {
      ...AdditionalDocumentFragment
    }
  }
  ${AdditionalDocumentFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateAdditionalDocumentGQL extends Apollo.Mutation<
  UpdateAdditionalDocumentMutation,
  UpdateAdditionalDocumentMutationVariables
> {
  document = UpdateAdditionalDocumentDocument;
  client = 'kyc';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteAdditionalDocumentDocument = gql`
  mutation deleteAdditionalDocument($input: DeleteOneAdditionalDocumentInput!) {
    deleteOneAdditionalDocument(input: $input) {
      id
      company_id
      original_name
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DeleteAdditionalDocumentGQL extends Apollo.Mutation<
  DeleteAdditionalDocumentMutation,
  DeleteAdditionalDocumentMutationVariables
> {
  document = DeleteAdditionalDocumentDocument;
  client = 'kyc';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreatePersonDocument = gql`
  mutation createPerson($input: CreateOnePersonInput!) {
    createOnePerson(input: $input) {
      ...PersonFragment
    }
  }
  ${PersonFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CreatePersonGQL extends Apollo.Mutation<CreatePersonMutation, CreatePersonMutationVariables> {
  document = CreatePersonDocument;
  client = 'kyc';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateManyPeopleDocument = gql`
  mutation createManyPeople($input: CreateManyPeopleInput!) {
    createManyPeople(input: $input) {
      ...PersonFragment
    }
  }
  ${PersonFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CreateManyPeopleGQL extends Apollo.Mutation<CreateManyPeopleMutation, CreateManyPeopleMutationVariables> {
  document = CreateManyPeopleDocument;
  client = 'kyc';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdatePersonDocument = gql`
  mutation updatePerson($input: UpdateOnePersonInput!) {
    updateOnePerson(input: $input) {
      ...PersonFragment
    }
  }
  ${PersonFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class UpdatePersonGQL extends Apollo.Mutation<UpdatePersonMutation, UpdatePersonMutationVariables> {
  document = UpdatePersonDocument;
  client = 'kyc';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeletePersonDocument = gql`
  mutation deletePerson($input: DeleteOnePersonInput!) {
    deleteOnePerson(input: $input) {
      id
      is_director
      is_poc
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DeletePersonGQL extends Apollo.Mutation<DeletePersonMutation, DeletePersonMutationVariables> {
  document = DeletePersonDocument;
  client = 'kyc';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteManyPeopleDocument = gql`
  mutation deleteManyPeople($input: DeleteManyPeopleInput!) {
    deleteManyPeople(input: $input) {
      deletedCount
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DeleteManyPeopleGQL extends Apollo.Mutation<DeleteManyPeopleMutation, DeleteManyPeopleMutationVariables> {
  document = DeleteManyPeopleDocument;
  client = 'kyc';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateOneAdditionalFeeDocument = gql`
  mutation createOneAdditionalFee($input: CreateOneAdditionalFeeInput!) {
    createOneAdditionalFee(input: $input) {
      ...AdditionalFeeFragment
    }
  }
  ${AdditionalFeeFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CreateOneAdditionalFeeGQL extends Apollo.Mutation<
  CreateOneAdditionalFeeMutation,
  CreateOneAdditionalFeeMutationVariables
> {
  document = CreateOneAdditionalFeeDocument;
  client = 'kyc';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateOneAdditionalFeeDocument = gql`
  mutation updateOneAdditionalFee($input: UpdateOneAdditionalFeeInput!) {
    updateOneAdditionalFee(input: $input) {
      ...AdditionalFeeFragment
    }
  }
  ${AdditionalFeeFragmentFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateOneAdditionalFeeGQL extends Apollo.Mutation<
  UpdateOneAdditionalFeeMutation,
  UpdateOneAdditionalFeeMutationVariables
> {
  document = UpdateOneAdditionalFeeDocument;
  client = 'kyc';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteOneAdditionalFeeDocument = gql`
  mutation deleteOneAdditionalFee($input: DeleteOneAdditionalFeeInput!) {
    deleteOneAdditionalFee(input: $input) {
      id
      company_id
      deleted_at
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DeleteOneAdditionalFeeGQL extends Apollo.Mutation<
  DeleteOneAdditionalFeeMutation,
  DeleteOneAdditionalFeeMutationVariables
> {
  document = DeleteOneAdditionalFeeDocument;
  client = 'kyc';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
