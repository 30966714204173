import { RequestPath } from './models/request-path.model';
import { RequestMethod } from './models/request-method.model';
import { CustomError } from './models/custom-error.model';

export function getApiErrorMessagesList(): CustomError[] {
  return [
    {
      path: RequestPath.CoverInvestmentStrategy,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `All sellers's elements must be unique`,
      text: `All sellers's elements must be unique`,
    },
    {
      path: RequestPath.CoverInvestmentStrategy,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `sellers should not be empty`,
      text: `Sellers should not be empty`,
    },
    {
      path: RequestPath.CoverInvestmentStrategy,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `each value in sellers should not be empty`,
      text: `Each value in sellers should not be empty`,
    },
    {
      path: RequestPath.CoverInvestmentStrategy,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `sellers must be an array`,
      text: `Sellers must be an array`,
    },
    {
      path: RequestPath.CoverInvestmentStrategy,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `currency must be a valid enum value`,
      text: `Currency must be a valid enum value`,
    },
    {
      path: RequestPath.CoverInvestmentStrategy,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `currency must be a string`,
      text: `Currency must be a string`,
    },
    {
      path: RequestPath.CoverInvestmentStrategy,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `currency should not be empty`,
      text: `Currency should not be empty`,
    },
    {
      path: RequestPath.CoverInvestmentStrategy,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `return_max must not be less than 0`,
      text: `Return max must not be less than 0`,
    },
    {
      path: RequestPath.CoverInvestmentStrategy,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `return_max must not be greater than 1`,
      text: `Return max must not be greater than 1`,
    },
    {
      path: RequestPath.CoverInvestmentStrategy,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `return_max must be a positive number`,
      text: `Return max must be a positive number`,
    },
    {
      path: RequestPath.CoverInvestmentStrategy,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `return_max must be a number conforming to the specified constraints`,
      text: `Return max must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.CoverInvestmentStrategy,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `return_max should not be empty`,
      text: `Return max should not be empty`,
    },
    {
      path: RequestPath.CoverInvestmentStrategy,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `return_min must not be less than -1`,
      text: `Return min must not be less than -1`,
    },
    {
      path: RequestPath.CoverInvestmentStrategy,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `return_min must not be greater than 1`,
      text: `Return min must not be greater than 1`,
    },
    {
      path: RequestPath.CoverInvestmentStrategy,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `return_min must be a positive number`,
      text: `Return min must be a positive number`,
    },
    {
      path: RequestPath.CoverInvestmentStrategy,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `return_min must be a number conforming to the specified constraints`,
      text: `Return min must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.CoverInvestmentStrategy,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `return_min should not be empty`,
      text: `Return min should not be empty`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `Seller doesn't exist`,
      text: `Seller doesn't exist`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `Payer doesn't exist`,
      text: `Payer doesn't exist`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `seller_id must be a positive number`,
      text: `seller_id must be a positive number`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `seller_id should not be empty`,
      text: `seller_id should not be empty`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `seller_id must be a number conforming to the specified constraints`,
      text: `seller_id must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `payer_id must be a positive number`,
      text: `payer_id must be a positive number`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `payer_id should not be empty`,
      text: `payer_id should not be empty`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `payer_id must be a number conforming to the specified constraints`,
      text: `payer_id must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `period must be shorter than or equal to 255 characters`,
      text: `Period must be shorter than or equal to 255 characters`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `period must be a string`,
      text: `Period must be a string`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `is_active should not be empty`,
      text: `is_active should not be empty`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `is_active must be a boolean value`,
      text: `is_active must be a boolean value`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `sum of seller_pay_fee_share and payer_pay_fee_share must be less of equal 1`,
      text: `Sum of seller_pay_fee_share and payer_pay_fee_share must be less of equal 1`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `seller_pay_fee_share should not be empty`,
      text: `seller_pay_fee_share should not be empty`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `seller_pay_fee_share must not be greater than 1`,
      text: `seller_pay_fee_share must not be greater than 1`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `seller_pay_fee_share must not be less than 0`,
      text: `seller_pay_fee_share must not be less than 0`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `sum of seller_pay_fee_share and payer_pay_fee_share must be less of equal 1`,
      text: `Sum of seller_pay_fee_share and payer_pay_fee_share must be less of equal 1`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `payer_pay_fee_share should not be empty`,
      text: `payer_pay_fee_share should not be empty`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `payer_pay_fee_share must not be greater than 1`,
      text: `payer_pay_fee_share must not be greater than 1`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `payer_pay_fee_share must not be less than 0`,
      text: `payer_pay_fee_share must not be less than 0`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `advanced_payment_share should not be empty`,
      text: `advanced_payment_share should not be empty`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `advanced_payment_share must not be greater than 1`,
      text: `advanced_payment_share must not be greater than 1`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `advanced_payment_share must not be less than 0`,
      text: `advanced_payment_share must not be less than 0`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `funder_return should not be empty`,
      text: `Funder return should not be empty`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `funder_return must not be greater than 1`,
      text: `Funder return must not be greater than 1`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `funder_return must not be less than 0`,
      text: `Funder return must not be less than 0`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `daily_penalty should not be empty`,
      text: `Daily penalty should not be empty`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `daily_penalty must not be greater than 1`,
      text: `Daily penalty must not be greater than 1`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `daily_penalty must not be less than 0`,
      text: `Daily penalty must not be less than 0`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `interest_advanced should not be empty`,
      text: `interest_advanced should not be empty`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `interest_advanced must not be greater than 1`,
      text: `interest_advanced must not be greater than 1`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `interest_advanced must not be less than 0`,
      text: `interest_advanced must not be less than 0`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `default_payer_id should not be empty`,
      text: `default_payer_id should not be empty`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `platform_fee must not be greater than 1`,
      text: `Platform Fee must not be greater than 1`,
    },
    {
      path: RequestPath.SellerPayerMetadata,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `platform_fee must not be less than 0`,
      text: `Platform Fee must not be less than 0`,
    },
    {
      path: RequestPath.Payers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `name must be unique`,
      text: `Name must be unique`,
    },
    {
      path: RequestPath.Payers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `gln must be unique`,
      text: `gln must be unique`,
    },
    {
      path: RequestPath.Payers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `email must be unique`,
      text: `Email must be unique`,
    },
    {
      path: RequestPath.Payers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `country_id must be a positive number`,
      text: `country_id must be a positive number`,
    },
    {
      path: RequestPath.Payers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `country_id must be a number conforming to the specified constraints`,
      text: `country_id must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.Payers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `country_id should not be empty`,
      text: `country_id should not be empty`,
    },
    {
      path: RequestPath.Payers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `name must be shorter than or equal to 255 characters`,
      text: `Name must be shorter than or equal to 255 characters`,
    },
    {
      path: RequestPath.Payers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `name must be a string`,
      text: `Name must be a string`,
    },
    {
      path: RequestPath.Payers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `name should not be empty`,
      text: `Name should not be empty`,
    },
    {
      path: RequestPath.Payers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `country must be shorter than or equal to 255 characters`,
      text: `Country must be shorter than or equal to 255 characters`,
    },
    {
      path: RequestPath.Payers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `country must be a string`,
      text: `Country must be a string`,
    },
    {
      path: RequestPath.Payers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `country should not be empty`,
      text: `Country should not be empty`,
    },
    {
      path: RequestPath.Payers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `hq_country must be shorter than or equal to 255 characters`,
      text: `hq_country must be shorter than or equal to 255 characters`,
    },
    {
      path: RequestPath.Payers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `hq_country must be a string`,
      text: `hq_country must be a string`,
    },
    {
      path: RequestPath.Payers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `default_invoice_currency must be a valid enum value`,
      text: `Default invoice currency must be a valid enum value`,
    },
    {
      path: RequestPath.Payers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `default_invoice_currency should not be empty`,
      text: `Default invoice currency should not be empty`,
    },
    {
      path: RequestPath.Payers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `gln must be longer than or equal to 13 characters`,
      text: `gln must be longer than or equal to 13 characters`,
    },
    {
      path: RequestPath.Payers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `gln must be a string`,
      text: `gln must be a string`,
    },
    {
      path: RequestPath.Payers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `email must be an email`,
      text: `Email must be an email`,
    },
    {
      path: RequestPath.Payers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `email should not be empty`,
      text: `Email should not be empty`,
    },
    {
      path: RequestPath.Payers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `is_prepayment must be a boolean value`,
      text: `is_prepayment must be a boolean value`,
    },
    {
      path: RequestPath.Payers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `limit_amount must be a number conforming to the specified constraints`,
      text: `Limit amount must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.Payers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `limit_currency must be a string`,
      text: `Limit currency must be a string`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `Industry doesn't exist`,
      text: `Industry doesn't exist`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `name must be unique`,
      text: `Name must be unique`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `gln must be unique`,
      text: `gln must be unique`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `industry_id must be a positive number`,
      text: `industry_id must be a positive number`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `industry_id must be a number conforming to the specified constraints`,
      text: `industry_id must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `country_id must be a positive number`,
      text: `country_id must be a positive number`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `country_id must be a number conforming to the specified constraints`,
      text: `country_id must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `name must be shorter than or equal to 255 characters`,
      text: `Name must be shorter than or equal to 255 characters`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `name must be a string`,
      text: `Name must be a string`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `country must be shorter than or equal to 255 characters`,
      text: `Country must be shorter than or equal to 255 characters`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `country must be a string`,
      text: `Country must be a string`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `hq_country must be shorter than or equal to 255 characters`,
      text: `hq_country must be shorter than or equal to 255 characters`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `hq_country must be a string`,
      text: `hq_country must be a string`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `default_invoice_currency must be a valid enum value`,
      text: `Default invoice currency must be a valid enum value`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `gln must be longer than or equal to 13 characters`,
      text: `gln must be longer than or equal to 13 characters`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `gln must be a string`,
      text: `gln must be a string`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `yearly_turnover must not be greater than 999999999999999`,
      text: `Yearly turnover must not be greater than 999999999999999`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `yearly_turnover must be a positive number`,
      text: `Yearly turnover must be a positive number`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `yearly_turnover must be a number conforming to the specified constraints`,
      text: `Yearly turnover must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview must be an object`,
      text: `Overview must be an object`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `short_name must be shorter than or equal to 255 characters`,
      text: `Short name must be shorter than or equal to 255 characters`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `short_name must be a string`,
      text: `Short name must be a string`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `is_sellers_restricted must be a boolean value`,
      text: `is_sellers_restricted must be a boolean value`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `is_draft must be a boolean value`,
      text: `is_draft must be a boolean value`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `fund_manager_id must be a positive number`,
      text: `fund_manager_id must be a positive number`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `fund_manager_id must be a number conforming to the specified constraints`,
      text: `fund_manager_id must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `is_prepayment must be a boolean value`,
      text: `is_prepayment must be a boolean value`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `tenor must not be greater than 360`,
      text: `Tenor must not be greater than 360 days`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `tenor must not be less than 0`,
      text: `Tenor must not be less than 0`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `tenor must be a number conforming to the specified constraints`,
      text: `Tenor must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `earliest_funding must not be greater than 360`,
      text: `Earliest funding date must not be greater than 360 days`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `earliest_funding must not be less than 0`,
      text: `Earliest funding must not be less than 0`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `earliest_funding must be a number conforming to the specified constraints`,
      text: `Earliest funding must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `latest_funding must not be greater than 360`,
      text: `Latest funding date must not be greater than 360 days`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `latest_funding must not be less than 0`,
      text: `Latest funding must not be less than 0`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `latest_funding must be a number conforming to the specified constraints`,
      text: `Latest funding must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `extension_tenor must not be greater than 360`,
      text: `Extension tenor must not be greater than 360`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `extension_tenor must not be less than 0`,
      text: `Extension tenor must not be less than 0`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `extension_tenor must be a number conforming to the specified constraints`,
      text: `Extension tenor must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `limit_amount must be a number conforming to the specified constraints`,
      text: `Limit amount must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `limit_currency must be a string`,
      text: `Limit currency must be a string`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.years_of_activity must be a string`,
      text: `Years of activity in Overview must be a string`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.internal_risk_rating must be shorter than or equal to 50 characters`,
      text: `Internal risk rating in Overview must be shorter than or equal to 50 characters`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.internal_risk_rating must be a string`,
      text: `Internal risk rating in Overview must be a string`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.term must not be greater than 10000`,
      text: `Term in Overview must not be greater than 10000`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.term must not be less than 0`,
      text: `Term in Overview must not be less than 0`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.term must be a number conforming to the specified constraints`,
      text: `Term in Overview must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.obligor_turnover must be a positive number`,
      text: `Obligor turnover in Overview must be a positive number`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.obligor_turnover must be a number conforming to the specified constraints`,
      text: `Obligor turnover in Overview must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.ebitda must not be greater than 999999999999999`,
      text: `Ebitda in Overview must not be greater than 999999999999999`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.ebitda must be a positive number`,
      text: `Ebitda in Overview must be a positive number`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.ebitda must be a number conforming to the specified constraints`,
      text: `Ebitda in Overview must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.net_ebitda must not be greater than 999999999999999`,
      text: `Net ebitda in Overview must not be greater than 999999999999999`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.net_ebitda must be a positive number`,
      text: `Net ebitda in Overview must be a positive number`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.net_ebitda must be a number conforming to the specified constraints`,
      text: `Net ebitda in Overview must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.interest_coverage must be a positive number`,
      text: `Interest coverage in Overview must be a positive number`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.interest_coverage must be a number conforming to the specified constraints`,
      text: `Interest coverage in Overview must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.total_asset must be a positive number`,
      text: `Total asset in Overview must be a positive number`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.total_asset must be a number conforming to the specified constraints`,
      text: `Total asset in Overview must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.annual_revenue must not be greater than 999999999999999`,
      text: `Annual revenue in Overview must not be greater than 999999999999999`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.annual_revenue must be a positive number`,
      text: `Annual Revenue must be a positive number`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.annual_revenue must be a number conforming to the specified constraints`,
      text: `Annual revenue in Overview must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.business_description must be a string`,
      text: `Business description in Overview must be a string`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.transaction_summary must be a string`,
      text: `Transaction summary in Overview must be a string`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.credit_mitigants must be a string`,
      text: `Credit mitigants in Overview must be a string`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.capitalization must be a positive number`,
      text: `Capitalization in Overview must be a positive number`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.capitalization must be a number conforming to the specified constraints`,
      text: `Capitalization in Overview must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.leverage_ratio must not be greater than 999999999999999`,
      text: `Leverage ratio in Overview must not be greater than 999999999999999`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.leverage_ratio must be a positive number`,
      text: `Leverage ratio in Overview must be a positive number`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.leverage_ratio must be a number conforming to the specified constraints`,
      text: `Leverage ratio in Overview must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.coverage_ratio must be a positive number`,
      text: `Coverage ratio in Overview must be a positive number`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.coverage_ratio must be a number conforming to the specified constraints`,
      text: `Coverage ratio in Overview must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.gurantor_name must be a string`,
      text: `Gurantor name in Overview must be a string`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.gurantor_jurisdiction must be a string`,
      text: `Gurantor jurisdiction in Overview must be a string`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.gurantor_jurisdiction_country_id must be a positive number`,
      text: `overview.gurantor_jurisdiction_country_id must be a positive number`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.gurantor_jurisdiction_country_id must be a number conforming to the specified constraints`,
      text: `overview.gurantor_jurisdiction_country_id must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.cash_reserved_balance must not be greater than 999999999999999`,
      text: `Cash reserved balance in Overview must not be greater than 999999999999999`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.cash_reserved_balance must be a number conforming to the specified constraints`,
      text: `Cash reserved balance in Overview must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.cash_reserved_balance_currency must be a valid enum value`,
      text: `Cash reserved balance in Overview currency must be a valid enum value`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.obligor_in_compliance must be a string`,
      text: `Obligor in compliance in Overview must be a string`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.obligor_in_compliance_description must be a string`,
      text: `Obligor in compliance description in Overview must be a string`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.seller_discount must be a number conforming to the specified constraints`,
      text: `Seller discount in Overview must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.PayersById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `overview.seller_discount should not be empty`,
      text: `Seller discount in Overview should not be empty`,
    },
    {
      path: RequestPath.PayersSellerRestricted,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `is_sellers_restricted must be a boolean value`,
      text: `is_sellers_restricted must be a boolean value`,
    },
    {
      path: RequestPath.PayersSellerRestricted,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `is_sellers_restricted should not be empty`,
      text: `is_sellers_restricted should not be empty`,
    },
    {
      path: RequestPath.Funders,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `name must be unique`,
      text: `Name must be unique`,
    },
    {
      path: RequestPath.Funders,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `country_id must be a number conforming to the specified constraints`,
      text: `country_id must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.Funders,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `name must be a string`,
      text: `Name must be a string`,
    },
    {
      path: RequestPath.Funders,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `name should not be empty`,
      text: `Name should not be empty`,
    },
    {
      path: RequestPath.Funders,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `display_currency must be longer than or equal to 3 characters`,
      text: `Display currency must be longer than or equal to 3 characters`,
    },
    {
      path: RequestPath.Funders,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `display_currency must be a string`,
      text: `Display currency must be a string`,
    },
    {
      path: RequestPath.Funders,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `representative_id must be a number conforming to the specified constraints`,
      text: `representative_id must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.Funders,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `fund_manager_entity_id must be a number conforming to the specified constraints`,
      text: `fund_manager_entity_id must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `name must be shorter than or equal to 255 characters`,
      text: `Name must be shorter than or equal to 255 characters`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `name must be a string`,
      text: `Name must be a string`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `name should not be empty`,
      text: `Name should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `amount must be a positive number`,
      text: `Amount must be a positive number`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `amount must be a number conforming to the specified constraints`,
      text: `Amount must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `amount should not be empty`,
      text: `Amount should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters should not be empty`,
      text: `Parameters should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.All sellers's elements must be unique`,
      text: `parameters.All sellers's elements must be unique`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.sellers should not be empty`,
      text: `parameters.sellers should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.each value in sellers should not be empty`,
      text: `parameters.each value in sellers should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.sellers must be an array`,
      text: `parameters.sellers must be an array`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.All currencies's elements must be unique`,
      text: `parameters.All currencies's elements must be unique`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.currencies should not be empty`,
      text: `parameters.currencies should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.each value in currencies must be a valid enum value`,
      text: `parameters.each value in currencies must be a valid enum value`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.currencies must be an array`,
      text: `parameters.currencies must be an array`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.currencies should not be empty`,
      text: `parameters.currencies should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.All payers's elements must be unique`,
      text: `parameters.All payers's elements must be unique`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.payers should not be empty`,
      text: `parameters.payers should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.each value in payers should not be empty`,
      text: `parameters.each value in payers should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.payers must be an array`,
      text: `parameters.payers must be an array`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.currency must be a valid enum value`,
      text: `parameters.currency must be a valid enum value`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.currency must be a string`,
      text: `parameters.currency must be a string`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.return_max must not be less than 0`,
      text: `parameters.return_max must not be less than 0`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.return_max must not be greater than 1`,
      text: `parameters.return_max must not be greater than 1`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.return_max must be a positive number`,
      text: `parameters.return_max must be a positive number`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.return_max must be a number conforming to the specified constraints`,
      text: `parameters.return_max must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.return_max should not be empty`,
      text: `parameters.return_max should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.return_min must not be less than -1`,
      text: `parameters.return_min must not be less than -1`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.return_min must not be greater than 1`,
      text: `parameters.return_min must not be greater than 1`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.return_min must be a number conforming to the specified constraints`,
      text: `parameters.return_min must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.return_min should not be empty`,
      text: `parameters.return_min should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.term_max must not be less than 30`,
      text: `parameters.term_max must not be less than 30`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.term_max must not be greater than 10000`,
      text: `parameters.term_max must not be greater than 10000`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.term_max must be a positive number`,
      text: `parameters.term_max must be a positive number`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.term_max must be a number conforming to the specified constraints`,
      text: `parameters.term_max must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.term_max should not be empty`,
      text: `parameters.term_max should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.term_min must not be less than 0`,
      text: `parameters.term_min must not be less than 0`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.term_min must not be greater than 10000`,
      text: `parameters.term_min must not be greater than 10000`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.term_min must be a number conforming to the specified constraints`,
      text: `parameters.term_min must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.term_min should not be empty`,
      text: `parameters.term_min should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.limit_per_payer must not be less than 0`,
      text: `parameters.limit_per_payer must not be less than 0`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.limit_per_payer must not be greater than 1`,
      text: `parameters.limit_per_payer must not be greater than 1`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.limit_per_payer must be a positive number`,
      text: `parameters.limit_per_payer must be a positive number`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.limit_per_payer must be a number conforming to the specified constraints`,
      text: `parameters.limit_per_payer must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.limit_per_payer should not be empty`,
      text: `parameters.limit_per_payer should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.is_deal must be a boolean value`,
      text: `parameters.is_deal must be a boolean value`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.is_deal should not be empty`,
      text: `parameters.is_deal should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.is_hedged must be a boolean value`,
      text: `parameters.is_hedged must be a boolean value`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.is_hedged should not be empty`,
      text: `parameters.is_hedged should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.payout_my_returns must be a boolean value`,
      text: `parameters.payout_my_returns must be a boolean value`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.payout_my_returns should not be empty`,
      text: `parameters.payout_my_returns should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.beneficiary_uuid_for_payout must be a UUID`,
      text: `parameters.beneficiary_uuid_for_payout must be a UUID`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.beneficiary_uuid_for_payout should not be empty`,
      text: `parameters.beneficiary_uuid_for_payout should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.investment_horizon must be a string`,
      text: `parameters.investment_horizon must be a string`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.investment_horizon should not be empty`,
      text: `parameters.investment_horizon should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.wallet_currency must be a valid enum value`,
      text: `parameters.wallet_currency must be a valid enum value`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `parameters.wallet_currency should not be empty`,
      text: `parameters.wallet_currency should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `wallet_uuid must be a UUID`,
      text: `wallet_uuid must be a UUID`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `wallet_uuid should not be empty`,
      text: `wallet_uuid should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `is_auto_reinvest_return must be a boolean value`,
      text: `is_auto_reinvest_return must be a boolean value`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `is_auto_reinvest_return should not be empty`,
      text: `is_auto_reinvest_return should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `refresh_interval must not be less than 0`,
      text: `refresh_interval must not be less than 0`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `refresh_interval must not be greater than 90`,
      text: `refresh_interval must not be greater than 90`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `refresh_interval must be a positive number`,
      text: `refresh_interval must be a positive number`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `refresh_interval must be a number conforming to the specified constraints`,
      text: `refresh_interval must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `is_active must be a boolean value`,
      text: `is_active must be a boolean value`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `name must be shorter than or equal to 255 characters`,
      text: `name must be shorter than or equal to 255 characters`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `name must be a string`,
      text: `name must be a string`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `name should not be empty`,
      text: `name should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `amount must be a positive number`,
      text: `amount must be a positive number`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `amount must be a number conforming to the specified constraints`,
      text: `amount must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `amount should not be empty`,
      text: `amount should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters should not be empty`,
      text: `parameters should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.All sellers's elements must be unique`,
      text: `parameters.All sellers's elements must be unique`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.sellers should not be empty`,
      text: `parameters.sellers should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.each value in sellers should not be empty`,
      text: `parameters.each value in sellers should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.sellers must be an array`,
      text: `parameters.sellers must be an array`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.All currencies's elements must be unique`,
      text: `parameters.All currencies's elements must be unique`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.currencies should not be empty`,
      text: `parameters.currencies should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.each value in currencies must be a valid enum value`,
      text: `parameters.each value in currencies must be a valid enum value`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.currencies must be an array`,
      text: `parameters.currencies must be an array`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.currencies should not be empty`,
      text: `parameters.currencies should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.All payers's elements must be unique`,
      text: `parameters.All payers's elements must be unique`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.payers should not be empty`,
      text: `parameters.payers should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.each value in payers should not be empty`,
      text: `parameters.each value in payers should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.payers must be an array`,
      text: `parameters.payers must be an array`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.currency must be a valid enum value`,
      text: `parameters.currency must be a valid enum value`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.currency must be a string`,
      text: `parameters.currency must be a string`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.return_max must not be less than 0`,
      text: `parameters.return_max must not be less than 0`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.return_max must not be greater than 1`,
      text: `parameters.return_max must not be greater than 1`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.return_max must be a positive number`,
      text: `parameters.return_max must be a positive number`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.return_max must be a number conforming to the specified constraints`,
      text: `parameters.return_max must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.return_max should not be empty`,
      text: `parameters.return_max should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.return_min must not be less than -1`,
      text: `parameters.return_min must not be less than -1`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.return_min must not be greater than 1`,
      text: `parameters.return_min must not be greater than 1`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.return_min must be a number conforming to the specified constraints`,
      text: `parameters.return_min must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.return_min should not be empty`,
      text: `parameters.return_min should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.term_max must not be less than 30`,
      text: `parameters.term_max must not be less than 30`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.term_max must not be greater than 10000`,
      text: `parameters.term_max must not be greater than 10000`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.term_max must be a positive number`,
      text: `parameters.term_max must be a positive number`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.term_max must be a number conforming to the specified constraints`,
      text: `parameters.term_max must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.term_max should not be empty`,
      text: `parameters.term_max should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.term_min must not be less than 0`,
      text: `parameters.term_min must not be less than 0`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.term_min must not be greater than 10000`,
      text: `parameters.term_min must not be greater than 10000`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.term_min must be a number conforming to the specified constraints`,
      text: `parameters.term_min must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.term_min should not be empty`,
      text: `parameters.term_min should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.limit_per_payer must not be less than 0`,
      text: `parameters.limit_per_payer must not be less than 0`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.limit_per_payer must not be greater than 1`,
      text: `parameters.limit_per_payer must not be greater than 1`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.limit_per_payer must be a positive number`,
      text: `parameters.limit_per_payer must be a positive number`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.limit_per_payer must be a number conforming to the specified constraints`,
      text: `parameters.limit_per_payer must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.limit_per_payer should not be empty`,
      text: `parameters.limit_per_payer should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.is_deal must be a boolean value`,
      text: `parameters.is_deal must be a boolean value`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.is_deal should not be empty`,
      text: `parameters.is_deal should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.is_hedged must be a boolean value`,
      text: `parameters.is_hedged must be a boolean value`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.is_hedged should not be empty`,
      text: `parameters.is_hedged should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.payout_my_returns must be a boolean value`,
      text: `parameters.payout_my_returns must be a boolean value`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.payout_my_returns should not be empty`,
      text: `parameters.payout_my_returns should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.beneficiary_uuid_for_payout must be a UUID`,
      text: `parameters.beneficiary_uuid_for_payout must be a UUID`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.beneficiary_uuid_for_payout should not be empty`,
      text: `parameters.beneficiary_uuid_for_payout should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.investment_horizon must be a string`,
      text: `parameters.investment_horizon must be a string`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.investment_horizon should not be empty`,
      text: `parameters.investment_horizon should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.wallet_currency must be a valid enum value`,
      text: `parameters.wallet_currency must be a valid enum value`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `parameters.wallet_currency should not be empty`,
      text: `parameters.wallet_currency should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `wallet_uuid must be a UUID`,
      text: `wallet_uuid must be a UUID`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `wallet_uuid should not be empty`,
      text: `wallet_uuid should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `is_auto_reinvest_return must be a boolean value`,
      text: `is_auto_reinvest_return must be a boolean value`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `is_auto_reinvest_return should not be empty`,
      text: `is_auto_reinvest_return should not be empty`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `refresh_interval must not be less than 0`,
      text: `Refresh interval must not be less than 0`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `refresh_interval must not be greater than 90`,
      text: `Refresh interval must not be greater than 90`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `refresh_interval must be a positive number`,
      text: `Refresh interval must be a positive number`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `refresh_interval must be a number conforming to the specified constraints`,
      text: `Refresh interval must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.InvestmentStrategyById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `is_active must be a boolean value`,
      text: `is_active must be a boolean value`,
    },
    {
      path: RequestPath.SellerPayments,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `currencies_for_calculation should not be empty`,
      text: `currencies_for_calculation should not be empty`,
    },
    {
      path: RequestPath.SellerPayments,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `currencies_for_calculation.current_wallet must be a valid enum value`,
      text: `currencies_for_calculation.current_wallet must be a valid enum value`,
    },
    {
      path: RequestPath.SellerPayments,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `currencies_for_calculation.seller_receiving must be a valid enum value`,
      text: `currencies_for_calculation.seller_receiving must be a valid enum value`,
    },
    {
      path: RequestPath.SellerPayments,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `currencies_for_calculation.display must be a valid enum value`,
      text: `currencies_for_calculation.display must be a valid enum value`,
    },
    {
      path: RequestPath.FunderPortfolioValueData,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `current_wallet must be a valid enum value`,
      text: `current_wallet must be a valid enum value`,
    },
    {
      path: RequestPath.FunderPortfolioValueData,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `display must be a valid enum value`,
      text: `display must be a valid enum value`,
    },
    {
      path: RequestPath.FunderPortfolioValueData,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `seller_receiving must be a valid enum value`,
      text: `seller_receiving must be a valid enum value`,
    },
    {
      path: RequestPath.FunderAllocationData,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `current_wallet must be a valid enum value`,
      text: `current_wallet must be a valid enum value`,
    },
    {
      path: RequestPath.FunderAllocationData,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `display must be a valid enum value`,
      text: `Display must be a valid enum value`,
    },
    {
      path: RequestPath.FunderAllocationData,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `seller_receiving must be a valid enum value`,
      text: `Seller receiving must be a valid enum value`,
    },
    {
      path: RequestPath.FunderRepaymentData,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `current_wallet must be a valid enum value`,
      text: `Current wallet must be a valid enum value`,
    },
    {
      path: RequestPath.FunderRepaymentData,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `display must be a valid enum value`,
      text: `Display must be a valid enum value`,
    },
    {
      path: RequestPath.FunderRepaymentData,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `seller_receiving must be a valid enum value`,
      text: `seller_receiving must be a valid enum value`,
    },
    {
      path: RequestPath.FunderPayerExposures,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `current_wallet must be a valid enum value`,
      text: `current_wallet must be a valid enum value`,
    },
    {
      path: RequestPath.FunderPayerExposures,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `display must be a valid enum value`,
      text: `display must be a valid enum value`,
    },
    {
      path: RequestPath.FunderPayerExposures,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `seller_receiving must be a valid enum value`,
      text: `seller_receiving must be a valid enum value`,
    },
    {
      path: RequestPath.FunderTotals,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `current_wallet must be a valid enum value`,
      text: `current_wallet must be a valid enum value`,
    },
    {
      path: RequestPath.FunderTotals,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `display must be a valid enum value`,
      text: `display must be a valid enum value`,
    },
    {
      path: RequestPath.FunderTotals,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `seller_receiving must be a valid enum value`,
      text: `seller_receiving must be a valid enum value`,
    },
    {
      path: RequestPath.FunderTotals,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `current_wallet must be a valid enum value`,
      text: `current_wallet must be a valid enum value`,
    },
    {
      path: RequestPath.FunderTotals,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `display must be a valid enum value`,
      text: `display must be a valid enum value`,
    },
    {
      path: RequestPath.FunderTotals,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `seller_receiving must be a valid enum value`,
      text: `seller_receiving must be a valid enum value`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `current_wallet must be a valid enum value`,
      text: `current_wallet must be a valid enum value`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `display must be a valid enum value`,
      text: `display must be a valid enum value`,
    },
    {
      path: RequestPath.InvestmentStrategies,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `seller_receiving must be a valid enum value`,
      text: `seller_receiving must be a valid enum value`,
    },
    {
      path: RequestPath.ManagedInvestmentStrategies,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `current_wallet must be a valid enum value`,
      text: `current_wallet must be a valid enum value`,
    },
    {
      path: RequestPath.ManagedInvestmentStrategies,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `display must be a valid enum value`,
      text: `display must be a valid enum value`,
    },
    {
      path: RequestPath.ManagedInvestmentStrategies,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `seller_receiving must be a valid enum value`,
      text: `seller_receiving must be a valid enum value`,
    },
    {
      path: RequestPath.ManagedInvestmentStrategiesById,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `current_wallet must be a valid enum value`,
      text: `current_wallet must be a valid enum value`,
    },
    {
      path: RequestPath.ManagedInvestmentStrategiesById,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `display must be a valid enum value`,
      text: `display must be a valid enum value`,
    },
    {
      path: RequestPath.ManagedInvestmentStrategiesById,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `seller_receiving must be a valid enum value`,
      text: `seller_receiving must be a valid enum value`,
    },
    {
      path: RequestPath.Invoices,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `current_wallet must be a valid enum value`,
      text: `current_wallet must be a valid enum value`,
    },
    {
      path: RequestPath.Invoices,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `display must be a valid enum value`,
      text: `display must be a valid enum value`,
    },
    {
      path: RequestPath.Invoices,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `seller_receiving must be a valid enum value`,
      text: `seller_receiving must be a valid enum value`,
    },
    {
      path: RequestPath.InvoiceById,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `current_wallet must be a valid enum value`,
      text: `current_wallet must be a valid enum value`,
    },
    {
      path: RequestPath.InvoiceById,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `display must be a valid enum value`,
      text: `display must be a valid enum value`,
    },
    {
      path: RequestPath.InvoiceById,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `seller_receiving must be a valid enum value`,
      text: `seller_receiving must be a valid enum value`,
    },
    {
      path: RequestPath.FundManagers,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `current_wallet must be a valid enum value`,
      text: `current_wallet must be a valid enum value`,
    },
    {
      path: RequestPath.FundManagers,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `display must be a valid enum value`,
      text: `display must be a valid enum value`,
    },
    {
      path: RequestPath.FundManagers,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `seller_receiving must be a valid enum value`,
      text: `seller_receiving must be a valid enum value`,
    },
    {
      path: RequestPath.ManagedFunds,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `current_wallet must be a valid enum value`,
      text: `current_wallet must be a valid enum value`,
    },
    {
      path: RequestPath.ManagedFunds,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `display must be a valid enum value`,
      text: `display must be a valid enum value`,
    },
    {
      path: RequestPath.ManagedFunds,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `seller_receiving must be a valid enum value`,
      text: `seller_receiving must be a valid enum value`,
    },
    {
      path: RequestPath.PayerPayments,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `current_wallet must be a valid enum value`,
      text: `current_wallet must be a valid enum value`,
    },
    {
      path: RequestPath.PayerPayments,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `display must be a valid enum value`,
      text: `display must be a valid enum value`,
    },
    {
      path: RequestPath.PayerPayments,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `seller_receiving must be a valid enum value`,
      text: `seller_receiving must be a valid enum value`,
    },
    {
      path: RequestPath.Payers,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `current_wallet must be a valid enum value`,
      text: `current_wallet must be a valid enum value`,
    },
    {
      path: RequestPath.Payers,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `display must be a valid enum value`,
      text: `display must be a valid enum value`,
    },
    {
      path: RequestPath.Payers,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `seller_receiving must be a valid enum value`,
      text: `seller_receiving must be a valid enum value`,
    },
    {
      path: RequestPath.PayerCashOutData,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `current_wallet must be a valid enum value`,
      text: `current_wallet must be a valid enum value`,
    },
    {
      path: RequestPath.PayerCashOutData,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `display must be a valid enum value`,
      text: `display must be a valid enum value`,
    },
    {
      path: RequestPath.PayerCashOutData,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `seller_receiving must be a valid enum value`,
      text: `seller_receiving must be a valid enum value`,
    },
    {
      path: RequestPath.PayerHedgingData,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `current_wallet must be a valid enum value`,
      text: `current_wallet must be a valid enum value`,
    },
    {
      path: RequestPath.PayerHedgingData,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `display must be a valid enum value`,
      text: `display must be a valid enum value`,
    },
    {
      path: RequestPath.PayerHedgingData,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `seller_receiving must be a valid enum value`,
      text: `seller_receiving must be a valid enum value`,
    },
    {
      path: RequestPath.PayerTotals,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `current_wallet must be a valid enum value`,
      text: `current_wallet must be a valid enum value`,
    },
    {
      path: RequestPath.PayerTotals,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `display must be a valid enum value`,
      text: `display must be a valid enum value`,
    },
    {
      path: RequestPath.PayerTotals,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `seller_receiving must be a valid enum value`,
      text: `seller_receiving must be a valid enum value`,
    },
    {
      path: RequestPath.SellerReceivedAndPendingData,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `current_wallet must be a valid enum value`,
      text: `current_wallet must be a valid enum value`,
    },
    {
      path: RequestPath.SellerReceivedAndPendingData,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `display must be a valid enum value`,
      text: `display must be a valid enum value`,
    },
    {
      path: RequestPath.SellerReceivedAndPendingData,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `seller_receiving must be a valid enum value`,
      text: `seller_receiving must be a valid enum value`,
    },
    {
      path: RequestPath.SellerTotals,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `current_wallet must be a valid enum value`,
      text: `current_wallet must be a valid enum value`,
    },
    {
      path: RequestPath.SellerTotals,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `display must be a valid enum value`,
      text: `display must be a valid enum value`,
    },
    {
      path: RequestPath.SellerTotals,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `seller_receiving must be a valid enum value`,
      text: `seller_receiving must be a valid enum value`,
    },
    {
      path: RequestPath.InvoiceWorkflowInvoicesAwaitingConfirmation,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `current_wallet must be a valid enum value`,
      text: `current_wallet must be a valid enum value`,
    },
    {
      path: RequestPath.InvoiceWorkflowInvoicesAwaitingConfirmation,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `display must be a valid enum value`,
      text: `display must be a valid enum value`,
    },
    {
      path: RequestPath.InvoiceWorkflowInvoicesAwaitingConfirmation,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `seller_receiving must be a valid enum value`,
      text: `seller_receiving must be a valid enum value`,
    },
    {
      path: RequestPath.InvoiceWorkflowInvoicesAwaitingConfirmationCheck,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `current_wallet must be a valid enum value`,
      text: `current_wallet must be a valid enum value`,
    },
    {
      path: RequestPath.InvoiceWorkflowInvoicesAwaitingConfirmationCheck,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `display must be a valid enum value`,
      text: `display must be a valid enum value`,
    },
    {
      path: RequestPath.InvoiceWorkflowInvoicesAwaitingConfirmationCheck,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `seller_receiving must be a valid enum value`,
      text: `seller_receiving must be a valid enum value`,
    },
    {
      path: RequestPath.InvoiceWorkflowInvoicesAwaitingRiskConfirmation,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `current_wallet must be a valid enum value`,
      text: `current_wallet must be a valid enum value`,
    },
    {
      path: RequestPath.InvoiceWorkflowInvoicesAwaitingRiskConfirmation,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `display must be a valid enum value`,
      text: `display must be a valid enum value`,
    },
    {
      path: RequestPath.InvoiceWorkflowInvoicesAwaitingRiskConfirmation,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `seller_receiving must be a valid enum value`,
      text: `seller_receiving must be a valid enum value`,
    },
    {
      path: RequestPath.InvoiceWorkflowInvoicesAwaitingRiskConfirmationCheck,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `current_wallet must be a valid enum value`,
      text: `current_wallet must be a valid enum value`,
    },
    {
      path: RequestPath.InvoiceWorkflowInvoicesAwaitingRiskConfirmationCheck,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `display must be a valid enum value`,
      text: `display must be a valid enum value`,
    },
    {
      path: RequestPath.InvoiceWorkflowInvoicesAwaitingRiskConfirmationCheck,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `seller_receiving must be a valid enum value`,
      text: `seller_receiving must be a valid enum value`,
    },
    {
      path: RequestPath.FunderAllocationData,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `each value in statuses must be a valid enum value`,
      text: `each value in statuses must be a valid enum value`,
    },
    {
      path: RequestPath.FunderAllocationData,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `each value in invoice_ids must be a number conforming to the specified constraints`,
      text: `each value in invoice_ids must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.FunderAllocationData,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `invoice_ids must be an array`,
      text: `invoice_ids must be an array`,
    },
    {
      path: RequestPath.FunderPayerExposures,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `each value in statuses must be a valid enum value`,
      text: `each value in statuses must be a valid enum value`,
    },
    {
      path: RequestPath.FunderPayerExposures,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `each value in invoice_ids must be a number conforming to the specified constraints`,
      text: `each value in invoice_ids must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.FunderPayerExposures,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `invoice_ids must be an array`,
      text: `invoice_ids must be an array`,
    },
    {
      path: RequestPath.FunderTotals,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `each value in statuses must be a valid enum value`,
      text: `each value in statuses must be a valid enum value`,
    },
    {
      path: RequestPath.FunderTotals,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `each value in invoice_ids must be a number conforming to the specified constraints`,
      text: `each value in invoice_ids must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.FunderTotals,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `invoice_ids must be an array`,
      text: `invoice_ids must be an array`,
    },
    {
      path: RequestPath.Invoices,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `each value in statuses must be a valid enum value`,
      text: `each value in statuses must be a valid enum value`,
    },
    {
      path: RequestPath.Invoices,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `each value in invoice_ids must be a number conforming to the specified constraints`,
      text: `each value in invoice_ids must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.Invoices,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `invoice_ids must be an array`,
      text: `invoice_ids must be an array`,
    },
    {
      path: RequestPath.PayerCashOutData,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `each value in statuses must be a valid enum value`,
      text: `each value in statuses must be a valid enum value`,
    },
    {
      path: RequestPath.PayerCashOutData,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `each value in invoice_ids must be a number conforming to the specified constraints`,
      text: `each value in invoice_ids must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.PayerCashOutData,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `invoice_ids must be an array`,
      text: `invoice_ids must be an array`,
    },
    {
      path: RequestPath.PayerHedgingData,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `each value in statuses must be a valid enum value`,
      text: `each value in statuses must be a valid enum value`,
    },
    {
      path: RequestPath.PayerHedgingData,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `each value in invoice_ids must be a number conforming to the specified constraints`,
      text: `each value in invoice_ids must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.PayerHedgingData,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `invoice_ids must be an array`,
      text: `invoice_ids must be an array`,
    },
    {
      path: RequestPath.PayerTotals,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `each value in statuses must be a valid enum value`,
      text: `each value in statuses must be a valid enum value`,
    },
    {
      path: RequestPath.PayerTotals,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `each value in invoice_ids must be a number conforming to the specified constraints`,
      text: `each value in invoice_ids must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.PayerTotals,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `invoice_ids must be an array`,
      text: `invoice_ids must be an array`,
    },
    {
      path: RequestPath.SellerTotals,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `each value in statuses must be a valid enum value`,
      text: `each value in statuses must be a valid enum value`,
    },
    {
      path: RequestPath.SellerTotals,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `each value in invoice_ids must be a number conforming to the specified constraints`,
      text: `each value in invoice_ids must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.SellerTotals,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `invoice_ids must be an array`,
      text: `invoice_ids must be an array`,
    },
    {
      path: RequestPath.InvoiceWorkflowInvoicesAwaitingConfirmation,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `each value in statuses must be a valid enum value`,
      text: `each value in statuses must be a valid enum value`,
    },
    {
      path: RequestPath.InvoiceWorkflowInvoicesAwaitingConfirmation,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `each value in invoice_ids must be a number conforming to the specified constraints`,
      text: `each value in invoice_ids must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.InvoiceWorkflowInvoicesAwaitingConfirmation,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `invoice_ids must be an array`,
      text: `invoice_ids must be an array`,
    },
    {
      path: RequestPath.InvoiceWorkflowInvoicesAwaitingConfirmationCheck,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `each value in statuses must be a valid enum value`,
      text: `each value in statuses must be a valid enum value`,
    },
    {
      path: RequestPath.InvoiceWorkflowInvoicesAwaitingConfirmationCheck,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `each value in invoice_ids must be a number conforming to the specified constraints`,
      text: `each value in invoice_ids must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.InvoiceWorkflowInvoicesAwaitingConfirmationCheck,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `invoice_ids must be an array`,
      text: `invoice_ids must be an array`,
    },
    {
      path: RequestPath.InvoiceWorkflowInvoicesAwaitingRiskConfirmation,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `each value in statuses must be a valid enum value`,
      text: `each value in statuses must be a valid enum value`,
    },
    {
      path: RequestPath.InvoiceWorkflowInvoicesAwaitingRiskConfirmation,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `each value in invoice_ids must be a number conforming to the specified constraints`,
      text: `each value in invoice_ids must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.InvoiceWorkflowInvoicesAwaitingRiskConfirmation,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `invoice_ids must be an array`,
      text: `invoice_ids must be an array`,
    },
    {
      path: RequestPath.InvoiceWorkflowInvoicesAwaitingRiskConfirmationCheck,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `each value in statuses must be a valid enum value`,
      text: `each value in statuses must be a valid enum value`,
    },
    {
      path: RequestPath.InvoiceWorkflowInvoicesAwaitingRiskConfirmationCheck,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `each value in invoice_ids must be a number conforming to the specified constraints`,
      text: `each value in invoice_ids must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.InvoiceWorkflowInvoicesAwaitingRiskConfirmationCheck,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `invoice_ids must be an array`,
      text: `invoice_ids must be an array`,
    },
    {
      path: RequestPath.FunderById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `name must be unique`,
      text: `Name must be unique`,
    },
    {
      path: RequestPath.FunderById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `representative_id must be a number conforming to the specified constraints`,
      text: `representative_id must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.FunderById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `fund_manager_entity_id must be a number conforming to the specified constraints`,
      text: `fund_manager_entity_id must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.FunderById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `country_id must be a number conforming to the specified constraints`,
      text: `country_id must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `name must be unique`,
      text: `name must be unique`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `registration_no must be unique`,
      text: `registration_no must be unique`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `industry_id must be a number conforming to the specified constraints`,
      text: `industry_id must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `industry_id should not be empty`,
      text: `industry_id should not be empty`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `Industry doesn't exist`,
      text: `Industry doesn't exist`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `activity_years must be a string`,
      text: `activity_years must be a string`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `prod_and_service_id_list must be an array`,
      text: `prod_and_service_id_list must be an array`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `prod_and_service_id_list should not be empty`,
      text: `prod_and_service_id_list should not be empty`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `name must be shorter than or equal to 255 characters`,
      text: `Name must be shorter than or equal to 255 characters`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `name must be a string`,
      text: `Name must be a string`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `name should not be empty`,
      text: `Name should not be empty`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `gln must be longer than or equal to 13 characters`,
      text: `gln must be longer than or equal to 13 characters`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `gln must be a string`,
      text: `gln must be a string`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `registration_no must be shorter than or equal to 255 characters`,
      text: `Registration no must be shorter than or equal to 255 characters`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `registration_no must be a string`,
      text: `Registration no must be a string`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `registration_no should not be empty`,
      text: `Registration no should not be empty`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `display_currency must be a valid enum value`,
      text: `display_currency must be a valid enum value`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `display_currency must be shorter than or equal to 3 characters`,
      text: `Display currency must be shorter than or equal to 3 characters`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `display_currency must be a string`,
      text: `Display currency must be a string`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `display_currency should not be empty`,
      text: `Display currency should not be empty`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `description must be shorter than or equal to 255 characters`,
      text: `Description must be shorter than or equal to 255 characters`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `description must be a string`,
      text: `Description must be a string`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `yearly_turnover must not be greater than 99999999999999`,
      text: `Yearly turnover must not be greater than 99999999999999`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `yearly_turnover must be a number conforming to the specified constraints`,
      text: `Yearly turnover must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `is_edi_traded must be a boolean value`,
      text: `is_edi_traded must be a boolean value`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `is_edi_traded should not be empty`,
      text: `Is edi traded should not be empty`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `country_id must be a number conforming to the specified constraints`,
      text: `country_id must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `country_id should not be empty`,
      text: `country_id should not be empty`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `country must be a string`,
      text: `Country must be a string`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `country should not be empty`,
      text: `Country should not be empty`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `autosell_available must be a boolean value`,
      text: `autosell_available must be a boolean value`,
    },
    {
      path: RequestPath.SellerById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `autosell_available should not be empty`,
      text: `Autosell available should not be empty`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `name must be unique`,
      text: `Name must be unique`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `registration_no must be unique`,
      text: `Registration no must be unique`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `industry_id must be a number conforming to the specified constraints`,
      text: `industry_id must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `industry_id should not be empty`,
      text: `Industry id should not be empty`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `Industry doesn't exist`,
      text: `Industry doesn't exist`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `activity_years must be a string`,
      text: `Activity years must be a string`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `prod_and_service_id_list must be an array`,
      text: `prod_and_service_id_list must be an array`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `prod_and_service_id_list should not be empty`,
      text: `prod_and_service_id_list should not be empty`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `name must be shorter than or equal to 255 characters`,
      text: `Name must be shorter than or equal to 255 characters`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `name must be a string`,
      text: `Name must be a string`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `name should not be empty`,
      text: `Name should not be empty`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `gln must be longer than or equal to 13 characters`,
      text: `gln must be longer than or equal to 13 characters`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `gln must be a string`,
      text: `gln must be a string`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `registration_no must be shorter than or equal to 255 characters`,
      text: `Registration no must be shorter than or equal to 255 characters`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `registration_no must be a string`,
      text: `Registration no must be a string`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `registration_no should not be empty`,
      text: `Registration no should not be empty`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `display_currency must be a valid enum value`,
      text: `display_currency must be a valid enum value`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `display_currency must be shorter than or equal to 3 characters`,
      text: `Display currency must be shorter than or equal to 3 characters`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `display_currency must be a string`,
      text: `Display currency must be a string`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `display_currency should not be empty`,
      text: `Display currency should not be empty`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `description must be shorter than or equal to 255 characters`,
      text: `Description must be shorter than or equal to 255 characters`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `description must be a string`,
      text: `Description must be a string`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `yearly_turnover must not be greater than 99999999999999`,
      text: `Yearly turnover must not be greater than 99999999999999`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `yearly_turnover must be a number conforming to the specified constraints`,
      text: `Yearly turnover must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `is_edi_traded must be a boolean value`,
      text: `is_edi_traded must be a boolean value`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `is_edi_traded should not be empty`,
      text: `Is edi traded should not be empty`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `country_id must be a number conforming to the specified constraints`,
      text: `country_id must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `country_id should not be empty`,
      text: `country_id should not be empty`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `country must be a string`,
      text: `Country must be a string`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `country should not be empty`,
      text: `Country should not be empty`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `autosell_available must be a boolean value`,
      text: `autosell_available must be a boolean value`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `autosell_available should not be empty`,
      text: `Autosell available should not be empty`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `email must be an email`,
      text: `Email must be an email`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `email must be a string`,
      text: `Email must be a string`,
    },
    {
      path: RequestPath.Sellers,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `email should not be empty`,
      text: `Email should not be empty`,
    },
    {
      path: RequestPath.FunderAllocationData,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `allocation_type must be a valid enum value`,
      text: `allocation_type must be a valid enum value`,
    },
    {
      path: RequestPath.FunderAllocationData,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `allocation_type should not be empty`,
      text: `Allocation type should not be empty`,
    },
    {
      path: RequestPath.InvoiceById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `Payer doesn't exist`,
      text: `Payer doesn't exist`,
    },
    {
      path: RequestPath.InvoiceById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `Payer doesn't exist`,
      text: `Payer doesn't exist`,
    },
    {
      path: RequestPath.InvoiceById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `Seller doesn't exist`,
      text: `Seller doesn't exist`,
    },
    {
      path: RequestPath.InvoiceById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `seller_id must be a number conforming to the specified constraints`,
      text: `seller_id must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.InvoiceById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `seller_id should not be empty`,
      text: `seller_id should not be empty`,
    },
    {
      path: RequestPath.InvoiceById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `invoice_no must be unique`,
      text: `Invoice no must be unique`,
    },
    {
      path: RequestPath.InvoiceById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `delivered_goods must be shorter than or equal to 255 characters`,
      text: `Delivered goods must be shorter than or equal to 255 characters`,
    },
    {
      path: RequestPath.InvoiceById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `delivered_goods must be a string`,
      text: `Delivered goods must be a string`,
    },
    {
      path: RequestPath.InvoiceById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `payer_id must be a number conforming to the specified constraints`,
      text: `payer_id must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.InvoiceById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `payer_id should not be empty`,
      text: `payer_id should not be empty`,
    },
    {
      path: RequestPath.InvoiceById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `purchase_order_mimetype must be MIME type format`,
      text: `purchase_order_mimetype must be MIME type format`,
    },
    {
      path: RequestPath.InvoiceById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `purchase_order_mimetype must be a string`,
      text: `purchase_order_mimetype must be a string`,
    },
    {
      path: RequestPath.InvoiceById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `purchase_order_size must not be greater than 50000000`,
      text: `Purchase order size must not be greater than 50000000`,
    },
    {
      path: RequestPath.InvoiceById,
      method: RequestMethod.PATCH,
      statusCode: 400,
      message: `purchase_order_size must be a number conforming to the specified constraints`,
      text: `purchase_order_size must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.PayerPayments,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `invoice_ids should not be empty`,
      text: `invoice_ids should not be empty`,
    },
    {
      path: RequestPath.PayerPayments,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `each value in invoice_ids must be a number conforming to the specified constraints`,
      text: `each value in invoice_ids must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.PayerPayments,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `invoice_ids must be an array`,
      text: `invoice_ids must be an array`,
    },
    {
      path: RequestPath.PayerPaymentsRepaymentReference,
      method: RequestMethod.GET,
      statusCode: 400,
      message: `repayment_reference must be shorter than or equal to 255 characters`,
      text: `Repayment reference must be shorter than or equal to 255 characters`,
    },
    {
      path: RequestPath.PayerPaymentsRepaymentReference,
      method: RequestMethod.DELETE,
      statusCode: 400,
      message: `repayment_reference must be shorter than or equal to 255 characters`,
      text: `Repayment reference must be shorter than or equal to 255 characters`,
    },
    {
      path: RequestPath.Invoices,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `Seller doesn't exist`,
      text: `Seller doesn't exist`,
    },
    {
      path: RequestPath.Invoices,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `Payer doesn't exist`,
      text: `Payer doesn't exist`,
    },
    {
      path: RequestPath.Invoices,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `invoice_no must be unique`,
      text: `Invoice no must be unique`,
    },
    {
      path: RequestPath.Invoices,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `seller_id must be a number conforming to the specified constraints`,
      text: `seller_id must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.Invoices,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `seller_id should not be empty`,
      text: `seller_id should not be empty`,
    },
    {
      path: RequestPath.Invoices,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `payer_id must be a number conforming to the specified constraints`,
      text: `payer_id must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.Invoices,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `payer_id should not be empty`,
      text: `payer_id should not be empty`,
    },
    {
      path: RequestPath.Invoices,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `delivered_goods must be shorter than or equal to 255 characters`,
      text: `Delivered goods must be shorter than or equal to 255 characters`,
    },
    {
      path: RequestPath.Invoices,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `delivered_goods must be a string`,
      text: `Delivered goods must be a string`,
    },
    {
      path: RequestPath.Invoices,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `currency must be a valid enum value`,
      text: `Currency must be a valid enum value`,
    },
    {
      path: RequestPath.Invoices,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `currency must be shorter than or equal to 3 characters`,
      text: `Currency must be shorter than or equal to 3 characters`,
    },
    {
      path: RequestPath.Invoices,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `currency must be a string`,
      text: `Currency must be a string`,
    },
    {
      path: RequestPath.Invoices,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `currency should not be empty`,
      text: `Currency should not be empty`,
    },
    {
      path: RequestPath.Invoices,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `purchase_order_mimetype must be MIME type format`,
      text: `purchase_order_mimetype must be MIME type format`,
    },
    {
      path: RequestPath.Invoices,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `purchase_order_mimetype must be a string`,
      text: `purchase_order_mimetype must be a string`,
    },
    {
      path: RequestPath.Invoices,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `purchase_order_size must not be greater than 50000000`,
      text: `purchase_order_size must not be greater than 50000000`,
    },
    {
      path: RequestPath.Invoices,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `purchase_order_size must be a number conforming to the specified constraints`,
      text: `purchase_order_size must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.Invoices,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `invoice_no must be shorter than or equal to 255 characters`,
      text: `Invoice no must be shorter than or equal to 255 characters`,
    },
    {
      path: RequestPath.Invoices,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `invoice_no must be a string`,
      text: `Invoice no must be a string`,
    },
    {
      path: RequestPath.Invoices,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `invoice_no should not be empty`,
      text: `Invoice no should not be empty`,
    },
    {
      path: RequestPath.Invoices,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `amount must not be greater than 999999999`,
      text: `Amount must not be greater than 999999999`,
    },
    {
      path: RequestPath.Invoices,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `amount must be a number conforming to the specified constraints`,
      text: `Amount must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.Invoices,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `amount should not be empty`,
      text: `Amount should not be empty`,
    },
    {
      path: RequestPath.InvoicesBatch,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `invoices_excel_file should not be empty`,
      text: `Invoices excel file should not be empty`,
    },
    {
      path: RequestPath.InvoicesBatch,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `invoices_excel_file_mimetype must be a valid enum value`,
      text: `invoices_excel_file_mimetype must be a valid enum value`,
    },
    {
      path: RequestPath.InvoicesBatch,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `invoices_excel_file_mimetype should not be empty`,
      text: `invoices_excel_file_mimetype should not be empty`,
    },
    {
      path: RequestPath.InvoicesBatch,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `invoices_excel_file_size must not be greater than 28800`,
      text: `invoices_excel_file_size must not be greater than 28800`,
    },
    {
      path: RequestPath.InvoicesBatch,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `invoices_excel_file_size must be a number conforming to the specified constraints`,
      text: `invoices_excel_file_size must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.InvoicesBatch,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `invoices_excel_file_size should not be empty`,
      text: `invoices_excel_file_size should not be empty`,
    },
    {
      path: RequestPath.PayerPaymentsAttachManually,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `invoices_excel_file should not be empty`,
      text: `Invoices excel file should not be empty`,
    },
    {
      path: RequestPath.PayerPaymentsAttachManually,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `invoices_excel_file_mimetype must be a valid enum value`,
      text: `invoices_excel_file_mimetype must be a valid enum value`,
    },
    {
      path: RequestPath.PayerPaymentsAttachManually,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `invoices_excel_file_mimetype should not be empty`,
      text: `invoices_excel_file_mimetype should not be empty`,
    },
    {
      path: RequestPath.PayerPaymentsAttachManually,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `invoices_excel_file_size must not be greater than 28800`,
      text: `invoices_excel_file_size must not be greater than 28800`,
    },
    {
      path: RequestPath.PayerPaymentsAttachManually,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `invoices_excel_file_size must be a number conforming to the specified constraints`,
      text: `invoices_excel_file_size must be a number conforming to the specified constraints`,
    },
    {
      path: RequestPath.PayerPaymentsAttachManually,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `invoices_excel_file_size should not be empty`,
      text: `invoices_excel_file_size should not be empty`,
    },
    {
      path: RequestPath.RegenerateOrderForms,
      method: RequestMethod.POST,
      statusCode: 400,
      message: `no_default_beneficiary_found`,
      text: `Default beneficiary not found`,
    },
  ];
}
